<template>
    <div :class="styling">
        <SplitTests feature-key="Fsst-sense_of_urgency_labels"
                    fallback-variation="sense-of-urgency-off">
            <template #sense-of-urgency-off>
                <div v-if="selectedVariant.availabilityLabel?.label"
                     class="pt-4 pb-3 px-4 md:pt-5 md:pb-4 md:px-8 leading-8 text-9 sm:text-8 md:text-10 text-brown-70 tracking-normal font-brandon-regular uppercase"
                     :class="bgColor">
                    {{ selectedVariant.availabilityLabel?.label }}
                </div>
            </template>
            <template #sense-of-urgency-on>
                <div v-if="labelToShow"
                     class="pt-4 pb-3 px-4 md:pt-5 md:pb-4 md:px-8 leading-8 text-9 sm:text-8 md:text-10 text-brown-70 tracking-normal font-brandon-regular uppercase"
                     :class="bgColor">
                    {{ labelToShow }}
                </div>
            </template>
        </SplitTests>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { v4 } from '@/types/serverContract';
import SplitTests from '../growthbook/SplitTests.vue';

const props = defineProps<{
    selectedVariant: v4.Products.VariantSimple
    styles?: string
}>();

const bgColor = computed(() => {
    switch (props.selectedVariant?.availabilityLabel?.availabilityType) {
        case v4.Products.AvailabilityType.Green:
            return 'bg-green-5';

        case v4.Products.AvailabilityType.Red:
            return 'bg-red-10';

        default:
            return 'bg-sand-30';
    }
});

const styling = ref(props.styles || 'absolute left-0 bottom-0 pointer-events-none');

const labelToShow = computed(()=> {
    return props.selectedVariant?.senseOfUrgencyLabel?.label ?? props.selectedVariant.availabilityLabel?.label;
});

</script>
