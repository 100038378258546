import { SpacingModel } from '@/types/serverContract';
import { contentSpacingClassTemplates, contentSpacingClassTemplatesEightPxgrid } from './contentSpacingConfig';

export function styleFunctionsForThemedContent(textColor: string): string {
    // default to theme-1 if not set
    return textColor ? `text-${textColor}` : 'text-theme-1';
}

export function styleFunctionsForThemedContentAlternate(textColor: string): string {
    // default to text-brown if not set
    return textColor && textColor === 'theme-2' ? 'text-white-500' : 'text-brown';
}

export function getClassForContentSpacing(spacing: SpacingModel, useEightPxGrid: boolean = false): string[] {
    const template = useEightPxGrid ? contentSpacingClassTemplatesEightPxgrid : contentSpacingClassTemplates;
    const spacingLowerCased = Object.fromEntries(
        Object.entries(spacing).map(([key, val]) => [key, val.toLowerCase()])
    );
    const defaultSize = useEightPxGrid ? 'small' : 'medium';

    const classes: string[] = [];
    const topSpacingValue = template[spacingLowerCased && spacingLowerCased.top in template ? spacingLowerCased.top : defaultSize];
    const topClass = topSpacingValue.split('{0}').join('t');
    classes.push(topClass);
    const bottomSpacingValue = template[spacingLowerCased && spacingLowerCased.bottom in template ? spacingLowerCased.bottom : defaultSize];
    const bottomClass = bottomSpacingValue.split('{0}').join('b');
    classes.push(bottomClass);
    return classes;
}
