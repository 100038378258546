<template>
    <div ref="videoElement"></div>
</template>

<script setup lang="ts">
import Player from '@vimeo/player';
import { onMounted, ref } from 'vue';
import { VimeoPlayerOptions } from './vimeoPlayerOptions';

const props = defineProps<{
    videoId: string,
    options?: VimeoPlayerOptions
}>();

const videoElement = ref(undefined);
onMounted(() => {
    new Player(videoElement.value, {id: props.videoId, ...props.options});
});

</script>