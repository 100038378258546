import { pageResolver } from '@/core/spa/componentResolver.service';

import DefaultPage from './DefaultPage.vue';
import ProductDetailsPage from './ProductDetailsPage.vue';
import CategoryDetailsPage from './CategoryDetailsPage.vue';
import PageNotFound404Page from '@/project/spa/pages/PageNotFound404Page.vue';
import { defineAsyncComponent } from 'vue';
const ChangePasswordPage = defineAsyncComponent(() => import('@/project/spa/pages/ChangePasswordPage.vue'));
const MyPagePage = defineAsyncComponent(() => import('@/project/spa/pages/MyPagePage.vue'));
const MyPersonalInformationPage = defineAsyncComponent(() => import('@/project/spa/pages/MyPersonalInformationPage.vue'));
const MyFavoriteProductsPage = defineAsyncComponent(() => import('@/project/spa/pages/MyFavoriteProductsPage.vue'));
const MyFavoriteContentPage = defineAsyncComponent(() => import('@/project/spa/pages/MyFavoriteContentPage.vue'));
const MyOrdersPage = defineAsyncComponent(() => import('@/project/spa/pages/MyOrdersPage.vue'));
const MyOrderDetailsPage = defineAsyncComponent(() => import('@/project/spa/pages/MyOrderDetailsPage.vue'));
const ExternalAccountPage = defineAsyncComponent(() => import('@/project/spa/pages/ExternalAccountPage.vue'));
const CheckoutPage = defineAsyncComponent(() => import('@/project/spa/pages/CheckoutPage.vue'));
const ReceiptPage = defineAsyncComponent(() => import('@/project/spa/pages/ReceiptPage.vue'));
const TopicDetailsPage = defineAsyncComponent(() => import('./TopicDetailsPage.vue'));
const FaqPage = defineAsyncComponent(() => import('./FaqPage.vue'));
const CategoryListPage = defineAsyncComponent(() => import('./CategoryListPage.vue'));
const SearchResultBatchedSearch = defineAsyncComponent(() => import('./SearchResultBatchedSearch.vue'));
const StoreListPage = defineAsyncComponent(() => import('@/project/spa/pages/StoreListPage.vue'));
const StoreDetailsPage = defineAsyncComponent(() => import('@/project/spa/pages/StoreDetailsPage.vue'));
const CookiePolicyPage = defineAsyncComponent(() => import('./CookiePolicyPage.vue'));
const ArticlePage = defineAsyncComponent(() => import('./ArticlePage.vue'));
const DiyListPage = defineAsyncComponent(() => import('./DiyListPage.vue'));
const DiyDetailsPage = defineAsyncComponent(() => import('@/project/spa/pages/DiyDetailsPage.vue'));
const RoomDetailsPage = defineAsyncComponent(() => import('@/project/spa/pages/RoomDetailsPage.vue'));
const RoomListPage = defineAsyncComponent(() => import('@/project/spa/pages/RoomListPage.vue'));
const LegalPage = defineAsyncComponent(() => import('@/project/spa/pages/LegalPage.vue'));
const MomentFrontPage = defineAsyncComponent(() => import('@/project/spa/pages/MomentFrontPage.vue'));
const MomentDetailsPage = defineAsyncComponent(() => import('@/project/spa/pages/MomentDetailsPage.vue'));
const MomentListPage = defineAsyncComponent(() => import('@/project/spa/pages/MomentListPage.vue'));
const MomentThemeDetails = defineAsyncComponent(() => import('@/project/spa/pages/MomentThemeDetails.vue'));
const HowToListPage = defineAsyncComponent(() => import('@/project/spa/pages/HowToListPage.vue'));
const MomentInspirationalPage = defineAsyncComponent(() => import('@/project/spa/pages/MomentInspirationalPage.vue'));

pageResolver.registerFallback(DefaultPage);
pageResolver.register('home', DefaultPage);
pageResolver.register('article', ArticlePage);
pageResolver.register('productDetails', ProductDetailsPage);
pageResolver.register('categoryDetails', CategoryDetailsPage);
pageResolver.register('brandPage', CategoryDetailsPage);
pageResolver.register('roomDetails', RoomDetailsPage);
pageResolver.register('categoryList', CategoryListPage);
pageResolver.register('topic', TopicDetailsPage);
pageResolver.register('faq', FaqPage);
pageResolver.register('diyList', DiyListPage);
pageResolver.register('diyDetails', DiyDetailsPage);
pageResolver.register('searchResultBatchedSearch', SearchResultBatchedSearch);
pageResolver.register('storeList', StoreListPage);
pageResolver.register('storeDetails', StoreDetailsPage);
pageResolver.register('pageNotFound', PageNotFound404Page);
pageResolver.register('myPage', MyPagePage);
pageResolver.register('myFavoriteProducts', MyFavoriteProductsPage);
pageResolver.register('myFavoriteContent', MyFavoriteContentPage);
pageResolver.register('myOrders', MyOrdersPage);
pageResolver.register('myOrderDetails', MyOrderDetailsPage);
pageResolver.register('cookiePolicy', CookiePolicyPage);
pageResolver.register('checkout', CheckoutPage);
pageResolver.register('receipt', ReceiptPage);
pageResolver.register('changePassword', ChangePasswordPage);
pageResolver.register('myPersonalInformation', MyPersonalInformationPage);
pageResolver.register('externalAccount', ExternalAccountPage);
pageResolver.register('momentFrontpage', MomentFrontPage);
pageResolver.register('momentDetails', MomentDetailsPage);
pageResolver.register('roomList', RoomListPage);
pageResolver.register('legalPage', LegalPage);
pageResolver.register('momentList', MomentListPage);
pageResolver.register('howToList', HowToListPage);
pageResolver.register('themeDetails', MomentThemeDetails);
pageResolver.register('momentInspirationalPage', MomentInspirationalPage);
