<template>
    <div class="c-text-input"
         :class="customClassesAndExtra">
        <label v-if="!label && showErrorComputed && error && !showErrorBelow"
               :for="id"
               class="c-text-input__label c-text-input--error opacity-100 mt-15 text-left font-light">
            {{ error }}
        </label>
        <div class="relative flex flex-col"
             :class="{
                 'c-text-input--show-label': hasFocus || hasValue(modelValue) || labelPasswordCharCount || showErrorComputed && error,
                 'c-text-input--focus': hasFocus,
                 'c-text-input--disabled': isDisabled,
                 'c-text-input--error': showErrorComputed && error
             }">
            <label v-if="label || labelPasswordCharCount"
                   class="c-text-input__label order-0 flex"
                   :for="id">
                <div v-if="label"
                     :class="{'c-text-input--error': showErrorComputed && error}">
                    {{ showErrorComputed && error ? error : label }}
                </div>
                <div v-if="labelPasswordCharCount"
                     class="c-text-input--password-min-label ml-auto">
                    {{ labelPasswordCharCount }}
                </div>
            </label>
            <div class="c-text-input__input-wrap flex"
                 :class="{
                     [customClasses]: customClasses,
                     'c-text-input--input-not-empty': hasValue(modelValue),
                     'c-text-input--disabled': isDisabled,
                     'c-text-input--error': showErrorComputed && error,
                     'c-text-input--has-clear': hasClearButton,
                     'c-text-input--placeholder-shown': placeholderShown,
                 }">
                <label v-if="iconName"
                       :for="id"
                       class="c-text-input__icon">
                    <c-icon :name="iconName"
                            width="20"
                            height="15"/>
                </label>
                <label v-if="valuePrefix"
                       :for="id"
                       class="c-text-input__value-prefix">{{ valuePrefix }}</label>
                <input
                    :id="id"
                    ref="inputRef"
                    v-prohibit-zoom
                    class="c-text-input__input order-1"
                    :class="inputClasses"
                    :name="name"
                    :value="modelValue"
                    :placeholder="placeholder"
                    :autocomplete="autocomplete"
                    v-bind="inputAttributes"
                    :type="computedType"
                    :required="required"
                    @input="onInput"
                    @blur="onBlur"
                    @focus="onFocus">
            </div>
            <transition mode="out-in"
                        enter-active-class="animated fadeIn u-anim-dur-200"
                        leave-active-class="animated fadeOut u-anim-dur-200">
                <button v-if="hasClearButton && hasValue(modelValue) && !allowPasswordSneakyPeek"
                        type="button"
                        class="c-text-input__clear h-15 w-15 rounded-full right-20 y-center flex items-center justify-center absolute bg-grey md:w-20 md:h-20"
                        :class="{ 'mt-8' : label }"
                        :aria-label="$translate('search.Overlay.ClearTermField')"
                        tabindex="-1"
                        @click.prevent="clearInput">
                    <c-icon name="close"
                            width="6"
                            height="6"/>
                </button>
                <button v-else-if="tooltipText"
                        ref="tooltipTriggerRef"
                        v-tracking="{ 'trigger': 'click', 'event' : 'overlay', 'overlayName': tooltipText }"
                        type="button"
                        class="absolute flex font-brandon items-center justify-center md:h-20 md:w-20 right-20 text-13 y-center"
                        :class="{ 'mt-8' : label }"
                        @click="shopTooltipChange(true)">
                    ?
                </button>
            </transition>
            <button v-if="allowPasswordSneakyPeek"
                    type="button"
                    class="h-15 md:h-20 px-4 right-20 y-center flex items-center justify-center absolute text-10 uppercase opacity-75 font-brandon font-light tracking-medium"
                    :class="{ 'mt-8' : label }"
                    tabindex="-1"
                    @click.prevent="togglePasswordView">
                <transition mode="out-in"
                            leave-active-class="animated fadeOut u-anim-dur-300"
                            enter-active-class="animated fadeIn u-anim-dur-300">
                    <c-icon v-if="!showPassword"
                            :aria-label="$translate('login.CreateUser.Fields.Password.ShowPassword')"
                            name="show"
                            width="20"/>
                    <c-icon v-else
                            name="hide"
                            :aria-label="$translate('login.CreateUser.Fields.Password.HidePassword')"
                            width="20"/>
                </transition>
            </button>
        </div>
        <label v-if="!label && showErrorComputed && error && showErrorBelow"
               :for="id"
               class="opacity-100 text-left block mt-10 ml-8 text-red-600">
            {{ error }}
        </label>
        <div v-if="description"
             class="c-text-input__description text-10 leading-16 font-gibson font-pale">
            {{ description }}
        </div>
        <div v-if="tooltipText"
             ref="tooltipContainerRef"
             class="absolute right-1/2 lg:right-0 top-0 lg:-top-40"/>
        <div v-if="tooltipText">
            <breakpoints>
                <template #max-md>
                    <VDropdown 
                        trigger="manual"
                        :shown="tooltipOpen"
                        placement="bottom-right"
                        :container="tooltipContainerRef"
                        :boundary="tooltipBounderyElement"
                        popover-class="tooltip--in-portal z-overlay md:w-400 lg:max-w-250 xl:max-w-310"
                        @update:shown="shopTooltipChange">
                        <template #popper>
                            <div v-html="tooltipText"/>
                        </template>
                    </VDropdown>
                </template>
                <template #min-lg>
                    <VDropdown 
                        trigger="manual"
                        :shown="tooltipOpen"
                        placement="right"
                        :container="tooltipContainerRef"
                        :boundary="tooltipBounderyElement"
                        popover-class="tooltip--in-portal z-overlay md:w-400 lg:max-w-250 xl:max-w-310"
                        @update:shown="shopTooltipChange">
                        <template #popper>
                            <div v-html="tooltipText"/>
                        </template>
                    </VDropdown>
                </template>
            </breakpoints>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, useAttrs, nextTick, toRef } from 'vue';
import translateFilter from '@/core/translation/translate.filter';
import useFieldValidation from '@/core/form/useFieldValidation';
import { z } from 'zod';

const modelValue = defineModel<string | number | undefined>();
const props = withDefaults(defineProps<{
    label?: string;
    name: string;
    type?: string;
    placeholder?: string;
    constraints: z.Schema<any>,
    description?: string;
    customClasses?: string;
    inputClasses?: string;
    showClear?: boolean;
    showError?: boolean;
    autofocus?: boolean;
    selectTextOnFocus?: boolean;
    allowPasswordSneakyPeek?: boolean;
    valuePrefix?: string;
    iconName?: string;
    tooltipText?: string;
    showErrorBelow?: boolean;
    minimumPasswordCharCount?: number;
    autocomplete?: string;
}>(), {
    label: '',
    type: 'text',
    placeholder: '',
    description: '',
    customClasses: '',
    inputClasses: '',
    showClear: true,
    showError: true,
    selectTextOnFocus: false,
    allowPasswordSneakyPeek: false,
    valuePrefix: '',
    iconName: '',
    tooltipText: '',
    minimumPasswordCharCount: undefined,
    autocomplete: undefined
});

const hasFocus = ref(false);
const showPassword = ref(false);
const tooltipOpen = ref(false);
const tooltipBounderyElement = document.querySelector('.c-teleport-overlay .c-teleport-overlay__content-wrapper') || document.body;
const inputRef = ref<HTMLInputElement | null>(null);
const tooltipTriggerRef = ref<HTMLButtonElement | null>(null);
const tooltipContainerRef = ref<HTMLDivElement | null>(null);

const $attrs = useAttrs();
const emit = defineEmits(['update:modelValue', 'update:error', 'blur']);

const { field, validate } = useFieldValidation(props.name, props.label, toRef(props, 'constraints'), toRef(modelValue));

const { class: inheritedClass, ...inputAttributes } = $attrs;

defineExpose({ validate, field });

const showErrorComputed = computed(() => {
    return props.showError || field.error;
});

const customClassesAndExtra = computed(() => {
    return props.customClasses + (field.error ? 'has-validation-error' : '') + (inheritedClass || '');
});

const id = computed(() => {
    return props.name + new Date().valueOf();
});

const error = computed(() => {
    return field.error;
});

const valid = computed(() => {
    if (!field.validated) {
        return false;
    }
    return field.valid;
});

const required = computed(() => {
    return field.required;
});

const placeholderShown = computed(() => {
    return !modelValue.value && props.placeholder;
});

watch(() => props.autofocus, (value) => {
    if (value) {
        try {
            setTimeout(() => focus(), 400);
        } catch (e) {} // Safari sometimes
    }
}, { immediate: true });

watch(() => error.value, (value) => {
    emit('update:error', value);
});

const focus = () => {
    inputRef.value && inputRef.value.focus();
};

const onInput = (ev: Event) => {    
    const target = ev.target as HTMLInputElement;
    emit('update:modelValue', target.value);

    if (field.validated && !field.valid) {
        validate();
    }
};

const clearInput = () => {
    if (isDisabled.value) return;
    hasFocus.value = true;
    nextTick(() => {
        hasFocus.value = false;
        emit('update:modelValue', '');
    });
};

const onBlur = (ev: Event) => {
    hasFocus.value = false;

    if (field.dirty) {
        validate();
    }
    emit('blur', ev, valid.value);
};

const onFocus = () => {
    if (isDisabled.value) return;
    hasFocus.value = true;
    if (props.selectTextOnFocus) {
        try {
            // selects text so that user can just type a new text
            inputRef.value?.select();
        } catch (e) {}
    }
};

const hasValue = (value: any) => {
    return value !== '' && value != null;
};

const isDisabled = computed(() => {
    return !!$attrs.disabled;
});

const togglePasswordView = () => {
    showPassword.value = !showPassword.value;
};

const computedType = computed(() => {
    return showPassword.value ? 'text' : props.type;
});

const hasClearButton = computed(() => {
    return props.showClear && !props.tooltipText;
});

const labelPasswordCharCount = computed(() => {
    if (props.type !== 'password' || !props.minimumPasswordCharCount) return null;
    const valueLength = modelValue.value?.toString().length || 0;
    const numMinLength = props.minimumPasswordCharCount;
    const constraintMinComputed = numMinLength - valueLength;
    if (constraintMinComputed < 1 || valueLength === 0) return null;
    return translateFilter('login.CreateUser.Fields.Password.PasswordMinLength', constraintMinComputed.toString());
});

const shopTooltipChange = (state: boolean) => {
    tooltipOpen.value = state;
};

</script>