import translateFilter from '@/core/translation/translate.filter';
import { ICheckoutStep } from '@/project/checkout/checkoutPageStepIndexes';
import { IPaymentCard } from '@/project/checkout/payment-cards';
import {
    Country,
    FooterNavigationModel,
    HeaderNavigationModel,
    LegalContentModel,
    Media,
    NameAndUrl,
    PaymentProviderSettingsModel,
    SearchContextModel,
    ServerCultureModel,
    SitePageData
} from '@/types/serverContract';

import { Historian } from '@/project/tracking/historian/historian.utils';

declare global {
    interface Window {
        CookieInformation: {
            getConsentGivenFor(string): boolean;
            loadConsent();
        };
        mParticle: any;
        historian: Historian;
        ApplePaySessionQuickPay?: { canMakePayments?: unknown };
        PaymentRequest?: { canMakePayments?: unknown };
        vertica: {
            pageData: SitePageData;
            searchContext: SearchContextModel;
            footerNavigation: FooterNavigationModel;
            headerNavigation: HeaderNavigationModel | undefined;
            headerNavigationFocusedLinks: NameAndUrl[];
            serverCultures: ServerCultureModel[];
            culture: string;
            language: string;
            currencyCode: string;
            serverVersion: string;
            storeListUrl: string;
            storeDetailsUrl: string;
            diyListUrl: string;
            compliancePageUrl: string;
            privacyPolicyUrl: string;
            baseUrl: string;
            market: 'BE' | 'DK' | 'FI' | 'FR' | 'NL' | 'IE' | 'NO' | 'CH' | 'GB' | 'SE' | 'DE' | 'AT';
            enableStoreInventory: boolean;
            mfl10Enabled: boolean;
            enableGeoCoding: boolean;
            enableBackendPurchaseTracking: boolean;
            marketUrl: string;
            frontPage: NameAndUrl;
            isProduction: boolean;
            availableCountries: Country[];
            checkoutContext: {
                hasCheckout: boolean;
                checkoutUrl: string;
                checkoutTermsUrl: string;
                currencyCode: string;
                paymentMethods: string[];
                disableLoginPromotion: boolean;
                validatePostalCodeAndCity: boolean;
                deliveryInfoUrl?: string;
                footerInformation?: {
                    icon?: string;
                    text: string;
                    title: string;            
                }[];
            };
            pageDisclaimer: string;
            paymentProviderSettingsModel: PaymentProviderSettingsModel;
            userContext: {
                hasLogin: boolean;
                fbLoginEnabled: boolean;
                appleLoginEnabled: boolean;
                loginScreenFooterLinks: NameAndUrl[];
                myPageUrl: string | false;
                myPageFavoriteProductsUrl: string | false;
                newsletterSignupConsentText: string;
            };
            frontendLogging: string[];
            appContext?: {
                appleStoreLink?: string;
                appleStoreImage?: Media;
                googleStoreLink?: string;
                googleStoreImage?: Media;
            };
            chat: {
                certainlyConfig: {
                    botId: string;
                    handoverModule: string;
                    takeoverModule: string;
                    transferCallback(string): any;
                };
                webchatKey: string;
                visitor: any;
                botxoCallback(any): any;
                switchToSalesforce(): any;
                startSalesforceChat(): any;
                salesforce: {
                    directToAgentRoutingButtonId: string;
                    embedID: string;
                    embedChat: string;
                    embedSettings: {
                        baseLiveAgentContentURL: string;
                        deploymentId: string;
                        buttonId: string;
                        baseLiveAgentURL: string;
                        eswLiveAgentDevName: string;
                        isOfflineSupportEnabled: boolean;
                    };
                    extraPrechatFormDetails: any[];
                    baseUrl: string;
                    agentUrl: string;
                };
            };
        };
        certainly: any;
        // eslint-disable-next-line camelcase
        embedded_svc: any; // Sales Force chat object on window
        legalTexts: LegalContentModel;
    }
}

class ServerContextService {
    supportsApplePay: boolean = false;
    supportsGooglePay: boolean = false;
    constructor() {
        if (!window.vertica) {
            throw new Error('### window.vertica object should exist ###');
        }
        this.verifyApplePaySupport();
        this.verifyPaymentMethodSupport('https://google.com/pay');
    }

    private verifyApplePaySupport() {
        if (window.ApplePaySessionQuickPay?.canMakePayments) {
            this.supportsApplePay = true;
        }
    }

    private verifyPaymentMethodSupport(supportedMethod: string): boolean {
        try {
            if (window.PaymentRequest) {
                const details = {
                    total: {
                        label: 'Total',
                        amount: {
                            currency: window.vertica.currencyCode,
                            value: '0.00'
                        }
                    }
                };
                const methodData = [
                    {
                        supportedMethods: supportedMethod
                    }
                ];
                const paymentRequest = new PaymentRequest(methodData, details);
                paymentRequest
                    .canMakePayment()
                    .then((result) => {
                        this.supportsGooglePay = result === true;
                    })
                    .catch(() => {
                        return false;
                    });
            }
        } catch {
            return false;
        }
        return false;
    }

    public get pageData(): SitePageData {
        return window.vertica.pageData;
    }

    public get searchContext(): SearchContextModel {
        return window.vertica.searchContext;
    }

    public get footerNavigation(): FooterNavigationModel {
        return window.vertica.footerNavigation;
    }

    public get headerNavigation(): HeaderNavigationModel | undefined {
        return window.vertica.headerNavigation;
    }

    public get culture(): string {
        return window.vertica.culture;
    }

    public get language(): string {
        return window.vertica.language;
    }

    public get market() {
        return window.vertica.market;
    }

    public get checkoutContext() {
        return window.vertica.checkoutContext || {};
    }

    public get checkoutTermsUrl(): string {
        return this.checkoutContext.checkoutTermsUrl;
    }

    public get hasCheckout(): boolean {
        return this.checkoutContext.hasCheckout;
    }

    public get disableLoginPromotion(): boolean {
        return this.checkoutContext.disableLoginPromotion;
    }

    public get currencyCode(): string {
        // Used for tracking to GTM
        return window.vertica.currencyCode;
    }

    public get paymentMethods(): IPaymentCard[] {
        const darkColor = 'currentColor';
        const transparentColor = 'rgba(0,0,0,0)';
        const paymentMethodsOverride: IPaymentCard[] = [
            {
                name: 'Dankort',
                colors: [transparentColor, darkColor, darkColor]
            },
            {
                name: 'Visa',
                colors: [transparentColor, darkColor]
            },
            {
                name: 'Mastercard',
                colors: [darkColor]
            },
            {
                name: 'MobilePay',
                // iconCompact: 'payment-mobilepay-logo',
                fill: true
            }
        ];
        const summedMethods: IPaymentCard[] = [];
        if (this.hasCheckout && this.checkoutContext.paymentMethods) {
            this.checkoutContext.paymentMethods.map((method) => {
                const overrideMethod = paymentMethodsOverride.find((i) => method === i.name);
                const paymentMethod = {
                    icon: 'payment-' + method.toLowerCase(),
                    name: method,
                    showAsPaymentOption: true,
                    ...overrideMethod
                } as IPaymentCard;
                if (paymentMethod.name === 'ApplePay' && !this.supportsApplePay) {
                    paymentMethod.showAsPaymentOption = false;
                }
                if (paymentMethod.name === 'GooglePay' && !this.supportsGooglePay) {
                    paymentMethod.showAsPaymentOption = false;
                }
                summedMethods.push(paymentMethod);
            });
        }
        return summedMethods;
    }

    public get cultures(): ServerCultureModel[] {
        return window.vertica.serverCultures;
    }

    public get serverVersion(): string {
        return window.vertica.serverVersion;
    }

    public get privacyPolicyUrl(): string {
        return window.vertica.privacyPolicyUrl;
    }

    public get storeListUrl(): string {
        return window.vertica.storeListUrl;
    }

    public get storeDetailsUrl(): string {
        return window.vertica.storeDetailsUrl;
    }

    public get enableStoreInventory(): boolean {
        return window.vertica.enableStoreInventory;
    }

    public get mfl10Enabled(): boolean {
        return window.vertica.mfl10Enabled;
    }

    public get enableGeoCoding(): boolean {
        return window.vertica.enableGeoCoding;
    }

    public get enableBackendPurchaseTracking(): boolean {
        return window.vertica.enableBackendPurchaseTracking;
    }

    public get compliancePageUrl(): string {
        return window.vertica.compliancePageUrl;
    }

    public get baseUrl(): string {
        return window.vertica.baseUrl;
    }

    public get marketUrl(): string {
        return window.vertica.marketUrl;
    }

    public get availableCountries() {
        return window.vertica.availableCountries;
    }

    public getSelectedCountryPhone(phonePrefix): Country {
        const country = this.availableCountries.find((c) => phonePrefix === c.phonePrefix);

        return country || this.getCurrentMarketCountrySettings || this.availableCountries[0];
    }

    public get getCurrentMarketCountrySettings(): Country {
        return this.availableCountries.find((c) => c.countryCode === this.market) as Country;
    }

    public get pageDisclaimer(): string | null {
        return window.vertica.pageDisclaimer || null;
    }

    public get hasLogin(): boolean {
        return window.vertica.userContext.hasLogin;
    }

    public get facebookLoginEnabled(): boolean {
        return window.vertica.userContext.fbLoginEnabled;
    }

    public get appleLoginEnabled(): boolean {
        return window.vertica.userContext.appleLoginEnabled;
    }

    public get myPageUrl(): string | false {
        return window.vertica.userContext.myPageUrl ?? false;
    }

    public get myPageFavoriteProductsUrl(): string | false {
        return window.vertica.userContext.myPageFavoriteProductsUrl ?? false;
    }

    public get loginScreenFooterLinks() {
        return window.vertica.userContext.loginScreenFooterLinks ?? null;
    }

    public get diyListUrl(): string {
        return window.vertica.diyListUrl;
    }

    public get newsletterSignupConsentText(): string {
        return window.vertica.userContext.newsletterSignupConsentText;
    }

    public get checkoutSteps(): ICheckoutStep[] {
        return [
            {
                label: translateFilter('checkout.Steps.User'),
                icon: 'user'
            },
            {
                label: translateFilter('checkout.Steps.Delivery'),
                icon: 'delivery'
            },
            {
                label: translateFilter('checkout.Steps.Payment'),
                icon: 'creditcards'
            }
        ];
    }

    public get userTitleOptions(): string[] | null {
        // Find title options in dictionary
        const matchKeyName = 'generic.Person.Title.Options.';
        const titleOptions: string[] = [];
        for (const propertyName in window.dictionary) {
            const x = propertyName.substr(0, matchKeyName.length);
            // Use lowercase as keys are with first char lower cased.
            if (x === matchKeyName && window.dictionary[propertyName].toLowerCase() !== propertyName.toLowerCase() && window.dictionary[propertyName].toLowerCase() !== '') {
                titleOptions.push(translateFilter(propertyName));
            }
        }
        if (titleOptions.length) {
            return titleOptions;
        } else {
            return null;
        }
    }

    public get frontendLogging() {
        return {
            vueErrorHandler: window.vertica.frontendLogging.indexOf('Vue errorHandler') !== -1,
            onunhandledrejection: window.vertica.frontendLogging.indexOf('window.onunhandledrejection') !== -1,
            onerror: window.vertica.frontendLogging.indexOf('window.onerror') !== -1,
            warn: window.vertica.frontendLogging.indexOf('logging warn') !== -1,
            exception: window.vertica.frontendLogging.indexOf('logging exception') !== -1,
            debug: window.vertica.frontendLogging.indexOf('logging debug') !== -1,
            info: window.vertica.frontendLogging.indexOf('logging info') !== -1,
            error: window.vertica.frontendLogging.indexOf('logging error') !== -1
        };
    }

    public get validateAddressLocation(): boolean {
        return window.vertica.checkoutContext.validatePostalCodeAndCity;
    }

    public get appAppleStoreHref(): undefined | string {
        return window.vertica.appContext?.appleStoreLink || undefined;
    }

    public get appAppleStoreImage(): undefined | Media {
        return window.vertica.appContext?.appleStoreImage || undefined;
    }

    public get appGoogleStoreHref(): undefined | string {
        return window.vertica.appContext?.googleStoreLink || undefined;
    }

    public get appGoogleStoreImage(): undefined | Media {
        return window.vertica.appContext?.googleStoreImage || undefined;
    }

    public get isProductionEnvironment(): boolean {
        return window.vertica.isProduction;
    }

    public get paymentProviderSettings(): PaymentProviderSettingsModel {
        return window.vertica.paymentProviderSettingsModel;
    }

    public get legalTexts(): LegalContentModel {
        return window.legalTexts;
    }
}

export default new ServerContextService();
