let cancelled = false;
let timedOut = false;
const TIMEOUT = 5000; // ms.

export function ensure<T>(expr: () => boolean, onFullfilled: T): Promise<T> {
    cancelled = false;
    timedOut = false;

    return new Promise((resolve, reject) => {
        setTimeout(() => timedOut = true, TIMEOUT);
        checkExpression();

        function checkExpression() {
            if (cancelled) {
                reject(new Error('cancelled'));
                return;
            }
            if (timedOut) {
                reject(new Error('timed out'));
                return;
            }
            if (expr()) {
                resolve(onFullfilled);
                return;
            }
            setTimeout(checkExpression, 50);
        }
    });
}

export function cancel() {
    cancelled = true;
}
