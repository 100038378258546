<script lang="ts">
import { defineComponent, computed, onMounted, watch } from 'vue';
import DomService from '@/core/dom.service';
import spaStore from '@/core/spa/store/spa.store';
import navStore from '@/store/nav.store';
import httpService, { HttpCancelError, HttpRedirectError } from '@/core/http/http.service';
import { ImageType, MediaType, SitePageData, TrackingData } from '@/types/serverContract';
import { useRoute } from 'vue-router';

export const ErrorPageData: SitePageData = {
    navigation: {
        breadcrumb: [],
    },
    metadata: {
        id: '',
        index: false,
        languages: [],
        navigationTitle: '',
        seoDescription: '',
        seoImage: {
            name: '',
            url: '',
            extension: '',
            focalPoint: { left: 0, top: 0 },
            height: 0,
            width: 0,
            config: {},
            type: MediaType.Image,
            imageType: ImageType.Unspecified,
        },
        seoTitle: '',
        url: '',
    },
    messages: {
        productIds: [],
    },
    entity: {
        id: 0,
        provider: '',
    },
    jsonContent: {
        alias: 'error',
        content: {},
    },
    tracking: {} as TrackingData,
    showChatbot: false,
    showWatermelonChatbot: false,
};

export default defineComponent({
    name: 'SpaServer',
    setup(props, { slots }) {
        const route = useRoute();

        onMounted(() => {
            // Load first page if not preloaded
            if (!spaStore.pageData) {
                fetchPage(route.path);
            }
        });

        watch(
            () => route.path,
            (newPath, oldPath) => {
                if (newPath === oldPath) {
                    // Close menu if navigate to same page as current (e.g. via top menu, only changing pagination / facets).
                    navStore.setNavigationStateActive(false);
                    return;
                }
                fetchPage(newPath);
            }
        );

        const metadata = computed(() => spaStore.metadata);
        
        watch(
            () => metadata.value,
            (metadata) => {
                if (metadata) {
                    DomService.updateMetaData(metadata, spaStore.entity, spaStore.pageData.jsonContent);
                }
            },
            { immediate: true }
        );


        async function fetchPage(path: string): Promise<void> {
            try {
                spaStore.isFetchingSpaPage = true;
                spaStore.mainAriaLabel = null; // Set to null on page load; the new page might set a new aria label.
                const page = await httpService.getPage(path);
                spaStore.updatePage(page);
                spaStore.isFetchingSpaPage = false;
            } catch (error) {
                if (error instanceof HttpCancelError) {
                    // Ignore - new page request while waiting.
                } else if (error instanceof HttpRedirectError) {
                    // Ignore - redirect in progress.
                } else {
                    spaStore.updatePage(ErrorPageData);
                    spaStore.isFetchingSpaPage = false;
                }
            }
        }

        return () => slots.default?.()[0];
    }
});
</script>