<template>
    <button v-if="!fromTile"
            class="c-btn c-btn--primary-outlined c-btn--sm flex h-full justify-center w-full"
            @click="remindMe">
        <c-icon name="bell"
                class="w-16 h-16"/>
        <span class="block pl-8 font-brandon text-12 uppercase font-medium tracking-0.6">{{ $translate('product.Variant.RemindMe.TriggerButton') }}</span>
    </button>
    <div v-else 
         class="w-22 h-22 z-3 ml-auto md:w-24 md:h-24 sm:mr-9 md:mr-7 lg:mr-0">
        <button
            v-prohibit-zoom
            class="w-full h-full"
            @click="remindMe">
            <c-icon name="bell"/>
        </button>
    </div>
</template>

<script setup lang="ts">
import { v4 } from '@/types/serverContract';
import bus from '@/core/bus';
import { OpenVariantPickerKey, OpenVariantRemindMeKey } from '@/project/config/constants';
import { type VariantRemindMeInfo } from './variantRemindMeTypes';

const props = defineProps<{
    product: v4.Products.ProductSimple | v4.Products.ProductDetails;
    variant: v4.Products.VariantSimple | v4.Products.ProductDetailsVariant;
    inventory?: v4.Products.Inventory;
    fromTile?: boolean;
}>();

const remindMe = (): void => {
    if (props.fromTile && props.product.variants?.length > 1) {
        openVariantPicker();
    } else {
        openRemindMeOverlay();
    }
};

const openRemindMeOverlay = () => {
    bus.emit(OpenVariantRemindMeKey, {
        productId: props.product.id,
        sku: props.variant.sku,
    } as VariantRemindMeInfo);
};

const openVariantPicker = () => {
    bus.emit(OpenVariantPickerKey, {
        selectedVariant: props.variant,
        inventory: props.inventory,
        product: props.product
    });
};

</script>
