import { reactive } from 'vue';
import makeStateDebugAccessible from '@/store/stateDebug';
import Api, { IFavoriteInterface } from '@/project/http/api';
import { FavoritesModel } from '@/types/serverContract';
import userStore from '@/store/user.store';
import { MomentType } from '@/types/frontend';

interface IFavoritesState {
    favorites: IFavoriteInterface[] | null;
    isLoadingFavorites: boolean;
}

class FavoritesStore {
    private state: IFavoritesState = reactive({
        favorites: null,
        isLoadingFavorites: false
    });

    constructor() {
        makeStateDebugAccessible('favoritesState', this.state);
    }

    public loadFavorites() {
        Api.user.favorites().then(this.setFavoritesFromApi);
    }

    private isMomentType(type: string) {
        // @ts-ignore
        return [MomentType.FeatureStore, MomentType.Gallery, MomentType.Experience].indexOf(type.toLocaleLowerCase()) > -1;
    }

    public addFavorites(identifier: string, type: string): void {
        if (this.isMomentType(type)) {
            type = 'moment';
        }
        const items = this.state.favorites;

        const favorite: IFavoriteInterface = {
            identifier,
            type
        };

        const favoritesList = items || [];

        favoritesList.push(favorite);
        this.state.favorites = favoritesList;
    }

    public removeFavorites(identifier: string, type: string): void {
        if (!this.state.favorites) return;
        if (this.isMomentType(type)) {
            type = 'moment';
        }
        const items = this.state.favorites;

        const favorite: IFavoriteInterface = {
            identifier,
            type
        };

        const filteredItems = items.filter((item: IFavoriteInterface) => item.identifier !== favorite.identifier || item.type !== favorite.type);

        this.state.favorites = filteredItems;
    }

    public setFavoritesFromApi(favoriteResponse: FavoritesModel) {
        const updatedFavoritesList: IFavoriteInterface[] = [];
        favoriteResponse.products.forEach(
            identifer => updatedFavoritesList.push({ identifier: identifer, type: 'product' })
        );
        favoriteResponse.content.forEach(
            identifer => updatedFavoritesList.push({ identifier: identifer.id, type: 'content' })
        );
        favoriteResponse.inspiration.forEach(
            identifer => updatedFavoritesList.push({ identifier: identifer, type: 'inspiration' })
        );
        this.state.favorites = updatedFavoritesList;
    }

    private initFavorites() {
        if (this.state.isLoadingFavorites || !userStore.isLoggedIn) return;

        this.state.isLoadingFavorites = true;
        Api.user.favorites().then(() => {
            this.state.isLoadingFavorites = false;
        });
    }

    public get favorites() {
        if (!this.state.favorites && !this.state.isLoadingFavorites && userStore.isLoggedIn) this.initFavorites();
        return this.state.favorites;
    }

    public isFavorite(id: string) {
        if (this.state.favorites === null) {
            return false;
        }

        return this.state.favorites.some((x) => x.identifier === id);
    }
}
export default new FavoritesStore();
