<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import { PropType } from 'vue';
import productsStore from '@/store/products/products.store';
import every from 'lodash-es/every';
import { v4 } from '@/types/serverContract';

export default defineComponent({
    name: 'ProductProvider',
    inheritAttrs: false,
    props: {
        productId: {
            type: String,
            required: false,
            default: null
        },
        productIds: {
            type: Array as PropType<string[]>,
            required: false,
            default: () => []
        }
    },
    setup(props, { slots }) {
        const normalizedProductIds = computed(() => {
            if (props.productId != null) {
                return [props.productId];
            } else if (props.productIds) {
                return props.productIds;
            }
            return [];
        });

        onMounted(() => {
            productsStore.ensureLoaded(normalizedProductIds.value);
        });

        const productsByIds = computed(() => {
            return normalizedProductIds.value
                .map(productId => productsStore.getProduct(productId))
                .filter(product => product !== null) as (v4.Products.ProductSimple | undefined)[];
        });

        const product = computed(() => productsByIds.value[0]);

        const isProductsLoaded = computed(() => {
            return every(Object.values(productsByIds.value), val => val !== undefined);
        });

        return () => {
            return slots.default!({
                product: product.value,
                products: productsByIds.value,
                productsLoaded: isProductsLoaded.value
            });
        };
    }
});
</script>
