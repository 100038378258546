<template>
    <div class="c-radiobutton"
         :class="error ? 'has-validation-error' : ''">
        <input
            :id="id"
            ref="input"
            v-prohibit-zoom
            type="radio"
            class="c-radiobutton__input"
            :name="name"
            :value="theValue"
            :checked="shouldBeChecked"
            v-bind="$attrs"
            @change="updateValue">
        <label class="c-radiobutton__icon-wrap"
               :class="iconWrapClass"
               :for="id">
            <transition name="fadeover">
                <c-icon
                    v-if="shouldBeChecked"
                    :key="'checked'"
                    name="radiobutton"
                    :color="svgColor"
                    width="20"
                    class="c-radiobutton__icon h-20"/>
                <c-icon
                    v-else
                    :key="'unchecked'"
                    name="radiobutton-unchecked"
                    :color="svgColor"
                    width="20"
                    class="c-radiobutton__icon h-20"/>
            </transition>
        </label>
        <label v-if="label"
               class="c-radiobutton__label"
               :class="[error && 'c-radiobutton__label--error']"
               :for="id">
            <span class="c-radiobutton__label-wrapper"
                  :class="labelWrapClass">
                <span :class="[labelClass, labelCheckedClassGenerated]">
                    <slot name="htmlLabel"/>
                    <template v-if="!hasHtmlLabel">
                        {{ label }}
                    </template>
                </span>
                <slot/>
            </span>
        </label>
        <div v-if="!hideErrors && error"
             class="mb-5 ml-30 text-red">
            {{ error }}
        </div>
    </div>
</template>


<script setup lang="ts">
/*
Inspired by this: https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/
 */
import useFieldValidation from '@/core/form/useFieldValidation';
import { ref, computed, useAttrs, useSlots } from 'vue';

const props = withDefaults(defineProps<{
    label: string;
    name: string;
    theValue: string;
    labelClass?: string;
    labelCheckedClass?: string;
    labelWrapClass?: string;
    iconWrapClass?: string;
    hideErrors?: boolean;
    modelValue?: string | null;
    required?: boolean,
}>(), {
    labelClass: '',
    labelCheckedClass: '',
    labelWrapClass: '',
    iconWrapClass: '',
    errorLabelKey: '',
    modelValue: null,
    required: false,
    hideErrors: false,
});

const emit = defineEmits(['change']);
const slots = useSlots();
const $attrs = useAttrs();
const input = ref<HTMLInputElement | null>(null);

const id = computed(() => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1));
const shouldBeChecked = computed(() => props.modelValue === props.theValue);

const validator = () => {
    if (!props.required) return null;

    const radioInGroupIsChecked = Array.from(document.querySelectorAll<HTMLInputElement>(`input[name="${props.name}"]`)).some((el: HTMLInputElement) => el.checked);
    if (!radioInGroupIsChecked) {
        return 'Please select an option';
    }

    return null;
};

const { field } = useFieldValidation(props.name, props.label, validator, shouldBeChecked);

const labelCheckedClassGenerated = computed(() => (shouldBeChecked.value ? props.labelCheckedClass : null));

const svgColor = computed(() => (error.value ? '#EA9291' : 'currentColor'));

const error = computed(() => field.error);

const hasHtmlLabel = computed(() => 'htmlLabel' in slots);

const updateValue = () => {
    emit('change', props.theValue);
};

</script>