<template>
    <ColorVariant v-if="colorVariantType"
                  :product="product"/>
    <TextVariant v-else-if="TextVariantType"
                 :product="product"/>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, computed } from 'vue';
import { v4 } from '@/types/serverContract';
const ColorVariant = defineAsyncComponent(() => import('@/project/product/variant/ColorVariant.vue'));
const TextVariant = defineAsyncComponent(() => import('@/project/product/variant/TextVariant.vue'));

const props = defineProps<{
    product: v4.Products.ProductSimple;
}>();

const colorVariantType = computed(() => {
    return props.product.variantInfo?.type === v4.Products.GenericVariantType.Color;
});

const TextVariantType = computed(() => {
    return props.product.variantInfo?.type === v4.Products.GenericVariantType.Text;
});

</script>
