import { reactive } from 'vue';
import { RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router';
import bus from '@/core/bus';
import serverContext from '@/core/serverContext.service';
import { RouterAfterEachEventKey } from '@/core/spa/router';
import Api from '@/project/http/api';
import makeStateDebugAccessible from '@/store/stateDebug';
import { Basket, PaymentMethodLogo, ShippingMethod } from '@/types/serverContract';
import { minBy } from 'lodash-es';

interface IBasketState {
    basket: Basket | null;
    itemCount: number;
    isLoadingBasket: boolean;
    modifiedByServer: boolean;
    modifiedSkus: string[];
    removedSkus: string[];
    basketActive: boolean;
    clientSelectedShippingMethod: ShippingMethod | null;
    estimatedShippingPriceRequested: boolean;
    availableShippingMethods: null | ShippingMethod[];
    paymentMethodLogoUrlsLoaded: boolean;
    paymentMethodLogoUrls: null | PaymentMethodLogo[];
    returnUrl: null | string;
}

class BasketStore {
    private state: IBasketState = reactive({
        basket: null,
        itemCount: 0,
        isLoadingBasket: false,
        modifiedByServer: false,
        modifiedSkus: [],
        removedSkus: [],
        basketActive: false,
        clientSelectedShippingMethod: null,
        estimatedShippingPriceRequested: false,
        availableShippingMethods: null,
        paymentMethodLogoUrlsLoaded: false,
        paymentMethodLogoUrls: null,
        returnUrl: null
    });

    constructor() {
        makeStateDebugAccessible('basketState', this.state);
        bus.on(RouterAfterEachEventKey, ({ to, from }: { to: RouteLocationNormalizedLoaded; from: RouteLocationNormalized }) => {
            if (to.path === serverContext.checkoutContext.checkoutUrl) {
                this.state.returnUrl = from.path;
            }
        });
    }

    private async initBasket() {
        if (this.isLoadingBasket) return;

        this.setIsLoadingBasket(true);

        try {
            await Api.basket.get().then((basket) => {
                if (basket) {
                    this.updateBasket(basket);
                    this.setIsLoadingBasket(false);
                }
            });
        } catch (e) {
            // avoid logging with an empty catch...
            this.setIsLoadingBasket(true); // Dont set loading state to false if request fails, then it will not try to reload basket infinite times.
        }
    }

    public get basket(): Basket | null {
        if (!this.state.basket && !this.isLoadingBasket) this.initBasket();
        return this.state.basket;
    }

    public get modifiedByServer() {
        return this.state.modifiedByServer;
    }

    public get modifiedByServerModifiedSkus() {
        return this.state.modifiedSkus;
    }

    public get modifiedByServerRemovedSkus() {
        return this.state.removedSkus;
    }

    public get itemCount() {
        return this.basket && this.state.itemCount;
    }

    public getSkuCount(sku: string) {
        if (!this.state.basket || !(sku && sku.length)) {
            return 0;
        }
        let count = 0;
        this.state.basket &&
            this.state.basket.lines &&
            this.state.basket.lines.map((l) => {
                if (l.sku === sku) {
                    count = l.quantity;
                }
            });
        return count;
    }

    public get isLoadingBasket(): boolean {
        return this.state.isLoadingBasket;
    }

    public get basketActive(): boolean {
        return this.state.basketActive;
    }

    public setBasketActiveState(state: boolean) {
        this.state.basketActive = state;
    }

    public toggleBasketActiveState() {
        this.setBasketActiveState(!this.state.basketActive);
    }

    public updateBasket(data: Basket, updateCouldAffectShipping: boolean = false) {
        this.state.basket = { ...data, lines: Object.freeze(data.lines) } as Basket;
        let count = 0;
        data && data.lines && data.lines.map((l) => (count += l.quantity));
        this.state.itemCount = count;

        // force update shipping methods to check if customer is eligible for free shipping
        if (updateCouldAffectShipping) {
            Api.basket.shippingMethods().then((shippingMethods: ShippingMethod[]) => {
                if (this.clientSelectedShippingMethod) {
                    const selectedShippingMethod = shippingMethods.find(
                        (s) => s.id === this.clientSelectedShippingMethod?.id
                    );
                    if (selectedShippingMethod) {
                        this.clientSelectedShippingMethod = selectedShippingMethod;
                    } else {
                        this.clientSelectedShippingMethod = null;
                    }
                }
            });
        } else {
            if (this.state.basket && this.state.basket.shipping && this.state.basket.shipping.shippingMethod) {
                this.state.clientSelectedShippingMethod = this.state.basket.shipping.shippingMethod;
            }
        }

        if (data.modifiedByServer) {
            this.setModifiedByServer(true, data);
        }
    }

    public get clientSelectedShippingMethod() {
        return this.state.clientSelectedShippingMethod;
    }

    public set clientSelectedShippingMethod(shippingMethod: ShippingMethod | null) {
        this.state.clientSelectedShippingMethod = shippingMethod;
    }

    /**
     * Set upon shippingMethods api call completion
     *
     * The server has calculated the shipping price for each shipping method, based on the basket for the user.
     * It does so, when the shippingprice endpoint is called.
     * Marianne and Bjørn have discussed that some performance could be gained if the shipping methods
     * could be returned when updating the basket (optianally, as it is not always needed)
     *
     * The shipping methods are ordered by the server such that the first one is the cheapest one.
     * exception: warehousePickup is placed last. The future storePickup will also be placed last.
     * The reason for that exception is to allow us simply to use the first shipping method in order to get
     * the cheapest relevant option (warehouse pickup is not so relevant when displaying estimated shipping, as
     * it is not common to pick that option)
     * If free freight has been obtained, the price will be 0 (and therefore at the top of the order)
     * If a speciel freight item has been added (ie a sofa), only the shipping methods that supports that are returned
     *
     * Regarding freeLimit:
     * It specifies what amount the user must buy for in order to obtain free freight for that shipping method
     * Free freight is not always possible. Warehouse pickup usually does not offer free freight.
     * Also, if the user has placed a product that requires special handling (ie a sofa), only shipping methods
     * that supports special handling will be returned. The ones that does support it will typically not offer
     * free freight at a limit. So in that case, all shipping methods will have freeLimit set to null
     *
     * @param shippingMethods
     */
    public setAvailableShippingMethods(shippingMethods: ShippingMethod[] | null) {
        this.state.availableShippingMethods = shippingMethods;
    }

    public get availableShippingMethods(): ShippingMethod[] | null {
        return this.state.availableShippingMethods;
    }

    /**
     * Get estimated shipping price (relevant before shipping method has been selected)
     *
     * It returns the cheapest price, except that warehouse pickup is only used, if it is the only method
     * available. See setAvailableShippingMethods().
     */
    public get estimatedShippingPrice(): number | null {
        if (
            (this.state.availableShippingMethods === null || this.state.availableShippingMethods?.length === 0) &&
            !this.state.estimatedShippingPriceRequested
        ) {
            this.state.estimatedShippingPriceRequested = true;
            Api.basket.shippingMethods();
        }
        return this.state.availableShippingMethods?.[0]?.price ?? null;
    }

    // @deprecated - you should probably be using estimatedShippingPrice instead
    public get cheapestShippingMethod(): ShippingMethod | null {
        if (this.availableShippingMethods) {
            // Default to cheapest shipping option
            const cheapestMethod = minBy(this.availableShippingMethods, (method) => {
                return !method.warehousePickup ? method.price : 99999;
            });
            if (cheapestMethod) {
                return cheapestMethod;
            }
        }
        return null;
    }

    /**
     * Get lowest free shipping limit among the shipping methods.
     *
     * While it is not probable, it is conceivable that there are different free limits for the different
     * shipping methods. This method gets the lowest.
     *
     * In case the basket contains a special freight item, none of the shipping methods will allow free freight
     * In that case, null is returned, meaning that the free shipping is not available
     *
     */
    public get freeShippingLimit(): number | null {
        if (!this.state.availableShippingMethods || this.state.availableShippingMethods.length === 0) {
            return null;
        }

        const methods = [...this.state.availableShippingMethods];
        methods.sort((a: ShippingMethod, b: ShippingMethod) => {
            return (a?.freeLimit ?? Number.MAX_SAFE_INTEGER) - (b?.freeLimit ?? Number.MAX_SAFE_INTEGER);
        });

        const firstMethod: ShippingMethod = methods[0];
        return firstMethod.freeLimit;
    }

    public setIsLoadingBasket(isLoading: boolean) {
        this.state.isLoadingBasket = isLoading;
    }

    public setModifiedByServer(modifiedByServer: boolean, basketSnapshot: Basket | null = null) {
        this.state.modifiedByServer = modifiedByServer;
        if (modifiedByServer && basketSnapshot) {
            basketSnapshot.changedSkus.map((sku) => {
                if (!this.state.modifiedSkus.includes(sku)) {
                    this.state.modifiedSkus.push(sku);
                }
            });
            basketSnapshot.removedSkus.map((sku) => {
                if (!this.state.removedSkus.includes(sku)) {
                    this.state.removedSkus.push(sku);
                }
            });
        }
        if (!modifiedByServer) {
            this.state.modifiedSkus.length = 0;
            this.state.removedSkus.length = 0;
        }
    }

    private async initPaymentMethodLogoUrls() {
        if (this.state.paymentMethodLogoUrlsLoaded) return;
        const response = await Api.basket.getPaymentMethodLogoUrls(serverContext.market);
        this.state.paymentMethodLogoUrls = response;
        this.state.paymentMethodLogoUrlsLoaded = true;
    }

    public get paymentMethodLogoUrls() {
        if (!this.state.paymentMethodLogoUrls && !this.state.paymentMethodLogoUrlsLoaded) {
            this.initPaymentMethodLogoUrls();
        }
        return this.state.paymentMethodLogoUrls;
    }

    public get returnUrl() {
        return this.state.returnUrl;
    }
}
export default new BasketStore();
