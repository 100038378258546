<template>
    <div v-if="inStores"
         class="flex items-center">
        <cIcon name="building"
               width="18"
               height="18"
               class="text-brown-80 -mt-2 mr-8"/>
        <button v-if="inStores.status === v4.Products.Inventory.StoreInventory.AvailabilityStatus.Available"
                class="font-gibson-regular text-14 leading-18 text-brown-80 underline hover:no-underline"
                @click="openStoreOverlay = true">
            {{ inStores?.description }}
        </button>
        <span v-else>
            <span class="font-gibson-regular text-14 leading-18 text-brown-80">
                {{ inStores?.description }}
            </span>
        </span>
        <div>
            <StoreOverlayWrapper v-model:show="openStoreOverlay"
                                 :details="details"
                                 :selected-variant="selectedVariant"
                                 :mode="storeOverlayMode"
                                 :veto-close="handleStoreOverlayCloseRequest"
                                 @cancelled="openStoreOverlay = false"/>
            <PreferredStorePickNudgeOverlay :open="openNudgeOverlay"
                                            @close="nudgeOverlayCloses"/>
        </div>
    </div>
</template>

<script lang="ts" setup>
import cIcon from '@/project/config/c-icon.vue';
import { v4 } from '@/types/serverContract';
import { ref, computed } from 'vue';
import { StoreOverlayMode } from '@/types/frontend';
import StoreOverlayWrapper from '@/project/product/StoreOverlayWrapper.vue';
import { shouldNudgeForPreferredStorePick } from '@/project/product/details/preferredStorePickNudge';
import PreferredStorePickNudgeOverlay from '@/project/product/details/PreferredStorePickNudgeOverlay.vue';
import trackingUtils from '@/project/tracking/tracking.utils';

const storeOverlayMode = ref<StoreOverlayMode>(StoreOverlayMode.StockStatus);
const openStoreOverlay = ref(false);
const openNudgeOverlay = ref(false);
const nudgeCount = ref<number>(0);

const props = defineProps<{
    selectedVariant: v4.Products.ProductDetailsVariant;
    details: v4.Products.ProductDetails;
}>();

const inStores = computed(() => {
    return props.selectedVariant.inventory?.inStores;
});

const handleStoreOverlayCloseRequest = () => {
    // Never veto close if the store overlay is in store picker mode, but reset state for multiple opens
    if (storeOverlayMode.value === StoreOverlayMode.StorePicker) {
        setTimeout(() => {
            storeOverlayMode.value = StoreOverlayMode.StockStatus;
        }, 1000);
        return Promise.resolve(false);
    }

    const { shouldNudge, nudgeCount: theNudgeCount } = shouldNudgeForPreferredStorePick();
    if (shouldNudge) {
        nudgeCount.value = theNudgeCount;
        openNudgeOverlay.value = true;
        trackingUtils.overlay.trackNudgeForStoreSelection('view', nudgeCount.value);
        return Promise.resolve(true);
    }
    return Promise.resolve(false);
};

const nudgeOverlayCloses = (acceptNudge: unknown) => {
    if (acceptNudge) {
        storeOverlayMode.value = StoreOverlayMode.StorePicker;
        trackingUtils.overlay.trackNudgeForStoreSelection('yes', nudgeCount.value);
    } else {
        openStoreOverlay.value = false;
        trackingUtils.overlay.trackNudgeForStoreSelection('not_now', nudgeCount.value);
    }
    openNudgeOverlay.value = false;
};

</script>
