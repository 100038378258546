<template>
    <div>
        <router-link :aria-label="ariaLabel"
                     rel="next"
                     :to="url"
                     :class="itemClass"
                     @click="nextPage">
            <slot/>
        </router-link>
        <div v-if="allPages && allPages > 0"
             class="hidden">
            <router-link v-for="page in allPages"
                         :key="page"
                         :to="getPaginationUrl(page)">
                {{ page }}
            </router-link>
        </div>
    </div>
</template>


<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import urlHelper from '@/project/facets/urlHelper.service';

const props = defineProps<{
  ariaLabel?: string;
  itemClass?: string;
  isSearching?: boolean;
  totalCount?: number;
  pageSize?: number;
}>();

const updateNextPageUrl = (): RouteLocationRaw => {
    const url = urlHelper.getLocationForPage(urlHelper.getPage() + 1);
    return url;
};

watch(() => props.isSearching, (isSearching) => {
    if (!isSearching) {
        url.value = updateNextPageUrl();
    }
});

const url = ref<RouteLocationRaw>(updateNextPageUrl());

const nextPage = (e: Event) => {
    e && e.preventDefault();
    if (!props.isSearching) {
        urlHelper.setPage(urlHelper.getPage() + 1);
    }
};

const allPages = computed(() => {
    if (!props.totalCount || !props.pageSize) {
        return 0;
    }
    if (props.totalCount < props.pageSize * urlHelper.getPage()) {
        return null;
    }
    return Math.ceil(props.totalCount / props.pageSize);
});

const getPaginationUrl = (num: number): RouteLocationRaw => {
    return urlHelper.getLocationForPage(num);
};
</script>
