import spaStore from '@/core/spa/store/spa.store';
import { AccountInfo, Diy, InventoryState, v4 } from '@/types/serverContract';
import { DataLayer } from '@/types/tracking';
import urlHelper, { UrlFacets } from '@/project/facets/urlHelper.service';
import serverContext from '@/core/serverContext.service';
import debounce from 'lodash-es/debounce';
import chunk from 'lodash-es/chunk';
import concat from 'lodash-es/concat';
import userStore from '@/store/user.store';
import {
    getPageTypeForTracking,
    formatProductVariantStockToTracking,
    formatProductVariantImageCountToTracking,
    formatProductVariantSalesColorToTracking,
    formatProductShortDescriptionToTracking,
    formatProductBrandNameToTracking,
    formatProductCategoryToTracking,
    formatProductLabelingToTracking,
    getProductCountInBasketOrOrderReceipt,
    formatProductLongDescriptionToTracking,
    FormAction,
    OverlayEvent,
    NewsletterFormAction,
    SubmitType,
    iTrackPromotionClickArgs,
    formatInventoryStateToTrackingState
} from '@/project/tracking/tracking.utils';
import { BasketLineWithNewProduct } from '../http/api';

export interface tagManagerBaseProduct {
    name: string;
    id: string;
    price: number;
    dimension4?: string;
    dimension6?: string | undefined;
    dimension7?: string | undefined;
    dimension8?: number;
    dimension9?: number;
    dimension10?: number;
    dimension11?: number;
}
export interface tagManagerBasketProduct extends tagManagerBaseProduct {
    quantity: number;
    list?: string;
    brand?: string;
    category?: string;
    variant?: string;
}
export interface tagManagerProduct extends tagManagerBaseProduct {
    brand: string;
    category: string;
    dimension4: string;
    position: number;
    list?: string;
}
interface tagManagerDiy {
    name: string;
    id: string;
    category: string;
    position?: number;
}
interface tagManagerTopic {
    name: string;
    id?: string;
    category?: string;
}
export function getExistingFacetsForTracking(): string {
    const existingFacets = urlHelper.getFacets() as UrlFacets;
    const eventLabel: string[] = [];
    Object.keys(existingFacets).forEach((key) => {
        const labels = existingFacets[key].map((label) => {
            return key + ':' + label;
        });
        eventLabel.push(labels.join(', '));
    });
    return eventLabel.join();
}
export function doDataLayerPush(data): void {
    getDataLayer().push(data);
}

export function doDataLayerEcomPush(eventName: string, data: object) {
    const trackData = {
        event: eventName,
        LoggedInState: userStore.isLoggedIn ? 'LoggedIn' : 'LoggedOut',
        pageType: spaStore.jsonContent && spaStore.jsonContent.alias,
        ecommerce: {
            currencyCode: serverContext.currencyCode,
            ...data
        }
    } as any;
    getDataLayer().push(trackData);
}
export function doDataLayerEcomPushv4(
    eventName: string,
    data: object,
    list: string | undefined,
    overlayName: string | undefined
) {
    const trackData = {
        event: eventName,
        item_list_name: overlayName,
        pageType: spaStore.jsonContent && spaStore.jsonContent.alias,
        ecommerce: {
            currencyCode: serverContext.currencyCode,
            ...data
        }
    } as any;
    if (overlayName) {
        trackData.overlayName = overlayName;
    }
    if (list) {
        trackData.list = [getPageTypeForTracking(), list].join('_');
    }
    getDataLayer().push(trackData);
}
export function formatProductToGTMv4Item(product: v4.Products.ProductSimple, variantSku: string, quantity: number = 1) {
    const variant = product.variants.find((x) => x.sku === variantSku);
    return {
        item_id: product.id,
        item_name: product.name,
        item_brand: formatProductBrandNameToTracking(product),
        item_category: formatProductCategoryToTracking(product),
        item_variant: variantSku,
        price: (variant?.pricing.unit?.amount ?? 0),
        currency: serverContext.currencyCode,
        quantity: quantity,
        dimension4: googleTagManagerUtils.catalog.formatProductComplianceToDimension4(product),
        dimension6: googleTagManagerUtils.basket.formatProductToDimension6(product, variant),
        dimension7: variant ? googleTagManagerUtils.basket.formatProductToDimension7(variant) : undefined,
        dimension8: googleTagManagerUtils.basket.formatProductToDimension8(product, variant),
        dimension9: product.tracking?.shortDescriptionLength ?? 0,
        dimension10: product.tracking?.descriptionLength ?? 0,
        dimension11: googleTagManagerUtils.basket.formatProductToDimension11(variant?.sku ?? ''),
        value: (variant?.pricing.unit?.amount ?? 0) * quantity
    };
}
export function formatProductsToGTMv4Items(itemsIn: { product: v4.Products.ProductSimple; variantSku: string; quantity: number }[]) {
    const items: { items: any[]; value: number } = { items: [], value: 0 };
    itemsIn.map((item) => {
        const variant = item.product.variants.find((x) => x.sku === item.variantSku);
        const quantity = item.quantity || 1;
        items.items.push(formatProductToGTMv4Item(item.product, item.variantSku, quantity));
        items.value += (variant?.pricing.unit?.amount ?? 0) * quantity;
    });
    return items;
}
export function trackUserKnowledgeEvent(eventName, accountInfo: Partial<AccountInfo>) {
    doDataLayerPush({
        event: eventName,
        userOptions: {
            emailKey: accountInfo.encryptedEmail,
            phoneKey: accountInfo.encryptedPhone
        }
    });
}
export interface iPromotionClickNamingParts {
    componentName: string | undefined;
    trackingName?: string;
    trackingTitle?: string;
    position?: number;
    creativeText?: string;
}
export interface iProductClickNamingParts extends iPromotionClickNamingParts {}

export function promotionClick(click: iPromotionClickNamingParts) {
    googleTagManagerUtils.promotion.trackPromotionClick(click);
}

export function getDataLayer(): DataLayer {
    return (window.dataLayer = window.dataLayer || []);
}

class BasketController {
    public trackModifyCart(
        product: v4.Products.ProductSimple,
        quantity: number,
        isAddToCart: boolean = true,
        price: undefined | number = undefined,
        list: undefined | string = undefined,
        variantSku: string
    ) {
        try {
            const eventName = isAddToCart ? 'addToCart' : 'removeFromCart';
            const dataObj: any = {
                products: [this.formatProduct(product, Math.abs(quantity), price, variantSku)]
            };
            if (list) {
                dataObj.actionField = { list: list };
            }
            const data: object = {};
            data[isAddToCart ? 'add' : 'remove'] = dataObj;
            doDataLayerEcomPush(eventName, data);
        } catch (e) {}
    }

    public trackModifyCartMany(
        items: {
            product: v4.Products.ProductSimple;
            quantity: number;
            quantityAcum: number;
            price?: undefined | number;
            variantSku: string;
        }[],
        list: undefined | string = undefined,
        isAddToCart: boolean = true
    ) {
        try {
            const eventName = isAddToCart ? 'addToCart' : 'removeFromCart';
            const dataObj: any = { products: [] };
            if (list) {
                dataObj.actionField = { list: list };
            }
            items.map((productItem) => {
                dataObj.products.push(
                    this.formatProduct(
                        productItem.product,
                        Math.abs(productItem.quantityAcum),
                        productItem.price,
                        productItem.variantSku                    )
                );
            });
            const data: object = {};
            data[isAddToCart ? 'add' : 'remove'] = dataObj;
            doDataLayerEcomPush(eventName, data);
        } catch (e) {}
    }

    public trackCheckoutStep(
        stepIndex: number,
        products: tagManagerBasketProduct[] | undefined = undefined,
        option: undefined | string = undefined
    ) {
        try {
            const data: {
                checkout: { actionField: { step: number; option?: string }; products?: tagManagerBasketProduct[] };
            } = {
                checkout: {
                    // note: if we have some step data for tracking (like payment method etc), it can be added as 'option' to actionField.
                    actionField: { step: stepIndex }
                }
            };
            if (option) {
                data.checkout.actionField.option = option;
            }
            if (products) {
                data.checkout.products = products;
            }
            doDataLayerEcomPush('checkout', data);
        } catch (e) {}
    }

    public trackTransactionComplete(
        id: string | number,
        products: BasketLineWithNewProduct[],
        total: string | number,
        tax: string | number,
        shipping: string | number = 0
    ) {
        try {
            const trackingProducts = products.map((p) => this.formatLineItemProductToTrackingStyle(p, products));
            const data = {
                purchase: {
                    actionField: {
                        id,
                        affiliation: 'webshop', // TODO: if support for pick up in store, add store id / name here.
                        revenue: total,
                        tax,
                        shipping
                    },
                    products: trackingProducts
                }
            };
            doDataLayerEcomPush('transactionComplete', data);
        } catch (e) {}
    }

    public formatProduct(
        product: v4.Products.ProductSimple,
        quantity: number,
        price: undefined | number = undefined,
        variantSku: string | null = null
    ): tagManagerBasketProduct | null {
        try {
            const variant = product.variants.find((x) => x.sku === variantSku);

            const obj: any = {
                name: product.name,
                id: product.id,
                price: price || product.variants[0].pricing.unit.amount,
                quantity: quantity,
                category: product.categoryIds?.length > 0 ? product.categoryIds[0] : '',
                variant: variantSku || undefined,
                dimension4: googleTagManagerUtils.catalog.formatProductComplianceToDimension4(product),
                dimension6: googleTagManagerUtils.basket.formatProductToDimension6(product, variant),
                dimension7: variant ? this.formatProductToDimension7(variant) : undefined,
                dimension8: this.formatProductToDimension8(product, variant),
                dimension9: formatProductShortDescriptionToTracking(product),
                dimension10: product.tracking?.descriptionLength ?? 0,
                dimension11: this.formatProductToDimension11(variantSku || '')
            };

            return obj;
        } catch (e) {
            return null;
        }
    }

    public formatProductToDimension7(variant: v4.Products.VariantSimple): string | undefined {
        return formatProductVariantSalesColorToTracking(variant);
    }

    public formatProductToDimension6(product: v4.Products.ProductSimple, variant: v4.Products.VariantSimple | undefined): string | undefined {
        return formatProductVariantStockToTracking(product, variant);
    }

    public formatProductToDimension8(product: v4.Products.ProductSimple, variant?: v4.Products.VariantSimple): number {
        return formatProductVariantImageCountToTracking(product, variant);
    }

    public formatProductToDimension11(sku: string, products: BasketLineWithNewProduct[] | null = null): number {
        return getProductCountInBasketOrOrderReceipt(sku, products);
    }

    public formatLineItemProductToTrackingStyle(
        line: BasketLineWithNewProduct,
        products: BasketLineWithNewProduct[] | null = null,
    ): tagManagerBasketProduct | null {
        try {
            const variant = line.product.variants.find((l) => l.sku === line.sku);

            const product = line.product;
            const obj: tagManagerBasketProduct = {
                name: product.name,
                id: product.id,
                price: variant ? variant.pricing.unit.amount : product.variants[0].pricing.unit.amount,
                quantity: line.quantity,
                brand: formatProductBrandNameToTracking(product),
                category: product.categoryIds.length > 0 ? product.categoryIds[0] : '',
                variant: line.sku,
                dimension4: googleTagManagerUtils.catalog.formatProductComplianceToDimension4(product),
                dimension6: googleTagManagerUtils.basket.formatProductToDimension6(product, variant),
                dimension7: variant ? this.formatProductToDimension7(variant) : undefined,
                dimension8: this.formatProductToDimension8(product, variant),
                dimension9: formatProductShortDescriptionToTracking(product),
                dimension10: formatProductLongDescriptionToTracking(product),
                dimension11: this.formatProductToDimension11(line.sku, products)
            };
            return obj;
        } catch (e) {
            return null;
        }
    }
}
const maxGTMPackageSize = 8000;
class ProductImpressionController {
    private productImpressions: tagManagerProduct[] = [];
    private promotionImpressions: any[] = [];
    private debouncedDoTrackProductImpressions = debounce(this.doTrackProductImpressions, 400);
    private debouncedDoTrackPromotionImpressions = debounce(this.doTrackPromotionImpressions, 400);

    public trackProductImpressions(
        products: v4.Products.ProductSimple[],
        trackingListName: string,
        position: number | undefined = undefined,
        variant: v4.Products.VariantSimple | undefined = undefined
    ) {
        try {
            // Skip empty products, map the rest
            const productsForTracking: tagManagerProduct[] = [];
            products
                .filter((product) => product)
                .map((product, index) => {
                    const p = this.formatProductToGoogleTrackingStyle(
                        product,
                        position !== undefined ? position : index + 1,
                        trackingListName,
                        variant
                    );
                    if (p) {
                        productsForTracking.push(p);
                    }
                });

            if (!productsForTracking.length) return;
            this.productImpressions = concat(this.productImpressions, productsForTracking);
            this.debouncedDoTrackProductImpressions();
        } catch (e) {}
    }

    public trackPromotionImpressions(promotions) {
        try {
            if (!promotions) return;
            this.promotionImpressions = concat(this.promotionImpressions, promotions);
            this.debouncedDoTrackPromotionImpressions();
        } catch (e) {}
    }

    public trackProductClick(
        product: v4.Products.ProductSimple,
        trackingListName: string,
        position: number | undefined = undefined,
        variant: v4.Products.VariantSimple | undefined = undefined
    ) {
        try {
            if (!product) return;
            doDataLayerEcomPush('productClick', {
                click: {
                    actionField: {
                        list: trackingListName
                    },
                    products: [this.formatProductToGoogleTrackingStyle(product, position, undefined, variant)]
                }
            });
        } catch (e) {}
    }

    public formatProductComplianceToDimension4(product: v4.Products.ProductSimple): string {
        return formatProductLabelingToTracking(product);
    }

    public formatProductToGoogleTrackingStyle(
        product: v4.Products.ProductSimple,
        position?: number,
        list?: string,
        variant?: v4.Products.VariantSimple
    ): tagManagerProduct | null {
        try {
            const payload: any = {
                name: product.name,
                id: product.id,
                brand: formatProductBrandNameToTracking(product),
                category: product.categoryIds.length > 0 ? product.categoryIds[0] : '',
                dimension4: googleTagManagerUtils.catalog.formatProductComplianceToDimension4(product),
                dimension6: googleTagManagerUtils.basket.formatProductToDimension6(product, variant),
                dimension8: googleTagManagerUtils.basket.formatProductToDimension8(product, variant),
                dimension9: product.tracking?.shortDescriptionLength ?? 0,
                dimension10: product.tracking?.descriptionLength ?? 0
            };
            payload.dimension4 = this.formatProductComplianceToDimension4(product);
            if (variant) {
                payload.variant = variant.sku;
                payload.price = variant.pricing.unit.amount;
                payload.dimension7 = googleTagManagerUtils.basket.formatProductToDimension7(variant);
            } else {
                payload.price = product.variants[0].pricing.unit.amount;
            }
            if (position !== undefined) {
                payload.position = position;
            }
            if (list) {
                payload.list = list;
            }
            return payload;
        } catch (e) {
            return null;
        }
    }

    // TODO read all props from ProductDetails['tracking'] when available
    public formatProductDataToGoogleTrackingStyle(options: {
        name: string;
        price: number;
        id: string;
        variantSku: string;
        variantColorName?: string;
        brandName?: string;
        shortDescriptionLength?: number;
        description?: string;
        category: string;
        labelling?: string;
        inventory?: InventoryState;
        numberOfImages?: number;
        position?: number;
        list?: string;
    }): tagManagerProduct | null {
        const {
            name,
            price,
            id,
            variantSku,
            variantColorName,
            brandName = 'Søstrene Grene',
            shortDescriptionLength,
            description,
            category,
            labelling,
            inventory,
            numberOfImages = 0,
            position,
            list
        } = options;

        try {
            const payload: any = {
                name: name,
                id: id,
                brand: brandName,
                category: category,
                variant: variantSku,
                price: price,
                dimension4: labelling,
                dimension6: inventory ? formatInventoryStateToTrackingState(inventory) : undefined,
                dimension7: variantColorName ?? undefined,
                dimension8: numberOfImages,
                dimension9: shortDescriptionLength,
                dimension10: description?.length ?? 0
            };

            if (position !== undefined) {
                payload.position = position;
            }

            if (list) {
                payload.list = list;
            }

            return payload;
        } catch (e) {
            return null;
        }
    }

    private doTrackProductImpressions() {
        try {
            if (!this.productImpressions.length) return;
            // Dont push more than 8 KB to Google Tag Manager, so split into chunks.
            const chunks = Math.ceil(JSON.stringify(this.productImpressions).length / maxGTMPackageSize);
            const pushes = chunk(this.productImpressions, this.productImpressions.length / chunks);
            pushes.map((push) => {
                const trackData = {
                    event: 'productImpressions',
                    ecommerce: {
                        currencyCode: serverContext.currencyCode,
                        impressions: push
                    }
                } as any;
                getDataLayer().push(trackData);
            });
            this.productImpressions = [];
        } catch (e) {}
    }

    private doTrackPromotionImpressions() {
        try {
            if (!this.promotionImpressions.length) return;
            const trackData = {
                promoView: {
                    promotions: this.promotionImpressions
                }
            };
            doDataLayerEcomPush('promotionImpressions', trackData);
            this.promotionImpressions = [];
        } catch (e) {}
    }
}
class DiyImpressionController {
    public trackDiyClick(diy: Diy, trackingListName: string, position: number | undefined = undefined) {
        try {
            doDataLayerEcomPush('productClick', {
                click: {
                    actionField: {
                        list: trackingListName
                    },
                    products: [this.formatDiyToTrackingStyle(diy, undefined, position)]
                }
            });
        } catch (e) {}
    }

    public formatDiyToTrackingStyle(diy: Diy, listName?: string, position?: number): tagManagerDiy | null {
        try {
            const payload: tagManagerDiy = {
                id: diy.id,
                name: diy.title,
                category: `DIY/${diy.categoryName}`
            };
            if (position !== undefined) {
                payload.position = position;
            }
            return payload;
        } catch (e) {
            return null;
        }
    }
}
class TopicImpressionController {
    public trackTopicClick(topic: tagManagerTopic | null, trackingListName: string, position: number = 0) {
        try {
            const t: tagManagerTopic | undefined = !topic ? undefined : topic;
            doDataLayerEcomPush('productClick', {
                click: {
                    actionField: {
                        list: trackingListName
                    },
                    products: [this.formatTopicToTrackingStyle(t, position)]
                }
            });
        } catch (e) {}
    }

    public formatTopicToTrackingStyle(
        topic: tagManagerTopic | undefined = undefined,
        position: number = 0
    ): tagManagerTopic | null {
        try {
            const id: string = topic ? topic.id || topic.name + '_' + position : spaStore.metadata.id;
            const t: tagManagerTopic = {
                name: topic ? topic.name : spaStore.metadata.seoTitle,
                id,
                category: topic ? topic.category : 'topic'
            };
            return t;
        } catch (e) {
            return null;
        }
    }
}

class OverlayController {
    public trackOverlayOrMinipage(data: OverlayEvent) {
        doDataLayerPush(data);
    }
}
class FavoritesController {
    public trackAddToWishlist(
        eventAction: string | undefined,
        eventLabel: string | undefined,
        product: v4.Products.ProductSimple | undefined,
        listName: string | undefined,
        variantSku: string | undefined
    ) {
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'Favorit',
            eventAction: eventAction || spaStore.metadata.navigationTitle,
            eventLabel: eventLabel || undefined,
            eventValue: undefined
        });

        if (product && variantSku) {
            // GTM v4 product add_to_wishlist tracking, but not for remove
            doDataLayerEcomPushv4(
                'add_to_wishlist',
                formatProductsToGTMv4Items([{ product: product, variantSku: variantSku, quantity: 1 }]),
                listName,
                eventLabel
            );
        }
    }

    public trackRemoveFromWishlist(eventAction: string | undefined, eventLabel: string | undefined) {
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'Favorit_Remove',
            eventAction: eventAction || spaStore.metadata.navigationTitle,
            eventLabel: eventLabel || undefined,
            eventValue: undefined
        });
    }
}

class SearchController {
    public trackQuickLinkClick(popularTerm: string, eventLabel: 'Suggest' | 'NoResults' | 'quicklink' | 'latest' | 'term_suggestion' | 'category_suggestion' | 'category_link' | 'continuation' | 'did_you_mean') {
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'searchQuicklinksClick',
            eventAction: popularTerm,
            eventLabel: eventLabel,
            eventValue: undefined
        });
    }

    public trackSearchPreview(searchTerm: string, productCount: number, momentCount: number) {
        doDataLayerPush({
            event: 'searchOverlayResultPage',
            productCount: productCount,
            momentCount: momentCount,
            searchTerm: searchTerm
        });
    }

    public trackTileClick(searchTerm: string, type: 'Inspiration' | 'Product' | 'Diy', count: number) {
        doDataLayerPush({
            event: `Search${type}ResultClick`,
            count: count,
            searchTerm: searchTerm
        });
    }
}

class ErrorController {
    public track404(fullPath: string) {
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'Error Page',
            eventAction: '404',
            eventLabel: fullPath,
            eventValue: undefined
        });
    }
}

class StoreController {
    public trackSearch(addressOrGeolocation: string, isMyStore: boolean) {
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: isMyStore ? 'MyStore' : 'Store',
            eventAction: addressOrGeolocation,
            eventLabel: undefined,
            eventValue: undefined
        });
    }

    public trackOpenClick(addressOrGeolocation: string, storeName: string) {
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'Store',
            eventAction: addressOrGeolocation,
            eventLabel: 'Open:' + storeName,
            eventValue: undefined
        });
    }
}

class PromotionController {
    public trackPromotionClick(click: iTrackPromotionClickArgs) {
        const componentName = click.componentName || '';
        const creative = click.creativeText || '';
        const trackingNameField = click.trackingName || click.trackingTitle;
        const position = click.position || 0;
        doDataLayerEcomPush('promotionClick', {
            promoClick: {
                promotions: [
                    {
                        id: `${componentName}:${trackingNameField}_${getPageTypeForTracking()}_${position}`,
                        name: `${componentName}:${trackingNameField}`,
                        creative: creative,
                        position: position
                    }
                ]
            }
        });
    }
}

class FacetFilterController {
    private facetsToString(existingFacets: UrlFacets) {
        const eventLabel: string[] = [];
        Object.keys(existingFacets).forEach((key) => {
            const labels = existingFacets[key].map((label) => {
                return key + ':' + label;
            });
            eventLabel.push(labels.join(', '));
        });
        return eventLabel.join();
    }

    public trackClearFilter(existingFacets: UrlFacets) {
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'Facet',
            eventAction: 'Remove all facets',
            eventLabel: this.facetsToString(existingFacets),
            eventValue: undefined
        });
    }

    public trackFacetUse(
        actionPrefix: 'Add=' | 'Remove=' | '',
        facetGroup: string,
        facet: string | undefined,
        existingFacets: UrlFacets
    ) {
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'Facet',
            eventAction: actionPrefix + facetGroup + ':' + facet || '',
            eventLabel: this.facetsToString(existingFacets),
            eventValue: undefined
        });
    }

    public trackFacetUpdate(facetGroup: string, facet: string | undefined, existingFacets: UrlFacets) {
        this.trackFacetUse('', facetGroup, facet, existingFacets);
    }

    public trackFacetAdd(facetGroup: string, facet: string | undefined, existingFacets: UrlFacets) {
        this.trackFacetUse('Add=', facetGroup, facet, existingFacets);
    }

    public trackFacetRemove(facetGroup: string, facet: string | undefined, existingFacets: UrlFacets) {
        this.trackFacetUse('Remove=', facetGroup, facet, existingFacets);
    }

    public trackSortingChange(newSorting: string, existingSorting: string) {
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'Facet',
            eventAction: 'Sortering: ' + newSorting,
            eventLabel: 'Sortering: ' + existingSorting,
            eventValue: undefined
        });
    }
}

class NewsletterController {
    public trackNewsletterFormAction(submitAction: NewsletterFormAction, submitType: SubmitType | undefined) {
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'NewsletterSignup',
            eventAction: submitType,
            eventLabel: undefined,
            eventValue: undefined
        });
    }
}

class FormsController {
    public trackFormAction(action: FormAction, formName: string, eventLabel?: string) {
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: formName,
            eventAction: action,
            eventLabel: eventLabel,
            eventValue: undefined
        });
    }
}

export default class googleTagManagerUtils {
    public static basket = new BasketController();
    public static catalog = new ProductImpressionController();
    public static diy = new DiyImpressionController();
    public static topic = new TopicImpressionController();
    public static favorites = new FavoritesController();
    public static overlay = new OverlayController();
    public static search = new SearchController();
    public static error = new ErrorController();
    public static store = new StoreController();
    public static promotion = new PromotionController();
    public static facetFilter = new FacetFilterController();
    public static newsletter = new NewsletterController();
    public static forms = new FormsController();
}
