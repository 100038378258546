<template>
    <div>
        <button class="c-additional-shipping-info -m-5 p-5"
                @click="showShippingTextOverlay">
            +
            <span class="c-additional-shipping-info__label">
                {{ additionalShippingInfo.label }}
            </span>
        </button>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { v4 } from '@/types/serverContract';
import bus from '@/core/bus';
import { OpenShippingInformationKey } from '@/project/config/constants';

const props = defineProps<{
    details: v4.Products.ProductDetails;
}>();

const additionalShippingInfo = computed(() => props.details.additionalShippingInfo);

const showShippingTextOverlay = () => {
    bus.emit(OpenShippingInformationKey, additionalShippingInfo.value);
};

</script>

<style lang="less" scoped>
.c-additional-shipping-info {
    font: inherit;

    &__label {
        text-decoration: underline;
    }

    &:hover,
    &:focus {
        .c-additional-shipping-info__label {
            text-decoration: none;
        }
    }
}

:deep(.wysiwyg) {
    p {
        margin-bottom: 3.2rem;
    }
}
</style>
