import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import userStore from '@/store/user.store';
import serverContext from '@/core/serverContext.service';

function authenticationResponseInterceptor(response: AxiosResponse) {
    // If we get a token in the response, set it in store
    if ((response.status >= 200 && response.status < 300) && response.data?.token) {
        userStore.setToken(response.data.token);
    }
    return response;
}

function authenticationRequestInterceptor(value: AxiosRequestConfig): AxiosRequestConfig {
    // Only add Bearer token for local URLs (it took some time to figure out why Google Maps stopped working)
    if (userStore.token && value.url?.startsWith('/')) {
        value.headers.Authorization = `Bearer ${userStore.token}`;
    }
    if ((!serverContext.hasLogin && (value.url?.indexOf('/umbraco/api/account') ?? -1) === 0)) {
        throw new axios.Cancel('No Login at site');
    }
    return value;
}
axios.interceptors.response.use(authenticationResponseInterceptor);
axios.interceptors.request.use(authenticationRequestInterceptor);
