<template>
    <article class="w-full h-full"
             :class="lightOrDarkTheme">
        <div class="mb-10 relative overflow-hidden">
            <div class="opacity-10"
                 :class="bgClass"
                 :style="skeletonImageBg"/>
            <div class="u-shimmer"/>
        </div>
        <div class="px-10 md:flex md:justify-between">
            <div class="mb-5 block h-15 flex-1 mr-40 md:mb-0 relative">
                <span class="absolute inset-0 opacity-10"
                      :class="bgClass"/>
                <span class="u-shimmer"/>
            </div>
            <div class="block h-15 flex-1 max-w-60 relative">
                <span class="absolute inset-0 opacity-10"
                      :class="bgClass"/>
                <span class="u-shimmer"/>
            </div>
        </div>
    </article>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import LightOrDarkColor from '@/project/shared/LightOrDarkColor';

const props = withDefaults(defineProps<{
    bgTheme?: string | null;
    aspectRatio?: number;
}>(), {
    bgTheme: undefined,
    aspectRatio: 311 / 400
});

const bgClass = computed(() => {
    const bgClass = props.bgTheme ? LightOrDarkColor(props.bgTheme) : '';
    return bgClass && bgClass === 'dark' ? 'bg-white-100' : 'bg-white-400';
});

const lightOrDarkTheme = computed(() => {
    const lightOrDark = props.bgTheme ? LightOrDarkColor(props.bgTheme) : 'light';
    return `${lightOrDark}-theme`;
});

const skeletonImageBg = computed(() => {
    const paddingTop = `${(100 / (props.aspectRatio ?? 311 / 400)).toFixed(2)}%`;
    return { paddingTop };
});
</script>

<style lang="less" scoped>
.u-shimmer {
    @apply absolute inset-0 h-full w-full overflow-hidden z-2;
    &::after {
        @apply inset-0 absolute;
        content: '';
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.10) 50%, rgba(255,255,255,0) 100%);
        transform: translateX(-100%);
        animation: productShimmer 2s infinite;

        .light-theme & {
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.40) 50%, rgba(255,255,255,0) 100%);
        }
    }
}
@keyframes productShimmer {
    100% {
      transform: translateX(100%);
    }
}
</style>
