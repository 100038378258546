<template>
    <Teleport v-if="active"
              :to="`[data-teleport-target='${portal}']`">
        <div class="c-teleport-overlay fixed inset-0"
             :class="[zClass, outerClass]"
             role="dialog"
             v-bind="$attrs">
            <transition
                appear
                :enter-active-class="animationEnterClass"
                :leave-active-class="animationLeaveClass"
                @after-enter="contentVisible"
                @after-leave="cleanup">
                <div
                    v-if="visible"
                    :key="id"
                    ref="contentWrapper"
                    class="c-teleport-overlay__content-wrapper u-scroll-bar-hide fixed w-full overflow-x-hidden focus:outline-none"
                    :class="[overlayClasses, { 'portal-content-is-scrolled': scrollPosition }]"
                    @scroll="debounceScroll">
                    <slot v-if="showCloseButton && showStickyHeader && !disableUserClose"
                          name="header">
                        <div class="c-teleport-overlay__header">
                            <button
                                class="c-teleport-overlay__close"
                                :aria-label="$translate('generic.Close')"
                                @click="userCancel()">
                                <c-icon name="close"
                                        width="16"/>
                            </button>
                        </div>
                    </slot>
                    <button
                        v-else-if="showCloseButton && !disableUserClose"
                        class="c-teleport-overlay__close"
                        :aria-label="$translate('generic.Close')"
                        @click="userCancel()">
                        <c-icon name="close"
                                width="16"/>
                    </button>
                    <slot :scroll-position="scrollPosition"/>
                </div>
            </transition>
            <slot name="footer"/>
            <transition
                appear
                enter-active-class="animated fadeIn u-anim-dur-300"
                leave-active-class="animated fadeOut u-anim-dur-1000">
                <page-blind v-if="visible && showBlind"
                            :click-handler="userCancel"/>
            </transition>
        </div>
    </Teleport>
</template>
<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { SCROLL_TO_TOP } from '@/project/config/constants';
import bus from '@/core/bus';
import keyboardService from '@/core/keyboard.service';
import scrollService from '@/core/scroll/scroll.service';
import Deferred from '@/core/async/deferred';
import PageBlind from '@/project/layout/page-blind/PageBlind.vue';
import throttle from 'lodash-es/throttle';
import ITeleportOverlay from './ITeleportOverlay';
import useOverlay from '@/core/teleport-overlay/useOverlay';

const emit = defineEmits(['visible', 'cancelled', 'update:show']);

const { resetTabOrder, beginActivation, cleanupOverlays, setOverlayInitiator, overlayInitiator } = useOverlay();

const props = defineProps({
    side: {
        type: String,
        default: 'fade',
        validator: (value: string) => ['left', 'right', 'top', 'center', 'fade', 'bottom'].includes(value)
    },
    portal: { type: String, default: 'overlay' },
    show: { type: Boolean, default: false },
    showStickyHeader: { type: Boolean, default: false },
    showCloseButton: { type: Boolean, default: true },
    showBlind: { type: Boolean, default: true },
    contentAutoScroll: { type: Boolean, default: true },
    disableBodyScroll: { type: Boolean, default: true },
    outerClass: { type: String, default: '' },
    zClass: { type: String, default: 'z-overlay' },
    wrapperClass: { type: String, default: '' },
    disableUserClose: { type: Boolean, default: false },
    closeOnRouteChange: { type: Boolean, default: false },
    vetoClose: { type: Function, default: () => Promise.resolve(false) },
    focusOverlay: { type: Boolean, default: true }
});

const state = ref<'ready' | 'waiting_for_other_portal' | 'visible' | 'animating_out'>('ready');
const animatedOutDeferred = ref<Deferred<void> | null>(null);
const scrollPosition = ref(0);
const debounceScroll = throttle((e) => handleScroll(e), 100);

const id = computed(() => props.portal + new Date().valueOf());
const active = computed(() => state.value === 'visible' || state.value === 'animating_out');
const visible = computed(() => state.value === 'visible');

const route = useRoute();

const contentWrapper = ref<HTMLElement | null>(null);

const portalOverlay: ITeleportOverlay = {
    portal: props.portal,  
    close: close,
    id: id.value,
};

onMounted(() => {
    bus.on(SCROLL_TO_TOP, scrollTo);
    if (props.show) {
        doShow(true);
    }
});

onBeforeUnmount(() => {
    bus.off(SCROLL_TO_TOP, scrollTo);

    if (state.value !== 'visible') return; // All other states are very temporary - no handling...
    close();
    cleanup();
});

watch(() => route.path, () => {
    if (props.closeOnRouteChange) {
        close();
    }
});

watch(() => props.show, (show) => {
    doShow(show);
});

async function doShow(show: boolean) {
    if (show && state.value === 'ready') {
        
        // Await that a another PortalOverlay using same portal has animated out.
        state.value = 'waiting_for_other_portal';
        await beginActivation(portalOverlay);
        state.value = 'visible';
    } else if (!show) {
        cleanupTabOrder();
        close();
    }
}

function close(): Promise<void> {
    if (state.value !== 'visible') return Promise.resolve();
    state.value = 'animating_out';

    emit('visible', false);
    document.removeEventListener('keyup', keyUp);
    if (props.disableBodyScroll) {
        scrollService.enableBodyScroll(contentWrapper.value as HTMLElement);
    }

    animatedOutDeferred.value = new Deferred<void>();
    return animatedOutDeferred.value.promise;
}

function cleanup() {
    state.value = 'ready';
    animatedOutDeferred.value?.resolve();
    emit('update:show', false);
    cleanupOverlays(portalOverlay);
}

function contentVisible(el: Element) {
    emit('visible', true);
    document.addEventListener('keyup', keyUp);
    if (props.disableBodyScroll) {
        scrollService.disableBodyScroll(el as HTMLElement, {
            allowTouchMove: (el) => {
                while (el && el !== document.body) {
                    if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                        return true;
                    }
                    el = el.parentElement;
                }
            }
        });
    }
    hijackTabOrder();
}

function hijackTabOrder() {
    // save the currently active element to be able to reset focus to it
    // only set if not already and if we should set it
    if(props.focusOverlay && !overlayInitiator.value) setOverlayInitiator();

    // If there is already focus inside the overlay, don't change the focus
    const focusedElementInsideOverlay = contentWrapper.value?.querySelector(':focus');
    if (focusedElementInsideOverlay) return;

    // Set tabindex to the contentwrapper only to make the next focusable element the first inside the wrapper
    contentWrapper.value?.setAttribute('tabindex', '-1');
    contentWrapper.value?.focus();
}

function cleanupTabOrder() {
    contentWrapper.value?.removeAttribute('tabindex');
    // Reset focus to the initiator of the overlay
    resetTabOrder();
}

function keyUp(event: KeyboardEvent) {
    if (!keyboardService.isEscape(event)) {
        return;
    }
    userCancel();
}

function userCancel() {
    if (props.disableUserClose) {
        return;
    }
    
    props.vetoClose().then((doVetoClose) => {
        if (!doVetoClose) {
            emit('cancelled');
            close();
        }
    });
}

const overlayClasses = computed(() => {
    return {
        [props.wrapperClass]: true,
        [props.side]: true,
        'overflow-y-scroll md:overflow-y-scroll': props.contentAutoScroll
    };
});

const animationEnterClass = computed(() =>  {
    const animTypes = {
        left: 'slideInLeftOpacity',
        right: 'slideInRightOpacity',
        top: 'slideInDown',
        bottom: 'slideInUp',
        fade: 'fadeIn'
    };
    return animationClass(animTypes);
});

const animationLeaveClass = computed(() => {
    const animTypes = {
        left: 'slideOutLeftOpacity',
        right: 'slideOutRightOpacity',
        top: 'slideOutDown',
        bottom: 'slideOutDown',
        fade: 'fadeOut'
    };
    return animationClass(animTypes);
});

function animationClass(animTypes: { [side: string]: string }) {
    return `animated ${animTypes[props.side]} u-anim-delay-100 u-anim-dur-300 md:u-anim-dur-600`;
}

function handleScroll(e: Event) {
    scrollPosition.value = (e.target as HTMLElement).scrollTop;
}

function scrollTo(scrollVal = 0) {
    if (contentWrapper.value) {
        contentWrapper.value.scrollTo({ top: scrollVal, behavior: 'smooth' });
    }
}

</script>

<style lang="less">
// Fixes Chrome-browser rendering issue, where content underneath overlay wasn't rendered after anim out
@keyframes slideInRightOpacity {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        opacity: 0;
        animation-duration: 300ms;
    }

    1% {
        opacity: 0.9;
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.slideInRightOpacity {
    -webkit-animation-name: slideInRightOpacity;
    animation-name: slideInRightOpacity;
}

.slideOutRightOpacity {
    -webkit-animation-name: slideInRightOpacity;
    animation-name: slideInRightOpacity;
    animation-direction: reverse;
}

@keyframes slideInLeftOpacity {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
        opacity: 0;
        animation-duration: 300ms;
    }

    1% {
        opacity: 0.9;
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.slideInLeftOpacity {
    -webkit-animation-name: slideInLeftOpacity;
    animation-name: slideInLeftOpacity;
}

.slideOutLeftOpacity {
    -webkit-animation-name: slideInLeftOpacity;
    animation-name: slideInLeftOpacity;
    animation-direction: reverse;
    animation-duration: 200ms;
}
</style>
