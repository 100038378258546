<template>
    <div>
        <slot :facets="facets"
              :active-sort-by-key="activeSortByKey"
              :products="products"
              :total-count="totalProductsCount"/>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch, nextTick} from 'vue';
import Api from '@/project/http/api';
import urlHelper from '../facets/urlHelper.service';
import { FacetGroup, ProductSearchResultNew, v4 } from '@/types/serverContract';
import { doDataLayerPush } from '@/project/tracking/googleTagManager.utils';
import { format, isPrerenderUseragent } from '../shared/string.util';
import DomService from '@/core/dom.service';
import spaStore from '@/core/spa/store/spa.store';
import userStore from '@/store/user.store';
import ScrollService from '@/core/scroll/scroll.service';
import { useRoute } from 'vue-router';

const props = withDefaults(defineProps<{
    categoryId?: string;
    brandId?: string;
    roomId?: string;
    trackingPageType: string;
    term: string;
    pageSize?: number;
    crossCategory?: string;
}>(), {
    pageSize: 28,
    categoryId: undefined,
    brandId: undefined,
    roomId: undefined,
    crossCategory: undefined
});

const emit = defineEmits(['searching']);
const searching = (isSearching: boolean) => emit('searching', isSearching);

const productSearchResult = ref<ProductSearchResultNew | null>(null);
const currentPage = ref(1);
const productsResult = ref<v4.Products.ProductSimple[]>([]);
const isFirstPageHit = ref(spaStore.isInitialPage); // the spa store will not change if just the category page is changing

onMounted(() => {
    if (isPrerenderUseragent()) {
        // Tell prerender to wait for search XHR
        // @ts-ignore
        window.prerenderReady = false;
    }
    search();
});

const totalProductsCount = computed(() => productSearchResult.value ? productSearchResult.value.total : 0 );
const facets = computed<FacetGroup[]>(() => productSearchResult.value ? productSearchResult.value.facets : []);
const products = computed<v4.Products.ProductSimple[]>(() => productsResult.value ? productsResult.value : []);
const activeSortByKey = computed(() => productSearchResult.value ? productSearchResult.value.sortBy : '');

const route = useRoute();
watch(() => route, (newRoute, oldRoute) => {
    if (newRoute.path === oldRoute.path) {
        search();
    }
}, { deep: true });

watch(() => props.term, () => {
    productSearchResult.value = null;
    search();
});

watch(() => props.categoryId, () => {
    productsResult.value = [];
    productSearchResult.value = null;
    search();
});

watch(() => props.brandId, () => {
    productsResult.value = [];
    productSearchResult.value = null;
    search();
});

watch(() => props.roomId, () => {
    productsResult.value = [];
    productSearchResult.value = null;
    search();
});

const search = async() => {
    // Only search if categoryId or term or crossCategory is set
    if (props.categoryId || props.brandId || props.term || props.crossCategory || props.roomId) {
        try {
            searching(true);

            // Adjust page and pagesize for first run, in order to get all products's up until current page.
            let realPageSize = props.pageSize;
            let realPage = urlHelper.getPage();
            if (!productSearchResult.value) {
                realPageSize = urlHelper.getPage() * props.pageSize;
                realPage = 1;
            }

            productSearchResult.value = await Api.catalog.search(
                props.term,
                props.categoryId,
                props.brandId,
                props.roomId,
                urlHelper.getFacets(),
                urlHelper.getSortBy(),
                realPage,
                realPageSize,
                props.crossCategory
            );

            if (productSearchResult.value) {
                if (!isFirstPageHit.value && realPage > 1) {
                    // Variables is used for tracking page changes on category pages
                    doDataLayerPush({
                        event: 'Variables',
                        LoggedInState: userStore.token ? 'LoggedIn' : 'LoggedOut',
                        pageType: spaStore.jsonContent && spaStore.jsonContent.alias
                    });
                }
                if (realPage === 1 && props.term) {
                    // Use timeout to allow Spa to finish and trigger 'Variables' event before this event
                    setTimeout(() => {
                        doDataLayerPush({
                            event: props.trackingPageType,
                            count: productSearchResult.value?.total,
                            searchTerm: props.term
                        });
                    }, 50);
                }
                const currentProducts = currentPage.value === urlHelper.getPage() ? [] : productsResult.value;
                const previousScrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
                productsResult.value = Object.freeze([...currentProducts, ...productSearchResult.value.searchResultItems]) as v4.Products.ProductSimple[];
                
                // format meta description with number of products.
                const metaDescription = format(spaStore.metadata.seoDescription, [productSearchResult.value?.total?.toString()]);
                DomService.setMetaDescription(metaDescription);
                DomService.setOpenGraphDescription(metaDescription);
                if (isPrerenderUseragent()) {
                    // Tell prerender that the XHR was completed, but only on success
                    // @ts-ignore
                    setTimeout(() => window.prerenderReady = true, 500);
                }

                if (productsResult.value?.length > 0) {
                    nextTick(() => {
                        ScrollService.simpleScroll(previousScrollPosition);
                    });
                }
                isFirstPageHit.value = false;
            }
        } finally {
            searching(false);
        }
    }
};
</script>

