<template>
    <div class="c-text-input"
         :class="customClasses">
        <div class="relative flex flex-col"
             :class="{
                 'c-text-input--show-label': hasFocus || hasValue(value),
                 'c-text-input--focus': hasFocus,
                 'c-text-input--disabled': isDisabled,
                 'c-text-input--error': false
             }">
            <label v-if="label"
                   class="c-text-input__label order-0"
                   :for="id">
                {{ label }}
            </label>
            <div class="c-text-input__input-wrap flex"
                 :class="{
                     [customClasses]: customClasses,
                     'c-text-input--input-not-empty': hasValue(value),
                     'c-text-input--disabled': isDisabled,
                     'c-text-input--has-clear': hasClearButton,
                     'c-text-input--placeholder-shown': placeholderShown,
                 }">
                <label v-if="iconName"
                       :for="id"
                       class="c-text-input__icon">
                    <c-icon :name="iconName"
                            width="20"
                            height="15"/>
                </label>
                <label v-if="valuePrefix"
                       :for="id"
                       class="c-text-input__value-prefix">{{ valuePrefix }}</label>
                <input
                    :id="id"
                    ref="input"
                    v-prohibit-zoom
                    class="c-text-input__input order-1"
                    :class="inputClasses"
                    :name="name"
                    :value="value"
                    :placeholder="placeholder"
                    v-bind="$attrs"
                    :type="type"
                    @input="onInput"
                    @blur="onBlur"
                    @focus="onFocus">
            </div>
            <transition mode="out-in"
                        enter-active-class="animated fadeIn u-anim-dur-200"
                        leave-active-class="animated fadeOut u-anim-dur-200">
                <button v-if="hasClearButton && hasValue(value)"
                        type="button"
                        class="c-text-input__clear h-15 w-15 rounded-full right-20 y-center flex items-center justify-center absolute bg-grey md:w-20 md:h-20"
                        :class="{ 'mt-8' : label }"
                        :aria-label="$translate('search.Overlay.ClearTermField')"
                        tabindex="-1"
                        @click.prevent="clearInput">
                    <c-icon name="close"
                            width="6"
                            height="6"/>
                </button>
                <button v-else-if="tooltipText"
                        ref="tooltipTrigger"
                        v-tracking="{ 'trigger': 'click', 'event' : 'overlay', 'overlayName': tooltipText }"
                        type="button"
                        class="absolute flex font-brandon items-center justify-center md:h-20 md:w-20 right-20 text-13 y-center"
                        :class="{ 'mt-8' : label }"
                        @click="shopTooltipChange(true)">
                    ?
                </button>
            </transition>
        </div>
        <div v-if="description"
             class="c-text-input__description mx-20 mt-5 text-15">
            {{ description }}
        </div>
        <div v-if="tooltipText"
             ref="tooltipContainer"
             class="absolute right-1/2 lg:right-0 top-0 lg:-top-40"/>
        <div v-if="tooltipText">
            <breakpoints>
                <template #max-md>
                    <VDropdown 
                        trigger="manual"
                        :shown="tooltipOpen"
                        placement="bottom-right"
                        :container="$refs.tooltipContainer"
                        :boundary="tooltipBounderyElement"
                        popover-class="tooltip--in-portal z-overlay md:w-400 lg:max-w-250 xl:max-w-310"
                        @update:shown="shopTooltipChange">
                        <template #popper>
                            <div v-html="tooltipText"/>
                        </template>
                    </VDropdown>
                </template>
                <template #min-lg>
                    <VDropdown 
                        trigger="manual"
                        :shown="tooltipOpen"
                        placement="right"
                        :container="$refs.tooltipContainer"
                        :boundary="tooltipBounderyElement"
                        popover-class="tooltip--in-portal z-overlay md:w-400 lg:max-w-250 xl:max-w-310"
                        @update:shown="shopTooltipChange">
                        <template #popper>
                            <div v-html="tooltipText"/>
                        </template>
                    </VDropdown>
                </template>
            </breakpoints>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, watch, computed, useAttrs } from 'vue';

const props = withDefaults(defineProps<{
    label?: string;
    name: string;
    type?: string;
    placeholder?: string;
    description?: string;
    isCustom?: boolean;
    customClasses?: string;
    inputClasses?: string;
    showClear?: boolean;
    autofocus?: boolean;
    selectTextOnFocus?: boolean;
    valuePrefix?: string;
    iconName?: string;
    tooltipText?: string;
    value: string | number | undefined
}>(), {
    label: '',
    type: 'text',
    placeholder: undefined,
    description: undefined,
    isCustom: false,
    customClasses: '',
    inputClasses: '',
    showClear: true,
    selectTextOnFocus: false,
    valuePrefix: undefined,
    iconName: undefined,
    tooltipText: undefined,
});

const emit = defineEmits(['input', 'blur']);
const attrs = useAttrs();

const hasFocus = ref(false);
const tooltipOpen = ref(false);
const tooltipBounderyElement = document.querySelector('.c-teleport-overlay .c-teleport-overlay__content-wrapper') || document.body;
const input = ref<HTMLInputElement | null>(null);
const tooltipTrigger = ref<HTMLButtonElement | null>(null);
const tooltipContainer = ref<HTMLDivElement | null>(null);

watch(() => props.autofocus, (value) => {
    if (value) {
        try {
            setTimeout(() => input.value && input.value.focus(), 400);
        } catch (e) {} // Safari sometimes
    }
}, { immediate: true });

const id = computed(() => props.name + new Date().valueOf());
const placeholderShown = computed(() => !props.value && props.placeholder);
const isDisabled = computed(() => !!attrs.disabled);
const hasClearButton = computed(() => props.showClear && !props.tooltipText);

const onInput = (ev) => {
    emit('input', ev.target.value);
};

const clearInput = () => {
    if (isDisabled.value) return;
    emit('input', '');
};

const onBlur = (ev) => {
    hasFocus.value = false;
    emit('blur', ev);
};

const onFocus = () => {
    if (isDisabled.value) return;
    hasFocus.value = true;
    if (props.selectTextOnFocus && input.value) {
        try {
            // selects text so that user can just type a new text
            input.value.select();
        } catch (e) {}
    }
};

const hasValue = (value) => {
    return value !== '' && value != null;
};

const shopTooltipChange = (state) => {
    tooltipOpen.value = state;
};

</script> 