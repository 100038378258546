import parsePhoneNumber from 'libphonenumber-js';
import { Phone } from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';

export interface EnhancedPhone extends Phone {
    formatted: string;
}

export default function formatPhone(
    phone: Phone,
    shouldRemoveLeadingZeroForSelectedCountryCode?: boolean
): EnhancedPhone {
    const currentMarket = serverContext.getCurrentMarketCountrySettings;
    const shouldFormat =
        shouldRemoveLeadingZeroForSelectedCountryCode ||
        (currentMarket.removeLeadingZero && phone.number.length === currentMarket.phoneMaxLength);

    let formatted = `${phone.phonePrefix} ${phone.number}`;
    const phoneNumber = parsePhoneNumber(`${phone.phonePrefix} ${phone.number}`);

    if (phoneNumber) {
        formatted = phoneNumber.formatInternational();
    }

    if (!shouldFormat) {
        return { ...phone, formatted };
    }

    if (phone.number?.startsWith('0')) {
        phone.number = phone.number.substring(1);
    }

    return {
        phonePrefix: phone.phonePrefix,
        number: phone.number,
        formatted
    };
}
