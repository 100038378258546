<template>
    <article class="relative">
        <div v-if="product">
            <div class="relative"
                 @mouseover="showDataOnHover = true"
                 @mouseleave="showDataOnHover = false">
                <RouterLinkConditional :to="productUrl ?? ''"
                                       class="block"
                                       :title="product.name"
                                       @click="trackProductClick($event, product, variant)">
                    <ProductTileImages :product="product"
                                       :selected-variant="relevantVariant"
                                       :override-image="content.image"
                                       :width-on-screen="widthOnScreen"
                                       :image-alt="productAltText"
                                       :image-aspect-ratio="imageAspectRatio"
                                       :use-environmental-images="content.useEnvironmentalImage ?? false"
                                       @on-swipe="setShouldShowIcons"/>
                </RouterLinkConditional>
                <TileVariants
                    v-if="product.variants.length > 1"
                    :product="product"
                    class="absolute top-0 left-0 m-12 z-1"/>
                <div v-if="hasLogin"
                     class="absolute z-5 top-0 right-0 overflow-hidden"
                     style="transform: translate3d(0, 0, 0);">
                    <ProductDetailsFavoriteToggle
                        v-if="relevantVariant"
                        :details="product"
                        :selected-variant="relevantVariant"
                        :tracking-object="favoriteTrackingObject"
                        type="PLP"
                        frame-styling="text-brown-60"
                        :icon-width="iconWidthAndHeight"
                        :icon-height="iconWidthAndHeight"
                        class="bg-sand-10 rounded-2 mt-10 mr-10 p-6 md:p-10 md:h-40 md:mt-12 md:mr-12"/>
                </div>
                <ProductTileLabel v-if="relevantVariant?.availabilityLabel && isEcom"
                                  :selected-variant="relevantVariant"
                                  styles="mb-12 ml-12 z-1 absolute bottom-0 left-0 transform transition-all duration-300 delay-300 will-change-transform pointer-events-none"/>
                <ProductTileIcons v-if="relevantVariant"
                                  class="absolute bottom-0 right-0 m-12 z-1"
                                  :class="{ 'pointer-events-none': !isEnergyIcon }"
                                  :selected-variant="relevantVariant" 
                                  :is-energy-icon="isEnergyIcon"
                                  :show-icons="showCompliance && (showIcons && !showDataOnHover)"/>
            </div>
            <div>
                <div v-show="content.text"
                     class="text-14 max-w-462 mb-8 mt-16 md:text-15 tracking-text font-gibson-light md:leading-20">
                    {{ content.text }}
                </div>
                <div v-if="relevantVariant"
                     class="relative mb-20 md:mb-21">
                    <div class="flex">
                        <div>
                            <RouterLinkConditional :to="product.url ?? ''"
                                                   class="block mt-8"
                                                   :title="product.name"
                                                   @click="trackProductClick($event, product, variant)">
                                <div v-if="shouldShowBrand"
                                     class="font-brandon-normal uppercase leading-10 mb-4 text-8 h-10 md:text-10"
                                     :class="themeTextColor('text-brown-70')">
                                    {{ product.brand?.name }}
                                </div>
                                <div class="block text-13 leading-14 font-normal mb-5 md:text-16 md:leading-18"
                                     :class="themeTextColor('text-brown-80')">
                                    {{ product.name }}
                                </div>
                                <div v-if="relevantVariant?.pricing.unit.formatted"
                                     class="font-gibson-light text-13 md:text-16">
                                    {{ relevantVariant?.pricing.unit.formatted }}
                                </div>
                            </RouterLinkConditional>
                        </div>
                        <div class="ml-auto mb-5 md:ml-56 mt-auto">
                            <ProductTileOpenVariantSelectorButton v-if="isAvailableForPurchase" 
                                                                  class="relative"
                                                                  :product="product" 
                                                                  :selected-variant="relevantVariant" 
                                                                  :inventory="relevantVariant.inventory"
                                                                  tabindex="1"/>
                        </div>
                    </div>
                    <div v-if="relevantVariant.pricing.comparison?.formatted"
                         class="text-8 font-gibson font-light mt-20 md:mt-21 md:text-10">
                        {{ relevantVariant.pricing.comparison?.formatted }}
                    </div>
                </div>
                <template v-if="relevantVariant">
                    <EcoChargeButton :selected-variant="relevantVariant"
                                     :text-theme-color="themeTextColor('text-brown-60')"/>
                    <ProductSheetLink class="relative z-1"
                                      :selected-variant="relevantVariant"
                                      :text-color="textColor"/>
                    <ShippingTextLink v-if="isAvailableForPurchase && product.additionalShippingInfo"
                                      class="relative z-1"
                                      :shipping-info="product.additionalShippingInfo"
                                      :text-color="textColor"/>
                </template>
            </div>
        </div>
        <div v-else-if="!product && content && !content.productId && content.image">
            <div class="mb-16">
                <ResponsiveImage
                    :width-on-screen="widthOnScreen"
                    :aspect-ratio="imageAspectRatio"
                    :mode="'crop'"
                    :image-url="content.image.url"
                    :focal-point="content.image.focalPoint"
                    :media-url-config="content.image.mediaUrlConfig"
                    class="u-image-zoom"
                    bg-color="#faf7f0"/>
            </div>
            <div class="text-14 max-w-462 md:text-15 tracking-text font-gibson-light md:leading-20">
                {{ content.text }}
            </div>
        </div>
    </article>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, inject, ref } from 'vue';
import { ProductFocusBlockPartModel, v4 } from '@/types/serverContract';
import { selectedVariant } from '@/project/product/productHelper.utils';
import useProductTracking from '@/project/product/useProductTracking';
import ProductTileImages from '@/project/product/ProductTileImages.vue';
import ProductTileOpenVariantSelectorButton from '@/project/product/ProductTileOpenVariantSelectorButton.vue';
import EcoChargeButton from '@/project/product/EcoChargeButton.vue';
import ProductSheetLink from '@/project/product/ProductSheetLink.vue';
import ShippingTextLink from '@/project/product/ShippingTextLink.vue';
import serverContextService from '@/core/serverContext.service';
import RouterLinkConditional from '@/core/RouterLinkConditional.vue';
import ProductTileLabel from '@/project/product/ProductTileLabel.vue';
import ProductDetailsFavoriteToggle from '@/project/product/details/ProductDetailsFavoriteToggle.vue';
import breakpointsStateObservable from '@/core/responsive/breakpoints/breakpointsState.observable';
import serverContext from '@/core/serverContext.service';
import { IProvideInjectTrackingData, trackingDataSymbol } from '@/types/frontend';
const ProductTileIcons = defineAsyncComponent(() => import('@/project/product/ProductTileIcons.vue'));
const TileVariants = defineAsyncComponent(() => import('@/project/product/variant/TileVariants.vue'));

const props = withDefaults(
    defineProps<{
        product?: v4.Products.ProductSimple | undefined,
        content: ProductFocusBlockPartModel,
        imageAspectRatio: Record<string, number>,
        variant?: string,
        showCompliance?: boolean,
        textColor?: string,
        widthOnScreen?: Record<string, number> | number,
        trackingIndex: number
    }>(),
    {
        product: undefined,
        variant: '',
        textColor: '',
        widthOnScreen: 100,
        showCompliance: true
    }
);

const hasLogin = computed(() => serverContext.hasLogin);

const relevantVariant = computed(()=> {
    if (!props.product) return;
    return selectedVariant(props.product.variants, props.variant ?? props.product.mostRelevantVariant);
});

const isEnergyIcon = computed(() => {
    return !!relevantVariant.value?.labellingsFlattened?.some(i => i.type === v4.Products.LabelType.EnergyClass);
});

const showIcons = ref<boolean>(isEnergyIcon.value && !(props.content.useEnvironmentalImage && relevantVariant.value?.environmentalImage));
const showDataOnHover = ref(false);

const setShouldShowIcons = (val)=> {
    showIcons.value = val;

    trackProductImpression(trackingData, 'swipe', props.product);
};

function themeTextColor(defaultColor: string = ''): string {
    return props.textColor === 'theme-2' ? 'text-theme-2' : defaultColor;
}

const isAvailableForPurchase = computed(() => {
    return serverContextService.hasCheckout &&
           (relevantVariant.value?.availability?.status === v4.Products.AvailabilityStatus.Available ||
           relevantVariant.value?.availability?.status === v4.Products.AvailabilityStatus.AvailableOnlySoldOnline);
});

// As of now, the server returns the product url with the most relevant variant,
// but that does not match the variant from CMS. Fixed for now in client
const productUrl = computed(()=> {
    if (!props.product) return;
    return props.variant ? variantUrl(props.product.url) : props.product.url;
});

function variantUrl(url): string {
    const [baseUrl, queryString] = url.split('?');
    const params = new URLSearchParams(queryString);
    params.set('variant', props.variant);

    return `${baseUrl}?${params.toString()}`;
}

const isEcom = computed(() => {
    return serverContextService.hasCheckout;
});

const iconWidthAndHeight = computed(() => {
    return breakpointsStateObservable.isBreakpointActive('min-md') ? '20' : '18';
});

const productAltText = computed(() => {
    if (!props.product) return;
    return [props.product.name, props.product.subtitle].join(' ');
});

const shouldShowBrand = computed(()=> {
    const smallScreenBreakpointActive = breakpointsStateObservable.isBreakpointActive('max-ls');
    // Always show element with brand name if above mobile, in order to reserve space for content
    if (!smallScreenBreakpointActive) {
        return true;
    }
    else if (smallScreenBreakpointActive && !props.product?.brand && props.content.text) {
        return false;
    }
    else {
        return true;
    }
});

const trackingData = inject<IProvideInjectTrackingData | undefined>(trackingDataSymbol, undefined);

const trackingObject = {
    ...trackingData,
    trackingIndex: props.trackingIndex
};

const { trackProductClick, trackProductImpression, getTrackingListName } = useProductTracking(trackingObject);

const favoriteTrackingObject = computed(() => {
    return {
        trackingEventProduct: props.product,
        trackingList: getTrackingListName(),
        trackingEventVariantSku: relevantVariant.value,
        trackingEventIndex: props.trackingIndex,
    };
});
</script>
