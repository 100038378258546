<template>
    <d-icon v-if="showIcon" 
            :key="name"
            :data="iconData"
            :name="name"
            :width="width"
            :height="height"
            :original="original"
            :alt="altText"
            :color="color"
            :fill="fill"/>
</template>

<script setup lang="ts">
import { watch, onBeforeMount, computed, ref } from 'vue';

const props = defineProps<{
        name: string;
        height?: string;
        width?: string;
        original?: boolean;
        alt?: string;
        color?: string;
        fill?: string;
    }>();
const showIcon = ref(false);

onBeforeMount(() => {
    importIcon(props.name);
});

watch(() => props.name, (newName) => {
    importIcon(newName);
});

const iconData = ref<string | undefined>();
const importIcon = (name: string) => {
    import(`../../assets/icons/${name}.svg`).then((svgModule) => {
        iconData.value = svgModule.default;
        showIcon.value = true;
    });
};

const altText = computed(() => {
    return props.alt || props.name;
});

</script>
