<template>
    <div v-if="props.selectedVariant.eprelLink"
         class="pt-8 leading-none">
        <a :href="props.selectedVariant.eprelLink.link"
           target="_blank"
           class="font-light text-12 font-gibson underline hover:no-underline"
           :class="themeTextColor('text-brown-60 decoration-color-brown-40')"
           :title="props.selectedVariant.eprelLink.title">
            {{ props.selectedVariant.eprelLink.title }}
        </a>
    </div>
</template>

<script setup lang="ts">
import { v4 } from '@/types/serverContract';

const props = defineProps<{
    selectedVariant: v4.Products.VariantSimple
    textColor: string
}>();

const themeTextColor = (defaultColor:string = ''): string => {
    return props.textColor === 'theme-2' ? 'text-theme-2 decoration-color-white-100' : defaultColor;
};

</script>
