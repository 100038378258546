<template>
    <div :class="displayBoxAsContents ? 'contents' : ''">
        <template v-if="shouldShowComponent">
            <slot :split-test-variation="activeVariation"/>
        </template>
        <template v-else-if="activeVariation">
            <slot name="rest"
                  :split-test-variation="activeVariation"/>
        </template>
        <slot v-if="activeVariation || isFallback"
              name="all"
              :split-test-variation="activeVariation"/>
    </div>
</template>

<script lang="ts" setup>
// NOTE: This component can be used to wrap other elements,
// and show them based on a GrowthBook experiment.
// When using this component, consider importing the components async,
// so that only the needed components gets loaded.
import { ref } from 'vue';
import useGrowthbook, { type GrowthBookType } from '@/project/growthbook/useGrowthbook';

const props = withDefaults(defineProps<{
    variation?: string;
    featureKey: string;
    defaultVariation: string;
    isFallback?: boolean;
    displayBoxAsContents?: boolean;
}>(), {
    isFallback: false,
    displayBoxAsContents: true,
    variation: '',
});

const shouldShowComponent = ref(false);
const { growthBook, getActiveVariation, isFeatureAvailable } = useGrowthbook();
const activeVariation = ref<string | null>(null);

growthBook.then((gb) => {
    setVisibility(gb);
});

const setVisibility = async(gb: GrowthBookType) => {
    const variationToUse = props.variation || props.defaultVariation;

    if (!variationToUse) {
        return;
    }

    const featureAvailable = await isFeatureAvailable(gb, props.featureKey);
    if (!gb || !featureAvailable) {
        shouldShowComponent.value = props.isFallback;
        return;
    }
    
    activeVariation.value = await getActiveVariation(props.featureKey);
    shouldShowComponent.value = variationToUse === activeVariation.value;
};
</script>
