import { App } from 'vue';

// Own config
import './breakpointConfig';

// Directives
import prohibitZoomConfig from '../form/prohibitZoom.directive';
import errorConfig from '../responsive/image/onError.directive';
import delegateHtmlLinksConfig from '../delegateHtmlLinks.directive';
import scrollTriggerConfig from '../scroll/scrollTrigger.directive';
import inViewConfig from '../in-view/inView.directive';

// Filters 
import { initTranslateFilter } from '../translation/translate.filter';
// Not being used, skipping for now
// import '../translation/pluralize.filter';

// All core component registrations
import componentRegistration from './coreComponentRegistrations';


export default async function configconfig(app: App): Promise<void> {
    errorConfig(app);
    prohibitZoomConfig(app);
    delegateHtmlLinksConfig(app);
    scrollTriggerConfig(app);
    inViewConfig(app);
    componentRegistration(app);
    initTranslateFilter(app);
    // dictionaryConfig(app);
    // responsiveConfig(app);
    // formsConfig(app);
    // domConfig(app);
    // layoutConfig(app);
    // navigationConfig(app);
    // renderingConfig(app);
}