import { createApp } from 'vue';
import App from './App.vue';
import './styling/main.less';
import initErrorHandling from './core/errorHandling';
import router from './core/spa/router'; // Custom naming https://stackoverflow.com/a/56374668/2664445
import coreConfig from './core/config';
import projectConfig from './project/config';
import useHeaderNavigation from '@/project/layout/page-header/useHeaderNavigation';
// const vueDebugEnabled = location.search.includes('$enable-vue-devtools$=true');
// if (vueDebugEnabled) {
//     Vue.config.devtools = true;
// }

const app = createApp(App);

initErrorHandling(app);

const { setNavigation } = useHeaderNavigation();
setNavigation();

coreConfig(app)
    .then(() => {
        projectConfig(app)
            .then(() => {
                app.use(router);
                router.isReady().then(() => app.mount('#app'));
            });
    });
