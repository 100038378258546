<template>
    <div ref="wrapperElm">
        <OverlayWrapper :open="show"
                        body-bg-class="bg-sand-10"
                        :veto-close="vetoClose"
                        styling="py-0 bg-sand-20"
                        @cancelled="close">
            <template #header>
                <OverlayHeader 
                    :veto-close="vetoClose"
                    @close="close">
                    <template v-if="storeInventory && isStockStatusMode">
                        {{ storeInventory.labels.description }}
                    </template>
                    <template v-else>
                        {{ $translate('store.MyStore') }}
                    </template>
                </OverlayHeader>
            </template>

            <VariantMiniTile
                v-if="isStockStatusMode && details && selectedVariant"
                :ref="(el) => (variantMiniTileRef as any) = el"
                class="mx-16 md:mx-40 py-16"
                :details="details"
                :selected-variant="selectedVariant"/>

            <div v-if="isStockStatusMode || isStorePickerMode"
                 class="sticky top-58 md:top-82 bg-sand-10 z-1">
                <Form :aria-label="$translate('store.SearchPostalCodeOrCity')"
                      class="pb-16 px-24 pt-24 md:pt-32 md:pb-24">
                    <TextInput
                        v-model.trim="searchValue"
                        :class="{'input-field-error ': showError}"
                        name="postalCodeOrCity"
                        autocomplete="off"
                        :inputmode="postalCodePatternByMarket.inputmode"
                        :pattern="postalCodePatternByMarket.pattern"
                        :constraints="validationRulePostalCodeFromCountry(countryCode)"
                        :label="$translate('store.SearchPostalCode')"
                        :placeholder="$translate('store.SearchPostalCodePlaceholder')"/>
                    <Transition appear
                                enter-active-class="animated fadeIn u-anim-dur-300"
                                leave-active-class="animated fadeOut u-anim-dur-300">
                        <p v-if="showError"
                           key="no-results"
                           class="text-red-500 text-14 mt-8 font-gibson-light">
                            {{ storeInventory?.labels.searchYieldedNoResults }}
                        </p>
                    </Transition>
                    <div v-if="isStockStatusMode"
                         class="mt-35 flex items-center justify-between md:mt-40">
                        <div class="font-brandon-medium text-12 uppercase leading-12 text-brown-80">
                            {{ $translate('store.ShowOnlyInStock') }}
                        </div>
                        <CheckboxSwitch v-model="showInStockOnly"/>
                    </div>
                </Form>
                <StockDisclaimer v-if="isStockStatusMode"/>
            </div>
            <template #body>
                <div>
                    <template v-if="isStockStatusMode || isStorePickerMode">
                        <div v-if="selectedStore && isStorePickerMode"
                             :ref="(el) => (selectedStoreRef as any) = el"
                             class="-mb-16">
                            <div class="leading-12 pt-16 -mb-8 font-brandon-regular text-10 uppercase">
                                {{ $translate('checkout.Droppoint.Selected.Store') }}
                            </div>
                            <Transition mode="out-in"
                                        appear
                                        enter-active-class="animated fadeIn u-anim-dur-300"
                                        leave-active-class="animated fadeOut u-anim-dur-300">
                                <StorePicker :key="`selectedStore${selectedStore.id}`"
                                             :store="selectedStore"
                                             :details="details"
                                             :mode="StoreOverlayMode.StockStatus"
                                             :opening-hours-expanded="true"
                                             :selected-variant="selectedVariant"/>
                            </Transition>
                        </div>
                        <div v-show="isStorePickerMode"
                             key="title"
                             class="pb-8 border-b border-brown-50"
                             :class="{'pt-40 md:pt-48': !selectedStore, 'mt-24': selectedStore }">
                            <p class="font-brandon-medium leading-24 uppercase text-14">
                                {{ $translate('store.Stores') }}
                            </p>
                        </div>
                        <div v-if="!isPending">
                            <StorePicker v-for="(store, i) in storeInventoryFiltered"
                                         :key="store.id+i"
                                         :store="store"
                                         :mode="mode"
                                         :status="storeInventory?.status"/>
                        </div>
                        <template v-else>
                            <div v-for="(item, i) in skeletonLoad"
                                 :key="i">
                                <StorePickerSkeleton :is-stock-status-mode="isStockStatusMode"
                                                     :is-store-picker-mode="isStorePickerMode"/>
                            </div>
                        </template>
                    </template>
                </div>
            </template>
        </OverlayWrapper>
    </div>
</template>

<script lang="ts" setup>
import { v4, StoreInventoryState } from '@/types/serverContract';
import { ref, watch, computed } from 'vue';
import VariantMiniTile from '@/project/product/details/VariantMiniTile.vue';
import OverlayHeader from '@/project/shared/overlay/OverlayHeader.vue';
import OverlayWrapper from '@/project/shared/overlay/OverlayWrapper.vue';
import StorePicker from '@/project/product/StorePicker.vue';
import debounce from 'lodash-es/debounce';
import { StoreOverlayMode, PendingState } from '@/types/frontend';
import userStore from '@/store/user.store';
import trackingUtils from '@/project/tracking/tracking.utils';
import useStoreWithInventory from '@/project/shared/useStoreWithInventory';
import StorePickerSkeleton from '@/project/product/StorePickerSkeleton.vue';
import StockDisclaimer from '@/project/product/StockDisclaimer.vue';
import bus from '@/core/bus';
import { SCROLL_TO_TOP } from '@/project/config/constants';
import sessionStorageService from '@/core/storage.service';
import serverContext from '@/core/serverContext.service';
import { validationRulePostalCodeFromCountry } from '../shared/form/zodValidations';
import CheckboxSwitch from '@/project/shared/form/CheckboxSwitch.vue';

const props = withDefaults(defineProps<{
    selectedVariant?: v4.Products.ProductDetailsVariant;
    details?: v4.Products.ProductDetails;
    show: boolean;
    mode: StoreOverlayMode,
    vetoClose?:() => Promise<boolean>
}>(), {
    selectedVariant: undefined,
    details: undefined,
    vetoClose: () => Promise.resolve(false)
});

const emit = defineEmits(['cancelled']);
const state = ref(PendingState.Resolved);
const show = ref(props.show);
const searchValue = ref<string | null>('');
const storeInventory = ref<v4.Stores.StoresWithInventorySearch | null>(null);
const wrapperElm = ref<Element | null>(null);
const skeletonLoad = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const debounceValue = 800;
const variantMiniTileRef = ref<typeof VariantMiniTile | null>(null);
const selectedStoreRef = ref<Element | null>(null);
const scrollToVal = computed(() => {
    return isStorePickerMode.value ? selectedStoreRef.value?.clientHeight : variantMiniTileRef.value?.$el.clientHeight;
});
const showInStockOnly = ref(false);
const sessionStorage = sessionStorageService.create();
const sessiontStorageKey = 'POSTAL_CODE';
const postalCodeFromSession = ref<string | null >('');
const countryCode = computed(() => serverContext.market);
const setPostalCodeFromSession = async() => {
    const val = await sessionStorage.getItem(sessiontStorageKey);
    return val;
};

async function trySetSearchValue() {
    postalCodeFromSession.value = await setPostalCodeFromSession();
    if (isStockStatusMode.value && postalCodeFromSession.value) {
        searchValue.value = postalCodeFromSession.value;
    } else {
        getStores();
    }
}

const getStores = async(searchValue?: string | null) => {
    state.value = PendingState.Pending;
    const { result } = await useStoreWithInventory(props.mode, searchValue, props.selectedVariant?.sku);
    storeInventory.value = await result;
    state.value = PendingState.Resolved;
    if (searchValue) {
        bus.emit(SCROLL_TO_TOP, scrollToVal.value);
        trackingUtils.store.trackSearch(`Address:${result.stores[0].street},${result.stores[0].postalCode}`, true);
    }
};

const postalCodePatternByMarket = computed((): { pattern: string | null; inputmode: string | null; regex: string } => {
    const postalPatternContainChart =
        serverContext?.getCurrentMarketCountrySettings?.postalCodePattern?.match('a-z') ?? false;
    const returnValue = postalPatternContainChart
        ? {
            pattern: null,
            inputmode: null,
            regex: serverContext?.getCurrentMarketCountrySettings?.postalCodePattern ?? ''
        }
        : {
            pattern: '[0-9]*',
            inputmode: 'numeric',
            regex: serverContext?.getCurrentMarketCountrySettings?.postalCodePattern ?? ''
        };

    return returnValue;
});

const debounceSearch = debounce((val) => search(val), debounceValue);

const search = async(val) => {
    if (!val) return;
    await getStores(val);
    setPostalCodeInSession(val);
};

const close = () => {
    emit('cancelled');
};

const showError = computed(() => {
    return state.value !== PendingState.Pending && searchValue.value?.length && storeInventory?.value?.status === v4.Stores.StoreSearchStatus.NoGeocodingMatch;
});

const favoritStoreId = computed(() => {
    return userStore.userFavoriteStore?.id;
});

const selectedStore = computed(() => {
    return storeInventory.value?.stores?.find(s => s.id === favoritStoreId.value);
});

const isPending = computed(() => {
    return state.value === PendingState.Pending;
});

const storeInventoryFiltered = computed(() => {
    const stores = isStorePickerMode.value ? storeInventory.value?.stores?.filter(s => s.id !== favoritStoreId.value) : storeInventory.value?.stores;

    if (!stores) return [];

    if (showInStockOnly.value) {
        return stores.filter(s => s.inventory?.some(i => i.state === StoreInventoryState.FewInStock || i.state === StoreInventoryState.InStock));
    } else {
        return stores;
    }
});

const isStockStatusMode = computed(() => {
    return props.mode === StoreOverlayMode.StockStatus;
});

const isStorePickerMode = computed(() => {
    return props.mode === StoreOverlayMode.StorePicker;
});

watch(searchValue, (val, oldVal) => {
    if (val && val !== oldVal) {
        debounceSearch(val);
    } else {
        debounceSearch.cancel();
    }
});

watch(() => props.selectedVariant?.sku, async(val, oldVal) => {
    if (val && val !== oldVal) {
        const postalCode = await setPostalCodeFromSession();
        getStores(postalCode);
    }
});

watch(
    () => props.show,
    (newValue) => {
        show.value = newValue;
        if (newValue) {
            trySetSearchValue();
            trackOverlayWrapperOpen();
        }
    },
    { immediate: true }
);

const trackOverlayWrapperOpen = () => {
    trackingUtils.navigation.trackOverlayOrMinipage({
        event: 'overlay',
        overlayName: isStorePickerMode.value ? 'productDetailsLabel_changeStore' : 'productDetailsLabel_storeStock'
    });
};

function setPostalCodeInSession(val) {
    if (!showError.value) {
        sessionStorage.setItem(sessiontStorageKey, val);
    }
}

</script>

<style lang="less" scoped>

.blur {
    filter: blur(3px);
}

.input-field-error :deep(.c-text-input__input-wrap) {
    @apply border-red-500 text-red-500;
}
.input-field-error :deep(.c-text-input__input) {
    outline-color: theme('colors.red-500');
}
.input-field-error :deep(.c-text-input__label) {
    @apply text-red-500;
}
</style>
