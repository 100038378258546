<template>
    <div v-if="showBreadcrumb"
         class="o-container print:hidden mb-11 xl:mt-20">
        <TransitionGroup name="list-fade"
                         tag="div"
                         class="mb-20 md:mb-0">
            <ul :key="currentPage.url"
                :class="justificationClass"
                :aria-label="$translate('generic.AriaLabels.Breadcrumb')"
                class="c-breadcrumb__current-page h-24 flex items-center mt-12 mb-11 overflow-hidden whitespace-nowrap tracking-medium font-normal">
                <template v-for="(item, ix) in breadcrumb">
                    <li v-if="ix === breadcrumb.length - 1 || showAllBreadCrumbs"
                        :key="item.url"
                        class="text-11 md:text-12 font-brandon uppercase text-brown flex items-center">
                        <c-icon name="chevron"
                                height="12"
                                width="12"
                                class="md:hidden mr-5 relative -ml-3 text-brown"/>
                        <RouterLink :to="item.url"
                                    class="text-brown pr-5 leading-none hover:text-brown-80 hover:underline active:text-brown-80 active:underline focus:text-brown-80 focus:underline"  
                                    :aria-label="item.name"
                                    :data-breadcrumb-link="ix">
                            {{ item.name }}
                        </RouterLink>
                        
                        <c-icon name="chevron"
                                height="12"
                                width="12"
                                class="hidden md:inline-block u-rotate-180 mr-5 relative text-brown"/>
                    </li>
                </template>
                <li class="hidden md:inline-block text-11 md:text-12 font-brandon uppercase text-brown-80 leading-none font-medium">  
                    <span class="pr-5 pb-5 text-brown-80"
                          :aria-label="currentPage.name">
                        {{ currentPage.name }}
                    </span>
                </li>
            </ul>
        </TransitionGroup>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import spaStore from '@/core/spa/store/spa.store';
import navStore from '@/store/nav.store';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';

const pagesToShowBreadcrumb = ['productDetails', 'categoryDetails', 'myPage', 'myPersonalInformation', 'myFavoriteProducts', 'myFavoriteContent', 'myOrders', 'myOrderDetails', 'roomDetails'];

defineProps({
    justificationClass: {
        type: String,
        default: 'md:justify-center'
    }
});

const showBreadcrumb = computed(() => {
    const isInWhiteList = pagesToShowBreadcrumb.includes(spaStore.jsonContent?.alias ?? 'unknown');
    return isInWhiteList && breadcrumb.value.length > 0 && !hasTransparentNavigation.value;
});

const hasTransparentNavigation = computed(() => navStore.hasHeroBlockAsFirstNavigation);

const breadcrumb = computed(() => spaStore.navigation.breadcrumb.slice(1));

const showAllBreadCrumbs = computed(() => breakpointsState.isBreakpointActive('min-md'));

const currentPage = computed(() => ({
    url: spaStore.metadata.url,
    name: spaStore.metadata.navigationTitle
}));
</script>

<style lang="less">
// Avoid hover effect lingering after click on touch devices
@media (hover: none) {
    [data-breadcrumb-link]:hover:not(:focus):not(:active) {
        color: inherit;
        text-decoration: none;
    }
}

.c-breadcrumb__current-page {
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 3rem;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(to right,  (color-mod(theme('colors.white-100') a(.01))) 1%, (color-mod(theme('colors.white-100') a(.99))) 99%);
    }
}
</style>

<style lang="less" scoped>
.list-fade-enter-active,
.list-fade-leave-active {
    transition: all 0.3s;
}

.list-fade-enter-active {
    transition-delay: 0.3s;
}

.list-fade-enter,
.list-fade-leave-to {
    opacity: 0;
}

.list-fade-enter {
    transition-delay: 0.3s;
}
</style>
