import log from '../logging.service';
import { format, lowercaseFirstChar } from '@/project/shared/string.util';

declare global {
    interface Window {
        dictionary: {
            [key: string]: string;
        };
    }
}

class DictionaryService {
    private dictionaryMap: Map<string, string> = new Map<string, string>();

    constructor() {
        try {
            if (!window.dictionary) {
                throw new Error('### window.dictionary not set ###');
            }

            this.dictionaryMap = new Map<string, string>(Object.entries(window.dictionary));
        } catch (e) {
            if (import.meta.env.MODE === 'production') {
                const url = '/500.html';
                log.debug(`Hard redirect. No dictionary loaded: ${url}`);
                window.location.href = url;
            }
        }
    }

    public get(key: string, args: any[] = []) {
        // The server for some reason lowercases the first char of the keys given in Umbraco
        // We do not want to do that manually
        key = lowercaseFirstChar(key);
        const translation = this.dictionaryMap.get(key) || (this.dictionaryMap.has(key) ? '' : undefined);
        if (translation === undefined) {
            log.warn(`### Key '${key}' not found in dictionary ###`);
            return key;
        }
        return format(translation, args);
    }
}

export default new DictionaryService();
