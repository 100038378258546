<template>
    <div v-if="image || vimeoVideoId"
         ref="blockHeroBanner"
         class="c-hero-banner bg-white-100"
         :class="{ 'c-hero-banner__no-max': vimeoVideoId }">
        <div v-if="content.supplementaryTitle || content.title"
             ref="heroContent"
             class="c-hero-banner__content">
            <div ref="heroContentText"
                 class="w-full px-10"
                 :style="textOffset">
                <component :is="content.headerType || 'h3'"
                           :id="headerId"
                           class="c-hero-banner__heading text-center mx-auto"
                           :style="maxTitleWidth">
                    <span v-if="content.supplementaryTitle"
                          class="c-hero-banner__heading-script block font-script opacity-80 u-reverse-rotate-6 leading-md -mb-15"
                          :class="[headerFontSizes.supplementaryTitle, headerTextColor]"
                          v-html="content.supplementaryTitle"/>

                    <span v-if="content.title"
                          class="c-hero-banner__heading-bold font-brandon block leading-tight uppercase"
                          :class="[headerFontSizes.title, headerTextColor]"
                          v-html="content.title"/>
                </component>
            </div>

            <div v-if="content.link"
                 ref="heroContentLink"
                 class="c-hero-banner__btn-wrap mt-auto mb-40 md:mb-60 w-full text-center mx-10">
                <router-link-conditional :to="content.link.url"
                                         :title="content.link.name"
                                         class="c-hero-banner__btn c-btn c-btn--themed inline-flex mx-auto"
                                         @click="trackPromotionClick(content.link.name)">
                    <span class="c-btn__text font-bold text-12 md:text-14 tracking-normal">
                        {{ content.link.name }}
                    </span>

                    <span class="c-btn__icon">
                        <c-icon name="new-light"/>
                    </span>
                </router-link-conditional>
            </div>
        </div>

        <div v-if="vimeoVideoId"
             class="c-hero-banner__vimeo">
            <VimeoPlayer :video-id="vimeoVideoId"
                         :options="vimeoPlayerOptions"/>
        </div>

        <div v-else-if="imageSrc"
             class="c-hero-banner__image relative z-0">
            <Parallaxy :speed="parallaxSpeedFactor"
                       direction="normal"
                       :animation="(delta) => `transform: translate3d(0px, ${(delta-50)*2}px, 0px);`"
                       :style="{ height: `${imageHeight}px` }">
                <img v-on-error
                     :src="imageSrc.x1"
                     :srcset="`${imageSrc.x1} 1x, ${imageSrc.x2} 2x`"
                     class="w-full"
                     loading="eager"
                     fetchpriority="high"
                     :alt="altText">
            </Parallaxy>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue';
import spaStore from '@/core/spa/store/spa.store';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import { HeroBlockModel, Media, ParallaxScrolling } from '@/types/serverContract';
import { getHeaderAnchorLink } from '@/project/shared/string.util';
import Parallaxy from '@lucien144/vue3-parallaxy';
import trackingUtils from '@/project/tracking/tracking.utils';
import VimeoPlayer from '@/core/vimeo/VimeoPlayer.vue';
import { VimeoPlayerOptions } from '@/core/vimeo/vimeoPlayerOptions';
enum ImageAspectMode {
    Square = 'square',
    Portrait = 'portrait',
    Landscape = 'landscape'
}

type HeaderFontSizes = { supplementaryTitle: string, title: string };

const props = defineProps<{
    content: HeroBlockModel;
}>();

const blockHeroBanner = ref<HTMLDivElement | null>(null);

const windowWidth = ref(0);
const windowHeight = ref(0);

const contentHeight = ref(0);
const contentTextHeight = ref(0);
const contentLinkHeight = ref(0);

const imageAspectMode = ref<ImageAspectMode>(ImageAspectMode.Square);

const animationFrame = ref<number | undefined>(undefined);
 
const observer = ref<ResizeObserver | null>(
    new ResizeObserver(() => {
        if (animationFrame.value) {
            cancelAnimationFrame(animationFrame.value);
        }

        animationFrame.value = requestAnimationFrame(() => setHeroStyes());
    })
);

// const vimeo = ref<VimeoPlayer | HTMLDivElement | null>(null);
const heroContent = ref<HTMLDivElement | null>(null);
const heroContentText = ref<HTMLDivElement | null>(null);
const heroContentLink = ref<HTMLDivElement | null>(null);

const headerFontSizes = ref<HeaderFontSizes>(getHeaderFontSizes());

const vimeoVideoId = ref<string | null>(null);
const vimeoPlayerOptions = ref<VimeoPlayerOptions>({
    id: vimeoVideoId.value,
    width: undefined,
    height: undefined,
    responsive: true,
    muted: true,
    loop: true,
    autoplay: true,
    background: true,
    controls: true,
    dnt: !spaStore?.cookieConsentStatus().cookieCatStatistic,
    playsinline: true
    
});

const image = computed<Media | null>(() => {
    const { image, imageTallFormat, imageSquareFormat } = props.content;

    if (imageAspectMode.value === ImageAspectMode.Portrait && imageTallFormat) {
        return imageTallFormat;
    } else if (imageAspectMode.value === ImageAspectMode.Square && imageSquareFormat) {
        return imageSquareFormat;
    }

    return image || null;
});

const imageSrc = computed<{ x1: string, x2: string } | null>(() => {
    if (!image.value) {
        return null;
    }

    const { focalPoint, url } = image.value;

    const focal = focalPoint ? `&rxy=${focalPoint.left},${focalPoint.top}` : '';

    return {
        x1: `${url}?height=${windowHeight.value}&width=${windowWidth.value}${focal}&quality=70&format=jpg&mode=crop`,
        x2: `${url}?height=${windowHeight.value * 2}&width=${windowWidth.value * 2}${focal}&quality=70&format=jpg&mode=crop`
    };
});

const imageHeight = computed<number | undefined>(() => {
    if (!image.value) {
        return undefined;
    }

    return Math.floor(Math.min(windowHeight.value, image.value.height) * 0.8);
});

const headerId = computed<string | undefined>(() => {
    return getHeaderAnchorLink(props.content);
});

const altText = computed<string | undefined>(() => {
    const { altText, supplementaryTitle, title } = props.content;

    if (altText) {
        return altText;
    } else if (supplementaryTitle) {
        return supplementaryTitle;
    } else if (title) {
        const prefix = supplementaryTitle ? `${supplementaryTitle} - ` : '';

        return `${prefix}${title}`;
    }

    return spaStore.pageData.metadata.seoTitle;
});

const maxTitleWidth = computed(() => {
    if (!props.content.maxTitleWidth) {
        return undefined;
    }

    return { width: `${props.content.maxTitleWidth}%` };
});

const headerTextColor = computed(() => {
    const { textColor, textColorMobile } = props.content;

    if (breakpointsState.isBreakpointActive('max-sm') && textColorMobile) {
        return textColorMobile === 'theme-1' ? 'text-theme-1' : 'text-theme-2';
    }

    return textColor === 'theme-1' ? 'text-theme-1' : 'text-theme-2';
});

const textOffset = computed(() => {
    const { textOffset, textOffsetMobile } = props.content;
    const mobileOffset = textOffsetMobile || textOffset;
    const offset = breakpointsState.isBreakpointActive('max-sm') ? mobileOffset : textOffset;

    return {
        marginTop: `${(offset || 60) * (contentHeight.value - (contentTextHeight.value + contentLinkHeight.value)) / (100 * 10)}rem`
    };
});

const parallaxSpeedFactor = computed(() => {
    switch (props.content.parallaxEffect) {
        case ParallaxScrolling.None:
            return 0;
        case ParallaxScrolling.Slow:
            return 48;
        case ParallaxScrolling.Fast:
            return 55;
        default:
            return 52;
    }
});

onMounted(() => {
    nextTick(() => {
        observer.value?.observe(document.body);
        observer.value?.observe(document.documentElement);
    });
});

onBeforeUnmount(() => {
    observer.value?.disconnect();
});

const setHeroStyes = (): void => {
    windowHeight.value = getWindowHeight();
    windowWidth.value = getWindowWidth();

    imageAspectMode.value = getImageAspectMode();
    headerFontSizes.value = getHeaderFontSizes();
    // contentHeight.value = document.documentElement.clientHeight || 0;
    // contentTextHeight.value = document.querySelector<HTMLDivElement>('#heroContentText')?.clientHeight || 0;
    // contentLinkHeight.value = document.querySelector<HTMLDivElement>('#heroContentLink')?.clientHeight || 0;
    contentHeight.value = blockHeroBanner.value?.clientHeight || 0;
    contentTextHeight.value = heroContentText.value?.clientHeight || 0;
    contentLinkHeight.value = heroContentLink.value?.clientHeight || 0;

    vimeoVideoId.value = getVimeoVideoId();
    vimeoPlayerOptions.value = {
        ...vimeoPlayerOptions.value,
        id: vimeoVideoId.value
    };
};

function getWindowHeight() {
    const heightDimension = window.innerWidth < 450 ? 700 : 1400;

    return Math.ceil(Math.min(window.innerHeight, heightDimension) / 100) * 100;
}

function getWindowWidth() {
    return Math.ceil(Math.min(window.innerWidth, 1600) / 100) * 100;
}

function getImageAspectMode() {
    const ratio = window.innerWidth / window.innerHeight;

    if (ratio < 0.9) {
        return ImageAspectMode.Portrait;
    } else if (ratio > 1.3) {
        return ImageAspectMode.Landscape;
    }

    return ImageAspectMode.Square;
}

function getHeaderFontSizes(): HeaderFontSizes {
    const height = Math.max(window.innerHeight, window.document.documentElement!.clientHeight);

    if (height > 790) {
        return {
            supplementaryTitle: 'text-40 ls:text-60 md:text-60',
            title: 'text-42 ls:text-70 md:text-70'
        };
    }

    return {
        supplementaryTitle: 'text-32 ls:text-40 md:text-40',
        title: 'text-34 ls:text-50 md:text-45'
    };
}

function getVimeoVideoId() {
    const isPortrait = window.innerWidth < window.innerHeight ||
                            window.screen?.orientation?.type.includes('portrait');
    const { vimeoIdDefault, vimeoIdPortrait } = props.content;
    const videoId = isPortrait ? vimeoIdPortrait : vimeoIdDefault;

    return videoId || null;
}

function trackPromotionClick(linkName: string) {
    trackingUtils.promotion.trackPromotionClick(
        {
            componentName: 'BlockHeroBanner',
            trackingName: props.content.trackingName,
            trackingTitle: props.content.title || props.content.supplementaryTitle,
            creativeText: linkName,
            position: 0
        }
    );
}
</script>

<style lang="less">
@import (reference) '../../../styling/0-Settings/index.less';

.c-hero-banner {
    @apply overflow-hidden relative;

    @screen lg {
        &.c-hero-banner__no-max {
            @apply max-h-none;
        }
    }
}

.c-hero-banner__content {
    @apply absolute inset-0 z-1 w-full h-full flex flex-wrap items-center justify-center;
}

.c-hero-banner__heading {
    @apply mb-0;

    margin-top: 6rem;

    @media @maxTitleWidthOverwriteMediaQuery {
        width: auto !important;
    }

    @screen lg {
        margin-top: 4.1rem;
    }
}

.c-hero-banner__heading-script {
    letter-spacing: 2.02px;
    margin-bottom: -0.9rem;
    margin-left: 0.5rem;

    @screen lg {
        letter-spacing: 0.12px;
        padding-top: .8rem;
        margin-bottom: -2.6rem;
        margin-left: -1.2rem;
    }
}

.c-hero-banner__heading-bold {
    letter-spacing: -0.5px;

    @screen lg {
        letter-spacing: -0.5px;
        line-height: .9;
    }
}

.c-hero-banner__btn-wrap {
    margin-bottom: 3.9rem;

    @screen lg {
        margin-bottom: 5.9rem;
    }

    .c-btn__text {
        letter-spacing: -.4px;
    }
}

.c-hero-banner__vimeo {
    iframe {
        @apply w-full;
    }
}

.c-hero-banner__image {
    .Masthead .is-parallax {
        @apply bottom-0;
    }
}
</style>
