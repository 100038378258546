import { NavigationLinkItem, NavigationLinkType } from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';

function isStandardLink(linkType: NavigationLinkType) {
    return linkType === NavigationLinkType.Link;
}

function isProductsLink(linkType: NavigationLinkType): boolean {
    return linkType === NavigationLinkType.Products;
}

function isRoomsLink(linkType: NavigationLinkType) {
    return linkType === NavigationLinkType.Rooms;
}

function getProductsLink(): NavigationLinkItem | undefined {
    const productLink = serverContext.headerNavigation?.links?.find(l => l.type === NavigationLinkType.Products);
    return productLink;
}

function getRoomLink(): NavigationLinkItem | undefined {
    const roomLink = serverContext.headerNavigation?.links?.find(l => l.type === NavigationLinkType.Rooms);
    return roomLink;
}

export default {
    isStandardLink,
    isProductsLink,
    isRoomsLink,
    getProductsLink,
    getRoomsLink: getRoomLink
};
