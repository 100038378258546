<template>
    <div>
        <div class="flex">
            <div>
                <p v-for="(label, index) in labels"
                   :key="index"
                   class="mr-24">
                    {{ label }}
                </p>
            </div>
            <div>
                <p v-for="(hour, index) in hours"
                   :key="index"
                   class="mr-24">
                    <template v-if="hour">
                        {{ hour }}
                    </template>
                    <template v-else>
                        {{ $translate('store.Closed') }}
                    </template>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { v4 } from '@/types/serverContract';
import { computed } from 'vue';

const props = defineProps<{
    openingHours: v4.Stores.OpeningHours | undefined;
}>();

const labels = computed(() => {
    return props.openingHours?.weekly?.map(i => i.label);
});

const hours = computed(() => {
    return props.openingHours?.weekly?.map(i => i.hours);
});

</script>
