<template>
    <section>
        <blocks-container :blocks="content && content.blocks"/>
        <section v-if="content.productRecommendations && content.productRecommendations.length"
                 class="o-container mt-120 mb-60">
            <h3 class="uppercase font-brandon tracking-wide text-9 mb-25 md:text-10 md:mb-30">
                {{ $translate('recommendations.ProductDetails.ProductRecommendationHeader') }}
            </h3>
            <recommendations-assortments :is-pending="false"
                                         :products="content.productRecommendations"
                                         :grid-columns="'w-1/2 md:w-1/4'"/>
        </section>
        <section v-if="content.diyRecommendations && content.diyRecommendations.length"
                 class="o-container mt-120 mb-60">
            <h3 class="font-script text-32 leading-sm tracking-normal md:text-42 mb-10 md:mb-10">
                {{ $translate('recommendations.DIYDetails.DiyRecommendationHeader') }}
            </h3>
            <recommendations-diys :diys="content.diyRecommendations"/>
        </section>
        <blocks-container :blocks="content && content.blocksBelow"/>
    </section>
</template>

<script setup lang="ts">
import { onMounted, defineAsyncComponent } from 'vue';
import { PageNotFoundContentModel } from '@/types/serverContract';
import { router } from '@/core/spa/router';
import trackingUtils from '@/project/tracking/tracking.utils';

const RecommendationsDiys = defineAsyncComponent(() => import('@/project/recommendations/RecommendationsDiys.vue'));
const RecommendationsAssortments = defineAsyncComponent(() => import('@/project/recommendations/RecommendationsAssortments.vue'));

defineProps<{
    content: PageNotFoundContentModel;
}>();

const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

onMounted(() => {
    // We are not sure why sleeping is needed.
    // It is not needed on local but it is needed on QA and Stage, otherwise the 404 event is not registred in the Live Stream
    // On Stage, debugging has shown that window.mParticle.logEvent is indeed called with the error.
    // For some reason it is not registred.
    // It might be related to the "Application State Transition" that happens when browsing to non-existing pages
    // (and reloading pages). That event is send quite late.
    sleep(2000).then(() => {
        trackingUtils.error.track404(router.currentRoute.value.fullPath);
    });
});
</script>