import { JL } from 'jsnlog';
import serverContext from '@/core/serverContext.service';

class LoggingService {
    public developmentOnly(logObject: any, ...args): void {
        if (this.isDebugMode) {
            // eslint-disable-next-line
            console.log(logObject, args);
        }
    }

    public debug(logObject: any): void {
        if (this.isDebugMode) {
            // eslint-disable-next-line
            console.log(logObject);
        } else if (serverContext.frontendLogging.debug) {
            logObject && JL && JL().debug(logObject);
        }
    }

    public info(logObject: any) {
        if (this.isDebugMode) {
            // eslint-disable-next-line
            console.info(logObject);
        } else if (serverContext.frontendLogging.info) {
            logObject && JL && JL().info(logObject);
        }
    }

    public warn(logObject: any) {
        // eslint-disable-next-line
        console.warn(logObject);
        if (!this.isDebugMode && serverContext.frontendLogging.warn) {
            logObject && JL && JL().warn(logObject);
        }
    }

    public error(logObject: any) {
        if (isWhitelisted(logObject)) return;

        // eslint-disable-next-line
        console.error(logObject);
        if (!this.isDebugMode && serverContext.frontendLogging.error) {
            logObject && JL && JL().error(logObject);
        }

        function isWhitelisted(logObject: any) {
            if (logObject && logObject.message === '$$redirect$$') return true;
            return false;
        }
    }

    public exception(logObject: any, e: any) {
        if (this.isDebugMode) {
            // eslint-disable-next-line
            console.error(logObject, e);
        } else if (serverContext.frontendLogging.exception) {
            (logObject || e) && JL && JL().fatalException(logObject, e);
        }
    }

    get isDebugMode() {
        return import.meta.env.MODE !== 'production';
    }
}

export default new LoggingService();
