import emitter from 'tiny-emitter/instance';

function emit(event: string, ...args: any[]) {
    emitter.emit(event, ...args);
}

function once(event: string, callback: (...args: any[]) => void) {
    emitter.once(event, callback);
}

function on(event: string, callback: (...args: any[]) => void) {
    emitter.on(event, callback);
}

function off(event: string, callback: (...args: any[]) => void) {
    emitter.off(event, callback);
}

export default {
    emit,
    once,
    on,
    off
};