import { App } from 'vue';
import { router } from './spa/router';
import { isHrefExternal, isHrefMedia, isExternalScheme } from '@/project/shared/string.util';

const delegateHtmlLinks = {
    beforeMount(el: HTMLElement) {
        el.addEventListener('click', event => {
            const { target }: any = event;

            if (isExternalScheme(target.href)) {
                return;
            }

            // only links that do not reference external resources
            if (target && target.href && !(isHrefExternal(target.href, location.host) || isHrefMedia(target.href))) {
                const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event;

                if (metaKey || altKey || ctrlKey || shiftKey) {
                    return;
                }

                if (defaultPrevented) {
                    return;
                }

                if (button !== undefined && button !== 0) {
                    return;
                }

                if (target && target.getAttribute) {
                    const linkTarget = target.getAttribute('target');
                    if (/\b_blank\b/i.test(linkTarget)) {
                        return;
                    }
                }

                const url = new URL(target.href);
                if (window.location.pathname !== url.pathname && event.preventDefault) {
                    event.preventDefault();
                    router.push(url.pathname + url.search + url.hash);
                }
            }
        });
    }
};

export default function config(app: App) {
    app.directive('delegateHtmlLinks', delegateHtmlLinks);
}
