<template>
    <div class="mx-auto flex max-w-350 flex-col md:mx-0 md:min-w-350">
        <Breakpoints>
            <template #max-ls>
                <div class="mt-16 md:mt-0 mb-32">
                    <template v-if="selectedVariant.packageQuantity && selectedVariant?.packageQuantity > 1">
                        <SplitTest feature-key="Fsst-package_quantity"
                                   default-variation="disabled"
                                   is-fallback>
                            <ProductDetailsAddToCartButton
                                ref="addToCartButtonRef"
                                :key="selectedVariant.sku"
                                :product="details"
                                :selected-variant="selectedVariant"/>
                        </SplitTest>
                        <SplitTest feature-key="Fsst-package_quantity"
                                   variation="enabled"
                                   default-variation="disabled">
                            <ProductDetailsAddToCartButton
                                ref="addToCartButtonRef"
                                :key="selectedVariant.sku"
                                should-use-package-quantity
                                :product="details"
                                :selected-variant="selectedVariant"/>
                        </SplitTest>
                    </template>
                    <template v-else>
                        <ProductDetailsAddToCartButton
                            ref="addToCartButtonRef"
                            :key="selectedVariant.sku"
                            :product="details"
                            :selected-variant="selectedVariant"/>
                    </template>
                </div>
            </template>
        </Breakpoints>
        <div class="flex">
            <div class="mb-32 flex-1 md:mt-0">
                <p
                    v-if="details.brand"
                    class="mb-10 font-brandon text-14 font-normal uppercase leading-16 text-brown-70 relative">
                    {{ details.brand.name }}
                    <RouterLink v-if="details.brand.url"
                                :to="details.brand.url"
                                :title="details.brand.name"
                                class="absolute inset-0"/>
                </p>
                <div class="flex">
                    <h1 class="mb-0 pr-32 text-18 font-brandon-medium uppercase leading-22 text-brown-80 md:pr-5">
                        {{ details.name }}
                        <template v-if="shouldShowVariantName">
                            {{ selectedVariant.variantName }}
                        </template>
                    </h1>
                    <Breakpoints>
                        <template #min-md>
                            <div class="ml-auto -mt-3">
                                <ProductDetailsFavoriteToggle :details="details"
                                                              :selected-variant="selectedVariant"
                                                              type="PDP"/>
                            </div>
                        </template>
                    </Breakpoints>
                </div>
                <p v-show="details.subtitle"
                   class="mt-8 pr-56 text-14 font-gibson-light leading-20 text-brown-70">
                    {{ details.subtitle }}
                </p>
            </div>
        </div>
        <div class="hidden"
             :class="{ block: isPrerenderUseragent }">
            {{ productDescription }}
        </div>

        <ProductDetailsVariants
            v-if="details.variants.length > 1"
            :key="details.id"
            :details="details"
            :selected-variant="selectedVariant"
            class="mb-8"/>
        <ProductDetailsPrice
            class="mb-24"
            :details="details"
            :selected-variant="selectedVariant"/>
        <StockStatusOnline
            v-if="props.selectedVariant.inventory?.description && hasCheckout"
            :selected-variant="selectedVariant"
            class="mb-16"/>
        <Breakpoints>
            <template #min-md>
                <div class="pb-32">
                    <template v-if="selectedVariant.packageQuantity && selectedVariant?.packageQuantity > 1">
                        <SplitTest feature-key="Fsst-package_quantity"
                                   default-variation="disabled"
                                   is-fallback>
                            <ProductDetailsAddToCartButton
                                ref="addToCartButtonRef"
                                :key="selectedVariant.sku"
                                :product="details"
                                :selected-variant="selectedVariant"/>
                        </SplitTest>
                        <SplitTest feature-key="Fsst-package_quantity"
                                   variation="enabled"
                                   default-variation="disabled">
                            <ProductDetailsAddToCartButton
                                ref="addToCartButtonRef"
                                :key="selectedVariant.sku"
                                should-use-package-quantity
                                :product="details"
                                :selected-variant="selectedVariant"/>
                        </SplitTest>
                    </template>
                    <template v-else>
                        <ProductDetailsAddToCartButton
                            ref="addToCartButtonRef"
                            :key="selectedVariant.sku"
                            :product="details"
                            :selected-variant="selectedVariant"/>
                    </template>
                </div>
            </template>
        </Breakpoints>
        <SplitTests feature-key="Fsst-12576_PDP_stock_status"
                    fallback-variation="show-stock-status">
            <template #show-stock-status>
                <StockStatusStore v-if="!shouldHideStockStatus"
                                  :selected-variant="selectedVariant"
                                  :details="details"
                                  class="mb-24"/>
            </template>
            <template #hide-stock-status></template>
        </SplitTests>
        <StockStatusMyStore
            v-if="shouldShowMyStore"
            :selected-variant="selectedVariant"
            :details="details"
            class="mb-32"/>

        <Teleport v-if="showStickyCta"
                  to="[data-teleport-target='pdp-sticky-cta']">
            <Transition
                appear
                enter-active-class="animated slideInUp u-anim-delay-100 u-anim-dur-300 md:u-anim-dur-600"
                leave-active-class="animated slideOutDown u-anim-delay-100 u-anim-dur-300 md:u-anim-dur-600">
                <div
                    class="border-t fixed inset-x-0 bottom-0 z-4 min-h-88 border-0 border-sand-40 bg-sand-10 px-20 py-13">
                    <div class="flex gap-16">
                        <ProductDetailsPrice
                            :details="details"
                            :selected-variant="selectedVariant"
                            :is-sticky="true"
                            class="flex-1"/>
                        <div class="flex-1 my-12">
                            <template v-if="selectedVariant?.packageQuantity && selectedVariant?.packageQuantity > 1">
                                <SplitTest feature-key="Fsst-package_quantity"
                                           default-variation="disabled"
                                           is-fallback>
                                    <ProductDetailsAddToCartButton
                                        :key="selectedVariant.sku"
                                        :product="details"
                                        :selected-variant="selectedVariant"
                                        :is-sticky="true"/>
                                </SplitTest>
                                <SplitTest feature-key="Fsst-package_quantity"
                                           variation="enabled"
                                           default-variation="disabled">
                                    <ProductDetailsAddToCartButton
                                        :key="selectedVariant.sku"
                                        :product="details"
                                        :selected-variant="selectedVariant"
                                        :is-sticky="true"
                                        should-use-package-quantity/>
                                </SplitTest>
                            </template>
                            <template v-else>
                                <ProductDetailsAddToCartButton
                                    :key="selectedVariant.sku"
                                    :product="details"
                                    :selected-variant="selectedVariant"
                                    :is-sticky="true"/>
                            </template>
                        </div>
                    </div>
                </div>
            </Transition>
        </Teleport>

        <SplitTest feature-key="Fsst-11819_usp_pdp"
                   default-variation="1usp">
            <Usp v-if="hasUsp"
                 :content="details.uniqueSellingPoints"
                 :max-usps="1"
                 class="mb-32"/>
        </SplitTest>
        <SplitTest feature-key="Fsst-11819_usp_pdp"
                   default-variation="1usp"
                   variation="3usp"
                   is-fallback>
            <Usp v-if="hasUsp"
                 :content="details.uniqueSellingPoints"
                 :max-usps="3"
                 class="mb-32"/>
        </SplitTest>
        <SplitTest feature-key="Fsst-11819_usp_pdp"
                   default-variation="1usp"
                   variation="0usp"/>

        <div class="mb-40">
            <ProductDetailsAccordion
                v-for="(information, index) in informationArray"
                :key="`v-${index}`"
                :information="information"
                class="w-full"
                :class="{ 'border-b': index === informationArray.length - 1 }"/>
        </div>
        <div v-if="selectedVariant.labelling && selectedVariant.labelling.length"
             class="mb-40">
            <Labellings :key="selectedVariant.sku"
                        :selected-variant="selectedVariant"/>
        </div>
        <div v-if="details.brand"
             class="mb-40">
            <ProductDetailsBrand :content="details.brand"/>
        </div>
        <AnnaClaraText v-if="details.annaAndClaraText"
                       :content="details.annaAndClaraText"/>
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';
import bus from '@/core/bus';
import BreakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import serverContext from '@/core/serverContext.service';
import { FOOTER_IN_VIEW } from '@/project/config/constants';
import ProductDetailsAddToCartButton from '@/project/product/details/ProductDetailsAddToCartButton.vue';
import ProductDetailsAccordion from '@/project/product/details/ProductDetailsAccordion.vue';
import ProductDetailsFavoriteToggle from '@/project/product/details/ProductDetailsFavoriteToggle.vue';
import ProductDetailsPrice from '@/project/product/details/ProductDetailsPrice.vue';
import ProductDetailsBrand from '@/project/product/details/ProductDetailsBrand.vue';
import AnnaClaraText from '@/project/product/details/AnnaClaraText.vue';
import ProductDetailsVariants from '@/project/product/details/ProductDetailsVariants.vue';
import Labellings from '@/project/product/details/Labellings.vue';
import Usp from '@/project/product/details/Usp.vue';
import { isPrerenderUseragent } from '@/project/shared/string.util';
import StockStatusOnline from '@/project/product/details/StockStatusOnline.vue';
import StockStatusStore from '@/project/product/details/StockStatusStore.vue';
import StockStatusMyStore from '@/project/product/details/StockStatusMyStore.vue';
import { v4 } from '@/types/serverContract';
import userStore from '@/store/user.store';
import SplitTest from '@/project/growthbook/SplitTest.vue';
import SplitTests from '@/project/growthbook/SplitTests.vue';

const props = defineProps<{
    details: v4.Products.ProductDetails;
    selectedVariant: v4.Products.ProductDetailsVariant;
}>();

const informationArray = computed(() => {
    return props.details.information ? [...props.selectedVariant.information, ...props.details.information] : props.selectedVariant.information;
});

const productDescription = computed(() => {
    return props.details.description;
});

const hasCheckout = computed(() => {
    return serverContext.checkoutContext.hasCheckout;
});

const hasUsp = computed(() => props.details.uniqueSellingPoints && props.details.uniqueSellingPoints.length > 0);

const isLoggedIn = computed(() => {
    return userStore.isLoggedIn && !!userStore.userFavoriteStore;
});

const shouldShowVariantName = computed(() => {
    return props.details?.variants?.length > 1 && props.details.variantInfo?.type === v4.Products.GenericVariantType.Text;
});

const availabilityStatus = computed(() => props.selectedVariant.availability.status);
const enableStickyCta = computed(
    () =>
        serverContext.checkoutContext.hasCheckout &&
        ['xs', 'sm', 'ls'].includes(BreakpointsState.activeBreakpoint) &&
        [v4.Products.AvailabilityStatus.Available, v4.Products.AvailabilityStatus.AvailableOnlySoldOnline].includes(availabilityStatus.value)
);
const addToCartButtonRef = ref<null | typeof ProductDetailsAddToCartButton>(null);
const addToCartObserver = ref<null | IntersectionObserver>(null);
const addToCartInView = ref<null | boolean>(null);
const footerInView = ref<null | boolean>(null);
const addToCartTop = ref<number>(0);

const shouldHideStockStatus = computed(() => {
    if (!serverContext.enableStoreInventory) {
        return true;
    }
    return [v4.Products.AvailabilityStatus.AvailableOnlySoldOnline, v4.Products.AvailabilityStatus.NotAvailableOnlySoldOnline, v4.Products.AvailabilityStatus.AvailableAtLaterDate].includes(availabilityStatus.value);
});

const shouldShowMyStore = computed(() => {
    return isLoggedIn.value && props.selectedVariant.inventory?.inStores?.status === v4.Products.Inventory.StoreInventory.AvailabilityStatus.Available && !shouldHideStockStatus.value;
});

const setFooterInView = (inView: null | boolean) => {
    footerInView.value = inView;
};

onMounted(() => {
    bus.on(FOOTER_IN_VIEW, setFooterInView);
});

onBeforeMount(() => {
    bus.off(FOOTER_IN_VIEW, setFooterInView);
});

const observe = () => {
    if (addToCartButtonRef.value && addToCartObserver.value) {
        addToCartObserver.value.observe(addToCartButtonRef.value.$el);
    }
};

const unObserve = () => {
    if (addToCartButtonRef.value && addToCartObserver.value) {
        addToCartObserver.value.unobserve(addToCartButtonRef.value.$el);
        addToCartObserver.value.disconnect();
    }
};

watch(
    [enableStickyCta, addToCartButtonRef],
    ([enableStickyCta, addToCartButtonRef]) => {
        if (!addToCartButtonRef || !addToCartButtonRef.$el) {
            return;
        }

        if (!addToCartObserver.value) {
            addToCartObserver.value = new IntersectionObserver((entries) => {
                const entry = entries[0];
                addToCartTop.value = entry.boundingClientRect.top;

                if (entry.isIntersecting) {
                    addToCartInView.value = true;
                } else {
                    addToCartInView.value = false;
                }
            });
        }

        if (enableStickyCta) {
            observe();
        } else {
            unObserve();
            addToCartInView.value = null;
        }
    },
    { immediate: true }
);

const showStickyCta = computed(() => {
    return enableStickyCta.value && addToCartTop.value < 0 && addToCartInView.value === false && footerInView.value === false;
});
</script>
