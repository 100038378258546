<template>
    <a v-if="isExternal"
       :href="realHref"
       :title="title"
       :target="target">
        <slot></slot>
    </a>
    <RouterLink v-else-if="relativeUrl"
                :to="relativeUrl"
                :title="title">
        <slot></slot>
    </RouterLink>
    <span v-else>
        <slot></slot>
    </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'RouterLinkConditional',
    props: {
        to: {
            type: [String, null],
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        target: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const realHref = props.to?.toLowerCase().startsWith('www') ? 'http://' + props.to : props.to ?? '';
        const relativeUrl = props.to?.indexOf('http') === 0 ? (new URL(props.to).pathname) : props.to; 

        function isExternal(): boolean {
            if (props.target === '_blank') return true;
            try {
                return new URL(realHref).origin !== location.origin;
            } catch {
                return false;
            }
        }

        return {
            isExternal: isExternal(),
            realHref,
            relativeUrl
        };
    },
});
</script>
