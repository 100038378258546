import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import querystring from 'querystring';
import isArray from 'lodash-es/isArray';
import forEach from 'lodash-es/forEach';
import HttpStatus from 'http-status-codes';
import { SitePageData } from '@/types/serverContract';
import messagesStore, { ClientMessageType } from '@/store/messages.store';
import serverContext from '@/core/serverContext.service';
import logging from '@/core/logging.service';
import userStore from '@/store/user.store';
import './authentication.interceptor';
import './clientServerVersion.interceptor';
import './redirect.interceptor';
import './validationErrors.interceptor';
import './basket.interceptor';

export class HttpService {
    private pageCancelTokenSrc: CancelTokenSource | null = null;

    private get defaultGetHeaders(): any {
        return {
            'Content-Type': 'application/json', // must use this casing for the authentication service to work
            culture: serverContext.culture
        };
    }

    constructor() {
        // Those status'es that should cause "then" to be executed (so we can have interceptors)
        const handledErrorStatusCodes = [
            HttpStatus.BAD_REQUEST,
            HttpStatus.NOT_FOUND,
            HttpStatus.INTERNAL_SERVER_ERROR,
            HttpStatus.UNAUTHORIZED
        ];
        axios.defaults.validateStatus = (status) =>
            (status >= 200 && status < 300) || handledErrorStatusCodes.includes(status);

        axios.defaults.paramsSerializer = (params) => {
            const sortedParams = this.getSortedParams(params);
            return querystring.stringify(sortedParams);
        };
    }

    public async get<T>(relativeUrl: string, params?: any): Promise<T> {
        const config = {
            headers: this.defaultGetHeaders,
            data: {}, // Axios kills application/json if no data object is provided https://github.com/axios/axios/issues/86#issuecomment-139638284
            params
        };
        return axios
            .get(this.getUrl(relativeUrl), config)
            .then((res) => this.handlePotentialErrorResponse(res))
            .catch((err) => this.handleErrorResponse(err));
    }

    public async delete<T>(relativeUrl: string, params?: any): Promise<T> {
        const config = {
            headers: this.defaultGetHeaders,
            data: {}, // Axios kills application/json if no data object is provided https://github.com/axios/axios/issues/86#issuecomment-139638284
            params
        };
        return axios
            .delete(this.getUrl(relativeUrl), config)
            .then((res) => this.handlePotentialErrorResponse(res))
            .catch((err) => this.handleErrorResponse(err));
    }

    public async post<T>(
        relativeUrl: string,
        payload?: any,
        messagesId?: string,
        ignoreTokenErrors?: boolean
    ): Promise<T> {
        const config = {
            messagesId,
            headers: this.defaultGetHeaders,
            ignoreTokenErrors: ignoreTokenErrors
        } as any;
        return axios
            .post(this.getUrl(relativeUrl), payload, config)
            .then((res) => this.handlePotentialErrorResponse(res))
            .catch((err) => this.handleErrorResponse(err));
    }

    public postWithCancel<T>(
        relativeUrl: string,
        payload?: any,
        messagesId?: string,
        ignoreTokenErrors?: boolean
    ): {
        cancel: () => void;
        request: Promise<void | T>;
    } {
        const cancelTokenSource = axios.CancelToken.source();

        const config: AxiosRequestConfig & { messagesId?: string; ignoreTokenErrors?: boolean } = {
            messagesId,
            headers: this.defaultGetHeaders,
            ignoreTokenErrors: ignoreTokenErrors,
            cancelToken: cancelTokenSource.token
        };

        const request = axios
            .post<T>(this.getUrl(relativeUrl), payload, config)
            .then((res) => this.handlePotentialErrorResponse(res))
            .catch((err) => this.handleErrorResponse(err));

        const cancel = () => {
            cancelTokenSource.cancel();
        };

        return { cancel, request };
    }

    async getPage(relativeUrl: string): Promise<SitePageData> {
        if (this.pageCancelTokenSrc) {
            this.pageCancelTokenSrc.cancel();
        }
        this.pageCancelTokenSrc = axios.CancelToken.source();
        try {
            const res = await axios.get<SitePageData>(relativeUrl, {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {}, // Axios kills application/json if no data object is provided https://github.com/axios/axios/issues/86#issuecomment-139638284
                cancelToken: this.pageCancelTokenSrc.token
            });
            const response = this.handlePotentialErrorResponse(res);
            this.pageCancelTokenSrc = null;
            return response;
        } catch (error) {
            if (axios.isCancel(error)) {
                throw new HttpCancelError();
                // Ignore - just because of new page-request while waiting.
            }
            throw error;
        }
    }

    private handlePotentialErrorResponse<T>(res: AxiosResponse<T>): Promise<T> {
        if (
            res.status === HttpStatus.BAD_REQUEST ||
            (res.status === HttpStatus.NOT_FOUND && res.config.url?.indexOf('/umbraco') === 0) ||
            res.status === HttpStatus.INTERNAL_SERVER_ERROR
        ) {
            // Validatestatus above is set to include these ones so it will trigger 'then'.
            // Reason is that interceptors will then be run automatically on this as well.
            // 404 pages from GetPage should not go here. 404 from API (e.g. url starts with /umbraco) should go here.
            throw res;
        } else if (res.status === HttpStatus.UNAUTHORIZED && serverContext.hasLogin) {
            if (res.headers['token-status'] === 'rejected') {
                userStore.setToken(null);
            } else if (res.headers['token-status'] === 'expired') {
                return this.refreshAuthLogic(res)
                    .then((reIssuedRequest: any) => reIssuedRequest.data)
                    .catch((reIssuedError) => {
                        userStore.setLoginFormActiveState(true);
                        throw reIssuedError;
                    });
                // @ts-ignore
            } else if (res.config.ignoreTokenErrors === true) {
                throw res;
            } else {
                userStore.setLoginFormActiveState(true);
                throw res; // Login required.
            }
        }
        return Promise.resolve(res.data);
    }

    // Refresh authorization
    private async refreshAuthLogic(failedRequest) {
        const req = await axios
            .post(this.getUrl('authenticate/refreshtoken'))
            .then((tokenRefreshResponse) => {
                if (tokenRefreshResponse.status === HttpStatus.OK) {
                    userStore.setToken(tokenRefreshResponse.data.token);
                    // Auth token refreshed, resuming old request that required login
                    failedRequest.config.headers.Authorization = `Token ${tokenRefreshResponse.data.token}`;
                    return axios.request(failedRequest.config);
                } else {
                    userStore.setToken(null);
                    userStore.setLoginFormActiveState(true);
                    // Auth failed...
                    return Promise.reject('Login required');
                }
            })
            .catch(() => {
                userStore.setToken(null);
                userStore.setLoginFormActiveState(true);
                // Auth failed
            });
        return req;
    }

    private handleErrorResponse(error: any): void {
        if (error && error.message === '$$redirect$$') {
            logging.developmentOnly('Handle redirect:', error);
            return;
        } else if (error.response && error.response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
            const message = error.response.data && error.response.data.message ? error.response.data.message : error;
            messagesStore.addApiMessages([{ message, messageType: ClientMessageType.Error }]);
        }
        throw error;
    }

    private getUrl(relativeUrl: string): string {
        relativeUrl = removeLeadingSlash(relativeUrl);
        return `/umbraco/api/${relativeUrl}`;

        function removeLeadingSlash(url: string) {
            return url.charAt(0) === '/' ? url.substr(1) : url;
        }
    }

    private getSortedParams(params: any): any {
        const keys: string[] = [];

        forEach(params, (value, key: string) => {
            keys.push(key);
        });

        const sortedParams = {};
        const sortedKeys = keys.sort();

        sortedKeys.forEach((value) => {
            const searchValue = params[value];
            const sortedValues = isArray(searchValue) ? searchValue.sort() : searchValue;
            sortedParams[value] = sortedValues;
        });

        return sortedParams;
    }
}
export class HttpCancelError extends Error {}
export class HttpRedirectError extends Error {}

export default new HttpService();
