import { reactive } from 'vue';
import breakpointService from './breakpoints.service';

class BreakpointsState {
    private breakpointsState: { [breakpoint: string]: boolean } = reactive({});

    constructor() {
        breakpointService.addListener(this.breakpointChangeHandler.bind(this));
    }

    /*
    Supports formats like
    'ls,md'
    'max-md'
    'min-md,max-xl'
    */
    public isBreakpointActive(breakpointsDescriptor: string) {
        return breakpointService.getExpandedBreakpoints(breakpointsDescriptor).some(bp => this.breakpointsState[bp]);
    }

    public get activeBreakpoint(): string {
        return Object.keys(this.breakpointsState).find(bp => this.breakpointsState[bp])!;
    }

    private breakpointChangeHandler() {
        breakpointService.getBreakpoints().forEach(bp => {
            this.breakpointsState[bp.name] = breakpointService.isActiveBreakpoint(bp.name);
        });
    }
}

export default new BreakpointsState();
