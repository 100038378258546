import { blockResolver } from '@/core/spa/componentResolver.service';
import { App, defineAsyncComponent } from 'vue';
import BlocksContainer from './BlocksContainer.vue';
import BlockHeader from './BlockHeader.vue';
import BlockTeaserText from './BlockTeaserText.vue';
import BlockHeroBanner from './BlockHeroBanner.vue';
import BlockCampaignProductFocus from './BlockCampaignProductFocus.vue';
import BlockContentSpot from './BlockContentSpot.vue';
import BlockProductList from './BlockProductList.vue';
import BlockImage from './BlockImage.vue';
import BlockText from './BlockText.vue';
import BlockFallback from './BlockFallback.vue';
import BlockTextBoxes from './BlockTextBoxes.vue';
import BlockTextSectionHeader from './BlockTextSectionHeader.vue';
import BlockTextSection from './BlockTextSection.vue';
const BlockInstagram = defineAsyncComponent(() => import('./BlockInstagram.vue'));
const BlockHeroBannerWithScreens = defineAsyncComponent(() => import('./BlockHeroScreens.vue'));
const BlockProductListContentTeaser = defineAsyncComponent(() => import('./BlockProductListContentTeaser.vue'));
const BlockArticleAsymmetricalImages = defineAsyncComponent(() => import('./article/BlockArticleAsymmetricalImages.vue'));
const BlockFullWidthPattern = defineAsyncComponent(() => import('./BlockFullWidthPattern.vue'));
const BlockProductAssortment = defineAsyncComponent(() => import('./BlockProductAssortment.vue'));
const BlockLinkGroup = defineAsyncComponent(() => import('./BlockLinkGroup.vue'));
const BlockContentFocus = defineAsyncComponent(() => import('./BlockContentFocus.vue'));
const BlockContentFocusPart = defineAsyncComponent(() => import('./BlockContentFocusPart.vue'));
const BlockProductFocusBlockPart = defineAsyncComponent(() => import('./largeProductTeaser/BlockLargeProductTeaserLargeProduct.vue'));
const BlockNewsletter = defineAsyncComponent(() => import('./BlockNewsletter.vue'));
const BlockLargeProductTeaser = defineAsyncComponent(() => import('./BlockLargeProductTeaser.vue'));
const BlockContentTeaser = defineAsyncComponent(() => import('./BlockContentTeaser.vue'));
const BlockContentTeaserImagePart = defineAsyncComponent(() => import('./BlockContentTeaserImagePart.vue'));
const BlockContentTeaserTextPart = defineAsyncComponent(() => import('./BlockContentTeaserTextPart.vue'));
const BlockQuote = defineAsyncComponent(() => import('./BlockQuote.vue'));
const BlockProductCollection = defineAsyncComponent(() => import('./BlockProductCollection.vue'));
const BlockVideo = defineAsyncComponent(() => import('./BlockVideo.vue'));
const BlockArticleImage = defineAsyncComponent(() => import('./article/BlockArticleImage.vue'));
const BlockArticleText = defineAsyncComponent(() => import('./article/BlockArticleText.vue'));
const BlockHorizontalLine = defineAsyncComponent(() => import('./BlockHorizontalLine.vue'));
const BlockFaqGroup = defineAsyncComponent(() => import('./faq/BlockFaqGroup.vue'));
const BlockFindStore = defineAsyncComponent(() => import('./BlockFindStore.vue'));
const TwoIcons = defineAsyncComponent(() => import('./TwoIcons.vue'));
const BlockProductGallery = defineAsyncComponent(() => import('./BlockProductGallery.vue'));
const BlockProductDisplay = defineAsyncComponent(() => import('./BlockProductDisplay.vue'));
const BlockIconList = defineAsyncComponent(() => import('./BlockIconList.vue'));
const BlockShoppingList = defineAsyncComponent(() => import('./BlockShoppingList.vue'));
const BlockSplitTest = defineAsyncComponent(() => import('./BlockSplitTest.vue'));
const BlockAccordion = defineAsyncComponent(() => import('./BlockAccordion.vue'));
const BlockUniqueSellingPoints = defineAsyncComponent(() => import('./BlockUniqueSellingPoints.vue'));

const BlockProductRecommendations = defineAsyncComponent(() => import('./BlockProductRecommendations.vue'));
const BlockCategoryListCategory = defineAsyncComponent(() => import('./category/BlockCategoryListCategory.vue'));
const BlockCategoryListTopicTeaser = defineAsyncComponent(() => import('./category/BlockCategoryListTopicTeaser.vue'));
const BlockCategoryListContentTeaser = defineAsyncComponent(() => import('./category/BlockCategoryListContentTeaser.vue'));

const BlockTopicListContentTeaser = defineAsyncComponent(() => import('./topics/BlockTopicListContentTeaser.vue'));

const BlockContentHubGalleryStoryHeading = defineAsyncComponent(() => import('./contentHub/BlockContentHubGalleryStoryHeading.vue'));
const BlockFeatureStoryFooter = defineAsyncComponent(() => import('./contentHub/featureStory/BlockFeatureStoryFooter.vue'));
const BlockFeatureStoryCombinedHeader = defineAsyncComponent(() => import('./contentHub/featureStory/BlockFeatureStoryCombinedHeader.vue'));
const BlockContentHubHeader = defineAsyncComponent(() => import('./contentHub/BlockContentHubHeader.vue'));
const BlockContentHubSimpleHeader = defineAsyncComponent(() => import('./contentHub/BlockContentHubSimpleHeader.vue'));
const BlockContentHubQuote = defineAsyncComponent(() => import('./contentHub/BlockContentHubQuote.vue'));
const BlockContentHubTeaserText = defineAsyncComponent(() => import('./contentHub/BlockContentHubTeaserText.vue'));
const BlockContentHubText = defineAsyncComponent(() => import('./contentHub/BlockContentHubText.vue'));
const BlockContentHubDate = defineAsyncComponent(() => import('./contentHub/BlockContentHubDate.vue'));
const BlockContentHubAnnaAndClaraDialogue = defineAsyncComponent(() => import('./contentHub/BlockContentHubAnnaAndClaraDialogue.vue'));
const BlockContentHubContentTeaser = defineAsyncComponent(() => import('./contentHub/BlockContentHubContentTeaser.vue'));
const BlockContentHubContentTeaserNoImage = defineAsyncComponent(() => import('./contentHub/BlockContentHubContentTeaserNoImage.vue'));
const BlockContentHubQuoteBox = defineAsyncComponent(() => import('./contentHub/BlockContentHubQuoteBox.vue'));
const BlockContentHubPreviewList = defineAsyncComponent(() => import('./contentHub/BlockContentHubPreviewList.vue'));
const BlockContentHubImageWithTextBox = defineAsyncComponent(() => import('./contentHub/BlockContentHubImageWithTextBox.vue'));
const BlockContentHubImage = defineAsyncComponent(() => import('./contentHub/BlockContentHubImage.vue'));
const BlockContentHubTwoImages = defineAsyncComponent(() => import('./contentHub/BlockContentHubTwoImages.vue'));
const BlockContentHubExperienceIntro = defineAsyncComponent(() => import('./contentHub/experience/BlockContentHubExperienceIntro.vue'));
const BlockContentHubExperienceDescription = defineAsyncComponent(() => import('./contentHub/experience/BlockContentHubExperienceDescription.vue'));
const BlockContentHubExperienceHints = defineAsyncComponent(() => import('./contentHub/experience/BlockContentHubExperienceHints.vue'));
const BlockContentHubExperienceExit = defineAsyncComponent(() => import('./contentHub/experience/BlockContentHubExperienceExit.vue'));
const BlockContentHubFrontPageHeader = defineAsyncComponent(() => import('./contentHub/inspiration/BlockContentHubFrontPageHeader.vue'));
const BlockContentHubMomentPreviewWrapper = defineAsyncComponent(() => import('./contentHub/inspiration/BlockContentHubMomentPreviewWrapper.vue'));
const BlockContentHubCollectionBlock = defineAsyncComponent(() => import('./contentHub/inspiration/BlockContentHubCollectionBlock.vue'));
const BlockContentHubThemeBlock = defineAsyncComponent(() => import('./contentHub/BlockContentHubThemeBlock.vue'));
const BlockContentHubProductsRecommendations = defineAsyncComponent(() => import('./contentHub/BlockContentHubProductsRecommendations.vue'));
const BlockRoomListRoom = defineAsyncComponent(() => import('./rooms/BlockRoomListRoom.vue'));

// Product modules
const BlockProductModuleDisplayModule = defineAsyncComponent(() => import('./contentHub/modules/BlockProductModuleDisplayModule.vue'));

export default function blocksConfig(app: App) {
    app.component('BlocksContainer', BlocksContainer);
}

blockResolver.register('headerBlock', BlockHeader);
blockResolver.register('linkBlock', BlockLinkGroup);
blockResolver.register('heroBlock', BlockHeroBanner);
blockResolver.register('heroScrollBlock', BlockHeroBannerWithScreens);
blockResolver.register('campaignProductFocusBlock', BlockCampaignProductFocus);
blockResolver.register('productFocusBlockPartBlock', BlockProductFocusBlockPart);
blockResolver.register('contentSpotBlock', BlockContentSpot);
blockResolver.register('contentTeasersBlock', BlockContentTeaser);
blockResolver.register('contentTeasersBlockImagePart', BlockContentTeaserImagePart);
blockResolver.register('contentTeasersBlockTextPart', BlockContentTeaserTextPart);
blockResolver.register('quoteBlock', BlockQuote);
blockResolver.register('productListBlock', BlockProductList);
blockResolver.register('productListBlockContentTeaserPart', BlockProductListContentTeaser);
blockResolver.register('productCollectionBlock', BlockProductCollection);
blockResolver.register('imageBlock', BlockImage);
blockResolver.register('newsletterSignupBlock', BlockNewsletter);
blockResolver.register('teaserTextBlock', BlockTeaserText);
blockResolver.register('videoBlock', BlockVideo);
blockResolver.register('textBlock', BlockText);
blockResolver.register('productDisplayBlock', BlockProductDisplay);
blockResolver.register('categoryListCategoryBlock', BlockCategoryListCategory);
blockResolver.register('categoryListTopicTeaserBlock', BlockCategoryListTopicTeaser);
blockResolver.register('categoryListContentTeaserBlock', BlockCategoryListContentTeaser);
blockResolver.register('faqGroupBlock', BlockFaqGroup);
blockResolver.register('articleTextBlock', BlockArticleText);
blockResolver.register('articleImageBlock', BlockArticleImage);
blockResolver.register('articleAsymmetricalImagesBlock', BlockArticleAsymmetricalImages);
blockResolver.register('topicListContentTeaserBlock', BlockTopicListContentTeaser);
blockResolver.register('productAssortmentBlock', BlockProductAssortment);
blockResolver.register('contentFocusBlock', BlockContentFocus);
blockResolver.register('contentFocusBlockPart', BlockContentFocusPart);
blockResolver.register('largeProductTeaserBlock', BlockLargeProductTeaser);
blockResolver.register('findStoreBlock', BlockFindStore);
blockResolver.register('twoIcons', TwoIcons);
blockResolver.register('productGalleryBlock', BlockProductGallery);
blockResolver.register('horizontalLineBlock', BlockHorizontalLine);
blockResolver.register('textSectionHeaderBlock', BlockTextSectionHeader);
blockResolver.register('textSectionBlock', BlockTextSection);
blockResolver.register('textBoxesBlock', BlockTextBoxes);
blockResolver.register('recommendationsBlock', BlockProductRecommendations);
blockResolver.register('iconListBlock', BlockIconList);
blockResolver.register('shoppingListBlock', BlockShoppingList);
blockResolver.register('splitTest', BlockSplitTest);
blockResolver.register('accordionBlock', BlockAccordion);
blockResolver.register('uniqueSellingPointsBlock', BlockUniqueSellingPoints);
blockResolver.register('fullWidthPatternBlock', BlockFullWidthPattern);
blockResolver.register('roomListRoomBlock', BlockRoomListRoom);
blockResolver.register('instagramBlock', BlockInstagram);

blockResolver.register('contentHubGalleryStoryHeaderBlock', BlockContentHubGalleryStoryHeading);
blockResolver.register('contentHubFeatureStoryCombinedHeaderBlock', BlockFeatureStoryCombinedHeader);
// blockResolver.register('contentHubFeatureStoryIntroTextBlock', BlockFeatureStoryIntroText);
blockResolver.register('contentHubFeatureStoryFooterBlock', BlockFeatureStoryFooter);
blockResolver.register('contentHubHeaderBlock', BlockContentHubHeader);
blockResolver.register('contentHubSimpleHeaderBlock', BlockContentHubSimpleHeader);
blockResolver.register('contentHubQuoteBlock', BlockContentHubQuote);
blockResolver.register('contentHubTeaserTextBlock', BlockContentHubTeaserText);
blockResolver.register('contentHubTextBlock', BlockContentHubText);
blockResolver.register('contentHubDateBlock', BlockContentHubDate);
blockResolver.register('contentHubAnnaAndClaraDialogueBlock', BlockContentHubAnnaAndClaraDialogue);
blockResolver.register('contentHubContentTeaserBlock', BlockContentHubContentTeaser);
blockResolver.register('contentHubContentTeaserNoImageBlock', BlockContentHubContentTeaserNoImage);
blockResolver.register('contentHubQuoteBoxBlock', BlockContentHubQuoteBox);
blockResolver.register('contentHubPreviewListBlock', BlockContentHubPreviewList);
blockResolver.register('contentHubImageWithTextBoxBlock', BlockContentHubImageWithTextBox);
blockResolver.register('contentHubImageBlock', BlockContentHubImage);
blockResolver.register('contentHubTwoImagesBlock', BlockContentHubTwoImages);
blockResolver.register('contentHubExperienceIntroBlock', BlockContentHubExperienceIntro);
blockResolver.register('contentHubExperienceDescriptionBlock', BlockContentHubExperienceDescription);
blockResolver.register('contentHubExperienceHintsBlock', BlockContentHubExperienceHints);
blockResolver.register('contentHubExperienceExitBlock', BlockContentHubExperienceExit);
blockResolver.register('contentHubFrontPageHeaderBlock', BlockContentHubFrontPageHeader);
blockResolver.register('contentHubMomentPreviewWrapperBlock', BlockContentHubMomentPreviewWrapper);
blockResolver.register('contentHubCollectionBlock', BlockContentHubCollectionBlock);
blockResolver.register('contentHubThemeBlock', BlockContentHubThemeBlock);
blockResolver.register('contentHubProductRecommendationsBlock', BlockContentHubProductsRecommendations);

// Product modules
blockResolver.register('DisplayModule', BlockProductModuleDisplayModule);

// Fallback block component
blockResolver.registerFallback(BlockFallback);
