import { router } from '@/core/spa/router';
import { doDataLayerPush } from '@/project/tracking/googleTagManager.utils';
import { isHrefExternal } from '@/project/shared/string.util';
import { HttpRedirectError } from '@/core/http/http.service';
import axios from 'axios';

function redirectInterceptor(response) {
    const redirectUrl = response.headers.clientredirect || response.headers.hardclientredirect || response.headers.authredirect;
    if (redirectUrl) {
        if (response.headers.authredirect) {
            trackLoginAttempt();
            // TODO: set cookie / localstorage with login attempt. Then when app reloads, log if user is logged in or not (login success or login failed)
        }
        if (redirectUrl) {
            isHrefExternal(redirectUrl) || response.headers.hardclientredirect ? hardRedirect(redirectUrl) : softRedirect(redirectUrl);
        } else if (response.data.reloadCurrentPage) {
            hardReload();
        }
        // Payload should not be used. This will be treated special
        throw new HttpRedirectError();
    }
    return response;
}
axios.interceptors.response.use(redirectInterceptor);

function hardReload() {
    window.location.reload();
}

function softRedirect(url: string) {
    router.replace(url);
}

function hardRedirect(url: string) {
    setTimeout(() => { window.location.replace(url); }, 100);
}

function trackLoginAttempt():void {
    doDataLayerPush({
        event: 'GAEvent',
        eventCategory: 'Login',
        eventAction: 'Submit',
        eventLabel: undefined,
        eventValue: undefined
    });
}
