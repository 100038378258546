import translateFilter from '@/core/translation/translate.filter';
import serverContext from '@/core/serverContext.service';
import { z } from 'zod';
import Api from '@/project/http/api';
import {
    validateDhlCustomerNo,
    validateName,
    validateStringHasChars,
    validateStringHasNumber
} from '@/project/shared/string.util';

export function validationRuleRequired(required = true) {
    return required ? z.string().min(1).trim() : z.string();
}

export function validationRuleName() {
    return validationRuleRequired()
        .max(40)
        .superRefine(createSuperRefine(validateName, 'validation.Custom.Name.NoIllegalCharacters'));
}

export function validationRuleAddress(market: string) {
    // Some addresses does not have streetnumber in Norway and others: https://www.posten.no/en/sending/preparation/addressing
    const rules = validationRuleRequired()
        .max(50)
        .superRefine(createSuperRefine(validateStringHasChars, 'validation.Custom.Street.HasChars'));
    if (['NO', 'IE', 'GB'].includes(market) === false) {
        return rules.superRefine(createSuperRefine(validateStringHasNumber, 'validation.Custom.Street.HasNumber'));
    }
    return rules;
}

export function validationRulePostalCodeFromCountry(selectedCountry: string) {
    const exampleFormat = getExampleFormat(selectedCountry);
    return validationRuleRequired()
        .max(20)
        .superRefine(createSuperRefine(validate, 'validation.Custom.PostalCode.ByCountry', exampleFormat));

    function validate(value: string) {
        const selectedMarket = serverContext.availableCountries.find((c) => c.countryCode === selectedCountry);
        const postalPattern = selectedMarket ? selectedMarket.postalCodePattern : null;
        if (postalPattern) {
            const regex = new RegExp(postalPattern, 'ig');
            const regmatch = value.trim().match(regex);
            const match = regmatch && regmatch.length > 0;
            return match || false;
        }
        // if we dont have any pattern, this validation will just pass true.
        return true;
    }
    
    function getExampleFormat(selectedCountry: string) {
        let exampleFormat = '';
        switch (selectedCountry) {
            // Postal Codes are easy, just like 4 or 5 digits:
            // 4 digits
            case 'BE':
                exampleFormat = '1041';
                break;
            case 'DK':
                exampleFormat = '8200';
                break;
            case 'NO':
                exampleFormat = '0010';
                break;
            case 'CH':
                exampleFormat = '3436';
                break;
            case 'AT':
                exampleFormat = '1901';
                break;

                // 5 digits
            case 'FI':
                exampleFormat = '00100';
                break;
            case 'FR':
                exampleFormat = '75008';
                break;
            case 'DE':
                exampleFormat = '10115';
                break;

            case 'SE':
                // Then Sweden is a little special
                exampleFormat = '10x xx';
                break;
            case 'NL':
                // Holland too...
                exampleFormat = '1000 AP';
                break;
            case 'FO':
            case 'IS':
                // Few are unknown
                exampleFormat = ''; // Unknown
                break;
            case 'IE':
                // Ireland is odd...
                exampleFormat = 'A94 X7Y0';
                break;
            case 'GB':
                // England, Brexit, dont say a thing...
                exampleFormat = 'SW11 7US';
                break;
        }
        return exampleFormat;
    }
}

export function validationRuleEmail(emailExist = false) {
    const baseSchema = validationRuleRequired()
        .max(130)
        .email();
    if (!emailExist) {
        return baseSchema;
    }
    return baseSchema
        .refine(async(value) => {
            const result = await Api.user.doesAccountExistByEmail({ email: value });
            return !result.exists;
        }, {
            message: translateFilter('login.CreateUser.Error.UserExists'),
        });
}

export function validationRulePhoneNumberWithSpace(phonePrefix: string | undefined, required: boolean = true) {
    return (required ? validationRuleRequired() : z.string())
        .superRefine((value, ctx) => {

            type ValidationData = {
                value: string,
                isRegexMatch: boolean,
                countrySpecificMinValid: boolean,
                countrySpecificMinLength: number,
                countrySpecificMaxValid: boolean,
                countrySpecificMaxLength: number,
                phonePrefix: string | undefined
            };

            type ValidationResult = {
                valid: boolean,
                data: ValidationData
            };

            const validate = (value: string, phonePrefix: string | undefined): ValidationResult => {
                const selectedMarket = serverContext.availableCountries.find((c) => c.phonePrefix === phonePrefix);
                const regex = new RegExp(/^[0-9 ]+$/); // Space and Numbers
                const regmatch = value.match(regex);
                const match = regmatch && regmatch.length > 0;
                const isRegexMatch = match || false;
                const valueWithoutSpaces = value.replace(/ /g, '');
                // Country specific validation
                const countrySpecificMinLength = selectedMarket?.phoneMinLength || 3;
                const countrySpecificMaxLength = selectedMarket?.phoneMaxLength || 20;
                // See https://en.wikipedia.org/wiki/Telephone_numbers_in_Europe
                const countrySpecificMinValid = valueWithoutSpaces.toString().length >= countrySpecificMinLength;
                const countrySpecificMaxValid = valueWithoutSpaces.toString().length <= countrySpecificMaxLength;
                const isValid = isRegexMatch && countrySpecificMinValid && countrySpecificMaxValid;
                return {
                    valid: isValid,
                    data: {
                        value: valueWithoutSpaces,
                        isRegexMatch,
                        countrySpecificMinValid,
                        countrySpecificMinLength,
                        countrySpecificMaxValid,
                        countrySpecificMaxLength,
                        phonePrefix
                    }
                };
            };

            const getMessage = (data: ValidationData): string => {
                const { isRegexMatch, countrySpecificMinLength, countrySpecificMaxLength } = data;
                if (!isRegexMatch) return translateFilter('validation.Custom.InvoiceAddressPhone.Integer');
                return translateFilter(
                    countrySpecificMinLength === countrySpecificMaxLength
                        ? 'validation.Custom.InvoiceAddressPhone.CountrySpecificFixedLength'
                        : 'validation.Custom.InvoiceAddressPhone.CountrySpecificLength',
                    '__field__',
                    countrySpecificMinLength.toString(),
                    countrySpecificMaxLength.toString()
                );
            };

            const result = validate(value, phonePrefix);
            if (result.valid) {
                return;
            }

            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: getMessage(result.data)
            });
        });
}

export function validationRuleIsSame(base: string, labelOfBase: string) {
    return validationRuleRequired()
        .refine((value) => value === base, 
                {
                    message: translateFilter('validation.Is', labelOfBase),
                });
}

export function validationRuleMinAge(schema: z.Schema<any>, minAge: number) {
    // Expects date in format yyyy-mm-dd
    return schema.superRefine((value: string, ctx) => {
        const now = new Date();
        const birthday = new Date(value);
        const hadBirthdayThisYear = now.getDate() >= birthday.getDate() && now.getMonth() >= birthday.getMonth();    
        const age = now.getFullYear() - birthday.getFullYear() - (hadBirthdayThisYear ? 0 : 1);
        const valid = age >= minAge;

        if (!valid) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: translateFilter('login.CreateUser.Error.BirthdayMinAge')
            });
        }
    });
}

export function validationRuleBirthday(minYear: number, maxYear: number) {
    // Expects date in format yyyy-mm-dd
    return validationRuleRequired()
        .superRefine((value, ctx) => {
            if (!value) return;
            
            const [year, month, day] = value.split('-');
            mayBeError('day', day);
            mayBeError('month', month);
            mayBeError('year', year, minYear, maxYear);

            function mayBeError(part: string, value: string, minValue: number | undefined = undefined, maxValue: number | undefined = undefined) {
                const numValue = Number(value);
                if (Number.isNaN(numValue) || (minValue && numValue < minValue) || (maxValue && numValue > maxValue)) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: translateFilter('validation.Custom.Birthday'),
                        params: {
                            field: part
                        }
                    });
                }
            }
        });
}

export function validationRuleDhlCustomerNumber() {
    return validationRuleRequired()
        .superRefine(createSuperRefine(validateDhlCustomerNo, 'validation.Custom.DhlCustomerNo'));
}

function createSuperRefine(validator: (value: string) => boolean, label: string, ...args: any[]) {
    return (value: string, ctx: z.RefinementCtx) => {
        if (validator(value)) {
            return;
        }
        ctx.addIssue({ 
            code: z.ZodIssueCode.custom,
            message: translateFilter(label, '__field__', ...args)
        });
    };
}       