import { App } from 'vue';
import '@/styling/5-Components/v-tooltip.less';
import 'floating-vue/dist/style.css';
import FloatingVue from 'floating-vue';

export default function vTooltipPlugin(app: App): void {
    app.use(FloatingVue, {
        themes: {
            'custom-dropdown': {
                $extend: 'dropdown',
                $resetCss: true
            }
        }
    });
}