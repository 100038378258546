<template>
    <div class="flex flex-col gap-8">
        <div class="relative bg-sand-50"
             :class="wrapperClass">
            <transition
                mode="out-in"
                leave-active-class="animated fadeOut u-anim-dur-200"
                enter-active-class="animated fadeIn u-anim-dur-500">
                <button
                    v-if="enablePrevious"
                    class="absolute inset-y-0 left-0 -ml-40 hidden md:block"
                    :aria-label="$translate('generic.Prev')"
                    @click="previousSlide">
                    <cIcon name="chevron"
                           class="w-20"/>
                </button>
            </transition>
            <div ref="scrollContainer"
                 class="container"
                 body-scroll-lock-ignore
                 @scroll="handleScroll">
                <slot/>
            </div>
            <transition mode="out-in"
                        enter-active-class="animated fadeIn u-anim-dur-1000">
                <button
                    v-if="enableNext"
                    class="absolute inset-y-0 right-0 -mr-40 hidden md:block"
                    :aria-label="$translate('generic.Next')"
                    @click="nextSlide">
                    <cIcon name="chevron"
                           class="u-rotate-180 w-20"/>
                </button>
            </transition>
        </div>
        <div class="c-hcs-dots h-24 px-20"
             :class="dotsClass">
            <CarouselDots
                v-if="slides.length > 1"
                :slides="slides"
                :active-index="activeIndex"
                class="c-hcs-dots--center"
                @go-to="selectSlide"/>
            <div class="c-hcs-dots--right text-brown-80">
                <slot name="carousel-dots"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import debounce from 'lodash-es/debounce';
import CarouselDots from '@/project/product/details/CarouselDots.vue';

const props = defineProps<{
    activeIndex: number;
    slides: number[];
    wrapperClass?: string;
    dotsClass?: string;
}>();

const firstMount = ref(true);
const activeIndex = ref(props.activeIndex);
const scrollContainer = ref<HTMLElement | null>(null);
const scrollPosition = ref(0);

const emit = defineEmits(['slide-changed']);

watch(
    () => props.activeIndex,
    (activeIndexFromProps) => {
        if (activeIndexFromProps === activeIndex.value) return;
        selectSlide(activeIndexFromProps);
    }
);

watch(activeIndex, (activeIndex) => {
    emit('slide-changed', activeIndex);
});

const elementWidth = computed(() => {
    return scrollContainer.value?.children[0].clientWidth || 0;
});

watch(
    [scrollContainer, elementWidth, activeIndex],
    ([scrollContainer, elementWidth, activeIndex]) => {
        if (!scrollContainer || !elementWidth) return;
        if (firstMount.value) {
            selectSlide(activeIndex, false);
            firstMount.value = false;
        }
    },
    { immediate: true }
);

const enablePrevious = computed(() => {
    return activeIndex.value > 0;
});

const enableNext = computed(() => {
    return activeIndex.value < props.slides.length - 1;
});

const selectSlide = (index: number, smooth = true) => {
    activeIndex.value = index;
    scrollPosition.value = elementWidth.value * index;

    if (!scrollContainer.value) return;
    scrollContainer.value.scroll({
        left: scrollPosition.value,
        behavior: smooth ? 'smooth' : 'auto'
    });
};

const previousSlide = () => {
    if (!enablePrevious.value) return;
    selectSlide(activeIndex.value - 1);
};

const nextSlide = () => {
    if (!enableNext.value) return;
    selectSlide(activeIndex.value + 1);
};

const handleScroll = debounce(() => {
    if (!scrollContainer.value) return;

    scrollPosition.value = scrollContainer.value.scrollLeft;
    activeIndex.value = Math.round(scrollPosition.value / elementWidth.value);
}, 100);
</script>

<style lang="less" scoped>
.container::-webkit-scrollbar {
    display: none;
}

.container {
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    flex-flow: row nowrap;

    @apply flex h-full w-full flex-none overflow-auto;

    > :deep(div)  {
        scroll-snap-align: center;
        @apply h-full w-full flex-none;
    }
}

.c-hcs-dots {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: '. c-hcs-dots-center c-hcs-dots-right';
    justify-content: center;
    align-items: center;
}

.c-hcs-dots--center {
    grid-area: c-hcs-dots-center;
}

.c-hcs-dots--right {
    justify-self: end;
    grid-area: c-hcs-dots-right;
}
</style>
