<template>
    <article>
        <div v-if="facetGroup">
            <header class="uppercase mb-10 text-10 tracking-medium-heading">
                <span>
                    {{ $translate('search.Filters.Color') }}
                </span>
            </header>
            <ul class="flex flex-wrap items-center">
                <li v-for="(facet, facetIndex) in facetGroup.values"
                    :key="facet.key">
                    <span class="c-search-color"
                          :title="facet.label">
                        <input :id="facet.key + '_' + facetIndex"
                               v-model="facet.selected"
                               class="c-search-color__input"
                               type="checkbox"
                               :name="facet.key + '_' + facetIndex"
                               :value="facet.key"
                               @change="facetChange(facetGroup, facet)">
                        <label :for="facet.key + '_' + facetIndex"
                               class="c-search-color__label"
                               :class="{ 'c-search-color__label--selected' : facet.selected}"
                               :aria-label="facet.label">
                            <div class="c-search-color__label-bg"
                                 :style="{backgroundColor: '#'+facet.color}"/>
                            <div class="c-search-color__label-circle"/>
                        </label>
                    </span>
                </li>
            </ul>
        </div>
    </article>
</template>
<script setup lang="ts">
import urlHelper from '../facets/urlHelper.service';
import { FacetGroup, FacetValue } from '@/types/serverContract';
import trackingUtils from '@/project/tracking/tracking.utils';

defineProps<{
  facetGroup?: FacetGroup;
}>();

const facetChange = (facetGroup: FacetGroup, facet: FacetValue) => {
    trackingUtils.facetFilter.trackFacetAddOrRemove(
        facet.selected,
        facetGroup.key,
        facet?.key,
        urlHelper.getFacets()
    );
    urlHelper.updateFacetValue(facetGroup.key, facet.key, facet.selected);
};
</script>
<style lang="less" scoped>
    .c-search-color {
        @apply flex mr-10 mb-10;
    }

    .c-search-color__label-bg {
        @apply h-16 w-16 inline-block bg-brown-80 rounded-full shadow-color-dot;
        transition: transform .3s cubic-bezier(.215,.61,.355,1);
        content: '';
    }

    .c-search-color__label-circle {
        @apply rounded-full border-2 border-white-100 absolute z-2 bg-transparent;
        content: '';
        transition: transform .3s cubic-bezier(.215,.61,.355,1), opacity .25s cubic-bezier(.215,.61,.355,1);
        opacity: 0;
        height: 1.3rem;
        width: 1.3rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(.5);
    }

    .c-search-color__label {
        @apply flex items-center justify-center relative cursor-pointer;
        height: 2rem;
        width: 2rem;

        &.c-search-color__label--selected {
            .c-search-color__label-bg {
            }

            .c-search-color__label-circle {
                transition-delay: .15s;
                opacity: 1;
                transform: translate(-50%, -50%) scale(1);
            }
        }
    }

    .c-search-color__input{
        display: none;
    }
</style>
