import { Store } from '@/types/serverContract';
import translateFilter from '@/core/translation/translate.filter';
import DOMPurify from 'dompurify';
export enum confettiModes {
    birthdayInput = 'birthdayInput',
    birthdayCelebration = 'birthdayCelebration',
    nationalCelebration = 'nationalCelebration',
    searchTermExplosion = 'searchTerm.Explosion',
    searchTermMildExplosion = 'searchTerm.MildExplosion'
}

export function lowercaseFirstChar(text: string): string {
    if (!text) return '';
    return text.substr(0, 1).toLowerCase() + text.substring(1);
}

export function uppercaseFirstChar(text: string): string {
    if (!text) return '';
    return text.substr(0, 1).toUpperCase() + text.substring(1);
}

export function newLineToBr(text: string, regExSearch: RegExp = /\n/g): string {
    if (!text) return '';
    return text.replace(regExSearch, '<br>');
}

// returns true if href points to an external url
export function isHrefExternal(href: string, host: string = location.host): boolean {
    const external = RegExp('^((f|ht)tps?:)?//(?!' + host + ')');
    return external.test(href);
}

// if a href points to some files in the Umbraco /media folder
export function isHrefMedia(href: string): boolean {
    let pathname;
    if (href.indexOf('http') === 0) {
        const url = new URL(href);
        pathname = url.pathname;
    } else {
        pathname = href;
    }
    return pathname.indexOf('/media/') === 0;
}

// if a href points to external scheme e.g. (mailto:)
export function isExternalScheme(href: string): boolean {
    const externalScheme = RegExp('^(?:javascript|data|chrome|mailto|tel|sms|callto|mms|skype):.+$');
    return externalScheme.test(href);
}

// Finds Anchor link id for blocks. Used if block.content does not have a title
export function getBlockAnchorLink(block: any): string | null {
    if (block && block.content && block.content.anchorLink && !block.content.title) {
        return block.content.anchorLink;
    }
    return null;
}

// Finds Anchor link id for content inside blocks. Used if content has a title.
export function getHeaderAnchorLink(content: any): string | undefined {
    if (content && content.anchorLink && content.title) {
        return content.anchorLink;
    }
    return undefined;
}

export function format(translated: string, args: string[] = []): string {
    return args.reduce((result, arg, ix) => {
        return result.split(`{${ix}}`).join(arg);
    }, translated);
}

export function isPrerenderUseragent(): boolean {
    return !!(navigator?.userAgent?.match(/Prerender \(\+https:\/\/github.com\/prerender\/prerender\)/) ?? false);
}

export function isFacebookInAppUseragent(): boolean {
    // @ts-ignore
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua as any).indexOf('FBAN') > -1 || (ua as any).indexOf('FBAV') > -1;
}

export function getMonthLabelKey(month: number) {
    const monthLabes = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    return `generic.${monthLabes[month]}`;
}

export function jsDateToServerStringDate(date: Date | string, zeroPad: boolean = true): string | null {
    if (typeof date === 'string') return date;
    const dateObject = new Date(date);
    const month = dateObject.getMonth() + 1; // Date month are zero based.
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
    return `${year}-${(zeroPad && month < 10 ? '0' : '') + month}-${(zeroPad && day < 10 ? '0' : '') + day}`;
}

export function serverStringDateToJsDate(date: string | null): Date {
    const dateStringParts: any[] = date ? date.split('-') : [];
    if (dateStringParts.length === 3) {
        dateStringParts[0] = parseInt(dateStringParts[0]);
        dateStringParts[1] = parseInt(dateStringParts[1]) - 1; // Date month are zero based.
        dateStringParts[2] = parseInt(dateStringParts[2]);
        const date = new Date(dateStringParts[0], dateStringParts[1], dateStringParts[2]);
        return date;
    }
    return new Date(0);
}

export function millisecondsUntilNextBirthday(nextBirthday: Date): number {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0, 1, 0);
    nextBirthday.setHours(0);
    nextBirthday.setMinutes(0, 1, 0);
    const addAnExtraYear: boolean =
        nextBirthday.getMonth() < today.getMonth() ||
        (nextBirthday.getMonth() === today.getMonth() && nextBirthday.getDate() < today.getDate());
    nextBirthday.setFullYear(today.getFullYear() + (addAnExtraYear ? 1 : 0));
    // To calculate the time difference of two dates
    const differenceInTime = nextBirthday.getTime() - today.getTime();
    return differenceInTime;
}

export function verySimpleEmailValidation(email: string): boolean {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
}

export function validateStringHasChars(value: string = ''): boolean {
    const regex = /\p{L}/gu;
    const regmatch = value.match(regex);
    const match = regmatch && regmatch.length >= 1;
    return match || false;
}

export function validateStringHasNumber(value: string = ''): boolean {
    const regex = /\d/;
    const regmatch = value.match(regex);
    const match = regmatch && regmatch.length > 0;
    return match || false;
}

export function validateName(input: string): boolean {
    const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ0-9&()'´\d\s-]+$/;
    return regex.test(input);
}

export function validationRuleNameField() {
    return ['required', 'max:49', 'name'].join('|');
}

export function validationRuleAddressField(market: string): string {
    // Some addresses does not have streetnumber in Norway: https://www.posten.no/en/sending/preparation/addressing
    const rules = ['required', 'max:50', 'streetname'];
    if (['NO', 'IE', 'GB'].includes(market) === false) {
        rules.push('streetnumber');
    }
    return rules.join('|');
}

export function validationRulePostalCode(postalcode: string, countryCode: string, serverContext: any) {
    const selectedMarket = serverContext.availableCountries.find((c) => c.countryCode === countryCode);
    const postalPattern = selectedMarket ? selectedMarket.postalCodePattern : null;

    if (postalPattern) {
        const regex = new RegExp(postalPattern, 'ig');
        const regmatch = postalcode.trim().match(regex);
        const match = regmatch && regmatch.length > 0;
        return match || false;
    }
    // if we dont have any pattern, this validation will just pass true.
    return true;
}

export function validationRuleEmailField(emailExist = false) {
    const rules: any = {
        required: true,
        max: 130,
        email: {
            allow_utf8_local_part: false
        }
    };

    if (emailExist) {
        rules.email_exist = true;
    }

    return rules;
}

export function markupForStoreOpeningsHoursToday(
    store: Store,
    useDots: boolean = false,
    opensStateClass: string = 'md:font-medium',
    openStateClass: string = 'md:font-medium',
    closedStateClass: string = 'md:font-medium'
): string {
    const now = new Date();
    // In JS dates Monday: 1 ... Sunday = 0... lets find the correct index of days
    let todayIndex = now.getDay() - 1;
    if (todayIndex === -1) {
        todayIndex = 6;
    }
    const hoursToday = store.openingHours[todayIndex];
    if (
        hoursToday.openingHour !== null &&
        hoursToday.openingMinute !== null &&
        (now.getHours() < hoursToday.openingHour ||
            (now.getHours() === hoursToday.openingHour && now.getMinutes() < hoursToday.openingMinute))
    ) {
        // Opens at
        let markup: string = '';
        if (useDots) {
            markup += '<span class="h-10 w-10 rounded-1/2 inline-block bg-store-opens-dot mr-5"></span>';
        }
        markup += `<span class="${opensStateClass}">${translateFilter(
            'myPage.MyStore.OpensAt'
        )}</span> ${translateFilter(
            'myPage.MyStore.OpensAt.Time',
            `${hoursToday.openingHour}.${hoursToday.openingMinute.toString().padEnd(2, '0')}`
        )}`;
        return markup;
    } else if (
        hoursToday.closingHour !== null &&
        hoursToday.closingMinut !== null &&
        (now.getHours() < hoursToday.closingHour ||
            (now.getHours() === hoursToday.closingHour && now.getMinutes() < hoursToday.closingMinut))
    ) {
        // Closes at
        let markup: string = '';
        if (useDots) {
            markup += '<span class="h-10 w-10 rounded-1/2 inline-block bg-store-open-dot mr-5"></span>';
        }
        markup += `<span class="${openStateClass}">${translateFilter('myPage.MyStore.Open')}</span> ${translateFilter(
            'myPage.MyStore.Open.Time',
            `${hoursToday.closingHour}.${hoursToday.closingMinut.toString().padEnd(2, '0')}`
        )}`;
        return markup;
    } else {
        // Closed for today
        let markup: string = '';
        if (useDots) {
            markup += '<span class="h-10 w-10 rounded-1/2 inline-block bg-store-closed-dot mr-5"></span>';
        }
        markup += `<span class="${closedStateClass}">${translateFilter(
            'myPage.MyStore.Closed'
        )}</span> ${translateFilter('myPage.MyStore.Closed.Time')}`;
        return markup;
    }
}

export function isTouchDevice(): boolean {
    return window.matchMedia('(pointer: coarse)').matches;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function isNationalCelebrationDay(market: string): boolean {
    // const now = new Date();
    // if (market === 'FR') {
    //     // Le 14 juillet
    //     return now.getMonth() === 6 && now.getDate() === 14;
    // }
    // if (market === 'NO') {
    //     // 17. Mai
    //     return now.getMonth() === 4 && now.getDate() === 17;
    // }
    return false;
}

export function confettiColorsByMarket(market: string): string[] | undefined {
    if (market === 'DK') {
        return ['#D3513C', '#FFFFFF'];
    } else if (market === 'DE') {
        return ['#D3513C', '#FFEB65', '#232323'];
    } else if (market === 'NL') {
        return ['#D3513C', '#FFFFFF', '#305FA4'];
    } else if (market === 'SE') {
        return ['#FFEB65', '#305FA4'];
    } else if (market === 'FR') {
        return ['#D3513C', '#FFFFFF', '#305FA4'];
    } else if (market === 'NO') {
        return ['#D3513C', '#FFFFFF', '#305FA4'];
    } else if (market === 'GB') {
        return ['#001B69', '#FFFFFF', '#C9072A'];
    } else if (market === 'IE') {
        return ['#009A49', '#FF7901', '#FFFFFF'];
    } else if (market === 'FI') {
        return ['#002f6c', '#FFFFFF'];
    } else if (market === 'AT') {
        return ['#FFFFFF', '#D81D04'];
    } else if (market === 'BE') {
        return ['#241E20', '#DF103A', '#FFCF28'];
    } else if (market === 'UK') {
        return ['#001B69', '#FFFFFF', '#C9072A'];
    } else if (market === 'CH') {
        return ['#EC1E25', '#FFFFFF'];
    }
    return undefined;
}

/**
 * Everywhere this function is used we should reconsider if we can avoid using v-html.
 **/
export function formatUserInputForVHtml(str: string) {
    return DOMPurify.sanitize(str, { ALLOWED_TAGS: ['#text'] });
}

export const validateDhlCustomerNo = (value: string) => {
    // DHL customer number is 6 digits or more
    return /^\d{6,}$/.test(value);
};

export const vimeoUrlToId = (url: string) => {
    // input: https://player.vimeo.com/progressive_redirect/playback/858621578/rendition/1080p/file.mp4?loc=external&signature=6d590fd175802d8a1e7431abe3907f7a0eb9f076508a44802f46c47c0f145a97#t=0.001
    // output: 858621578
    const match = url.match(/\/(\d+)/);
    return match ? match[1] : '';
};

export function removeHtmlTags(htmlString: string) {
    return htmlString
        .replace(/<br\s*\/?>/gi, '')
        .replace(/&shy;/g, '')
        .replace(/<[^>]+>/g, '');
}
