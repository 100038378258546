import spaStore from '@/core/spa/store/spa.store';
import serverContext from '@/core/serverContext.service';
import { UrlFacets } from '@/project/facets/urlHelper.service';
import googleTagManagerUtils, {
    doDataLayerEcomPushv4,
    doDataLayerPush
} from '@/project/tracking/googleTagManager.utils';
import mParticleUtils, { CommonCustomAttributes, ProductCustomAttributes } from '@/project/tracking/mParticle.utils';
import basketStore from '@/store/basket.store';
import productsStore from '@/store/products/products.store';
import {
    Basket,
    Color,
    File,
    InventoryState,
    StoreInventoryState,
    v4
} from '@/types/serverContract';
import { BasketLineWithNewProduct } from '../http/api';
import { selectedVariant } from '@/project/product/productHelper.utils';
import { IProvideInjectTrackingData } from '@/types/frontend';

export const ORDER_DETAILS_ACCORDION_NAME = 'orderDetails';

export interface OverlayEvent {
    overlayName: string;
    event: 'overlay' | 'minipage' | 'GAEvent'; // GAEvent is only used on StoreListPage (SEP 2022)
    eventCategory?: string; // only used on StoreListPage (SEP 2022)
    eventLabel?: string; // only used on StoreListPage (SEP 2022)
    eventValue?: string; // only used on StoreListPage (SEP 2022)
    'gtm.uniqueEventId'?: number;
}

export function getPageTypeForTracking(): string {
    return (spaStore.jsonContent?.alias ?? 'unknown') + 'Page';
}

export function formatInventoryStateToTrackingState(state: InventoryState | undefined): string {
    switch (state) {
        case InventoryState.Low:
            return 'Low';
        case InventoryState.VeryLow:
            return 'Very Low';
        case InventoryState.Normal:
            return 'Normal';
        default:
            return 'Sold out';
    }
}
export function formatStoreInventoryStateToTrackingState(state: StoreInventoryState): string {
    switch (state) {
        case StoreInventoryState.FewInStock:
            return 'Low';
        case StoreInventoryState.InStock:
            return 'Normal';
        case StoreInventoryState.NotInStock:
            return 'Sold out';
        default:
            return 'Stock not present';
    }
}
export function formatProductVariantStockToTracking(
    product: v4.Products.ProductSimple,
    variant: v4.Products.VariantSimple | undefined,
    useStock: boolean = true
): string | undefined {
    try {
        if (!variant || !useStock) return 'Stock not present';
        const inventory = productsStore.getInventory(product.id);
        const variantInventory = inventory?.find((i) => i.sku === (variant ? variant.sku : product.variants[0].sku));
        return formatInventoryStateToTrackingState(variantInventory?.state);
    } catch (e) {
        return undefined;
    }
}

export function formatProductVariantSalesColorToTracking(variant?: v4.Products.VariantSimple): string | undefined {
    try {
        return variant?.color?.name ?? undefined;
    } catch (e) {
        return undefined;
    }
}
export function formatProductVariantImageCountToTracking(product: v4.Products.ProductSimple, variant?: v4.Products.VariantSimple): number {
    try {
        const seletedVariantMedia = variant?.media ?? selectedVariant(product.variants, product.mostRelevantVariant).media;

        return seletedVariantMedia.length;
    } catch (e) {
        return 0;
    }
}
export function formatProductShortDescriptionToTracking(product: v4.Products.ProductSimple): number {
    return product?.tracking?.shortDescriptionLength ?? 0;
}
export function formatProductLongDescriptionToTracking(product: v4.Products.ProductSimple): number {
    return product?.tracking?.descriptionLength ?? 0;
}
export function formatProductBrandNameToTracking(product: v4.Products.ProductSimple): string {
    return product?.tracking?.brand || 'Søstrene Grene';
}
export function formatProductCategoryToTracking(product: v4.Products.ProductSimple): string {
    return product.tracking?.primaryCategoryId || '';
}
export function formatProductLabelingToTracking(product: v4.Products.ProductSimple) {
    return product.tracking?.labelling || '';
}
export function getProductCountInBasketOrOrderReceipt(sku: string, products: BasketLineWithNewProduct[] | null = null) {
    try {
        // On transactionComplete, we cannot lookup dimension11 in basketstore, so we get basketLine[].
        let index: number = -1;
        if (products) {
            index = products.findIndex((l) => l.sku === sku);
        } else {
            index = basketStore.basket?.lines.findIndex((l) => l.sku === sku) || 0;
        }
        if (index > -1) {
            // product was found in basket
            return index + 1;
        }
        return (basketStore.basket && basketStore.basket.lines.length + 1) || 1;
    } catch (e) {
        return -1000;
    }
}

export function getTrackingListNameForBlock(trackingData: IProvideInjectTrackingData | undefined, event: any = undefined, trackingListTitle: string = ''): string {
    const modifier = ['add2cart']?.includes(event) ? `_${event}` : '';
    const pageType = basketStore.basketActive ? 'BasketPage' : getPageTypeForTracking();
    const theTrackingData = trackingData ?? { blockName: 'unknown', trackingName: 'unknown', trackingListProvider: 'unknown' };
    const blockName = theTrackingData?.blockName ? `${theTrackingData?.blockName}` : theTrackingData?.trackingName ? `${theTrackingData?.trackingName}` : '';
    // because of historical reasons, we need to change the block name for some blocks...
    const modifiedBlockName = ['categoryDetails', 'roomDetails'].includes(blockName) ? 'SearchProductControl' : blockName;
    const trackingName = theTrackingData?.trackingName !== blockName ? `${theTrackingData?.trackingName ?? ''}` : '';

    // [Content modul name]:[tracking name field]_[Pagetype]_[System]
    return `${modifiedBlockName}${modifier}:${trackingName || theTrackingData.trackingTitle || trackingListTitle}_${pageType}_${theTrackingData.trackingSystem || theTrackingData.trackingListProvider || 'Editor'}`;
}

export const getTrackingListNameForRecommendationType = (recommendationType: v4.Products.ProductRecommendationType) => {
    switch (recommendationType) {
        case v4.Products.ProductRecommendationType.Similar:
            return 'ProductsSimilar';
        case v4.Products.ProductRecommendationType.PurchasedWith:
            return 'ProductsPurchasedWith';
        case v4.Products.ProductRecommendationType.SameSeries:
            return 'ProductsSameSeries';
        case v4.Products.ProductRecommendationType.SameBrand:
            return 'ProductsSameBrand';
        case v4.Products.ProductRecommendationType.RecentlyViewed:
            return 'ProductsRecentlyViewed';
        case v4.Products.ProductRecommendationType.Personal:
            return 'ProductsPersonal';
        case v4.Products.ProductRecommendationType.PurchasedWithMultiple:
            return 'ProductsPurchasedWithMultiple';
        case v4.Products.ProductRecommendationType.ProductsViewedAfterViewingProduct:
            return 'ProductsViewedAfterViewingProduct';
        case v4.Products.ProductRecommendationType.PopularPurchasedProducts:
            return 'PopularPurchasedProducts';
        case v4.Products.ProductRecommendationType.PopularViewedProducts:
            return 'PopularViewedProducts';    
        case v4.Products.ProductRecommendationType.ProductsViewedAfterViewingContent:
            return 'ProductsViewedAfterViewingContent';
        default:
            console.warn('Unknown recommendation type', recommendationType);
            return '';
    }
};

class ProductImpressionController {
    public trackProductClick(
        product: v4.Products.ProductSimple,
        trackingListName: string,
        position: number | undefined = undefined,
        variant: v4.Products.VariantSimple | undefined = undefined
    ) {
        try {
            if (!product) return;
            mParticleUtils.catalog.trackProductClick(product, trackingListName, position, variant);
            googleTagManagerUtils.catalog.trackProductClick(product, trackingListName, position, variant);
        } catch (e) {}
    }

    public trackProductImpression(
        product: v4.Products.ProductSimple,
        trackingListName: string,
        position: number | undefined = undefined,
        variant: v4.Products.VariantSimple | undefined = undefined
    ) {
        try {
            if (!product) return;
            mParticleUtils.catalog.trackProductImpression(product, trackingListName, position, variant);
            googleTagManagerUtils.catalog.trackProductImpressions([product], trackingListName, position, variant);
        } catch (e) {}
    }
}

class BasketController {
    // Track when product is added or removed from cart
    public trackModifyCart(
        product: v4.Products.ProductSimple,
        quantity: number,
        isAddToCart: boolean = true,
        price: undefined | number = undefined,
        list: undefined | string = undefined,
        variantSku: string,
        position?: number
    ) {
        mParticleUtils.catalog.trackModifyCart(product, quantity, isAddToCart, price, list, variantSku, position);
        googleTagManagerUtils.basket.trackModifyCart(product, quantity, isAddToCart, price, list, variantSku);
    }

    public trackModifyCartMany(
        items: {
            product: v4.Products.ProductSimple;
            quantity: number;
            quantityAcum: number;
            price?: undefined | number;
            variantSku: string;
        }[],
        list: undefined | string = undefined,
        isAddToCart: boolean = true,
        position?: number | undefined
    ) {
        mParticleUtils.catalog.trackModifyCartMany(items, list, isAddToCart, position);
        googleTagManagerUtils.basket.trackModifyCartMany(items, list, isAddToCart);
    }

    public trackAddToCart(
        product: v4.Products.ProductSimple,
        quantity: number,
        price: undefined | number = undefined,
        list: undefined | string = undefined,
        variantSku: string
    ) {
        this.trackModifyCart(product, quantity, true, price, list, variantSku);
    }

    public trackRemoveFromCart(
        product: v4.Products.ProductSimple,
        quantity: number,
        price: undefined | number = undefined,
        list: undefined | string = undefined,
        variantSku: string
    ) {
        this.trackModifyCart(product, quantity, false, price, list, variantSku);
    }

    public trackRegretMoveFromBasketToFavorite(product: v4.Products.ProductSimple) {
        mParticleUtils.catalog.trackRegretMoveFromBasketToFavorite(product);
    }
}

class CheckoutController {
    public trackTransactionComplete(
        orderNumber: string | number,
        email: string,
        convertedBasket: Basket,
        marketingPermission?: boolean | null,
        backendmParticleTrackingEnabled?: boolean | null,
    ) {
        const products = convertedBasket.lines as any[] as BasketLineWithNewProduct[];
        const total = convertedBasket.totalInclVat + convertedBasket.shipping.shippingMethod.price;
        const totalVat = convertedBasket.totalVat;

        googleTagManagerUtils.basket.trackTransactionComplete(orderNumber, products, total, totalVat);
        if(!backendmParticleTrackingEnabled){
            mParticleUtils.catalog.trackTransactionComplete(orderNumber, email, convertedBasket, marketingPermission);
        }
    }
}

class FavouritesController {
    public trackAddToWishlist(
        product: v4.Products.ProductSimple | undefined,
        listName: string | undefined,
        variant: v4.Products.VariantSimple | undefined = undefined,
        trackingEventIndex: number,
        contentTitle: string | undefined,
        eventLabel: string | undefined
    ) {
        mParticleUtils.favourites.trackAddToWishlist(
            product,
            listName,
            variant,
            trackingEventIndex,
            contentTitle
        );
        googleTagManagerUtils.favorites.trackAddToWishlist(
            contentTitle,
            eventLabel,
            product,
            listName,
            variant?.sku
        );
    }

    public trackRemoveFromWishlist(
        favouriteType: string, // ie "Product" or DIY type (ie "HowTo")
        product?: v4.Products.ProductSimple, // It is not only products that can be removed from wishlist
        listName?: string,
        eventAction?: string, // Content title for non-products. Product name(_variant) for products (ie "Kurv_Green" or simply "Kurv" when there are no variants)
        eventLabel?: string
    ) {
        googleTagManagerUtils.favorites.trackRemoveFromWishlist(eventAction, eventLabel);
        mParticleUtils.favourites.trackRemoveFromWishlist(product, eventAction || '', listName);
    }

    public trackAddToFavoritesPdp(options: {
        id: v4.Products.ProductDetails['id'];
        name: v4.Products.ProductDetails['name'];
        price: number;
        inventory?: InventoryState;
        variantSku: string;
        variantColorName?: Color['name'];
        brandName?: v4.Products.Brand['name'];
        shortDescriptionLength: v4.Products.ProductDetails['tracking']['shortDescriptionLength'];
        description: v4.Products.ProductDetails['description'];
        labelling: v4.Products.ProductDetails['tracking']['labelling'];
        category: v4.Products.ProductDetails['tracking']['categoryIds'][0];
        numberOfImages?: number;
        contentTitle?: string;
    }) {
        const {
            id,
            name,
            price,
            inventory,
            variantSku,
            variantColorName,
            brandName = 'Søstrene Grene',
            shortDescriptionLength,
            description,
            labelling,
            category,
            numberOfImages = 0,
            contentTitle
        } = options;

        let itemsInBasket = -1000;
        try {
            let index: number = -1;
            index = basketStore.basket?.lines.findIndex((l) => l.sku === variantSku) || 0;
            if (index > -1) {
                itemsInBasket = index + 1;
            } else {
                itemsInBasket = (basketStore.basket && basketStore.basket.lines.length + 1) || 1;
            }
        } catch (e) {
            itemsInBasket = -1000;
        }

        // We need to check if the inventory is 0 as well because the inventory status 'Normal' has the value 0, and JavaScript considers 0 as a falsy value.
        const inventoryHasValue = inventory || inventory === 0;

        mParticleUtils.shared.queueUpForTracking(() => {
            const commonCustomAttributes: CommonCustomAttributes = mParticleUtils.shared.getCustomAttributes();

            // We intensionally do not override CategoryId as category id from a category page is more relevant than the
            // main category of the clicked product
            if (!commonCustomAttributes.CategoryId && category) {
                commonCustomAttributes.CategoryId = category;
            }

            const productCustomAttributes: ProductCustomAttributes = {
                Marking: labelling || '',
                Stockstatus: inventoryHasValue ? formatInventoryStateToTrackingState(inventory) : undefined,
                ProductPictures: numberOfImages,
                ShortDescription: shortDescriptionLength,
                LongDescription: description?.length ?? 0,
                RealColourName: variantColorName || null,
                ItemsInBasket: itemsInBasket
            };

            const mParticleProductArgs = Object.values({
                name,
                id,
                price,
                quantity: undefined,
                variant: variantSku,
                category,
                brand: brandName,
                position: undefined,
                coupon: undefined,
                attributes: productCustomAttributes
            });
            const mParticleProduct = window.mParticle.eCommerce.createProduct(...mParticleProductArgs);

            const transactionAttributes = undefined;
            window.mParticle.eCommerce.setCurrencyCode(serverContext.currencyCode);
            window.mParticle.eCommerce.logProductAction(
                window.mParticle.ProductActionType.AddToWishlist,
                [mParticleProduct],
                { action: '', ...commonCustomAttributes },
                mParticleUtils.shared.getCustomFlags(),
                transactionAttributes
            );
        });

        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'Favorit',
            eventAction: contentTitle || spaStore.metadata.navigationTitle,
            eventLabel: undefined,
            eventValue: undefined
        });

        doDataLayerEcomPushv4(
            'add_to_wishlist',
            {
                items: {
                    item_id: id,
                    item_name: name,
                    item_brand: brandName,
                    item_category: category,
                    item_variant: variantSku,
                    price: price,
                    currency: serverContext.currencyCode,
                    quantity: 1,
                    dimension4: labelling,
                    dimension6: inventoryHasValue ? formatInventoryStateToTrackingState(inventory) : undefined,
                    dimension7: variantColorName ?? undefined,
                    dimension8: numberOfImages,
                    dimension9: shortDescriptionLength,
                    dimension10: description?.length ?? 0,
                    dimension11: itemsInBasket,
                    value: price
                },
                value: price
            },
            undefined,
            undefined
        );
    }

    public trackRemoveFromFavoritesPdp(options: {
        category: v4.Products.ProductDetails['tracking']['categoryIds'][0];
        contentTitle?: string;
    }) {
        const { category, contentTitle } = options;

        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'Favorit_Remove',
            eventAction: contentTitle || spaStore.metadata.navigationTitle,
            eventLabel: undefined,
            eventValue: undefined
        });

        mParticleUtils.shared.queueUpForTracking(() => {
            const commonCustomAttributes: CommonCustomAttributes = mParticleUtils.shared.getCustomAttributes();

            // We intensionally do not override CategoryId as category id from a category page is more relevant than the
            // main category of the clicked product
            if (!commonCustomAttributes.CategoryId && category) {
                commonCustomAttributes.CategoryId = category;
            }

            const customAttributes = {
                ...commonCustomAttributes,
                action: 'remove_from_wishlist',
                Wishlist_listname: undefined,
                Wishlist_product: contentTitle
            };

            window.mParticle.logEvent(
                'remove_from_wishlist',
                window.mParticle.EventType.Other,
                customAttributes,
                mParticleUtils.shared.getCustomFlags()
            );
        });
    }
}

/*
Overlay/minipages
Send when user opens a new tab (like materials on a DIY), folds out an accordion or opens an
overlay that doesn’t track as its own screen

TODO: Get rid of this class, functions have been moved to NavigationController (i don't want to touch too many files right now)
*/
class OverlayController {
    // @deprecated
    public trackAccordionOpen(accordionName: string) {
        trackingUtils.navigation.trackAccordionOpen(accordionName);
    }

    // used with v-tracking directive
    // @deprecated
    public trackOverlayOrMinipage(event: OverlayEvent) {
        trackingUtils.navigation.trackOverlayOrMinipage(event);
    }

    public trackNudgeForStoreSelection(action: 'view' | 'yes' | 'not_now', nudgeCount: number) {
        mParticleUtils.shared.queueUpForTracking(() => {
            const commonCustomAttributes: CommonCustomAttributes = mParticleUtils.shared.getCustomAttributes();

            const customAttributes = {
                ...commonCustomAttributes,
                action,
                formname: `storestock_use${nudgeCount}`
            };

            window.mParticle.logEvent(
                'push_notification',
                window.mParticle.EventType.Other,
                customAttributes,
                mParticleUtils.shared.getCustomFlags()
            );
        });
    }
}

class NavigationController {
    public trackCheckoutFlowNavigation(
        action: 'basket' | 'login' | 'customerinfo' | 'delivery' | 'paymentchoice' | 'topaymentprovider'
    ) {
        mParticleUtils.navigation.trackCheckoutFlowNavigation(action);
    }

    public trackAccordionOpen(accordionName: string) {
        this.trackOverlayOrMinipage({
            event: 'overlay',
            overlayName: accordionName
        });
    }

    public trackOverlayOrMinipage(event: OverlayEvent) {
        mParticleUtils.navigation.trackOverlayOrMinipage(event.overlayName);
        googleTagManagerUtils.overlay.trackOverlayOrMinipage(event);
    }
}

class SearchController {
    public trackQuickLinkClick(term: string, eventLabel: 'Suggest' | 'NoResults' | 'quicklink' | 'latest' | 'term_suggestion' | 'category_suggestion' | 'category_link' | 'continuation' | 'did_you_mean') {
        mParticleUtils.search.trackQuickLinkClick(term, eventLabel);
        googleTagManagerUtils.search.trackQuickLinkClick(term, eventLabel);
    }

    public trackSearchPreview(searchTerm: string, productCount: number, momentCount: number) {
        mParticleUtils.search.trackSearchPreview(searchTerm);
        googleTagManagerUtils.search.trackSearchPreview(searchTerm, productCount, momentCount);
    }

}

class ErrorController {
    public track404(fullPath: string) {
        mParticleUtils.error.track404();
        googleTagManagerUtils.error.track404(fullPath);
    }
}

class StoreController {
    // When user searches for store (by typing)
    public trackSearch(addressOrGeolocation: string, isMyStore: boolean) {
        mParticleUtils.store.trackSearch(addressOrGeolocation, isMyStore);
        googleTagManagerUtils.store.trackSearch(addressOrGeolocation, isMyStore);
    }

    // When user folds out a store in the accordion)
    public trackFoldOut(storeName: string, addressOrGeolocation: string, storeInventoryState?: StoreInventoryState) {
        mParticleUtils.store.trackFoldOut(storeName, addressOrGeolocation, storeInventoryState);
        // no GA event for this yet
    }

    // When user opens specific store
    public trackOpenClick(addressOrGeolocation: string, storeName: string) {
        mParticleUtils.store.trackOpenClick(addressOrGeolocation, storeName);
        googleTagManagerUtils.store.trackOpenClick(addressOrGeolocation, storeName);
    }
}

export interface iTrackPromotionClickArgs {
    componentName: string | undefined;
    trackingName?: string;
    trackingTitle?: string;
    position?: number;
    creativeText?: string;
}

class PromotionController {
    public trackPromotionClick(namedArgs: iTrackPromotionClickArgs) {
        mParticleUtils.promotion.trackPromotionClick(namedArgs);
        googleTagManagerUtils.promotion.trackPromotionClick(namedArgs);
    }
}

export enum SocialId {
    Youtube,
    Facebook,
    Instagram,
    LinkedIn,
    Email,
    phone
}
class LinksController {
    public trackLinkClickExternal(url: string) {
        const socialUrlsAndSearchStrings: Array<[SocialId, string]> = [
            [SocialId.Youtube, 'youtube.com'],
            [SocialId.Facebook, 'facebook.com'],
            [SocialId.Instagram, 'instagram.com'],
            [SocialId.LinkedIn, 'linkedin.com'],
            [SocialId.Youtube, 'youtube.com'],
            [SocialId.Email, 'mailto:'],
            [SocialId.phone, 'tel:']
        ];
        const socialLink = socialUrlsAndSearchStrings.find((tuple) => {
            const searchString = tuple[1];
            return url.indexOf(searchString) > -1;
        });
        if (socialLink) {
            const socialId = socialLink[0];
            mParticleUtils.links.trackSocialLinkClick(socialId);
        }
    }
}

/**
 * For tracking changes in filters/sorting on products / DIY
 *
 * The "facet" term is used very broadly. It encompasses product category, material. Sorting is also in this context considered a facet.
 * Example of a facet string sent to GA:
 * "productType:Rengøring,unitPriceInclVat:3, unitPriceInclVat:65,material:Træ,color:Nature-B6946C"
 *
 * Sorting is for some reason not part of "existing facets" but only sent when changed.
 * Example, GA:
 * eventAction: "Sortering: score,  (selected sorting)
 * eventLabel: "Sortering: recent"   (previous sorting)
 *
 */

class FacetFilterController {
    public trackClearFilter(existingFacets: UrlFacets) {
        mParticleUtils.facetFilter.trackClearFilter(existingFacets);
        googleTagManagerUtils.facetFilter.trackClearFilter(existingFacets);
    }

    public trackFacetAddOrRemove(
        addOrRemove: boolean,
        facetGroup: string,
        facet: string | undefined,
        existingFacets: UrlFacets
    ) {
        if (addOrRemove) {
            mParticleUtils.facetFilter.trackFacetAdd(facetGroup, facet, existingFacets);
            googleTagManagerUtils.facetFilter.trackFacetAdd(facetGroup, facet, existingFacets);
        } else {
            mParticleUtils.facetFilter.trackFacetRemove(facetGroup, facet, existingFacets);
            googleTagManagerUtils.facetFilter.trackFacetRemove(facetGroup, facet, existingFacets);
        }
    }

    public trackFacetAdd(facetGroup: string, facet: string | undefined, existingFacets: UrlFacets) {
        mParticleUtils.facetFilter.trackFacetAdd(facetGroup, facet, existingFacets);
        googleTagManagerUtils.facetFilter.trackFacetAdd(facetGroup, facet, existingFacets);
    }

    public trackFacetRemove(facetGroup: string, facet: string | undefined, existingFacets: UrlFacets) {
        mParticleUtils.facetFilter.trackFacetRemove(facetGroup, facet, existingFacets);
        googleTagManagerUtils.facetFilter.trackFacetRemove(facetGroup, facet, existingFacets);
    }

    public trackFacetUpdate(facetGroup: string, facet: string | undefined, existingFacets: UrlFacets) {
        mParticleUtils.facetFilter.trackFacetUpdate(facetGroup, facet, existingFacets);
        googleTagManagerUtils.facetFilter.trackFacetUpdate(facetGroup, facet, existingFacets);
    }

    public trackSortingChange(newSorting: string, existingSorting: string) {
        mParticleUtils.facetFilter.trackSortingChange(newSorting, existingSorting);
        googleTagManagerUtils.facetFilter.trackSortingChange(newSorting, existingSorting);
    }
}

class DownloadsController {
    public trackDownloadClick(download: File) {
        // download = this.getLastPartOfUrl(download);
        let shortName = '';
        if (download.name) {
            shortName = download.name;
        } else if (download.url) {
            const parts = download.url.split('/');
            shortName = parts.pop() || ''; // The || '' is only to satisfy typecheck...
        }
        mParticleUtils.downloads.trackDownloadClick(shortName);
    }

    public trackDiyDownloadClick(download: File) {
        this.trackDownloadClick(download);
    }

    public trackProductDownloadClick(download: File) {
        this.trackDownloadClick(download);
    }
}

export type SubmitType = 'SubmitNames' | 'Submit' | 'CheckoutSubmit' | 'NewUser';
export enum NewsletterFormAction {
    Submit = 'Submit',
    Success = 'Success',
    Error = 'Error',
    Checkbox = 'checkbox'
}

class NewsletterController {
    // Track when user completes the newsletter signup
    public trackNewsletterSignup() {
        // Consent is controlled by Salesforce, which is why the next line is DISABLED
        // mParticleUtils.user.trackNewsletterSignup();
    }

    // Track newsletter form actions, such as attempt to submit and how validation goes
    public trackNewsletterFormAction(submitAction: NewsletterFormAction, submitType: SubmitType | undefined) {
        mParticleUtils.newsletter.trackNewsletterFormAction(submitAction);
        googleTagManagerUtils.newsletter.trackNewsletterFormAction(submitAction, submitType);
    }

    // Set merelyCheckbox to true if the newsletter signup is just a checkbox (ie part of another form)
    public trackNewsletterSignupAttempt(merelyCheckbox: boolean, submitType: SubmitType | undefined) {
        if (merelyCheckbox) {
            this.trackNewsletterFormAction(NewsletterFormAction.Checkbox, submitType);
        } else {
            this.trackNewsletterFormAction(NewsletterFormAction.Submit, submitType);
        }
    }

    // Call after signup attempt
    public trackFormClientValidationResult(success: boolean, submitType: SubmitType | undefined) {
        if (success) {
            this.trackNewsletterFormAction(NewsletterFormAction.Success, submitType);
        } else {
            this.trackNewsletterFormAction(NewsletterFormAction.Error, submitType);
        }
    }
}

export enum FormAction {
    Submit = 'Submit',
    Success = 'Success',
    Error = 'Error',
    SuggestShow = 'suggest show',
    SuggestClick = 'suggest click'
}

class FormsController {
    public trackFormAction(action: FormAction, formName: string) {
        mParticleUtils.forms.trackFormAction(action, formName);
    }

    // Call when USER clicks submit (before client validation)
    public trackFormSubmitClick(formName: string, eventLabel?: string) {
        mParticleUtils.forms.trackFormAction(FormAction.Submit, formName);
        googleTagManagerUtils.forms.trackFormAction(FormAction.Submit, formName, eventLabel);
    }

    // Call after submit click, if client validation succeeds (even though actual submission might fail)
    public trackFormValidationSuccess(formName: string) {
        mParticleUtils.forms.trackFormAction(FormAction.Success, formName);
        googleTagManagerUtils.forms.trackFormAction(FormAction.Success, formName);
    }

    // Call if client validation fails
    public trackFormValidationFail(formName: string) {
        mParticleUtils.forms.trackFormAction(FormAction.Error, formName);
        googleTagManagerUtils.forms.trackFormAction(FormAction.Error, formName);
    }

    // Call after submit click, if client validation succeeds (even though actual submission might fail)
    public trackFormValidationResult(success: boolean, formName: string) {
        if (success) {
            this.trackFormValidationSuccess(formName);
        } else {
            this.trackFormValidationFail(formName);
        }
    }
}

class VideoController {
    private eventName = 'Video Watched';

    public trackVideoProgress(
        progress: 'Start' | '25' | '50' | '75' | 'complete',
        videoId: string,
        method: 'autoplay' | 'userstart'
    ) {
        mParticleUtils.shared.logEvent(this.eventName, window.mParticle.EventType.Navigation, {
            action: progress,
            label: videoId,
            method,
            ...mParticleUtils.shared.getCustomAttributes()
        });
    }
}

export default class trackingUtils {
    public static catalog = new ProductImpressionController();
    public static basket = new BasketController();
    public static checkout = new CheckoutController();
    public static favourites = new FavouritesController();
    public static overlay = new OverlayController();
    public static navigation = new NavigationController();
    public static search = new SearchController();
    public static error = new ErrorController();
    public static store = new StoreController();
    public static promotion = new PromotionController();
    public static links = new LinksController();
    public static facetFilter = new FacetFilterController();
    public static downloads = new DownloadsController();
    public static newsletter = new NewsletterController();
    public static forms = new FormsController();
    public static video = new VideoController();
}
