<template>
    <article
        class="flex flex-col h-full relative"
        @focusin="showDataOnHover = true, hasFocus = true"
        @focusout="showDataOnHover = false, hasFocus = false"
        @mouseover="showDataOnHover = true"
        @mouseleave="showDataOnHover = false">
        <div v-if="selectedVariant && product"
             class="relative">
            <TileVariants v-if="productHasVariants && showVariants"
                          :product="product"
                          class="absolute mt-8 ml-8 md:mt-12 md:ml-12 z-2"/>
            <div v-if="shouldShowFavoriteToggle"
                 class="absolute z-5 top-0 right-0 overflow-hidden"
                 style="transform: translate3d(0, 0, 0);">
                <div class="bg-sand-10 rounded-2 h-30 mt-10 mr-10 p-6 md:p-10 md:h-40 md:mt-12 md:mr-12 relative">
                    <ProductDetailsFavoriteToggle
                        :details="product"
                        :selected-variant="selectedVariant"
                        :tracking-object="favoriteTrackingObject"
                        type="PLP"
                        frame-styling="text-brown-60"
                        :icon-width="iconWidthAndHeight"
                        :icon-height="iconWidthAndHeight"/>
                </div>
            </div>
            <RouterLinkConditional :to="updateProductUrlIfVariantDiffers()"
                                   :title="props.product?.name"
                                   tabindex="-1"
                                   @click="overrideNavigationTracking ? handleTrackingFromParent() : trackProductClick($event, product)">
                <ProductTileImages v-if="selectedVariant?.media?.length"
                                   :product="product"
                                   :selected-variant="selectedVariant"
                                   :override-image="overrideImage"
                                   :image-alt="imageAlt"
                                   :image-aspect-ratio="imageAspectRatio"
                                   :image-mode="imageMode"
                                   :use-environmental-images="useEnvironmentalImages"
                                   :disable-image-swipe="disableImageSwipe"
                                   @on-swipe="setShouldShowIcons"/>
            </RouterLinkConditional>
            <ProductTileLabel v-if="(selectedVariant.availabilityLabel || selectedVariant.senseOfUrgencyLabel) && isEcom"
                              :selected-variant="selectedVariant"
                              :class="{ 'lg:-translate-y-36': computedShowDataOnHover }"
                              styles="mb-8 md:mb-12 ml-8 md:ml-12 z-1 absolute bottom-0 left-0 transform transition-all duration-300 delay-300 will-change-transform pointer-events-none"/>
            <ProductTileIcons v-if="!overrideImage"
                              class="absolute mr-8 mb-8 md:mr-12 md:mb-12 right-0 bottom-0 z-5 transform transition-all duration-300 delay-300 will-change-transform"
                              :class="{ 'lg:-translate-y-36': computedShowDataOnHover, 'pointer-events-none': !isEnergyIcon }"
                              :selected-variant="selectedVariant" 
                              :is-energy-icon="isEnergyIcon"
                              :show-icons="showCompliance && (showIcons && !computedShowDataOnHover)" 
                              has-label="productTileHasLabel"/>
        </div>
        <div v-if="selectedVariant && product"
             class="flex flex-col h-full">
            <div class="flex flex-1 flex-col relative pt-10 lg:pt-0"
                 :class="themeTextColor('text-brown-80')">
                <div class="relative lg:flex lg:justify-between flex-1 transform transition-translate duration-300 delay-300 will-change-transform"
                     :class="{ 'bg-white-100': !bgTheme, 'lg:-translate-y-36': computedShowDataOnHover }"
                     :style="{ backgroundColor: `#${bgTheme}` }">
                    <div class="flex">
                        <RouterLink :to="product.url ?? ''"
                                    class="flex-1 pr-10 relative z-2 lg:max-w-180 xl:max-w-220"
                                    :title="product.name"
                                    @click="overrideNavigationTracking ? handleTrackingFromParent() : trackProductClick($event, product)">
                            <div class="font-brandon uppercase text-8 h-10 -mt-4 md:mt-8 mb-4 lg:text-10"
                                 :class="themeTextColor('text-brown-70')">
                                {{ product.brand?.name }}
                            </div>

                            <span
                                class="inline-block font-medium leading-14 md:leading-18 text-13 mb-5 break-words md:text-16 lg:block"
                                :class="themeTextColor('text-brown-80')">
                                {{ product.name }}
                                <template v-if="shouldShowVariantName">
                                    {{ selectedVariant.variantName }}
                                </template>
                            </span>
                            <span
                                class="break-word break-words hidden text-12 opacity-0 transform transition-all duration-300 lg:leading-none lg:block lg:-mt-3 lg:mb-8"
                                :class="[computedShowDataOnHover ? 'opacity-100 translate-y-0 delay-500' : 'lg:opacity-0 md:translate-y-1 delay-0', themeTextColor('text-brown-70')]">
                                {{ product.subtitle }}
                            </span>
                            <template v-if="selectedVariant.packageQuantity && selectedVariant.packageQuantity > 1">
                                <SplitTest feature-key="Fsst-package_quantity"
                                           default-variation="disabled"
                                           is-fallback>
                                    <span v-if="showComparisonPrice"
                                          class="hidden lg:inline-block font-gibson font-light text-10 tracking-normal transform transition-all duration-300"
                                          :class="[computedShowDataOnHover ? 'translate-y-0 delay-300' : 'md:-translate-y-1 delay-300 duration-300', themeTextColor('text-brown-65')]">
                                        {{ selectedVariant.pricing?.comparison?.formatted }}
                                    </span>
                                </SplitTest>
                                <SplitTest feature-key="Fsst-package_quantity"
                                           variation="enabled"
                                           default-variation="disabled">
                                    <span class="hidden lg:inline-block font-gibson font-light text-10 tracking-normal transform transition-all duration-300"
                                          :class="[computedShowDataOnHover ? 'translate-y-0 delay-300' : 'md:-translate-y-1 delay-300 duration-300', themeTextColor('text-brown-65')]">
                                        {{ selectedVariant.pricing?.packageDescriptionLabel }}
                                    </span>
                                </SplitTest>
                            </template>
                            <template v-else>
                                <span v-if="showComparisonPrice"
                                      class="hidden lg:inline-block font-gibson font-light text-10 tracking-normal transform transition-all duration-300"
                                      :class="[computedShowDataOnHover ? 'translate-y-0 delay-300' : 'md:-translate-y-1 delay-300 duration-300', themeTextColor('text-brown-65')]">
                                    {{ selectedVariant.pricing?.comparison?.formatted }}
                                </span>
                            </template>
                        </RouterLink>
                        <Breakpoints v-if="isAvailableForPurchase">
                            <template #max-md>
                                <div 
                                    class="transition-opacity w-25 relative">
                                    <template v-if="selectedVariant.packageQuantity && selectedVariant.packageQuantity > 1">
                                        <SplitTest feature-key="Fsst-package_quantity"
                                                   default-variation="disabled"
                                                   :display-box-as-contents="false"
                                                   is-fallback>
                                            <ProductTileOpenVariantSelectorButton
                                                class="transition-opacity duration-300 absolute top-24" 
                                                :product="product"
                                                :selected-variant="selectedVariant" 
                                                :inventory="inventory"
                                                :text-color="textColor"/>
                                        </SplitTest>
                                        <SplitTest feature-key="Fsst-package_quantity"
                                                   variation="enabled"
                                                   default-variation="disabled"
                                                   :display-box-as-contents="false">
                                            <ProductTileOpenVariantSelectorButton
                                                should-use-package-quantity
                                                class="transition-opacity duration-300 absolute top-24" 
                                                :product="product"
                                                :selected-variant="selectedVariant" 
                                                :inventory="inventory" 
                                                :text-color="textColor"/>
                                        </SplitTest>
                                    </template>
                                    <template v-else>
                                        <ProductTileOpenVariantSelectorButton
                                            class="transition-opacity duration-300 absolute top-24" 
                                            :product="product"
                                            :selected-variant="selectedVariant" 
                                            :inventory="inventory" 
                                            :text-color="textColor"/>
                                    </template>
                                </div>
                            </template>
                        </Breakpoints>
                        <Breakpoints v-if="selectedVariant.allowRemindMe">
                            <template #max-md>
                                <div 
                                    class="transition-opacity w-25 relative">
                                    <VariantRemindMeButton :product="product"
                                                           :variant="selectedVariant"
                                                           :inventory="inventory"
                                                           from-tile
                                                           class="transition-opacity duration-300 absolute top-24"/>
                                </div>
                            </template>
                        </Breakpoints>
                    </div>
                    <div class="leading-none text-13 relative flex-1 z-2 mb-7 lg:mt-23 lg:text-14 lg:mb-0 lg:text-right"
                         :class="themeTextColor('text-brown-70')">
                        <span v-if="labelQuantityOverride"
                              class="block font-pale">
                            {{ labelQuantityOverride }}
                        </span>
                        <template v-if="selectedVariant.packageQuantity">
                            <SplitTest feature-key="Fsst-package_quantity"
                                       default-variation="disabled"
                                       is-fallback>
                                <span class="inline-block font-pale md:pr-12 lg:whitespace-nowrap">
                                    {{ selectedVariant.pricing?.unit?.formatted }}
                                </span>
                            </SplitTest>
                            <SplitTest feature-key="Fsst-package_quantity"
                                       variation="enabled"
                                       default-variation="disabled">
                                <span class="inline-block font-pale md:pr-12 lg:whitespace-nowrap">
                                    {{ selectedVariant.pricing?.packageComparison?.formatted }}
                                </span>
                            </SplitTest>
                        </template>
                        <template v-else>
                            <span class="inline-block font-pale md:pr-12 lg:whitespace-nowrap">
                                {{ selectedVariant.pricing?.unit?.formatted }}
                            </span>
                        </template>
                        <template v-if="selectedVariant.packageQuantity && selectedVariant.packageQuantity > 1">
                            <SplitTest feature-key="Fsst-package_quantity"
                                       default-variation="disabled"
                                       is-fallback>
                                <div v-if="showComparisonPrice"
                                     class="lg:hidden leading-12 mb-2 mt-8 font-gibson font-light text-9 tracking-normal transform transition-all duration-300"
                                     :class="themeTextColor('text-brown-65')">
                                    {{ selectedVariant.pricing?.comparison?.formatted }}
                                </div>
                            </SplitTest>
                            <SplitTest feature-key="Fsst-package_quantity"
                                       variation="enabled"
                                       default-variation="disabled">
                                <div class="lg:hidden leading-12 mb-2 mt-8 font-gibson font-light text-9 tracking-normal transform transition-all duration-300"
                                     :class="themeTextColor('text-brown-65')">
                                    {{ selectedVariant.pricing?.packageDescriptionLabel }}
                                </div>
                            </SplitTest>
                        </template>
                        <template v-else>
                            <div v-if="showComparisonPrice"
                                 class="lg:hidden leading-12 mb-2 mt-8 font-gibson font-light text-9 tracking-normal transform transition-all duration-300"
                                 :class="themeTextColor('text-brown-65')">
                                {{ selectedVariant.pricing?.comparison?.formatted }}
                            </div>
                        </template>                        
                        <Breakpoints v-if="isAvailableForPurchase">
                            <template #min-lg>
                                <div 
                                    class="relative z-2 mt-17 pr-12">
                                    <template v-if="selectedVariant.packageQuantity && selectedVariant?.packageQuantity > 1">
                                        <SplitTest feature-key="Fsst-package_quantity"
                                                   default-variation="disabled"
                                                   :display-box-as-contents="false"
                                                   is-fallback
                                                   class="transition-opacity duration-300 relative"
                                                   :class="showDataOnHover ? 'opacity-100 delay-500' : 'opacity-0 delay-100'">
                                            <ProductTileOpenVariantSelectorButton :product="product" 
                                                                                  :selected-variant="selectedVariant" 
                                                                                  :inventory="inventory"
                                                                                  :text-color="textColor"/>
                                        </SplitTest>
                                        <SplitTest feature-key="Fsst-package_quantity"
                                                   variation="enabled"
                                                   default-variation="disabled"
                                                   :display-box-as-contents="false"
                                                   class="transition-opacity duration-300 relative"
                                                   :class="showDataOnHover ? 'opacity-100 delay-500' : 'opacity-0 delay-100'">
                                            <ProductTileOpenVariantSelectorButton
                                                should-use-package-quantity 
                                                :product="product"
                                                :selected-variant="selectedVariant" 
                                                :inventory="inventory"  
                                                :text-color="textColor"/>
                                        </SplitTest>
                                    </template>
                                    <template v-else>
                                        <div class="transition-opacity duration-300 relative"
                                             :class="computedShowDataOnHover ? 'opacity-100 delay-500' : 'opacity-0 delay-100'">
                                            <ProductTileOpenVariantSelectorButton :product="product" 
                                                                                  :selected-variant="selectedVariant" 
                                                                                  :inventory="inventory"
                                                                                  :text-color="textColor"/>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </Breakpoints>
                        <Breakpoints v-if="selectedVariant.allowRemindMe">
                            <template #min-lg>
                                <div 
                                    class="relative z-2 mt-17 pr-12">
                                    <VariantRemindMeButton class="transition-opacity duration-300 relative" 
                                                           :class="computedShowDataOnHover ? 'opacity-100 delay-500' : 'opacity-0 delay-100'"
                                                           :product="product"
                                                           :variant="selectedVariant"
                                                           :inventory="inventory"
                                                           from-tile/>
                                </div>
                            </template>
                        </Breakpoints>
                    </div>
                </div>

                <EcoChargeButton :selected-variant="selectedVariant"
                                 :text-theme-color="themeTextColor('text-brown-60')"/>

                <ProductSheetLink class="relative z-1"
                                  :selected-variant="selectedVariant"
                                  :text-color="textColor"/>

                <ShippingTextLink v-if="isAvailableForPurchase && product.additionalShippingInfo"
                                  class="relative z-1 transition-all duration-300 md:opacity-0 delay-300"
                                  :class="{ 'md:opacity-100': computedShowDataOnHover }"
                                  :shipping-info="product.additionalShippingInfo"
                                  :text-color="textColor"/>
            </div>
        </div>
    </article>
</template>

<script setup lang="ts">
import { computed, ref, provide, defineAsyncComponent, inject } from 'vue';
import { Media, v4 } from '@/types/serverContract';
import TileVariants from '@/project/product/variant/TileVariants.vue';
import ProductTileImages from '@/project/product/ProductTileImages.vue';
import ProductTileLabel from '@/project/product/ProductTileLabel.vue';
import ProductTileIcons from '@/project/product/ProductTileIcons.vue';
import serverContextService from '@/core/serverContext.service';
import translateFilter from '@/core/translation/translate.filter';
import ProductSheetLink from '@/project/product/ProductSheetLink.vue';
import ShippingTextLink from '@/project/product/ShippingTextLink.vue';
import ProductTileOpenVariantSelectorButton from '@/project/product/ProductTileOpenVariantSelectorButton.vue';
import useProductTracking from '@/project/product/useProductTracking';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import EcoChargeButton from '@/project/product/EcoChargeButton.vue';
import VariantRemindMeButton from './variant/VariantRemindMeButton.vue';
import SplitTest from '@/project/growthbook/SplitTest.vue';
import serverContext from '@/core/serverContext.service';
import { IProvideInjectTrackingData, trackingDataSymbol } from '@/types/frontend';

const ProductDetailsFavoriteToggle = defineAsyncComponent(() => import('./details/ProductDetailsFavoriteToggle.vue'));

const props = withDefaults(
    defineProps<{
        product: v4.Products.ProductSimple | undefined,
        overrideImage?: Media | null,
        trackingListType?: string,
        trackingIndex?: number,
        trackingListProvider?: string | undefined,
        trackingName?: string | undefined;
        trackingBlock?: string | undefined;
        enableTracking?: boolean,
        showVariants?: boolean,
        showCompliance?: boolean,
        showFavoriteToggle?: boolean,
        showVariantText?: boolean,
        showComparisonPrice?: boolean,
        showExpiredText?: boolean,
        bgTheme?: string | null,
        textColor?: string,
        imageAspectRatio?: number,
        variant?: string,
        ignoreInventory?: boolean,
        imageAlt?: string | null,
        imageMode?: string | undefined,
        quantityOverride?: number | null,
        useEnvironmentalImages?: boolean
        disableImageSwipe?: boolean,
        disableShowDataOnHover?: boolean,
        overrideNavigationTracking?: boolean
    }>(),
    {
        product: undefined,
        overrideImage: null,
        trackingListType: '',
        trackingIndex: 0,
        trackingListProvider: undefined,
        trackingName: undefined,
        trackingBlock: undefined,
        enableTracking: true,
        showVariants: true,
        showCompliance: true,
        showFavoriteToggle: true,
        showVariantText: true,
        showComparisonPrice: true,
        showExpiredText: false,
        bgTheme: '',
        textColor: '',
        imageAspectRatio: 311 / 400,
        variant: '',
        ignoreInventory: false,
        imageAlt: null,
        imageMode: 'pad',
        quantityOverride: null,
        useEnvironmentalImages: false,
        disableImageSwipe: false,
        disableShowDataOnHover: false,
        overrideNavigationTracking: false
    }
);

const emit = defineEmits(['on-navigation-clicked']);

const showDataOnHover = ref(false);
const hasFocus = ref(false);

const shouldShowFavoriteToggle = computed(() => {
    return props.showFavoriteToggle && !props.overrideImage && serverContext.hasLogin;
});

const setShouldShowIcons = (val)=> {
    showIcons.value = val;
};

const isEnergyIcon = computed(() => {
    return selectedVariant.value?.labellingsFlattened?.some(i => i.type === v4.Products.LabelType.EnergyClass);
});

const isEcom = computed(() => {
    return serverContextService.hasCheckout;
});

const selectedVariant = computed(() => {
    return props.product?.variants?.find(v => v.sku === props.variant) ||
           (props.product?.variants?.find(v => v.sku === props.product?.mostRelevantVariant) ||
           props.product?.variants?.[0]);
});

const showIcons = ref((isEnergyIcon.value || !(props.useEnvironmentalImages && selectedVariant.value?.environmentalImage)));

const isAvailableForPurchase = computed(() => {
    return serverContextService.hasCheckout &&
           (selectedVariant.value?.availability?.status === v4.Products.AvailabilityStatus.Available ||
           selectedVariant.value?.availability?.status === v4.Products.AvailabilityStatus.AvailableOnlySoldOnline);
});

const ignoreInventory = computed(() => {
    return props.ignoreInventory || !serverContextService.hasCheckout;
});

const inventory = computed<v4.Products.Inventory | undefined>(() => {
    return !ignoreInventory.value ? selectedVariant.value?.inventory : undefined;
});

const labelQuantityOverride = computed<string | null>(() => {
    if (props.quantityOverride) {
        return translateFilter('blocks.ShoppingList.QuantityOfPrice', props.quantityOverride.toString());
    }
    return null;
});

function themeTextColor(defaultColor: string = ''): string {
    return props.textColor === 'theme-2' ? 'text-theme-2' : defaultColor;
}

function updateProductUrlIfVariantDiffers() {
    return props.variant 
        ? props.product?.url?.replace(/variant=([^&]+)/, `variant=${props.variant}`) 
        : props.product?.url || '';
}

const iconWidthAndHeight = computed(() => {
    return breakpointsState.isBreakpointActive('min-md') ? '20' : '18';
});

const productHasVariants = computed(() => {
    return (props.product?.variants?.length ?? 0) > 1;
});

const shouldShowVariantName = computed(() => {
    return productHasVariants.value && props.product?.variantInfo?.type === v4.Products.GenericVariantType.Text;
});

const trackingData = inject<IProvideInjectTrackingData | undefined>(trackingDataSymbol, undefined);

if (trackingData?.updateTrackingListProvider) {
    if (props.trackingListType) {
        trackingData.updateTrackingListProvider!(props.trackingListType);
    }
}

const trackingObject = {
    ...trackingData,
    trackingIndex: props.trackingIndex,
    ...(props.trackingBlock && { blockName: props.trackingBlock }),
    ...(props.trackingListType && { trackingName: props.trackingListType }),
    ...(props.trackingListProvider && { trackingListProvider: props.trackingListProvider }),
};

const { trackProductClick, getTrackingListName } = useProductTracking(trackingObject);

provide(trackingDataSymbol, trackingObject);

const handleTrackingFromParent = ()=> {
    emit('on-navigation-clicked', 1, props.product?.name);
};

const favoriteTrackingObject = computed(() => {
    return {
        trackingEventProduct: props.product,
        trackingList: getTrackingListName(),
        trackingEventVariantSku: selectedVariant.value,
        trackingEventIndex: props.trackingIndex,
    };
});

const computedShowDataOnHover = computed(() => {
    return !props.disableShowDataOnHover && showDataOnHover.value;
});

</script>

<style scoped>
.break-word {
    word-break: break-word;
}
</style>
