import { App, DirectiveBinding } from 'vue';
import trackingUtils, { OverlayEvent } from '@/project/tracking/tracking.utils';

interface VTrackAttribute extends OverlayEvent {
    trigger: string; // ie "click". Actually currently only "click" is currently used (SEP 2022)
    whenToTrack?: boolean; // actually not used currently (sep 2022)
    'gtm.uniqueEventId'?: number; // Detected by console-logging the event. We do not set it. Probably set by GTM
}

function hasClickTrigger(binding) {
    return binding.value && Object.prototype.hasOwnProperty.call(binding.value, 'trigger') && binding.value.trigger === 'click';
}
function hasWhenBinding(binding) {
    return binding.value && Object.prototype.hasOwnProperty.call(binding.value, 'whenToTrack');
}
function isWhenHonoured(binding) {
    return hasWhenBinding(binding) && binding.value.whenToTrack === true;
}
function trackOnClick(el, binding) {
    doTrack(binding.value);
}

function doTrack(trackingAttributeObject: VTrackAttribute) {
    const data:any = { ...trackingAttributeObject };
    delete data.trigger;
    delete data.whenToTrack;
    // delete data['gtm.uniqueEventId'];  Should we delete this or is it used?

    const event:OverlayEvent = data as OverlayEvent;

    trackingUtils.overlay.trackOverlayOrMinipage(event);
}

export default function trackingDirective(app: App) {
    app.directive('tracking', {
        beforeMount(el: HTMLElement, binding: DirectiveBinding) {
            if (hasClickTrigger(binding)) {
                el.addEventListener('click', () => trackOnClick(el, binding));
            }
        },
        updated(el, binding) {
            if (hasWhenBinding(binding)) {
                if (isWhenHonoured(binding)) {
                    // actually not run currently, as no v-tracking attributes currently have "whenToTrack" (SEP 2022)
                    doTrack(binding.value);
                }
            }
        },
        unmounted() {
        },
        mounted() {
        }
    });
}