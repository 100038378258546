<template>
    <div ref="imageContainerRef"
         class="relative"
         :class="{ 'image-container': !clientCanHover }"
         @mouseover="clientCanHover ? showSecondaryImageOnHover = true : showSecondaryImageOnHover = false"
         @mouseleave="onLeave"
         @touchstart="setTouchStart"
         @touchend="setTouchEnd">
        <div class="child-container fix-layout-bug">
            <ResponsiveImage class="w-full"
                             :class="{ 'c-on-error': !primaryImage, 'u-image-zoom': !environmentalImage }"
                             :width-on-screen="widthOnScreen"
                             :aspect-ratio="imageAspectRatio"
                             :image-url="primaryImage && primaryImage.url || ''"
                             :media-url-config="primaryImage && primaryImage.mediaUrlConfig"
                             :focal-point="primaryImage && primaryImage.focalPoint || null"
                             :alt="altText"
                             bg-color="#faf7f0"
                             format="webp"
                             :mode="primaryImageMode"/>
        </div>
        <div v-if="secondaryImageEnabled && secondaryImage"
             class="child-container fix-layout-bug">
            <Transition enter-active-class="animated fadeIn u-anim-dur-300"
                        leave-active-class="animated fadeOut u-anim-dur-300"
                        appear>
                <div v-show="shouldShowSecondaryImage"
                     :class="{ 'absolute top-0 bottom-0 right-0 left-0 opacity-0': clientCanHover }"
                     class="hover:opacity-100">
                    <ResponsiveImage class="w-full"
                                     :width-on-screen="widthOnScreen"
                                     :aspect-ratio="imageAspectRatio"
                                     :image-url="secondaryImage.url"
                                     :media-url-config="secondaryImage && secondaryImage.mediaUrlConfig"
                                     :focal-point="secondaryImage.focalPoint"
                                     :alt="altText"
                                     format="webp"
                                     bg-color="#faf7f0"
                                     mode="crop"/>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { Media, v4 } from '@/types/serverContract';
import BreakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';

const props = withDefaults(defineProps<{
    product: v4.Products.ProductSimple,
    overrideImage?: Media | null,
    imageAlt?: string | null,
    imageAspectRatio?: Record<string, number> | number | undefined,
    widthOnScreen?: Record<string, number> | number,
    imageMode?: string,
    useEnvironmentalImages: boolean,
    disableImageSwipe?: boolean,
    selectedVariant?: v4.Products.VariantSimple
}>(), {
    overrideImage: null,
    imageAlt: null,
    imageAspectRatio: undefined,
    widthOnScreen: () => ({ md: 25, xs: 50 }),
    imageMode: 'crop',
    selectedVariant: undefined
}
);

const emit = defineEmits(['on-swipe']);

const touchStartX = ref<number | undefined>(undefined);
const touchEndX = ref<number | undefined>(undefined);

const setTouchStart = (event: TouchEvent) => {
    touchStartX.value = event.changedTouches[0].screenX;
};

const setTouchEnd = (event: TouchEvent) => {
    touchEndX.value = event.changedTouches[0].screenX;
    onTouchHandler();
};

function onTouchHandler(): void {
    if (!props.selectedVariant?.environmentalImage || !touchStartX.value || !touchEndX.value) return;

    if (touchEndX.value < touchStartX.value) {
        emit('on-swipe', false);
    }

    if (touchEndX.value > touchStartX.value) {
        emit('on-swipe', true);
    }
}

const showSecondaryImageOnHover = ref(false);

const primaryImageMode = computed(() => {
    return props.useEnvironmentalImages ? 'crop' : props.imageMode;
});

const packshotImage = computed(() => {
    return props.selectedVariant?.packShotImage;
});

const environmentalImage = computed(() => {
    return props.selectedVariant?.environmentalImage;
});

const primaryImage = computed(() => {
    return props.overrideImage || (props.useEnvironmentalImages && environmentalImage.value ? environmentalImage.value : packshotImage.value);
});

const secondaryImage = computed(() => {
    return (props.overrideImage || props.useEnvironmentalImages) && packshotImage.value ? packshotImage.value : environmentalImage.value;
});

const altText = computed(() => {
    return props.imageAlt || [props.product?.name, props.product?.subtitle].join(' ') || '';
});

// https://css-irl.info/detecting-hover-capable-devices/
// Note: Android incorrectly reports being a hover device
const clientCanHover = !window.matchMedia || window.matchMedia('(any-hover: hover) and (any-pointer: fine)').matches;

const secondaryImageEnabled = computed(() => {
    return secondaryImage.value && !props.disableImageSwipe;
});

const shouldShowSecondaryImage = computed(() => {
    return isMobile.value || showSecondaryImageOnHover.value;
});

const isMobile = computed(() => {
    return ['xs', 'sm', 'ls'].includes(BreakpointsState.activeBreakpoint);
});

const onLeave = (e: MouseEvent) => {
    const element = (e.relatedTarget as HTMLElement);
    if (element && element.classList.contains('c-btn')) {
        return;
    }
    showSecondaryImageOnHover.value = false;
};

</script>

<style scoped lang="less">
.image-container {
    width: 100%;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    white-space: nowrap;
    display: flex;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.child-container {
    scroll-snap-align: center;
    flex: 0 0 auto;
    width: 100%;
}

.fix-layout-bug {
    width: calc(100% + 1px);
}

@screen md {
    .fix-layout-bug {
        @apply w-full;
    }
}
</style>
