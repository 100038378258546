<template>
    <div>
        <section v-for="(block, index) in blocks"
                 :key="index"
                 :class="getSectionClass(block.content)"
                 :style="getStyle(block)"
                 :block-section="index">
            <UmbracoBlocksAnchorLink :block="block"/>
            <BlockRender :block="block"
                         :class="getBlockClass(block)"
                         :tracking-position="index"/>
            <UmbracoBlocksAvailability :block="block"/>
        </section>
    </div>
</template>

<script setup lang="ts">
import { JsonContent } from '@/types/serverContract';
import { styleFunctionsForThemedContent, getClassForContentSpacing } from '@/project/shared/styleFunctionsForThemedContent';
import BlockRender from '@/project/shared/BlockRender.vue';
import UmbracoBlocksAvailability from '@/project/spa/blocks/UmbracoBlocksAvailability.vue';
import UmbracoBlocksAnchorLink from '@/project/spa/blocks/UmbracoBlocksAnchorLink.vue';
import spaStore from '@/core/spa/store/spa.store';

defineProps<{
    blocks?: JsonContent[];
}>();

const getStyle = (block: JsonContent): Record<string, string> => {
    const styling: Record<string, string> = {};
    const blockContent = block.content;
    const ignoreThese = ['contentHubFeatureStoryCombinedHeaderBlock', 'contentHubFeatureStoryFooterBlock', 'contentHubCollectionBlock'];
    if (ignoreThese.indexOf(block.alias) === -1) {
        if (blockContent?.backgroundColor) {
            if (blockContent.backgroundColor.startsWith('#')) {
                styling.backgroundColor = `${blockContent.backgroundColor}`;
            } else {
                styling.backgroundColor = `#${blockContent.backgroundColor}`;
            }
        }
    }
    return styling;
};

const getSectionClass = (blockContent: any): string => {
    return styleFunctionsForThemedContent(blockContent && blockContent.textColor);
};

const decideIfAddClassesOnBlock = (block: JsonContent): boolean => {
    if (!block.alias) {
        return false;
    }
    switch (block.alias) {
        case 'fullWidthPatternBlock':
        case 'contentHubExperienceIntroBlock':
        case 'contentHubExperienceDescriptionBlock':
        case 'contentHubExperienceHintsBlock':
        case 'contentHubExperienceExitBlock':
        case 'contentHubCollectionBlock':
            return false;
    }
    if (!block?.content) {
        return false;
    }

    if (!('spacing' in block.content)) {
        return false;
    }
    return true;
};

const getBlockClass = (block: JsonContent): string => {
    if (!block) return '';

    if (!decideIfAddClassesOnBlock(block)) return '';

    let classes: string[] = [];

    if (block?.content?.spacing) {
        classes = getClassForContentSpacing(block && block.content.spacing, spaStore.isMoment);
    } else {
        classes = [];
    }

    classes.push('o-container');
    if (spaStore.isMoment) {
        classes.push('o-container--eightpx-grid');
    }
    return classes.join(' ');
};
</script>

