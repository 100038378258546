import { App } from 'vue';
import stickyFill from 'stickyfilljs';

const stickyDirective = {
    mounted(el) {
        el.classList.add('sticky-fill');
        stickyFill.add(el);
    },
    unmounted(el) {
        stickyFill.remove(el);
        el.classList.remove('sticky-fill');
    }
};

export default function stickyDirectiveConfig(app: App): void {
    app.directive('sticky-fill', stickyDirective);
}
