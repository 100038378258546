import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import trackingUtils from '../tracking/tracking.utils';
import { router } from '@/core/spa/router';
import Api from '../http/api';
import { PendingState } from '@/types/frontend';
import { v4 } from '../../types/serverContract';
import serverContext from '@/core/serverContext.service';
import loggingService from '@/core/logging.service';
import mParticleUtils from '../tracking/mParticle.utils';
import useOverlay from '@/core/teleport-overlay/useOverlay';

const searchActive = ref(false);
const searchSuggestionsResponse = ref<v4.Search.Prediction.PredictionResponse | null>(null);
const searchResultsResponse = ref<v4.Search.BatchSearchResult | null>(null);
const searchResultItems = ref<(v4.Search.SearchBase[])>([]);
const searchTerm = ref('');
const suggestionsState = ref<PendingState | null>(null);
const resultsState = ref<PendingState | null>(null);
const searchPageUrl = serverContext.searchContext.batchSearchResultUrl;
const shouldShowConfetti = ref(false);
const { overlayInitiator, setOverlayInitiator } = useOverlay();

export default function useSearch() {
    const route = useRoute();

    const openOverlay = ()=> {
        if(!overlayInitiator.value) setOverlayInitiator(); // sets the overlay initiator before its focused to the input inside the overlay
        searchActive.value = true;
    };

    const closeOverlay = ()=> {
        searchActive.value = false;
    };

    const closeSearch = () => {
        const query = Object.assign({}, route.query);
        delete query.term;
        resetSearchResponse();

        // Dont change route if is is the same as current
        if (Object.keys(query).length !== Object.keys(route.query).length) {
            router.push({ query });
        }
    };

    const resetSearchResponse = () => {
        searchSuggestionsResponse.value = null;
        resultsState.value = null;
    };

    const isSearchPage = computed(()=> {
        return route.path === searchPageUrl;
    });
    
    const setQueryParam = (term?: string) => {
        // Dont change route if is is the same as current
        if (term && getQueryParam('term') !== searchTerm.value) {
            searchTerm.value = term;
            router.push({ path: searchPageUrl, query: { term: term } });
        }
    };

    const handleNavigation = (term)=> {
        searchTerm.value = term;
        closeOverlay();
    };

    const getQueryParam = (param: string)=> {
        return param in route.query && typeof route.query[param] === 'string' ? route.query[param] : '';
    };

    const handleHistorySearch = ()=> {
        const storedHistorySearches = sessionStorage.getItem('SEARCHHISTORY');
        const historySearches: string[] = storedHistorySearches ? JSON.parse(storedHistorySearches) : [];

        // Find the index of the string in the array
        const index = historySearches.indexOf(searchTerm.value);
        
        // If the string already exists in the array, remove it
        if (index !== -1) {
            historySearches.splice(index, 1);
        }
        
        if (typeof searchTerm.value === 'string' && searchTerm.value.length > 1 && !historySearches.includes(searchTerm.value.toLowerCase())) {
            historySearches.unshift(searchTerm.value.toLowerCase());
        }

        if (historySearches.length > 10) {
            historySearches.length = 10;
        }

        sessionStorage.setItem('SEARCHHISTORY', JSON.stringify(historySearches));
    };

    const clearResults = ()=> {
        resetSearchResultsResponse();
        searchSuggestionsResponse.value = null;

        resetSuggestionsState();
        searchResultItems.value = [];
    };

    const resetSearchResultsResponse = ()=> {
        searchResultsResponse.value = null;
    };

    const resetSuggestionsState = ()=> {
        suggestionsState.value = null;
    };

    const setSearchSuggestions = async(term: string)=> {
        if (!term) return;
        
        trackingUtils.search.trackSearchPreview(term, 0, 0);
        suggestionsState.value = PendingState.Pending;
        try {
            searchSuggestionsResponse.value = await Api.catalog.getSearchPredictions(term);
        } catch(e) {
            loggingService.exception('Failed to set suggestions', e);
        } finally {
            suggestionsState.value = PendingState.Resolved;
        }
    };

    const setSearchResults = async(term: string, shouldClearResult: boolean, page?: number)=> {
        if (!term) return;

        resultsState.value = PendingState.Pending;
        const isInitialRequest = !searchResultItems.value.length;
        if (shouldClearResult) {
            searchResultItems.value = [];
        }
        try {
            searchResultsResponse.value = await Api.catalog.getSearchResults(term, page, isInitialRequest, searchResultsResponse.value?.nextSkipContents, searchResultsResponse.value?.nextSkipProducts);
            searchResultItems.value.push(...(searchResultsResponse.value.searchResultItems  as unknown as v4.Products.ProductSimple[] | v4.Search.ContentResult[]));
            if (searchResultItems.value?.length) {
                handleHistorySearch();
            }
            shouldShowConfetti.value = !!searchResultsResponse.value?.effect;

            mParticleUtils.search.search(searchTerm.value, searchResultsResponse.value?.hitsProduct, searchResultsResponse.value?.hitsContent);
        } catch(e) {
            loggingService.exception('Failed to set search results', e);
        } finally {
            resultsState.value = PendingState.Resolved;
        }
    };

    return {
        closeSearch,
        searchActive,
        setSearchSuggestions,
        searchSuggestionsResponse,
        searchResultsResponse,
        searchTerm,
        openOverlay,
        closeOverlay,
        setQueryParam,
        setSearchResults,
        isSearchPage,
        handleNavigation,
        resultsState,
        suggestionsState,
        getQueryParam,
        searchResultItems,
        shouldShowConfetti,
        clearResults,
        resetSuggestionsState
    };
}
