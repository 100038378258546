<template>
    <article class="c-teaser-text text-center">
        <div class="c-teaser-text__content mx-auto"
             :style="maxTextWidth">
            <component :is="getHeaderTagType"
                       v-if="content.supplementaryTitle"
                       class="c-teaser-text__content-title"
                       :class="headerClass">
                {{ content.supplementaryTitle }}
            </component>
            <p v-if="content.text"
               class="c-teaser-text__content-text leading-sm text-17 pt-3 md:pt-2 md:text-24"
               :class="{'mb-30 md:mb-35': content.link}"
               v-html="textWithBrTags"/>
        </div>

        <RouterLinkConditional v-if="content.link"
                               :to="content.link.url"
                               :title="content.link.name"
                               :target="content.link && content.link.target"
                               class="c-btn c-btn--sm c-btn--themed-outlined inline-flex"
                               @click="trackPromotionClick">
            <span class="c-btn__text">
                {{ content.link.name }}
            </span>
        </RouterLinkConditional>
    </article>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { getCurrentInstance } from 'vue';
import { TeaserTextBlockModel } from '@/types/serverContract';
import { newLineToBr } from '@/project/shared/string.util';
import trackingUtils from '@/project/tracking/tracking.utils';

const props = defineProps<{
    content: TeaserTextBlockModel;
}>();

const instance = getCurrentInstance();

const maxTextWidth = computed((): Record<string, string> => {
    const styling: Record<string, string> = {};

    if (props.content && props.content.maxTextWidth) {
        styling.width = `${props.content.maxTextWidth}%`;
    }

    return styling;
});

const textWithBrTags = computed((): string => {
    return newLineToBr(props.content.text);
});

const getHeaderTagType = computed((): string => {
    return props.content.headerType || 'h3';
});

const largeHeading = computed((): boolean => {
    return !!props.content.largeTitle;
});

const headerClass = computed((): string => {
    if (largeHeading.value) {
        return 'block font-normal leading-title text-30 md:text-40 md:px-30 uppercase font-brandon mb-20 md:mb-25';
    }
    return 'uppercase text-12 opacity-80 block mb-10';
});

const trackPromotionClick = () => {
    try {
        trackingUtils.promotion.trackPromotionClick({
            componentName: instance?.proxy?.$options.__name || 'BlockTeaserText',
            trackingName: props.content.trackingName,
            trackingTitle: props.content.supplementaryTitle || props.content.text,
            creativeText: props.content.link?.name || props.content.text,
            position: 0
        });
    } catch (e) { }
};
</script>

<style lang="less" scoped>
    @import (reference) "../../../styling/0-Settings/index.less";
    .c-teaser-text__content {
        @apply mx-auto;
    }
    .c-teaser-text__content-title {
        letter-spacing: .7px;
    }
    .c-teaser-text__content-text {
        line-height: 1.53;
    }

    @media @maxTitleWidthOverwriteMediaQuery {
        .c-teaser-text__content {
            @apply max-w-620;
            width: auto !important;
        }
    }

    @screen lg {
        .c-teaser-text__content-title {
            letter-spacing: .3px;
        }
        .c-teaser-text__content-text {
            line-height: 1.5;
            letter-spacing: -.1px;
        }
    }
</style>
