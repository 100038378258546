<template>
    <div v-if="ecoChargeInfo">
        <button
            class="font-gibson text-12 font-light leading-16 text-brown-60 underline hover:no-underline focus:no-underline"
            @click="clickEcoCharge">
            {{ ecoChargePrice }}
        </button>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { v4 } from '@/types/serverContract';
import bus from '@/core/bus';
import { OpenEcoChargeKey } from '@/project/config/constants';

const props = defineProps<{
    selectedVariant: v4.Products.ProductDetailsVariant | v4.Products.VariantSimple;
}>();

const ecoChargePrice = computed(() => props.selectedVariant.pricing.ecoCharge?.formatted);
const ecoChargeInfo = computed(() => props.selectedVariant.ecoCharge);

const clickEcoCharge = () => {
    bus.emit(OpenEcoChargeKey, props.selectedVariant);
};

</script>

<style lang="less" scoped>
:deep(.wysiwyg) {
    p {
        margin-bottom: 3.2rem;
    }
}
</style>
