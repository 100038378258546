// returns a string, that determines whether the color is light or dark, based on the color it receives
// - https://awik.io/determine-color-bright-dark-using-javascript/
export default function lightOrDark(color) {
    if (typeof color !== 'string') return 'dark';
    // Variables for red, green, blue values
    let r, g, b;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // Is rgb
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    } else {
        // Is hex
        const colors = hexStringToDec(color);
        r = colors[0];
        g = colors[1];
        b = colors[2];
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
        return 'light';
    } else {
        return 'dark';
    }
}
// @ts-ignore
function hexStringToDec(h) { h[4] || (h = h.replace(/./g, '$&$&').slice(1)); return ['0x' + h[1] + h[2] | 0, '0x' + h[3] + h[4] | 0, '0x' + h[5] + h[6] | 0]; }
