<template>
    <div name="addToBasketFromTile"
         role="status"
         class="w-22 h-18 z-3 ml-auto md:w-24 md:h-20 sm:mr-9 md:mr-7 lg:mr-0">
        <button
            v-if="!plusButtonDisabled"
            v-prohibit-zoom
            :aria-label="ariaLabelAddToCart"
            class="w-full h-full"
            @click="addToCartFromTile">
            <AddToCartFromTileAnimation :show-animation="showAnimation"
                                        :text-color="textColor"/>
        </button>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import translateFilter from '@/core/translation/translate.filter';
import { v4 } from '@/types/serverContract';
import AddToCartFromTileAnimation from '@/project/product/AddToCartFromTileAnimation.vue';
import useBasket from '@/project/product/useBasket';

const props = defineProps<{
    product: v4.Products.ProductSimple;
    selectedVariant: v4.Products.VariantSimple;
    inventory?: v4.Products.Inventory;
    textColor?: string;
    shouldUsePackageQuantity?: boolean,
    trackingIndex?: number;
}>();

const {
    showAnimation,
    variants,
    addToCartFromTile,
    plusButtonDisabled
} = useBasket(props.product, props.selectedVariant, props.inventory, props.shouldUsePackageQuantity);

const ariaLabelAddToCart = computed(() => {
    return translateFilter(
        variants.value.length <= 1 ? 'product.AddToCart.AriaLabel' : 'product.AddToCart.AriaLabelWithVariant',
        props.product.name || '',
        props.selectedVariant.color?.name || ''
    );
});

</script>
