import httpService from '@/core/http/http.service';
import serverContext from '@/core/serverContext.service';
import { UrlFacets } from '@/project/facets/urlHelper.service';
import { jsDateToServerStringDate } from '@/project/shared/string.util';
import { doDataLayerPush } from '@/project/tracking/googleTagManager.utils';
import mParticleUtils from '@/project/tracking/mParticle.utils';
import basketStore from '@/store/basket.store';
import favoritesStore from '@/store/favorites.store';
import productsStore from '@/store/products/products.store';
import userStore from '@/store/user.store';
import {
    AccountCreateForExternalSourceRequest,
    AccountCreateFromBasketRequest,
    AccountExistsRequest,
    AccountExistsResult,
    AccountGetTokenWithAnonymousBasketRequest,
    AccountInfo,
    AdyenHandlePaymentRequest,
    AdyenSession,
    SessionRequest,
    Basket,
    BasketLineBasics,
    BasketUpdateRequest,
    BasketUpsertRequest,
    ChangePasswordRequest,
    DiySearchRequest,
    DiySearchResult,
    DiySnapshot,
    DiysViewedWithDiyRequest,
    DropPoint,
    DropPointsRequest,
    FavoritesModel,
    HeaderNavigationModel,
    InstagramImage,
    LoginRequest,
    MomentSearchRequest,
    MomentSearchResult,
    MomentSnapshot,
    NewsletterSubscribeRequest,
    OrderDetails,
    OrderSnapshot,
    Phone,
    ProductSearchRequest,
    ProductsPurchasedAndViewedWithProductRequest,
    ProductsPurchasedAndViewedWithProductResponse,
    ProductsPurchasedWithBasketRequest,
    ProductsPurchasedWithDiyRequest,
    RecommendationsModel,
    RequestResetPasswordRequest,
    ResetPasswordRequest,
    ShippingMethod,
    Store,
    StoreSearchRequest,
    StoreSearchResult,
    TopicSearchRequest,
    TopicSearchResult,
    PaymentMethodLogo,
    v4,
    ProductSearchResultNew,
    BasketLine,
    CreateRemindMeRequest,
    NotificationBarViewModel,
} from '@/types/serverContract';
import chunk from 'lodash-es/chunk';
import isArray from 'lodash-es/isArray';

export type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R>
    ? R
    : any;

// TODO - Remove this when we have BasketLine containing v4.Products.ProductSimple's.
export type BasketLineWithNewProduct = Omit<BasketLine, 'product'> & { product: v4.Products.ProductSimple };

class CatalogController {
    public async search(
        term: string | undefined = undefined,
        categoryId: string | undefined = undefined,
        brandId: string | undefined = undefined,
        roomId: string | undefined = undefined,
        facetOptions: UrlFacets | undefined = undefined,
        sortBy: string | undefined = undefined,
        page: number = 1,
        pageSize: number = 8,
        crossCategory: string | undefined = undefined,
    ): Promise<ProductSearchResultNew> {
        return httpService.get<ProductSearchResultNew>('/catalog/searchnew', {
            categoryId,
            brandId,
            roomId,
            sortBy,
            term,
            page,
            pageSize,
            crossCategory,
            ...facetOptions
        } as ProductSearchRequest);
    }

    public async simpleCategorySearch(
        categoryId: string,
        pageSize: number | undefined = undefined
    ): Promise<ProductSearchResultNew> {
        const payload: any = { categoryId };
        if (pageSize) {
            payload.pageSize = pageSize;
        }
        return httpService.get<ProductSearchResultNew>('/catalog/searchnew', payload as ProductSearchRequest);
    }

    public async productList(productIds: string[]): Promise<v4.Products.ProductSimple[]> {
        const productProductIdAsArrays = Array.from(productIds);
        const maxIdLength = 90; // avoid url to be too long.
        const chunkedProductIdArrays = chunk(productProductIdAsArrays, maxIdLength);
        let responses: v4.Products.ProductSimple[] = [];
        await Promise.all(
            chunkedProductIdArrays.map((chunk) => {
                return this.doLoadProductList(chunk).then((products) => {
                    if (products && products.length > 0) {
                        responses = responses.concat(...products);
                    }
                    productsStore.setProducts(chunk, products);
                });
            })
        );
        return responses;
    }

    private async doLoadProductList(productIds: string[]): Promise<v4.Products.ProductSimple[]> {
        return httpService.get<v4.Products.ProductSimple[]>('/catalog/productlistnew', {
            ids: productIds
        });
    }

    public async productListByVariant(variantsSkus: string[], includeExpired: boolean = false): Promise<v4.Products.ProductSimple[]> {
        const productVariantSkusAsArrays = Array.from(variantsSkus);
        const maxIdLength = 90; // avoid url to be too long.
        const chunkedSkusArrays = chunk(productVariantSkusAsArrays, maxIdLength);
        let responses: v4.Products.ProductSimple[] = [];
        await Promise.all(
            chunkedSkusArrays.map((chunk) => {
                return this.doLoadProductListByVariant(chunk, includeExpired).then((products) => {
                    if (products && products.length > 0) {
                        responses = responses.concat(...products);
                        
                        productsStore.setProducts(
                            products.map((p) => p.id),
                            products
                        );
                    }
                });
            })
        );
        return responses;
    }

    private async doLoadProductListByVariant(variantsSkus: string[], includeExpired): Promise<v4.Products.ProductSimple[]> {
        return httpService.get<v4.Products.ProductSimple[]>('/catalog/productlistbyvariantnew', {
            skus: variantsSkus,
            includeExpired: includeExpired
        });
    }

    public async getSearchPredictions(term: string): Promise<v4.Search.Prediction.PredictionResponse> {
        try {
            const payLoad = { term };
            return await httpService.get<Promise<v4.Search.Prediction.PredictionResponse>>('/search/prediction', payLoad);
        } finally {           
        }
    }

    public async getSearchResults(term: string, page: number = 1, isInitialRequest: boolean, skipContents?: number, skipProducts?: number): Promise<v4.Search.BatchSearchResult> {
        const incrementalPageSize = 40;
        try {
            const payLoad: v4.Search.BatchSearchRequest = {
                term,
                pageSize: isInitialRequest ? incrementalPageSize * page : incrementalPageSize,
                searchType: 0,
                skipContents: page && page > 1 ? skipContents : 0,
                skipProducts: page && page > 1 ? skipProducts : 0,
            };
            return await httpService.get<Promise<v4.Search.BatchSearchResult>>('/search/batchedsearch', payLoad);
        } finally {
        }
    }
}

// TODO - Remove this when we have BasketLine containing v4.Products.ProductSimple's.
export async function mergeWithProductsOfNewType(basket: Basket): Promise<Basket> {
    const productIds = basket.lines.map((line) => line.product.id);
    const products = await Api.catalog.productList(productIds);
    for (const line of basket.lines) {
        const product = products.find(p => p?.id === line.product.id);
        if (product) {
            (line as any as BasketLineWithNewProduct).product = product;
        }
    }
    return basket;
}

class BasketController {
    public async get(): Promise<Basket | null> {
        try {
            basketStore.setIsLoadingBasket(true);
            const basket = await httpService.get<Basket>('basket/get');
            return mergeWithProductsOfNewType(basket);
        } catch (e) {
            return null;
        } finally {
            basketStore.setIsLoadingBasket(false);
        }
    }

    public async updateLineItem(lines: BasketLineBasics | BasketLineBasics[]): Promise<Basket> {
        const linesArray = isArray(lines) ? lines : [lines];
        const basket = await httpService.post<Basket>('basket/update', { lines: linesArray } as BasketUpdateRequest);
        return mergeWithProductsOfNewType(basket);
    }

    public updateLineItemCancelable(lines: BasketLineBasics | BasketLineBasics[]) {
        const linesArray = isArray(lines) ? lines : [lines];
        const data: BasketUpdateRequest = { lines: linesArray };
        return httpService.postWithCancel<Basket>('basket/update', data);
    }

    public async upsertBasket(basketInformation: Partial<BasketUpsertRequest>): Promise<Basket> {
        // clone payload workaround to avoid mutating original object
        // this is necessary because backend need company to be `null` instead of empty string when not provided
        // but TextInput's value prop cannot handle null values
        const payload = JSON.parse(JSON.stringify(basketInformation));
        if (payload.invoiceAddress?.company === '') {
            (payload.invoiceAddress.company as unknown as any) = null;
        }
        const basket = await httpService.post<Basket>('basket/upsert', payload);
        return mergeWithProductsOfNewType(basket);
    }

    public async upsertBasketWithRecentShipping(): Promise<Basket> {
        const basket = await httpService.post<Basket>('basket/upsertbasketwithrecentshipping');
        return mergeWithProductsOfNewType(basket);
    }

    public shippingMethods(): Promise<ShippingMethod[]> {
        const req = httpService.get<ShippingMethod[]>('basket/shippingmethods');
        req.then((shippingMethods: ShippingMethod[]) => {
            basketStore.setAvailableShippingMethods(shippingMethods);
        });
        return req;
    }

    public droppoints(
        shippingMethodId: string,
        postalCode: string = '',
        street: string | undefined = undefined,
        city: string | undefined = undefined
    ): Promise<DropPoint[]> {
        return httpService.get<DropPoint[]>('basket/droppoints', {
            shippingMethodId: shippingMethodId,
            postalCode,
            street,
            city
        } as DropPointsRequest);
    }

    public session(payload: SessionRequest) {
        return httpService.post<AdyenSession>('basket/session', payload);
    }

    public handlePaymentResponse(payload: AdyenHandlePaymentRequest) {
        return httpService.post('basket/handleRedirect', payload);
    }

    public getPaymentMethodLogoUrls(countryCode: string): Promise<PaymentMethodLogo[]> {
        return httpService.get('basket/paymentmethodlogos?countrycode=' + countryCode);
    }
}

class SiteInfoController {
    public async getNavigationModel(): Promise<HeaderNavigationModel> {
        return await httpService.get<HeaderNavigationModel>('/siteinfo/headernavigation');
    }

    public async getNotificationBar(alias: string): Promise<NotificationBarViewModel> {
        return await httpService.get<NotificationBarViewModel>('/siteinfo/notificationbar', {
            alias
        });
    }
}

class TopicsController {
    public async search(term: string, page: number = 1, pageSize: number = 8): Promise<TopicSearchResult> {
        return httpService.get<TopicSearchResult>('/content/topics', { term, page, pageSize } as TopicSearchRequest);
    }
}

class DiyController {
    public async search(
        term: string | undefined,
        facetOptions: UrlFacets | undefined = undefined,
        page: number = 1,
        pageSize: number = 11
    ): Promise<DiySearchResult> {
        return httpService.get<DiySearchResult>('/diy/search', {
            ...facetOptions,
            term,
            page,
            pageSize
        } as DiySearchRequest);
    }

    public async diyList(ids: string[]): Promise<DiySnapshot[]> {
        return httpService.get<DiySnapshot[]>('/diy/diylist', {
            ids
        });
    }
}

class MomentController {
    public async momentList(ids: string[]): Promise<MomentSnapshot[]> {
        return httpService.get<MomentSnapshot[]>('/moment/momentlist', {
            ids
        });
    }

    public async search(
        term: string | undefined,
        facetOptions: UrlFacets | undefined = undefined,
        page: number = 1,
        pageSize: number = 11,
        includeDiys: boolean = true
    ): Promise<MomentSearchResult> {
        return httpService.get<MomentSearchResult>('/moment/search', {
            ...facetOptions,
            term,
            page,
            pageSize,
            includeDiys
        } as MomentSearchRequest);
    }
}

export interface IFavoriteInterface {
    identifier: string;
    type: string;
}
interface IAccountUpdateFavoriteStoreOnly {
    favoriteStoreId: string;
}
interface IAccountUpdateEmailOnly {
    email: string;
}
interface IAccountUpdateDateOfBirthOnly {
    dateOfBirth: string | Date;
}
interface IAccountUpdatePhoneOnly {
    phone?: Phone;
}
interface IAccountUpdateAddressOnly {
    firstName: string;
    lastName: string;
    street: string;
    postalCode: string;
    city: string;
    title: string;
}
interface IAccountUpdateAddressEmailPhoneMixed
    extends IAccountUpdateAddressOnly,
        IAccountUpdatePhoneOnly,
        IAccountUpdateEmailOnly {}
type IAccountUpdate =
    | IAccountUpdateFavoriteStoreOnly
    | IAccountUpdateEmailOnly
    | IAccountUpdateDateOfBirthOnly
    | IAccountUpdatePhoneOnly
    | IAccountUpdateAddressOnly
    | IAccountUpdateAddressEmailPhoneMixed;

class UserController {
    public async logout(): Promise<any> {
        mParticleUtils.user.trackUserLogout();
        doDataLayerPush({
            event: 'GAEvent',
            eventCategory: 'Login',
            eventAction: 'Logout',
            eventLabel: undefined,
            eventValue: undefined
        });
        return httpService.post<any>('authenticate/logout').then(() => {
            userStore.setToken(null);
            window.location.href = serverContext.marketUrl;
        });
    }

    public async login(
        email: string,
        password: string,
        persistent: boolean = false,
        newsletterSubscribe?: boolean
    ): Promise<any> {
        const data = {
            email,
            password,
            persistent
        };

        

        const payload = newsletterSubscribe
            ? {
                ...data,
                newsletterSubscribe,
                
            }
            : {
                ...data
            };

        const redirectUrl = window.location.pathname;

        const req = httpService.post(
            `authenticate/login?redirectUrl=${redirectUrl}`,
            payload as LoginRequest,
            '',
            true
        );
        await req;
        return req;
    }

    public async getTokenAndAddOrderToAccount(basketId: string, email: string, password: string): Promise<any> {
        const payload: AccountGetTokenWithAnonymousBasketRequest = {
            basketId,
            email,
            password
        };

        const req = await httpService.post('authenticate/gettokenandaddordertoaccount', payload, '', true);
        await req;
        return req;
    }

    public async facebookLogin(): Promise<any> {
        mParticleUtils.user.trackFormActionUserLogin('submit', 'facebook');
        return httpService.post('authenticate/facebooklogin');
    }

    public async appleLogin(): Promise<any> {
        mParticleUtils.user.trackFormActionUserLogin('submit', 'apple');
        return httpService.post('authenticate/applelogin');
    }

    public async refreshToken(): Promise<any> {
        return httpService.post('authenticate/refreshtoken');
    }

    public async createUser(
        firstName: string,
        lastName: string,
        title: string,
        email: string,
        password: string,
        phone: Phone | null,
        dateOfBirth: Date,
        newsletterSignup: boolean = false
    ): Promise<any> {
        const phoneWithoutSpace: Phone | null = phone
            ? { phonePrefix: phone.phonePrefix, number: phone.number.replace(/ /g, '') }
            : null;
        return httpService
            .post('account/create', {
                firstName,
                lastName,
                title,
                email,
                password,
                phone: phoneWithoutSpace,
                dateOfBirth: jsDateToServerStringDate(dateOfBirth),
                newsletterSignup
            } as IAccountUpdate)
            .then((res) => {
                // Force update of userStore...
                this.accountInfo();
                return res;
            });
    }

    public async createUserFromBasket(basketId: string, dateOfBirth: Date, password: string): Promise<any> {
        const formattedPayload: AccountCreateFromBasketRequest = {
            basketId,
            password,
            dateOfBirth: jsDateToServerStringDate(dateOfBirth) ?? ''
        };

        return httpService.post('account/createfrombasket', formattedPayload).then((res) => {
            // Force update of userStore...
            this.accountInfo();
            return res;
        });
    }

    public async createUserFromExternalAccount(
        userData: {
            firstName: string;
            lastName: string;
            email: string;
            externalToken: string;
            phone: Phone | null;
            dateOfBirth: Date;
            title: string;
            newsletterSignup: boolean;
        },
        source: string
    ): Promise<any> {
        const phoneWithoutSpace: Phone | null = userData.phone
            ? { phonePrefix: userData.phone.phonePrefix, number: userData.phone.number.replace(/ /g, '') }
            : null;
        return httpService.post('account/createforexternalsource ', {
            firstName: userData.firstName,
            lastName: userData.lastName,
            externalToken: userData.externalToken,
            dateOfBirth: jsDateToServerStringDate(userData.dateOfBirth),
            email: userData.email,
            phone: phoneWithoutSpace,
            title: userData.title || '',
            newsletterSignup: userData.newsletterSignup || false,
            source: source
        } as AccountCreateForExternalSourceRequest);
    }

    public async doesAccountExistByEmail(payload: AccountExistsRequest): Promise<AccountExistsResult> {
        return httpService.get<AccountExistsResult>('account/exists', payload);
    }

    public async resetPassword(email: string): Promise<any> {
        return httpService.post('account/requestresetpassword', { email } as RequestResetPasswordRequest);
    }

    public async resetPasswordConfirm(email: string, password: string, token: string): Promise<any> {
        return httpService.post('account/resetpassword', { email, password, token } as ResetPasswordRequest);
    }

    public async updateAccountInfoPassword(payload: ChangePasswordRequest) {
        return httpService.post('account/changepassword', payload as ChangePasswordRequest);
    }

    public async accountInfo(): Promise<AccountInfo> {
        return httpService.get<AccountInfo>('account/info').then((userInformation: AccountInfo) => {
            if (userInformation) {
                userStore.userInformation = userInformation;
            }
            return userInformation;
        });
    }

    public async updateAccountInfo(
        accountInformation: IAccountUpdate = {
            firstName: userStore.userInformation?.invoice.firstName || '',
            lastName: userStore.userInformation?.invoice.lastName || '',
            title: userStore.userInformation?.invoice.title || '',
            phone: userStore.userInformation?.phone || undefined
        }
    ): Promise<AccountInfo> {
        // Convert from Date to String, strip out dateOfBirth, make new object, convert date and insert it
        if ((<IAccountUpdateDateOfBirthOnly>accountInformation).dateOfBirth) {
            // @ts-ignore
            accountInformation.dateOfBirth = jsDateToServerStringDate(accountInformation.dateOfBirth || '');
        }
        // @ts-ignore
        const phone = accountInformation?.phone || undefined;
        if (phone) {
            const phoneWithoutSpace: Phone | undefined = phone.number
                ? {
                    phonePrefix: phone.phonePrefix,
                    number: phone.number.replace(/ /g, '')
                }
                : undefined;
            (accountInformation as IAccountUpdatePhoneOnly).phone = phoneWithoutSpace;
        }
        return httpService
            .post<AccountInfo>('account/update', accountInformation)
            .then((userInformation: AccountInfo) => {
                if (userInformation) {
                    userStore.userInformation = userInformation;
                }
                return userInformation;
            });
    }

    public async deleteAccount(): Promise<any> {
        return httpService.delete('account/delete');
    }

    public async favorites(): Promise<FavoritesModel> {
        return httpService.get<FavoritesModel>('account/favorites').then((response) => {
            favoritesStore.setFavoritesFromApi(response);
            return response;
        });
    }

    private addToFavoritesStorage(identifier: string, type: string): void {
        favoritesStore.addFavorites(identifier, type);
    }

    private removeFromFavoritesStorage(identifier: string, type: string): void {
        favoritesStore.removeFavorites(identifier, type);
    }

    public async addFavorite(identifier: string, type: string): Promise<any> {
        this.addToFavoritesStorage(identifier, type);

        return httpService.post<any>(`account/addFavorite/?identifier=${identifier}&type=${type}`, {
            identifier,
            type
        });
    }

    public async removeFavorite(identifier: string, type: string): Promise<any> {
        this.removeFromFavoritesStorage(identifier, type);
        return httpService.delete<any>('account/removeFavorite', { identifier, type });
    }

    public async shareFavorites(): Promise<string> {
        return httpService.post<string>('account/shareFavorites');
    }

    public async unShareFavorites(): Promise<any> {
        return httpService.post<any>('account/unshareFavorites');
    }

    public async newsletterSignup(
        email: string,
        firstName: string | null = null,
        lastName: string | null = null
    ): Promise<any> {
        const payload: NewsletterSubscribeRequest = { email, firstName, lastName };
        return httpService.post<NewsletterSubscribeRequest>('newsletter/subscribe', payload);
    }

    public async orders(): Promise<OrderSnapshot[]> {
        return httpService.get<OrderSnapshot[]>('account/orderhistory');
    }

    public async orderDetails(order: {
        orderNumber: string;
        state: string;
        unexported: boolean;
    }): Promise<OrderDetails> {
        const payload: { orderNumber: string; state: string; unexported: boolean } = {
            orderNumber: order.orderNumber,
            state: order.state,
            unexported: order.unexported
        };
        return httpService.get<OrderDetails>('account/orderdetails', payload);
    }

    public async createRemindMe(request : CreateRemindMeRequest): Promise<void> {
        return httpService.post<void>('account/createremindme', request);
    }

}

class StoreController {
    public async search(latitude: number, longitude: number): Promise<StoreSearchResult> {
        return httpService.get<StoreSearchResult>('/store/search', {
            latitude,
            longitude
        } as StoreSearchRequest);
    }

    public async getStore(storeId: string): Promise<Store | null> {
        userStore.isLoadingFavoriteStore = true;
        const req = httpService
            .get('/store/get', { storeId })
            .then((store: Store | any) => {
                if (store && userStore.userInformation?.favoriteStoreId === storeId) {
                    userStore.userFavoriteStore = store;
                }
                return store;
            })
            .finally(() => {
                userStore.isLoadingFavoriteStore = false;
            });
        return req;
    }
}

interface IBlockRecommendationSettings extends RecommendationsModel {
    contentId: string;
}
class RecommendationsController {
    public async productsPurchasedWithProduct(
        productsPurchasedWithProductRequest: ProductsPurchasedAndViewedWithProductRequest
    ): Promise<ProductsPurchasedAndViewedWithProductResponse> {
        return httpService.get<ProductsPurchasedAndViewedWithProductResponse>(
            '/recommendation/productspurchasedandviewedwithproduct',
            productsPurchasedWithProductRequest
        );
    }

    public async productsPurchasedWithDIY(
        productsPurchasedWithDIYRequest: ProductsPurchasedWithDiyRequest
    ): Promise<v4.Products.ProductSimple[]> {
        return httpService.get<v4.Products.ProductSimple[]>('/recommendation/productspurchasedwithdiy', productsPurchasedWithDIYRequest);
    }

    public async diysViewedWithDIY(diysViewedWithDiyRequest: DiysViewedWithDiyRequest): Promise<DiySnapshot[]> {
        return httpService.get<DiySnapshot[]>('/recommendation/diysviewedwithdiy', diysViewedWithDiyRequest);
    }

    public async productsPurchasedWithBasket(
        productsPurchasedWithBasketRequest: ProductsPurchasedWithBasketRequest
    ): Promise<v4.Products.ProductSimple[]> {
        return httpService.get<v4.Products.ProductSimple[]>(
            '/recommendation/productspurchasedwithbasket',
            productsPurchasedWithBasketRequest
        );
    }

    public async blockConfiguredRecommendations(configuration: RecommendationsModel): Promise<v4.Products.ProductSimple[]> {
        const payload: IBlockRecommendationSettings = {
            ...configuration,
            contentId: serverContext.pageData.entity.id.toString()
        };
        return httpService.get<v4.Products.ProductSimple[]>('/recommendation/ProductsFromUmbracoBlock', payload);
    }

    public async trackVariantImpression(productId: string, sku: string): Promise<any> {
        return httpService.post('tracking/productimpression', { productId, sku });
    }

    public async recommendations(productId: string): Promise<v4.Products.ProductRecommendations[]> {
        return httpService.get('/recommendation/productdetails', { productId });
    }

    public async recommendationsIdOnly(productId: string, variantId?: string): Promise<v4.Products.ProductRecommendationsIdOnly[]> {
        const params: { productId: string, variantId?: string } = { productId };
        if (variantId) {
            params.variantId = variantId;
        }
        return httpService.get('/recommendation/productdetailsidonly', params);
    }

    public async recommendationsBasketIdOnly(): Promise<v4.Products.ProductRecommendationsIdOnly[]> {
        return httpService.get('/recommendation/basketidonly');
    }

    public async recommendationsFromContentHub(contentId, blockKey): Promise<v4.Products.ProductSimple[]> {
        const payLoad = {
            contentId,
            blockKey
        };
        return httpService.get('recommendation/ProductsFromContentHubUmbracoBlock', payLoad);
    }
}

class VideoController {
    public async getPlaceholderImage(id): Promise<v4.Vimeo.VimeoPictures> {
        return httpService.get<v4.Vimeo.VimeoPictures>(`video/vimeothumbnaildetails?videoid=${id}`);
    }
}

class InstagramController {
    public async getImages(numberOfImages: number): Promise<InstagramImage[]> {
        return httpService.get<InstagramImage[]>('instagram/images', { numberOfImages });
    }
}

export default class Api {
    public static catalog = new CatalogController();
    public static basket = new BasketController();
    public static siteInfo = new SiteInfoController();
    public static topic = new TopicsController();
    public static diy = new DiyController();
    public static moment = new MomentController();
    public static store = new StoreController();
    public static user = new UserController();
    public static recommendations = new RecommendationsController();
    public static instagram = new InstagramController();
    public static video = new VideoController();
}
