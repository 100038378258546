<template>
    <article>
        <transition name="chosenFacets"
                    @before-enter="beforeEnter"
                    @enter="enter"
                    @after-enter="afterEnter"
                    @before-leave="beforeLeave"
                    @leave="onLeave">
            <div v-if="facetsToShow.length"
                 class="u-trans pt-8 pb-15 overflow-hidden">
                <ul class="flex flex-wrap justify-start ls:justify-center items-center">
                    <li v-for="(facet, index) in facetsToShow"
                        :key="facet.displayName + '_' + facet.facetGroupKey"
                        class="mr-10 mb-5 flex">
                        <div class="bg-brown-80 text-white-100 text-12 uppercase font-brandon flex justify-between items-center pl-10 rounded-3">
                            <span class="inline-block mr-15 leading-none font-normal">
                                {{ facet.displayName }}
                            </span>
                            <button class="flex p-10 pr-10"
                                    :aria-label="$translate('generic.Close')"
                                    @click="facet.clear()">
                                <c-icon name="close"
                                        width="10"/>
                            </button>
                        </div>
                        <button v-if="(facetsToShow.length > 1) && (index == facetsToShow.length - 1)"
                                class="c-btn c-btn--sm c-btn--underline c-btn--clean ml-20"
                                :aria-label="$translate('search.Filters.ClearAll')"
                                @click="clearAllFacets">
                            <transition enter-active-class="animated fadeIn u-anim-dur-200"
                                        appear
                                        leave-active-class="animated fadeOut u-anim-dur-200">
                                <span class="c-btn__text">
                                    {{ $translate('search.Filters.ClearAll') }}
                                </span>
                            </transition>
                        </button>
                    </li>
                </ul>
            </div>
        </transition>
    </article>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { PropType } from 'vue';
import urlHelper from './urlHelper.service';
import flatMap from 'lodash-es/flatMap';
import { FacetGroup, FacetType } from '@/types/serverContract';
import trackingUtils from '@/project/tracking/tracking.utils';
import translateFilter from '@/core/translation/translate.filter';
import currencyFilter from '@/project/shared/currency/currency.filter';
import useGrowthBook from '../growthbook/useGrowthbook';

interface FacetInfo {
    facetGroupKey: string;
    displayName: string;
    clear: () => void;
}

const props = defineProps({
    facetGroups: {
        type: Array as PropType<FacetGroup[]>,
        required: true,
    },
});

const urlFacets = ref(urlHelper.getFacets());
const isAvailableOnlineSplitTestActive = ref(false);

useGrowthBook().getActiveVariation('Fsst-available_online_toggle').then(variation => isAvailableOnlineSplitTestActive.value = variation === 'available_online_toggle_ON');

const allFacetValues = computed((): FacetInfo[] => {
    return flatMap(Object.entries(urlFacets.value), ([facetGroupKey, facetKeys]) => {
        if (!facetKeys) return [];
        const realFacetGroup = props.facetGroups.find((fg) => fg.key === facetGroupKey);
        if (!realFacetGroup) return []; // filters ?utm_source query strings away.
        const isRangeFacetGroup = realFacetGroup.type === FacetType.Range;
        const isBooleanFacetGroup = realFacetGroup.type === FacetType.Bool;

        if (isRangeFacetGroup) {
            const val1 = parseInt(facetKeys[0], 10);
            const val2 = parseInt(facetKeys[1], 10);
            const valueLow = Math.min(val1, val2);
            const valueHi = Math.max(val1, val2);
            const displayName = translateFilter(
                'search.Filters.RangeSummary',
                currencyFilter(valueLow, false, false),
                currencyFilter(valueHi, false, false)
            );
            return [
                {
                    displayName,
                    facetGroupKey,
                    clear: () => {
                        trackFacetChange(facetGroupKey, { selected: true, label: displayName });
                        urlHelper.setFacetValues(facetGroupKey, []);
                    },
                },
            ];
        } else if (isBooleanFacetGroup) {
            const displayName = translateFilter(`search.Filters.${facetGroupKey}`);
            return [
                {
                    displayName,
                    facetGroupKey,
                    clear: () => {
                        trackFacetChange(facetGroupKey, { selected: false, label: displayName });
                        urlHelper.setFacetValues(facetGroupKey, []);
                    },
                },
            ];
        } else {
            return facetKeys.map((facetKey) => {
                const facet = realFacetGroup.values.find((realFacet) => realFacet.key === facetKey);
                return {
                    displayName: facet ? facet.label : facetKey,
                    facetGroupKey,
                    clear: () => {
                        trackFacetChange(facetGroupKey, facet);
                        urlHelper.updateFacetValue(facetGroupKey, facetKey, false);
                    },
                } as FacetInfo;
            });
        }
    });
});

const facetsToShow = computed(()=> {
    if (!isAvailableOnlineSplitTestActive.value) {
        return allFacetValues.value;
    }
    return allFacetValues.value.filter((facet) => facet.facetGroupKey !== 'inStock');
});

function trackFacetChange(facetGroupKey: string, facet: any): void {
    trackingUtils.facetFilter.trackFacetRemove(facetGroupKey, facet?.key, urlHelper.getFacets());
}

function clearAllFacets(): void {
    trackingUtils.facetFilter.trackClearFilter(urlHelper.getFacets());
    urlHelper.clearFacets();
}

function beforeEnter(el: Element): void {
    (el as HTMLElement).style.height = '0';
}

function enter(el: Element): void {
    (el as HTMLElement).style.height = el.scrollHeight + 'px';
}

function afterEnter(el: Element): void {
    // Set height to auto when expanded, this will allow resize of element and not hiding content.
    (el as HTMLElement).style.height = 'auto';
}

function beforeLeave(el: Element): void {
    // As height is set to auto, set height to a pixel value
    (el as HTMLElement).style.height = el.scrollHeight + 'px';
}

function onLeave(el: Element): void {
    requestAnimationFrame(() => {
        // Trigger the animation with next tick.
        (el as HTMLElement).style.height = '0';
    });
}
</script>
