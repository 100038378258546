<template>
    <ResponsiveIcon v-if="isImage"
                    :image-url="image.url"
                    :width="image.width"
                    :height="image.height"
                    :alt-text="altText"
                    :bg-color="bgColor"/>
    <LottieWrap v-else-if="isLottie"
                :image="image"
                :alt-text="altText"/>
</template>
<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue';
import { Media } from '@/types/serverContract';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
const LottieWrap = defineAsyncComponent(() => import('@/project/shared/LottieWrap.vue'));

const props = defineProps<{
    image: Media;
    altText?: string;
    bgColor?: string;
}>();

const isImage = computed(() => {
    return props.image && props.image.extension.match('png|gif|jpg|jpeg|svg') !== null;
});

const isLottie = computed(() => {
    return props.image && props.image.extension.match('json') !== null;
});
</script>