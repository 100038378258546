import { App } from 'vue';
import serverContext from '@/core/serverContext.service';
import translateFilter from '@/core/translation/translate.filter';

const culture = serverContext.culture || 'da-DK';
const noOfFractionDigits = (culture === 'is-IS') ? 0 : 2;
const numberFormatter = Intl.NumberFormat(culture, {
    minimumFractionDigits: noOfFractionDigits,
    maximumFractionDigits: noOfFractionDigits
});
const numberFormatterNoDigits = Intl.NumberFormat(culture, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

function currencyFilter(number: number | string | undefined, withCurrencySymbol: boolean = false, useDigits: boolean = true): string {
    if (number === undefined) return '';
    const theNumber = typeof number === 'string' ? parseFloat(number) : number;
    if (isNaN(theNumber)) throw new Error(number + ' is not a valid number');

    let fixedFormattedNumber = useDigits ? numberFormatter.format(theNumber) : numberFormatterNoDigits.format(theNumber);
    if (culture === 'fr-CH' || culture === 'is-IS') {
        // hacking culture in french speaking Schweiz
        // fr-CH becomes 12 345 678.65 instead of 12 345 678,65 (de-CH is correctly 12’345’678.65)
        fixedFormattedNumber = fixedFormattedNumber.replace(',', '.');
    }
    return withCurrencySymbol ? translateFilter('culture.Amount', fixedFormattedNumber) : fixedFormattedNumber;
}


export function initCurrencyFilter(app: App) {
    app.config.globalProperties.$currency = currencyFilter;
}

export default currencyFilter;
