<template>
    <div class="o-container text-center">
        <div :style="setMaxTextWidth"
             class="mx-auto c-text-section-header__wrap">
            <component :is="getHeaderTagType"
                       v-if="content.title"
                       :id="getHeaderAnchorLinkComputed">
                <div v-if="content.supplementaryTitle"
                     class="font-script text-40 md:text-55 mb-5 md:mb-10">
                    {{ content.supplementaryTitle }}
                </div>
                <div v-if="content.title"
                     class="uppercase font-brandon font-normal leading-normal text-22 md:text-40">
                    {{ content.title }}
                </div>
            </component>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { TextSectionHeaderBlockModel } from '@/types/serverContract';
import { getHeaderAnchorLink } from '@/project/shared/string.util';

const props = defineProps<{
    content: TextSectionHeaderBlockModel;
}>();

const setMaxTextWidth = computed((): Record<string, string> => {
    const styling: Record<string, string> = {};

    if (props.content && props.content.maximumWidth) {
        styling.width = `${props.content.maximumWidth}%`;
    }

    return styling;
});

const getHeaderTagType = computed((): string => {
    return props.content.headerType || 'h3';
});

const getHeaderAnchorLinkComputed = computed((): string | undefined => {
    return getHeaderAnchorLink(props.content);
});
</script>

<style lang="less" scoped>
@import (reference) "../../../styling/0-Settings/index.less";
@media @maxTitleWidthOverwriteMediaQuery {
    .c-text-section-header__wrap {
        @apply max-w-620;
        width: auto !important;
    }
}
</style>
