<template>
    <spa-server>
        <div class="mx-auto flex min-h-screen flex-col"
             :class="getWrapperClasses">
            <a href="#main"
               class="sr-only">{{ $translate('generic.AriaLabels.SkipToMainContent') }}</a>
            <CulturePicker v-if="loadDelayed"/>
            <template v-if="cultureIsSet">
                <PageHeader v-if="!isThunnelLayout"
                            :class="getHeaderClasses"/>
                <main
                    id="main"
                    ref="main"
                    class="site-main print:pt-5"
                    role="main"
                    aria-live="polite"
                    :aria-label="labelMainElement"
                    :class="mainClasses">
                    <PageRender/>
                </main>
                <TeleportTarget name="pdp-sticky-cta"
                                slim/>
                <TeleportTarget name="blind"
                                slim/>
                <PageFooter v-show="!isThunnelLayout"/>
            </template>
            <TeleportTarget name="overlay"/>
            <TeleportTarget name="overlay2"/>
            <TeleportTarget name="energy-label-overlay"/>
            <TeleportTarget name="variant-picker-overlay"/>
            <TeleportTarget name="remind-me-overlay"/>
            <TeleportTarget name="ecocharge-overlay"/>
            <TeleportTarget name="shipping-information-overlay"/>
            <TeleportTarget name="specifications-overlay"/>
            <TeleportTarget name="login"/>
            <TeleportTarget name="password-reset-overlay"/>
            <TeleportTarget name="checkout-terms-overlay"/>
            <TeleportTarget
                name="countrySelect"/><!-- the country select can be inside another overlay, therefore: mush have both login and countrySelect targets -->
            <TeleportTarget name="dropPoint"/>

            <BasketOverlay v-if="loadDelayed"/>

            <TeleportOverlay
                ref="loginOverlay"
                v-model:show="loginActiveInternally"
                :disable-body-scroll="true"
                :show-close-button="false"
                :disable-user-close="true"
                :close-on-route-change="true"
                wrapper-class="flex bg-white-300"
                side="fade"
                portal="login">
                <!-- bg-white-300 on wrapper class is to make iOS 14 not showing bottom of login modal bad -->
                <LoginOverlay v-if="loadDelayed"
                              class="relative z-3"
                              @clear-show="closeLogin"/>
            </TeleportOverlay>

            <template v-if="loadDelayed">
                <VariantPickerOverlay/>
                <VariantRemindMeOverlay/>
                <SpecificationsOverlay/>
                <ShippingInformationOverlay/>
                <EcoChargeOverlay/>
                <EnergyLabelOverlay/>
                <ReceiptResetPasswordOverlay/>
            </template>

            <component
                :is="possibleConfetti"
                v-if="shouldConfettiLoad"
                :mode="confettiMode"
                :active="shouldConfettiLoad"
                :colors="birthdayConfettiColors"/>
        </div>
    </spa-server>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeMount, defineAsyncComponent } from 'vue';
import SpaServer from '@/core/spa/SpaServer.vue';
import PageRender from '@/core/spa/PageRender.vue';
import PageFooter from '@/project/layout/page-footer/PageFooter.vue';
import PageHeader from '@/project/layout/page-header/PageHeader.vue';
import spaStore from '@/core/spa/store/spa.store';
import userStore from '@/store/user.store';
import serverContext from '@/core/serverContext.service';
import { confettiColorsByMarket, /* isNationalCelebrationDay, */ confettiModes } from '@/project/shared/string.util';
import { prevRoute } from '@/core/spa/router/index';
import myFontCounter from '@/project/layout/css/myfonts';
import { useRoute, useRouter } from 'vue-router';
import TeleportTarget from './core/teleport-overlay/TeleportTarget.vue';
import TeleportOverlay from './core/teleport-overlay/TeleportOverlay.vue';

const CulturePicker = defineAsyncComponent(() => import('@/project/layout/culture/CulturePicker.vue'));
const BasketOverlay = defineAsyncComponent(() => import('@/project/checkout/BasketOverlay.vue'));
const VariantPickerOverlay = defineAsyncComponent(() => import('@/project/product/variant/VariantPickerOverlay.vue'));
const VariantRemindMeOverlay = defineAsyncComponent(() => import('@/project/product/variant/VariantRemindMeOverlay.vue'));
const SpecificationsOverlay = defineAsyncComponent(() => import('@/project/product/details/SpecificationsOverlay.vue'));
const ShippingInformationOverlay = defineAsyncComponent(() => import('@/project/product/ShippingInformationOverlay.vue'));
const EnergyLabelOverlay = defineAsyncComponent(() => import('@/project/product/EnergyLabelOverlay.vue'));
const EcoChargeOverlay = defineAsyncComponent(() => import('@/project/product/EcoChargeOverlay.vue'));
const ReceiptResetPasswordOverlay = defineAsyncComponent(() => import('@/project/checkout/receipt/ReceiptResetPasswordOverlay.vue'));

function loadConfetti() {
    return () => import('@/project/party/Confetti.vue');
}

defineExpose({
    loadConfetti,
});

const loginActiveInternally = ref<boolean>(false);
const isNationalCelebrationDay = ref<boolean>(false);

const main = ref<HTMLElement | null>(null);
const loginOverlay = ref<any>(null);

const router = useRouter();
const route = useRoute();

// Make app load faster by delaying the loading of overlay components
const loadDelayed = ref<boolean>(false);

onBeforeMount(() => {
    const returnPath = route.query.ReturnUrl?.toString();
    if (returnPath && returnPath !== route.path && serverContext.hasLogin) {
        if (userStore.token) {
            setTimeout(() => {
                router.replace(returnPath);
            }, 200);
        } else {
            userStore.setLoginFormActiveState(true);
        }
    }
});

onMounted(() => {
    setTimeout(myFontCounter, 1000);
    setTimeout(() => loadDelayed.value = true, 100);
});

const loginFormActive = computed(() => userStore.loginFormActive);

watch(loginFormActive, (loginFormActive) => {
    loginActiveInternally.value = loginFormActive;
});

watch(loginActiveInternally, (state) => {
    if (!state) {
        userStore.setLoginFormActiveState(state);
    }
});

const getWrapperClasses = computed<string>(() => {
    if (spaStore.isExperiencePage) {
        return 'bg-brown-80';
    }
    return '';
});

const getHeaderClasses = computed<string>(() => {
    return '';
});

const mainClasses = computed<string>(() => {
    return isThunnelLayout.value ? 'min-h-screen' : '';
});

const isThunnelLayout = computed(() => spaStore.isThunnelLayout);

const cultureIsSet = computed<boolean>(() => !!serverContext.culture);

function closeLogin(): void {
    if (spaStore.isThunnelLayout || !userStore.navigateBackOnCloseLogin) {
        // In checkout, we should not go back to previous page
        userStore.setLoginFormActiveState(false);
        return;
    }

    // Go back one page, unless there is no previous page,
    // then go to frontpage
    if (prevRoute && prevRoute.path !== '/') {
        router.back();
    } else {
        router.push(serverContext.marketUrl);
    }

    loginActiveInternally.value = false;
    
}

const shouldConfettiLoad = computed<boolean>(() => userStore.shouldConfettiLoad || isNationalCelebrationDay.value);

const possibleConfetti = computed(() => {
    return shouldConfettiLoad.value ? loadConfetti() : 'div';
});

const birthdayConfettiColors = computed<string[]>(() => {
    const colors = confettiColorsByMarket(serverContext.market);
    if (!colors) {
        return ['#E9EB91', '#8ED0AD', '#F1B8B9', '#91A5D8'];
    }
    return colors;
});

const confettiMode = computed(() => confettiModes.nationalCelebration);

const labelMainElement = computed<string>(() => {
    return spaStore.mainAriaLabel || spaStore.metadata.navigationTitle;
});

</script>

<style lang="less">
body {
    &.prevent-scroll {
        overflow: hidden;
        height: 100vh;
    }
}

.fadeIn-transition {
    transition: opacity 0.3s linear;
}

.fadeIn-enter,
.fadeIn-leave {
    opacity: 0;
}
</style>
