<template>
    <component :is="tag"
               ref="el">
        <slot :viewed="viewed"/>
    </component>
</template>
<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';

const props = withDefaults(defineProps<{
    offset?: number;
    threshold?: number;
    keepObserving?: boolean;
    tag?: string;
}>(), {
    offset: 0,
    threshold: 1.0,
    tag: 'div'
});

const el = ref<HTMLElement | null>(null);
const viewed = ref(false);
const observer = ref<IntersectionObserver | null>(null);

const observe = () => {
    const options: IntersectionObserverInit = { rootMargin: `${props.offset}px`, threshold: props.threshold };
    observer.value = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                viewed.value = true;
                if (!props.keepObserving) {
                    unObserve();
                }
            }
        });
    }, options);
    observer.value.observe(el.value!);
};

const unObserve = () => {
    if (observer.value) {
        observer.value.unobserve(el.value!);
        observer.value.disconnect();
        observer.value = null;
    }
};

onMounted(() => {
    observe();
});

onBeforeUnmount(() => {
    unObserve();
});
</script> 