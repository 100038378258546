<template>
    <button v-if="selectedVariant.ecoCharge"
            class="block mr-auto"
            type="button"
            @click="clickEcoCharge">
        <div class="font-gibson font-pale text-11 underline hover:no-underline leading-normal"
             :class="textThemeColor">
            <div>
                {{ selectedVariant.pricing.ecoCharge?.formatted }}
            </div>
        </div>
    </button>
</template>

<script setup lang="ts">
import { v4 } from '@/types/serverContract';
import bus from '@/core/bus';
import { OpenEcoChargeKey } from '@/project/config/constants';

const props = defineProps<{
    selectedVariant: v4.Products.VariantSimple;
    textThemeColor: string;
}>();

const clickEcoCharge = () => {
    bus.emit(OpenEcoChargeKey, props.selectedVariant);
};

</script>
