import { App, defineAsyncComponent } from 'vue';
import ProductProvider from './ProductProvider.vue';
import { blockResolver } from '@/core/spa/componentResolver.service';
import ProductTile from './ProductTile.vue';
const FeaturedProductBlock = defineAsyncComponent(() => import('./FeaturedProductBlock.vue'));
const RecommendationProductBlock = defineAsyncComponent(() => import('./RecommendedProductBlock.vue'));
const UspBlock = defineAsyncComponent(() => import('./UspBlock.vue'));
blockResolver.register('featuredProductBlock', FeaturedProductBlock);
blockResolver.register('recommendedProductEnrichmentBlock', RecommendationProductBlock);
blockResolver.register('uspBlock', UspBlock);
blockResolver.register('featuredProductEnrichmentBlock', FeaturedProductBlock);
blockResolver.register('uspEnrichmentBlock', UspBlock);

export default async function config(app: App): Promise<void> {
    app.component('ProductProvider', ProductProvider);
    app.component('ProductTile', ProductTile);
}
