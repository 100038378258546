import { App } from 'vue';
import componentRegistration from './projectComponentRegistrations';
import '../shared/form/zodValidation.plugin';
import vClickOutsidePlugin from './v-click-outside.plugin';
import configIcon from './svg-icons.plugin';
import stickyDirectiveConfig from './sticky.directive';
import { initCurrencyFilter } from '../shared/currency/currency.filter';

//Below are commented out because they are not used in the project
import { initNumberFilter } from '../shared/currency/number.filter';
// import '../shared/currency/unit.filter';
import { initUppercaseFilter } from '../shared/uppercase.filter';
import trackingConfig from '../tracking';
// import './vue-youtube.plugin';
import vTooltipPlugin from './v-tooltip.plugin';
// import './gmap.plugin';
import { initGsap } from './gsap.plugin';
import './chatbot.plugin';
import './watermelon-chatbot';
import './lottie.plugin';
import useGrowthBook from '../growthbook/useGrowthbook';

export default async function config(app: App): Promise<void> {
    useGrowthBook();
    componentRegistration(app);
    vClickOutsidePlugin(app);
    stickyDirectiveConfig(app);
    initCurrencyFilter(app);
    initNumberFilter(app);
    initUppercaseFilter(app);
    vTooltipPlugin(app);
    trackingConfig(app);
    initGsap(app);
    configIcon(app);
}