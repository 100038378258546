<template>
    <div v-if="mostPopularSearchs?.length"
         class="flex flex-col justify-center py-24 px-20 md:pb-32">
        <div class="relative mx-auto w-120 h-120">
            <DotLottieVue
                ref="lottieInstance"
                name="search-suggestion-anim"
                width="120"
                height="120"
                autoplay
                loop
                :data="search"
                :render-config="{devicePixelRatio: 2.5}"
                class="absolute inset-0 w-120 h-120"/>
        </div>
        <div class="my-8">
            <span class="block text-center font-brandon-regular text-22 leading-28 uppercase text-brown-80">
                {{ $translate('search.Overlay.MostPopularSearches') }}
            </span>
        </div>
        <div class="flex flex-wrap items-center justify-center pt-8 -mx-8">
            <RouterLink v-for="item in mostPopularSearchs" 
                        :key="item.url" 
                        :to="item.url" 
                        class="c-btn c-btn--sm c-btn--primary-outlined font-brandon-regular text-12 max-w-350 px-24 mt-16 mx-8"
                        @click="trackClick(item)">
                {{ item.name }}
            </RouterLink>
        </div>
    </div>
</template>

<script setup lang="ts">
import { search } from '@/project/shared/lottie/lottiejson';
import type { DotLottieVueInstance } from '@lottiefiles/dotlottie-vue';
import { onBeforeUnmount, ref } from 'vue';
import useHeaderNavigation from '@/project/layout/page-header/useHeaderNavigation';
import trackingUtils from '@/project/tracking/tracking.utils';
import { NameAndUrl } from '@/types/serverContract';

const lottieInstance = ref<DotLottieVueInstance | null>(null);

const { headerNavigation } = useHeaderNavigation();

const mostPopularSearchs = headerNavigation.value?.searchSuggestions;

const trackClick = (item: NameAndUrl )=> {
    trackingUtils.search.trackQuickLinkClick(item.name, 'quicklink');
};

onBeforeUnmount(() => {
    lottieInstance.value = null;
});

</script>

<style lang="less" scoped>
.shadow {
    box-shadow: 0px 4px 8px 0px rgba(80, 65, 57, 0.16);
}
</style>