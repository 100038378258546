<template>
    <div v-if="options"
         class="border-b border-t border-white-200 pt-20 pb-10">
        <div>
            <div class="uppercase mb-10 text-10 tracking-medium-heading">
                <span>{{ $translate('search.Filters.Sort') }}</span>
            </div>
        </div>
        <slot>
            <ul class="flex flex-wrap -ml-10">
                <li v-for="option in options"
                    :key="option.id"
                    class="w-1/2 pl-10 pb-10"
                    :title="$translate(option.label)">
                    <button type="button"
                            class="w-full pt-10 pb-6 relative font-light text-12 leading-sm uppercase"
                            :aria-label="$translate(option.label)"
                            :title="$translate(option.label)"
                            :class="{'bg-white-550':activeKey === option.id, 'bg-white-300':activeKey !== option.id}"
                            @click="onChange(option.id);">
                        <span>
                            {{ $translate(option.label ) }}
                        </span>
                    </button>
                </li>
            </ul>
        </slot>
    </div>
</template>

<script setup lang="ts">
import urlHelper from '../facets/urlHelper.service';
import trackingUtils from '@/project/tracking/tracking.utils';

const props = defineProps({
    activeKey: {
        type: String,
        required: true
    },
    isSearchResults: {
        type: Boolean,
        required: false,
        default: false
    }
});

const options = [
    {
        id: 'recent',
        label: 'search.Sorting.Recent'
    },
    {
        id: 'popularity',
        label: 'search.Sorting.Popularity'
    },
    {
        id: 'priceDesc',
        label: 'search.Sorting.PriceDescending'
    },
    {
        id: 'priceAsc',
        label: 'search.Sorting.PriceAscending'
    }];

const onChange = (event: string) => {
    urlHelper.setSortBy(event);
    trackingUtils.facetFilter.trackSortingChange(event, props.activeKey);
};
</script>
