import { ref, computed, watch, inject } from 'vue';
import basketStore from '@/store/basket.store';
import { v4 } from '@/types/serverContract';
import bus from '@/core/bus';
import { ToastMessage, OpenVariantPickerKey, TOAST_BUS_KEY } from '../config/constants';
import Api from '../http/api';
import translateFilter from '@/core/translation/translate.filter';
import debounce from 'lodash-es/debounce';
import trackingUtils from '../tracking/tracking.utils';
import useProductTracking from '@/project/product/useProductTracking';
import { IProvideInjectTrackingData, trackingDataSymbol } from '@/types/frontend';

export default function useBasket(product: v4.Products.ProductSimple | v4.Products.ProductDetails, selectedVariant: v4.Products.ProductDetailsVariant | v4.Products.VariantSimple, inventory, shouldUsePackageQuantity?: boolean) {
    const localQuantity = ref<number | null>(null);
    const showAnimation = ref(false);
    const variants = computed(() => product.variants);
    const availabilityStatus = computed(() => selectedVariant.availability.status);
    const inventoryMaxQuantity = computed(() => selectedVariant.inventory?.inStock);
    const canceler = ref<ReturnType<typeof Api.basket.updateLineItemCancelable>['cancel'] | null>(null);

    const trackingData = inject<IProvideInjectTrackingData | undefined>(trackingDataSymbol, undefined);
    const { trackProductImpression, getTrackingListName } = useProductTracking(trackingData);

    const plusButtonDisabled = computed(() => {
        return !(
            inventoryMaxQuantity.value !== null &&
            localQuantity.value !== null &&
            inventoryMaxQuantity.value &&
            (shouldUsePackageQuantity && selectedVariant.packageQuantity ? !isExceedingInventoryMaxQuantity.value : localQuantity.value < inventoryMaxQuantity.value) 
        );
    });

    const skuQuantityInBasket = computed(() => basketStore.getSkuCount(selectedVariant.sku));

    watch(
        skuQuantityInBasket,
        (quantity) => {
            localQuantity.value = quantity;
        },
        { immediate: true }
    );

    const openVariantPicker = () => {
        bus.emit(OpenVariantPickerKey, {
            selectedVariant: selectedVariant,
            inventory: inventory,
            product: product,
            trackingData: trackingData
        });
    };

    const addToCartFromTile = (): void => {
        if (product && product.variants?.length > 1) {
            trackProductImpression('add2cart', product, selectedVariant);
            openVariantPicker();
            return;
        }

        showAnimation.value = true;
        setTimeout(() => {
            showAnimation.value = false;
        }, 800);
        if (selectedVariant && localQuantity.value && localQuantity.value > 0) {
            addQuantity();
        } else if (selectedVariant && localQuantity.value === 0) {
            addFirst();
        }
    };

    const updateLineItem = () => {
        if (localQuantity.value === null) return;

        const { cancel, request } = Api.basket.updateLineItemCancelable({
            quantity: localQuantity.value,
            sku: selectedVariant.sku
        });
        canceler.value = cancel;
        request
            .catch(() => {
                // fail silently
            })
            .finally(() => {
                canceler.value = null;
            });
        const trackingIndex = getValueInclZero(trackingData?.trackingIndex) ?? 0;
        trackingUtils.basket.trackModifyCart(
            (product as v4.Products.ProductSimple),
            localQuantity.value - skuQuantityInBasket.value,
            localQuantity.value - skuQuantityInBasket.value > 0,
            selectedVariant.pricing.unit.amount,
            getTrackingListName(),
            selectedVariant.sku,
            trackingIndex);

        let message: ToastMessage;
        if (localQuantity.value > skuQuantityInBasket.value) {
            message = {
                type: 'ADD_TO_CART',
                message: translateFilter('product.AddToCart.Pdp.AddedToCart', product.name || '')
            };
        } else {
            message = {
                type: 'REMOVE_FROM_CART',
                message: translateFilter('product.AddToCart.Pdp.RemovedFromCart', product.name || '')
            };
        }

        if (shouldShowToastOnAddRemove.value) {
            bus.emit(TOAST_BUS_KEY, message);
        }
    };

    const debouncedUpdateLineItem = debounce(updateLineItem, 500);

    const onLocalQuantityChange = () => {
        const cancel = canceler.value;
        if (cancel) {
            cancel();
            canceler.value = null;
        }

        debouncedUpdateLineItem();
    };

    const addFirst = () => {
        if (isExceedingInventoryMaxQuantity.value) return;
        localQuantity.value = shouldUsePackageQuantity && selectedVariant.packageQuantity || 1;
        onLocalQuantityChange();
    };

    const subtractQuantity = () => {
        handQuantityChange(quantityToSubtract.value);
    };

    const addQuantity = () => {
        handQuantityChange(quantityToAdd.value);
    };

    const handQuantityChange = (newQuantity: number) => {
        if (newQuantity > 0 && isExceedingInventoryMaxQuantity.value) return;
        let q = (localQuantity.value || 0) + newQuantity;
        q = q >= 0 ? q : 0; // if quantity gets below 0
        if (q === localQuantity.value) return; // if quantity is untouched, ignore it
        localQuantity.value = q;
        onLocalQuantityChange();
    };

    const onQuantityUpdate = (quantity: string) => {
        const parsedQuantity = parseInt(quantity, 10);
        const isMinReached = parsedQuantity < 1;
        const isMaxReached =
            parsedQuantity > (inventoryMaxQuantity.value || localQuantity.value || skuQuantityInBasket.value);
        const stringedQuantity = parsedQuantity.toString();
        if (stringedQuantity === quantity) {
            if (isMinReached) {
                localQuantity.value = 0;
            } else if (isMaxReached) {
                localQuantity.value = inventoryMaxQuantity.value || null;
            } else {
                localQuantity.value = parsedQuantity;
            }
        }
        onLocalQuantityChange();
    };

    const shouldShowToastOnAddRemove = computed(() => {
        // We only want to show the first time an item is added or removed from cart
        return ((localQuantity.value === 1 && !skuQuantityInBasket.value) || (localQuantity.value === 0 && skuQuantityInBasket.value));
    });

    const quantityToAdd = computed(()=> {
        return shouldUsePackageQuantity && typeof selectedVariant?.packageQuantity === 'number' ? selectedVariant?.packageQuantity : 1;
    });
    
    const quantityToSubtract = computed(()=> {
        return shouldUsePackageQuantity && typeof selectedVariant?.packageQuantity === 'number' ? (selectedVariant?.packageQuantity * -1) : -1;
    });

    const isExceedingInventoryMaxQuantity = computed(()=> {
        return !!(inventoryMaxQuantity.value && localQuantity.value && (quantityToAdd.value + localQuantity.value) > inventoryMaxQuantity.value);
    });
    
    function getValueInclZero(value): number | null {
        return value >= 0 ? value : null;
    }

    return {
        localQuantity,
        showAnimation,
        variants,
        addToCartFromTile,
        availabilityStatus,
        onQuantityUpdate,
        plusButtonDisabled,
        inventoryMaxQuantity,
        updateLineItem,
        addFirst,
        addQuantity,
        subtractQuantity,
        isExceedingInventoryMaxQuantity
    };
}
