<template>
    <div class="flex px-24 py-16 bg-sand-40">
        <div class="mr-8">
            <cIcon name="info"
                   width="18"
                   height="18"/>
        </div>
        <p class="font-gibson-light text-14 leading-16 text-brown-80"
           v-html="label">
        </p>
    </div>
</template>

<script setup lang="ts">
import translateFilter from '@/core/translation/translate.filter';
import { newLineToBr } from '../shared/string.util';

const label = newLineToBr(translateFilter('store.StockDisclaimer'));
</script>
