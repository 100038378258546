import { reactive } from 'vue';
import makeStateDebugAccessible from '@/store/stateDebug';
import { AccountInfo, Address, Phone, Store } from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';
import { NoLoginAtSite, UserLoadedEventKey } from '@/project/config/constants';
import Api from '@/project/http/api';
import {
    millisecondsUntilNextBirthday,
    serverStringDateToJsDate,
    validateStringHasChars,
    validateStringHasNumber
} from '@/project/shared/string.util';
import SessionStorageService from '@/core/storage.service';
import basketStore from '@/store/basket.store';
import bus from '@/core/bus';
import { trackUserKnowledgeEvent } from '@/project/tracking/googleTagManager.utils';
const maxSetTimeoutDelay = 24 * 24 * 60 * 60 * 1000; // 24 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds or just something lower than Max_value for delay (about 24.8 days) in setTimeout https://stackoverflow.com/a/3468650/2664445
export function isUserAddressFilledForUse(
    user: null | Partial<{
        invoice?: Address;
        email?: string;
        phone?: Phone;
    }>
): boolean {
    if (
        user &&
        user.invoice &&
        user.invoice.street &&
        user.invoice.postalCode &&
        user.invoice.city &&
        user.invoice.firstName &&
        user.invoice.lastName &&
        user.email &&
        user.phone &&
        user.phone.number
    ) {
        const validationRule = ['NO', 'IE', 'GB'].includes(user?.invoice?.countryCode)
            ? validateStringHasChars(user.invoice.street)
            : validateStringHasChars(user.invoice.street) && validateStringHasNumber(user.invoice.street);
        return validationRule;
    }
    return false;
}

interface ITokenData {
    email: string;
}
interface IUserState {
    userInformation: null | AccountInfo;
    isLoadingUser: boolean;
    hasLoadedUser: boolean;
    isLoadingFavoriteStore: boolean;
    loginFormActive: boolean;
    token: string | null;
    tokenData: ITokenData | null;
    userFavoriteStore: Store | null;
    showBirthdayConfetti: boolean;
    userHasDeclinedCheckoutLogin: boolean;
    navigateBackOnCloseLogin: boolean;
}
export enum ISessionStorageEventActionOn {
    'PageLoadedAnonymous',
    'PageLoadedUserAuthenticated'
}
export enum ISessionStorageEventActionKey {
    'ShowBasketOverlay'
}
export interface ISessionStorageEventAction {
    on: ISessionStorageEventActionOn;
    key: ISessionStorageEventActionKey;
    timestamp?: number;
    ttl?: 180000 | 300000; // 3 min | 5 min
    executed?: boolean;
}
const sessionStoragePostLoginActionKey = 'postLoginActionKey';

bus.once(UserLoadedEventKey, (accountInfo: AccountInfo) => trackUserKnowledgeEvent('UserLoadedEventKey', accountInfo));
class UserStore {
    private birthDayTimeout: null | number = null;
    private mParticleUserCallback: null | any = null;
    private sessionStorageService = SessionStorageService.create();
    private state: IUserState = reactive({
        userInformation: null,
        isLoadingUser: false,
        hasLoadedUser: false,
        isLoadingFavoriteStore: false,
        loginFormActive: false,
        token: null,
        tokenData: null,
        userFavoriteStore: null,
        showBirthdayConfetti: false,
        userHasDeclinedCheckoutLogin: false,
        navigateBackOnCloseLogin: true
    });

    constructor() {
        this.applyTokenFromCookie();
        if (!this.isLoggedIn) {
            this.executeSessionStorageActions(ISessionStorageEventActionOn.PageLoadedAnonymous);
        } else {
            this.executeSessionStorageActions(ISessionStorageEventActionOn.PageLoadedUserAuthenticated);
        }
        makeStateDebugAccessible('userState', this.state);
        // trigger load of user after page load, if not already triggered from some other component
        setTimeout(() => {
            if (!this.state.userInformation) {
                this.initUser();
            }
        }, 5000);
    }

    private async initUser() {
        if (!serverContext.hasLogin || this.isLoadingUser || !this.token) return;
        this.isLoadingUser = true;

        await Api.user.accountInfo().then((accountInfo) => {
            this.isLoadingUser = false; // Dont set loading state to false if request fails, then it will not try to reload basket infinite times.
            this.state.hasLoadedUser = true;

            bus.emit(UserLoadedEventKey, accountInfo, {
                emailKey: this.state.userInformation?.encryptedEmail,
                phoneKey: this.state.userInformation?.encryptedPhone
            });
        });
    }

    private async initUserFavoriteStore() {
        if (
            !serverContext.hasLogin ||
            this.isLoadingFavoriteStore ||
            !this.token ||
            !(this.userInformation && this.userInformation.favoriteStoreId)
        ) {
            return;
        }
        this.updateFavoriteStore(this.userInformation.favoriteStoreId);
    }

    public async updateFavoriteStore(storeId: string) {
        if (this.isLoadingFavoriteStore || this.userFavoriteStore?.id === storeId) return;
        this.isLoadingFavoriteStore = true;
        return Api.store
            .getStore(storeId)
            .then((store) => {
                this.userFavoriteStore = store;
            })
            .finally(() => {
                this.isLoadingFavoriteStore = false;
            });
    }

    public get isLoadingUser(): boolean {
        return this.state.isLoadingUser;
    }

    public get hasLoadedUser(): boolean {
        return this.state.hasLoadedUser;
    }

    public set isLoadingUser(loadingState: boolean) {
        this.state.isLoadingUser = loadingState;
    }

    public get isLoadingFavoriteStore(): boolean {
        return this.state.isLoadingFavoriteStore;
    }

    public set isLoadingFavoriteStore(loadingState: boolean) {
        this.state.isLoadingFavoriteStore = loadingState;
    }

    private applyTokenFromCookie() {
        if (!serverContext.hasLogin) return;
        const cookies = document.cookie.split('; ');
        const auth = cookies.find((cake) => cake.indexOf('auth=') === 0);
        if (auth) {
            const token = auth.split('=');
            if (token.length > 1) {
                this.setToken(token[1]);
            }
        }
    }

    public setLoginFormActiveState(state: boolean, navigateBackOnCloseLogin: boolean = true) {
        if (!serverContext.hasLogin) {
            throw new NoLoginAtSite();
        }
        this.state.loginFormActive = state;
        this.state.navigateBackOnCloseLogin = navigateBackOnCloseLogin;
    }

    public get loginFormActive(): boolean {
        return this.state.loginFormActive;
    }

    public get navigateBackOnCloseLogin() {
        return this.state.navigateBackOnCloseLogin;
    }

    public setUserHasDeclinedCheckoutLogin() {
        this.state.userHasDeclinedCheckoutLogin = true;
    }

    public get userHasDeclinedCheckoutLogin(): boolean {
        return this.state.userHasDeclinedCheckoutLogin;
    }

    public get token(): string | null {
        return this.state.token;
    }

    public setToken(newToken: null | string) {
        this.state.token = newToken;
        this.state.tokenData = this.parseJwt(newToken);
    }

    public get email(): string | null {
        return this.state.tokenData?.email || null;
    }

    private parseJwt(token: string | null) {
        if (!token) return null;
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    get userInformation() {
        if (!this.state.userInformation && !this.isLoadingUser && this.token) {
            this.initUser();
        }
        return this.state.userInformation;
    }

    set userInformation(userInformation) {
        const doUpdateFavoriteStore =
            userInformation && userInformation.favoriteStoreId !== this.state.userInformation?.favoriteStoreId;
        this.state.userInformation = userInformation;
        if (doUpdateFavoriteStore) {
            this.initUserFavoriteStore();
        }
        this.initBirthdayCelebration();
    }

    initBirthdayCelebration() {
        if (this.state.userInformation?.dateOfBirth) {
            const birthday = serverStringDateToJsDate(this.state.userInformation.dateOfBirth);
            if (birthday) {
                const midnight = new Date();
                midnight.setHours(24, 0, 0);
                const milliseconds = millisecondsUntilNextBirthday(birthday);
                // if user has birthday today, show confetti now, else set timeout to milliseconds
                // Dont try to run setTimeout if milliseconds is larger than delay MAX_VALUE https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#Maximum_delay_value
                if (milliseconds < maxSetTimeoutDelay) {
                    this.birthDayTimeout = setTimeout(
                        () => {
                            this.state.showBirthdayConfetti = true;
                        },
                        birthday.getTime() < midnight.getTime() ? 0 : milliseconds
                    );
                }
            }
        }
    }

    get shouldConfettiLoad() {
        return this.state.showBirthdayConfetti;
    }

    get userFavoriteStore() {
        return this.state.userFavoriteStore;
    }

    set userFavoriteStore(userFavoriteStore: Store | null) {
        this.state.userFavoriteStore = userFavoriteStore;
    }

    get isLoggedIn(): boolean {
        return !!this.token;
    }

    setPostPageLoadAction(action: ISessionStorageEventAction) {
        if (action.executed === true) return;
        if (!action.ttl) {
            action.ttl = 300000;
        }
        action.timestamp = new Date().getTime();
        const actions =
            (this.sessionStorageService.getItemAs(sessionStoragePostLoginActionKey) as ISessionStorageEventAction[]) ||
            [];
        let savedAction = actions.find((a) => a.key === action.key && a.on === action.on);
        // Overwrite existing actions (expect only on of a kind)
        if (savedAction) {
            savedAction = action;
        } else {
            actions.push(action);
        }
        this.sessionStorageService.setItemAs(sessionStoragePostLoginActionKey, actions);
    }

    executeSessionStorageActions(condition: ISessionStorageEventActionOn) {
        let actions =
            (this.sessionStorageService.getItemAs(sessionStoragePostLoginActionKey) as ISessionStorageEventAction[]) ||
            [];
        const nowTimestamp = new Date().getTime();
        // Filter away actions that are expired.
        actions = actions.filter((action) => {
            const actionTimestamp = action.timestamp ? new Date(action.timestamp) : 0;
            const notExpiredAction = nowTimestamp < (Number(actionTimestamp) || 0) + (action.ttl || 0);
            return notExpiredAction;
        });
        actions.map((action) => {
            // Check that condition on execution is equal action.
            if (action.on === condition) {
                this.executeSessionStorageAction(action);
            }
        });
        // Filter away actions that has been executed.
        actions = actions.filter((action) => action.executed !== true);
        // Update storage.
        this.sessionStorageService.setItemAs(sessionStoragePostLoginActionKey, actions);
    }

    executeSessionStorageAction(action: ISessionStorageEventAction) {
        switch (action.key) {
            case ISessionStorageEventActionKey.ShowBasketOverlay:
            // Use timeout to allow app to wake up
                setTimeout(() => {
                    basketStore.setBasketActiveState(true);
                }, 500);
                action.executed = true;
                break;
        }
    }

    set mParticleUser(result) {
        this.mParticleUserCallback = result;
    }

    get mParticleUser() {
        return this.mParticleUserCallback;
    }
}
export default new UserStore();
