import { App } from 'vue';

type CurrencyFilterOptions = {
    first?: boolean;
};

function uppercaseFilter(input: string, options?: CurrencyFilterOptions): string {
    if (!input) {
        return '';
    }

    if (options && options.first) {
        return input.charAt(0).toUpperCase() + input.slice(1);
    }

    return input.toUpperCase();
}

export function initUppercaseFilter(app: App) {
    app.config.globalProperties.$uppercase = uppercaseFilter;
}

export default uppercaseFilter;
