<template>
    <component :is="renderComponent(block)"
               v-if="block"
               :content="block.content"
               :alias="block.alias"
               :tracking-position="trackingPosition"/>
</template>
<script setup lang="ts">
import { blockResolver } from '@/core/spa/componentResolver.service';
import { IProvideInjectTrackingData, trackingDataSymbol } from '@/types/frontend';
import { JsonContent } from '@/types/serverContract';

const props = defineProps<{
    block: JsonContent;
    trackingPosition?: number;
}>();

import { provide, reactive } from 'vue';

const trackingData = reactive<IProvideInjectTrackingData>({
    blockName: props.block.alias || undefined,
    blockPosition: props.trackingPosition,
    trackingName: props.block.content?.trackingName,
    trackingTitle: props.block.content?.title,
    updateTrackingListProvider: (value: string) => {
        trackingData.trackingListProvider = value;
    },
    updateBlockName: (value: string) => {
        trackingData.blockName = value;
    },
    updateTrackingTitle(value: string) {
        trackingData.trackingTitle = value;
    },
});

provide(trackingDataSymbol, trackingData);

const renderComponent = (block: JsonContent) => {
    return blockResolver.resolve(block.alias);
};
</script>