import { MediaType, v4 } from '@/types/serverContract';
import serverContext from '@/core/serverContext.service';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

export default function usePdpJsonld(product: v4.Products.ProductDetails, selectedVariant: v4.Products.ProductDetailsVariant): Record<string, any> {
    const route = useRoute();
    if (!(product && selectedVariant)) return {};
    const jsonldobj:any = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: product.name,
        description: product.description,
        brand: {
            '@type': 'Brand',
            name: product.brand?.name || 'Søstrene Grene'
        },
        alternateName: product.description,
        url: window.location.origin + route.fullPath
    };

    jsonldobj.image = selectedVariant.sortedMedia.map(m => m.url);

    const productInformation = selectedVariant.information.find(i => i.name === 'ProductInformation');
    const measurements = productInformation?.sections.find(s => s.header === 'Measurements');
    const specifications = productInformation?.sections.find(s => s.header === 'Specifications');
    const length = (measurements as any)?.value.find(m => m.key === 'SalesLength')?.formatted;
    const width = (measurements as any)?.value.find(m => m.key === 'SalesWidth')?.formatted;
    const height = (measurements as any)?.value.find(m => m.key === 'SalesHeight')?.formatted;
    const weight = (specifications as any)?.value.find(s => s.key === 'NetWeight')?.formatted;
    const material = (specifications as any)?.value.find(s => s.key === 'MaterialDetail')?.value?.map(v => v).join(',');
    const sku = (specifications as any)?.value.find(s => s.key === 'Sku')?.value;

    if (weight) {
        jsonldobj.weight = weight;
    }
    if (width) {
        jsonldobj.width = width;
    }
    if (height) {
        jsonldobj.height = height;
    }
    if (length) {
        jsonldobj.depth = length;
    }
    if (material) {
        jsonldobj.material = material;
    }
    jsonldobj.sku = sku;
    jsonldobj.color = product.variants?.map((c) => c.color?.name).join(',');

    if (product.variants?.length) {
        const offers: {'@type' : string, sku: string, price: string, itemCondition: string, availability: string, priceCurrency: string, url: string, priceValidUntil: string}[] = [];
        const date = new Date();
        const year = date.getFullYear() + 1;
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const priceValidUntilDate = year + '-' + month + '-' + day;
        product.variants.map((v:v4.Products.ProductDetailsVariant, variantIndex) => {
            const inventory = selectedVariant.inventory ?? null;
            offers.push({
                '@type': 'Offer',
                sku: v.sku,
                price: v.pricing.unit.amount.toString(),
                availability: inventory?.inStock ? 'https://schema.org/InStock' : 'https://schema.org/InStoreOnly',
                itemCondition: 'https://schema.org/NewCondition',
                priceCurrency: serverContext.currencyCode,
                url: `${window.location.origin}${window.location.pathname}${variantIndex > 0 ? `?variant=${v.sku}` : ''}`,
                priceValidUntil: priceValidUntilDate
            });
        });
        jsonldobj.offers = offers;
    }

    const video = computed(() => {
        return selectedVariant.sortedMedia.find(m => m.type === MediaType.Video);
    });
    if (video?.value?.url) {
        jsonldobj.video = {
            '@type': 'VideoObject',
            thumbnailUrl: selectedVariant.sortedMedia[0].url,
            embedUrl: video?.value?.url,
            contentUrl: route.fullPath,
            description: product.description,
            name: product.name,
            uploadDate: selectedVariant.availability.availableAt || ''
        };
    }
    return {
        jsonldobj
    };
}
