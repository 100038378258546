import logging from '@/core/logging.service';

type ComponentType = object | (() => object);

class ComponentResolver {
    components = new Map<string, ComponentType>();
    fallback: ComponentType | undefined;

    constructor(private context: string) {
    }

    register(alias: string, component: ComponentType) {
        if (this.components.get(alias)) {
            throw new Error(`${this.context}: ${alias} already registered`);
        }
        this.components.set(alias, component);
    }

    registerFallback(component: ComponentType) {
        this.fallback = component;
    }

    resolve(alias: string): ComponentType | null {
        if (!alias) return null;
        const component = this.components.get(alias);
        if (!component) {
            if (this.fallback) {
                logging.developmentOnly(`${this.context || 'no context'}: "${alias || 'empty Alias'}" not registered, using default. ${component ? 'Component found' : 'no Component found'}`);
                return this.fallback;
            } else {
                const msg = `${this.context}: ${alias} not registered and no default component`;
                logging.developmentOnly(msg);
                throw new Error(msg);
            }
        }
        return component;
    }
}

export const blockResolver = new ComponentResolver('block');
export const pageResolver = new ComponentResolver('page');
