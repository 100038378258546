<template>
    <article class="flex"
             :class="moduleAlignment">
        <div class="w-full max-w-650">
            <component :is="getHeaderTagType"
                       v-if="content.title"
                       :id="headerAnchorLink"
                       class="mb-25 md:mb-52 font-script text-42 md:text-60 tracking-normal md:pl-3 c-text-block__script">
                {{ content.title }}
            </component>
            <div v-if="content.text"
                 v-delegate-html-links
                 class="wysiwyg leading-sm text-16 md:text-18 -mt-1 pl-4 md:pt-2 c-text-block__body"
                 v-html="content.text"/>
        </div>
    </article>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { TextBlockModel } from '@/types/serverContract';
import { getHeaderAnchorLink } from '@/project/shared/string.util';

const props = defineProps<{
    content: TextBlockModel;
}>();

const moduleAlignment = computed(() => {
    return props.content.alignment === 'left' ? 'justify-start'
        : props.content.alignment === 'right' ? 'justify-end'
            : ' justify-center';
});

const headerAnchorLink = computed(() => {
    return getHeaderAnchorLink(props.content);
});

const getHeaderTagType = computed(() => {
    return props.content.headerType || 'h3';
}); 
</script>



<style lang="less" scoped>
.c-text-block__script {}
@screen md {
    .c-text-block__script {
        letter-spacing: -.7px;
    }
    .c-text-block__body {
        letter-spacing: -.05px;
        line-height: 1.45;
    }
}
</style>
