<template>
    <div class="o-container py-50">
        <h3 v-if="content.title"
            :id="getHeaderAnchorLink(content)"
            class="font-brandon text-11 md:text-13 mb-15 md:mb-30 uppercase tracking-medium md:tracking-normal"
            :class="themeAlternateTextColor">
            {{ content.title }}
        </h3>

        <div class="o-grid c-product-list">
            <BlockRender v-for="(block, blockIndex) in getContentBlocks"
                         :key="blockIndex"
                         class="o-grid-item h-full"
                         :block="block"
                         :style="{order:getContentBlockPosition(block.content.position)}"
                         :data-legacy-sort="getContentBlockPosition(block.content.position)"/>
            <product-provider v-for="(productListItem, productIndex) in content.products"
                              :key="`${productListItem.productId}-${productIndex}`"
                              #default="{ product }"
                              :change="debouncedProductsChange()"
                              :product-id="productListItem.productId">
                <ProductTile v-if="isProductsLoaded && product"
                             :product="product"
                             class="o-grid-item"
                             :override-image="productListItem.image"
                             :image-mode="productListItem.image ? 'crop' : null"
                             :tracking-index="productIndex"
                             :image-alt="altText"
                             :variant="productListItem.sku"
                             :style="{order:productIndex}"
                             :data-legacy-sort="productIndex"
                             :bg-theme="content.backgroundColor"
                             :show-expired-text="true"
                             :use-environmental-images="content.useEnvironmentalImages"/>
                <product-skeleton v-else-if="!isProductsLoaded"
                                  :bg-theme="content.backgroundColor"
                                  class="o-grid-item"/>
            </product-provider>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { ProductListBlockModel } from '@/types/serverContract';
import debounce from 'lodash-es/debounce';
import ProductSkeleton from '@/project/product/ProductSkeleton.vue';
import { styleFunctionsForThemedContentAlternate } from '@/project/shared/styleFunctionsForThemedContent';
import { getHeaderAnchorLink } from '@/project/shared/string.util';

const props = defineProps<{
    content: ProductListBlockModel;
}>();

const isProductsLoaded = ref(false);
// Bundle all product-change callbacks in this one
const debouncedProductsChange = debounce(productsLoadedHandler, 150);

function getContentBlockPosition(position: number) {
    return (position || 0) - 1;
}

const getContentBlocks = computed(() => {
    /*
        Dont show content blocks that have index larger than the number of products + 1.
        So if content blocks index is set to 100 and only 50 products are shown - cause they are not valid anymore,
        the content block position 100 will not be shown.
    */
    return props.content.blocks.slice(0, props.content.products ? props.content.products.length : 0);
});

const themeAlternateTextColor = computed(() => {
    return styleFunctionsForThemedContentAlternate(props.content.textColor);
});

function productsLoadedHandler() {
    isProductsLoaded.value = true;
}

const altText = computed((): string | null => {
    if (props.content.altText) {
        return props.content.altText;
    }
    return props.content.title;
});
</script>