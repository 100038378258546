import serverContextService from '../serverContext.service';
import log from '../logging.service';
import axios, { AxiosResponse } from 'axios';

function clientServerVersionResponseInterceptor(response: AxiosResponse) {
    if (response.headers) {
        const currentVersion = response.headers.serverversion;
        const initialVersion = serverContextService.serverVersion;
        if (currentVersion && currentVersion !== initialVersion) {
            log.debug(`Hard-reloading due to version mismatch. Initial: ${initialVersion}, Current: ${currentVersion}`);
            window.location.reload();
        }
    }
    return response;
}
axios.interceptors.response.use(clientServerVersionResponseInterceptor);
