import spaStore from '@/core/spa/store/spa.store';
import mParticleUtils from '@/project/tracking/mParticle.utils';

export default class PageViewController {
    // For tracking page view, we do not want all the usual standard custom attributes,
    // but only a selection. This method cherrypicks the ones we need
    private handpickCertainStandardCustomAttributes(): object {
        const customAttributes: any = {};
        const standardCustomAttributes: object = mParticleUtils.shared.getCustomAttributes();
        ['LoggedInState', 'pageType', 'CountryCode', 'screentitle', 'screentype'].forEach((key) => {
            customAttributes[key] = standardCustomAttributes[key];
        });
        return customAttributes;
    }

    private assembleCustomAttributesForUtmParamsInUrl(): object {
        const customAttributes: any = {};
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        ['source', 'medium', 'campaign', 'term', 'content', 'source_platform', 'creative_platform', 'marketing_tactic'].forEach((key) => {
            if (params.has('utm_' + key)) {
                customAttributes['c_' + key] = params.get('utm_' + key);
            }
        });
        return customAttributes;
    }

    private assembleCustomAttributesForReferrerEtc(): object {
        const documentReferrer = spaStore.jsonContent.alias === 'receipt' ? '' : document.referrer;

        if (documentReferrer === '') {
            return {};
        }

        const referrer = new URL(documentReferrer);
        const hostnameParts = referrer.host.split('.');

        const customAttributes: any = {
            'referring-page': documentReferrer,
            c_referrer: documentReferrer,

            // Set "Publisher" to "naked" domain name, stripping TLD and subdomain (ie "http://www.bing.com" => "bing") 
            Publisher: hostnameParts[hostnameParts.length - 2],
        };

        return customAttributes;
    }

    private assembleCustomAttributesForFirstPageView(): object {
        const sessionAttributes = window.mParticle?.Store?.sessionAttributes;

        const firstPageViewInSession: boolean = !(sessionAttributes?.firstPageViewMarkSet === true);
        if (sessionAttributes) {
            window.mParticle.setSessionAttribute('firstPageViewMarkSet', true);
        }

        const customAttributesForUtmParamsInUrl = this.assembleCustomAttributesForUtmParamsInUrl();

        if (Object.keys(customAttributesForUtmParamsInUrl).length !== 0 || this.hasExternalReferrer()) {
            return {
                firstpage: firstPageViewInSession,
                ...this.assembleCustomAttributesForReferrerEtc(),
                ...customAttributesForUtmParamsInUrl
            };
        } else {
            return {
                firstpage: firstPageViewInSession
            };
        }
    }

    private hasExternalReferrer(): boolean {
        return document.referrer !== '' && !document.referrer.includes(location.host);
    }

    private assembleCustomAttributesForContentIds(): object {
        const customAttributes: any = {};
        const trackingData = spaStore.pageData?.tracking;
        const pageUrl = window.location?.href; 

        customAttributes.contentGroupID = trackingData.contentGroupId;
        customAttributes.contentGroupName = trackingData.contentGroupName;
        customAttributes.contentID = trackingData.contentId;
        customAttributes.contentTypeID = trackingData.contentTypeId;
        customAttributes.pageUrl = pageUrl; 

        const content = spaStore?.jsonContent?.content;

        if (content?.categoryId) {
            customAttributes.CategoryID = content.categoryId;
        }
        if (content?.contentId) {
            customAttributes.contentID = content.contentId;
        }

        return customAttributes;
    }

    private logScreenView(customAttributes: object, customFlags: object) {
        mParticleUtils.shared.queueUpForTracking(() => {
            window.mParticle.logPageView(
                'Screen View',
                customAttributes,
                customFlags
            );
        });
    }

    public trackPageView() {
        if (spaStore.jsonContent?.alias === 'pageNotFound') {
            return;
        }
        const customAttributes: any = {
            page: window.location.pathname,
            ...this.handpickCertainStandardCustomAttributes(),
            ...this.assembleCustomAttributesForContentIds(),
            ...this.assembleCustomAttributesForFirstPageView()
        };
        const customFlags = {
            ...mParticleUtils.shared.getCustomFlags(),
            'Google.Page': window.location.pathname,
            'Google.Title': document.title
        };
        this.logScreenView(customAttributes, customFlags);
    }
}
