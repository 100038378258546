export const contentSpacingClassTemplates = {
    none: 'p{0}-0 md:p{0}-0',
    small: 'p{0}-20 md:p{0}-40',
    medium: 'p{0}-30 md:p{0}-50',
    large: 'p{0}-40 md:p{0}-80'
};

export const contentSpacingClassTemplatesEightPxgrid = {
    none: 'p{0}-0 md:p{0}-0',
    'x-small': 'p{0}-12 md:p{0}-24',
    small: 'p{0}-24 md:p{0}-48',
    medium: 'p{0}-36 md:p{0}-72',
    large: 'p{0}-48 md:p{0}-96'
};