import { v4 } from './serverContract';

export enum MomentType {
    Experience = 'experience',
    Gallery = 'gallerystory',
    FeatureStore = 'featurestory'
}

export enum StoreOverlayMode {
    StockStatus = 'stockstatus',
    StorePicker = 'storepicker',
}

export enum PendingState {
    Pending = 'Pending',
    Resolved = 'Resolved',
    Init = 'Init',
}

export enum FilterState {
    All = 'All',
    Products = 'Products',
    Inspiration = 'Inspiration',
}
export interface ITrackingProps {
    product: v4.Products.ProductSimple,
    trackingIndex: number,
    event: string
}

export interface IProvideInjectTrackingData {
    blockName?: string;
    blockPosition?: number;
    trackingSystem?: string;
    trackingName?: string;
    trackingListProvider?: string;
    trackingTitle?: string;
    trackingIndex?: number;
    updateTrackingTitle?: (trackingTitle: string) => void;
    updateTrackingSystem?: (trackingSystem: string) => void;
    updateTrackingListProvider?: (trackingListProvider: string) => void;
    updateBlockName?: (blockName: string) => void;
}

export const trackingDataSymbol = Symbol('trackingData');