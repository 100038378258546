import { GrowthBook } from '@growthbook/growthbook';
import mParticleUtils from '../tracking/mParticle.utils';
import Deferred from '@/core/async/deferred';
import logging from '@/core/logging.service';
import { router, SpaPageRenderedEventKey } from '@/core/spa/router'; // Custom naming https://stackoverflow.com/a/56374668/2664445
import bus from '@/core/bus';

/**
 * Configuration for the options passed to the app.use() call
 */
type GrowthBookConfig = {
    /**
     * The endpoint that your features are hosted on. Get this from the Environments -> SDK Endpoints section
     */
    featuresEndpoint: string;

    /**
     * Allows you to use the Chrome DevTools Extension to test/debug.
     * Learn more: https://docs.growthbook.io/tools/chrome-extension
     */
    enableDevMode: boolean;
};
export type GrowthBookType = GrowthBook<Record<string, any>> | null;

const growthBook = new Deferred<GrowthBook<Record<string, any>> | null>();
const enableDevMode: GrowthBookConfig['enableDevMode'] = import.meta.env.MODE !== 'production' || import.meta.env.VITE_DEPLOYMENT_ENV === 'stage';

const init = async() => {
    try {
        const gb = new GrowthBook({
            apiHost: import.meta.env.VITE_VUE_APP_GROWTHBOOK_ENDPOINT,
            clientKey: import.meta.env.VITE_VUE_APP_GROWTHBOOK_CLIENT_KEY,
            enableDevMode: enableDevMode,
            disableCache: enableDevMode,
            trackingCallback: (experiment, result) => {
                mParticleUtils.navigation.trackGrowthBookExposure(experiment, result);
            }
        });

        // gb.setFeatures(await getFeatures());
        await gb.init({
            streaming: true,
        });
        const mParticleGrowthBookAttributes = await mParticleUtils.store.getMParticleGrowthBookAttributes();
        gb.setAttributes(
            {
                ...mParticleGrowthBookAttributes
            }
        );

        growthBook.resolve(gb);
    } catch (e) {
        logging.exception('GrowthBook error', e);
        growthBook.resolve(null);            
    }
};

const getActiveVariation = async(featureKey: string): Promise<string | null> => {
    const gb = await growthBook.promise;

    if (!gb) {
        return null;
    }

    // Check if the feature is forced. querysyntax: &forcevariation=Fsst-11819_usp_pdp:2&forcevariation=Fsst-11819_order_pdp:2
    const forcedVariationQuery = router.currentRoute.value.query?.forcevariation;
    if (forcedVariationQuery) {
        const keyAndValueArray = Array.isArray(forcedVariationQuery) ? forcedVariationQuery : [forcedVariationQuery];
        const matchingFeatureKeyQuery = keyAndValueArray.find(keyValue => keyValue?.toLowerCase()?.startsWith(featureKey.toLowerCase()));
        if (matchingFeatureKeyQuery) {
            const [key, numericValue] = matchingFeatureKeyQuery.split(':');
            if (key.toLowerCase() === featureKey.toLowerCase() && !isNaN(Number(numericValue))) {
                gb.forceVariation(featureKey, Number(numericValue));
                logging.info(`Forced variation. ${featureKey}: ${numericValue}`);    
            }
        }
    }

    const featureValue =  gb.getFeatureValue(featureKey, '');
    return featureValue;
};

function isFeatureAvailable(gb: GrowthBookType, featureKey: string) {
    const isOn = gb?.isOn(featureKey);
    return isOn;
}

function getFeatureVariations(gb: GrowthBookType, featureKey: string) {
    const feature = gb?.getFeatures()[featureKey];
    const ruleWithVariations = feature?.rules?.find(rule => !!rule.variations);
    return ruleWithVariations?.variations ?? [];
}

// Let the GrowthBook instance know when the URL changes so the active experiments can update accordingly
bus.on(SpaPageRenderedEventKey, async() => {
    const gb = await growthBook.promise;
    if (gb) {
        gb.setURL(window.location.href);
    }
});

export default function useGrowthBook() {
    return {
        growthBook: growthBook.promise,
        getActiveVariation,
        isFeatureAvailable,
        getFeatureVariations
    };
}

init();
