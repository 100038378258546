import { reactive } from 'vue';
import { MessageData, Metadata, SitePageData, EntityData, NotificationBarViewModel } from '@/types/serverContract';
import serverContextService from '@/core/serverContext.service';
import productsStore from '@/store/products/products.store';
import makeStateDebugAccessible from '@/store/stateDebug';
import { InfoMessagesViewModel } from '@/project/_messages/types';
import bus from '@/core/bus';
import { CookieConsentChangeEventKey } from '@/project/config/constants';
import { MomentType } from '@/types/frontend';
import Api from '@/project/http/api';

interface ISpaState {
    pageData: SitePageData;
    notificationBar: NotificationBarViewModel | null;
    isLoadingSpaPage: boolean;
    isInitialPage: boolean;
    outstandingCatalogXHRs: number;
    chatbotLoaded: boolean;
    mainAriaLabel: null | string;
    cookieConsentStatus: CookieInformationDotComConsent;
}

export interface CookieInformationDotComConsent {
    cookieCatNecessary: null | boolean;
    cookieCatFunctional: null | boolean;
    cookieCatStatistic: null | boolean;
    cookieCatMarketing: null | boolean;
    cookieCatUnclassified: null | boolean;
}
class SpaStore {
    private state: ISpaState = reactive({
        pageData: serverContextService.pageData,
        isLoadingSpaPage: false,
        isInitialPage: true,
        outstandingCatalogXHRs: 0,
        chatbotLoaded: false,
        mainAriaLabel: null,
        notificationBar: null,
        cookieConsentStatus: {
            cookieCatNecessary: null,
            cookieCatFunctional: null,
            cookieCatStatistic: null,
            cookieCatMarketing: null,
            cookieCatUnclassified: null
        }
    });

    constructor() {
        makeStateDebugAccessible('spaState', this.state);

        this.handleMessages(this.state.pageData.messages);
        window.addEventListener('CookieInformationConsentGiven', () => this.handleCookieConsentChange(), false);

        this.updateNotificationBar();
    }

    public get pageData(): SitePageData {
        return this.state.pageData;
    }

    public get jsonContent() {
        return this.state.pageData.jsonContent;
    }

    public get navigation() {
        return this.state.pageData.navigation;
    }

    public get notificationBar(): NotificationBarViewModel | null {
        return this.state.notificationBar;
    }

    public get metadata(): Metadata {
        return this.state.pageData.metadata;
    }

    public get entity(): EntityData {
        return this.state.pageData.entity;
    }

    public get isLoadingSpaPage(): boolean {
        return this.state.isLoadingSpaPage;
    }

    public get isInitialPage(): boolean {
        return this.state.isInitialPage;
    }

    public get infoMessages(): InfoMessagesViewModel[] {
        return [];
    }

    public get isExperiencePage():boolean {
        return this.jsonContent?.content?.moment?.alias === MomentType.Experience;
    }

    public get isThunnelLayout(): boolean {
        return this.jsonContent?.alias === 'checkout' || this.jsonContent?.alias === 'externalAccount' || this.isExperiencePage;
    }

    public set isFetchingSpaPage(state: boolean) {
        this.state.isLoadingSpaPage = state;
        this.state.isInitialPage = false;
        this.isLoadingSpaPageSubscriber(state);
    }

    // todo: Will disappear when Vue supports watch outside component
    isLoadingSpaPageSubscriber: (value: boolean) => void = () => {};
    public subscribeIsLoadingSpaPage(subscriber: (value: boolean) => void) {
        this.isLoadingSpaPageSubscriber = subscriber;
    }

    public updatePage(data: SitePageData) {
        this.state.pageData = data;
        this.updateNotificationBar();
        window.vertica.pageData = data; // for ChatBot to be updated
    }

    private handleMessages(messages: MessageData) {
        if (messages && messages.productIds) {
            productsStore.ensureLoaded(messages.productIds);
        }
    }

    public get isPageProductRelated(): boolean {
        if (this.isFetchingSpaPage) return false;
        if (this.jsonContent?.alias === 'productDetails' || this.jsonContent?.alias === 'category' || this.jsonContent?.alias === 'categoryDetails' || this.jsonContent?.alias === 'roomDetails') return true;
        return false;
    }

    public get isMoment(): boolean {
        return (this.jsonContent?.alias === 'momentDetails' || this.jsonContent?.alias === 'momentInspirationalPage');
    }

    public get chatbotActive(): boolean {
        return this.state.pageData.showChatbot;
    }

    public get chatbotWatermeolActive(): boolean {
        return this.state.pageData.showChatbot && this.state.pageData.showWatermelonChatbot;
    }

    public set chatbotActiveLoaded(state: boolean) {
        this.state.chatbotLoaded = state;
    }

    public get chatbotActiveLoaded(): boolean {
        return this.state.chatbotLoaded;
    }

    public set mainAriaLabel(labelText: string | null) {
        this.state.mainAriaLabel = labelText;
    }

    public get mainAriaLabel(): string | null {
        return this.state.mainAriaLabel;
    }

    public cookieConsentStatus(): CookieInformationDotComConsent {
        const state: CookieInformationDotComConsent = {
            cookieCatNecessary: null,
            cookieCatFunctional: null,
            cookieCatStatistic: null,
            cookieCatMarketing: null,
            cookieCatUnclassified: null
        };

        if (window.CookieInformation && window.CookieInformation.getConsentGivenFor) {
            state.cookieCatNecessary = window.CookieInformation.getConsentGivenFor('cookie_cat_necessary');
            state.cookieCatFunctional = window.CookieInformation.getConsentGivenFor('cookie_cat_functional');
            state.cookieCatStatistic = window.CookieInformation.getConsentGivenFor('cookie_cat_statistic');
            state.cookieCatMarketing = window.CookieInformation.getConsentGivenFor('cookie_cat_marketing');
            state.cookieCatUnclassified = window.CookieInformation.getConsentGivenFor('cookie_cat_unclassified');
        }

        return state;
    }

    private handleCookieConsentChange() {
        const status = this.cookieConsentStatus();
        if (status.cookieCatFunctional === undefined) return; // if functional cookie are undefined, we are waiting for consent from user
        this.state.cookieConsentStatus.cookieCatNecessary = status.cookieCatNecessary;
        this.state.cookieConsentStatus.cookieCatFunctional = status.cookieCatFunctional;
        this.state.cookieConsentStatus.cookieCatStatistic = status.cookieCatStatistic;
        this.state.cookieConsentStatus.cookieCatMarketing = status.cookieCatMarketing;
        this.state.cookieConsentStatus.cookieCatUnclassified = status.cookieCatUnclassified;
        bus.emit(CookieConsentChangeEventKey, status);
    }

    private updateNotificationBar() {
        const alias = this.pageData?.jsonContent?.alias;
        if (!alias) return;
        Api.siteInfo.getNotificationBar(alias).then((data: NotificationBarViewModel) => {
            this.state.notificationBar = data.text ? data : null;
        });
    }
}
export default new SpaStore();
