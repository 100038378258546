<template>
    <img v-if="imageUrl"
         v-on-error
         class="responsive-icon"
         :src="imageSrcSets ? imageSrcSets.x1 : imageUrl"
         :srcset="imageSrcSets ? `${imageSrcSets.x1} 1x, ${imageSrcSets.x2} 2x` : undefined"
         :height="trueDimensions ? trueDimensions.height : undefined"
         :width="trueDimensions ? trueDimensions.width : undefined"
         :alt="alt">
</template>

<script setup lang="ts">
import spaStore from '@/core/spa/store/spa.store';
import { MediaUrlConfig } from '@/types/serverContract';
import { computed, PropType } from 'vue';
import { IImageOptions, ImageFormat } from './image.types';
import { generateCdnUrlWithParams } from './responsiveImage.service';

const props = defineProps({
    imageUrl: { type: String, default: '' },
    altText: { type: String, default: '' },
    height: { type: Number, default: 0 }, 
    // Number or e.g. 'auto'
    width: { type: Number, default: 0 },
    bgColor: { type: String, default: 'fffef8' },
    quality : { type: Number, default: 80 },
    mediaUrlConfig: { type: Object as PropType<MediaUrlConfig>, default: undefined },
    format : {
        type: String,
        default: 'jpg',
        validator: value => ['jpg', 'png', 'gif', 'webp', ''].includes(value as string)
    }
});
    
const alt = computed(() => props.altText || spaStore.pageData.metadata.seoTitle);
    
const imageSrcSets = computed(() => {
    if (!(props.imageUrl) || (props.imageUrl && (props.imageUrl.indexOf('.svg') > 0 || props.imageUrl.indexOf('.gif') > 0))) return undefined;

    if (!trueDimensions.value) {
        return undefined;
    }

    if (props.mediaUrlConfig) {
        const cdnParams: IImageOptions = {
            width: trueDimensions.value.width,
            height: trueDimensions.value.height,
            quality: props.quality,
            fit: 'pad'
        };

        if (props.format) {
            cdnParams.format = props.format as ImageFormat;
        }

        const bgColor = normalizedBgColor;
        if (bgColor.value) {
            cdnParams.background = bgColor.value;
        }

        const cdnRetinaParams: IImageOptions = cdnParams;
        cdnRetinaParams.width = trueDimensions.value.width * 2;
        cdnRetinaParams.height = trueDimensions.value.height * 2;

        return {
            x1: generateCdnUrlWithParams(props.mediaUrlConfig.cdnBaseUrl, props.mediaUrlConfig.sourceUrl, cdnParams),
            x2: generateCdnUrlWithParams(props.mediaUrlConfig.cdnBaseUrl, props.mediaUrlConfig.sourceUrl, cdnRetinaParams)
        };
    }

    const defaultParams: any = {
        width: trueDimensions.value.width,
        height: trueDimensions.value.height,
        quality: props.quality,
        rmode: 'pad'
    };

    if (props.format) {
        defaultParams.format = props.format;
    }

    const bgColor = normalizedBgColor;
    if (bgColor.value) {
        defaultParams.bgcolor = bgColor.value;
    }

    const params = new URLSearchParams();
    for (const key in defaultParams) {
        if (Object.prototype.hasOwnProperty.call(defaultParams, key)) {
            params.append(key, defaultParams[key]);
        }
    }

    const retinaParams = new URLSearchParams(params.toString());
    retinaParams.set('width', (trueDimensions.value.width * 2).toString());
    retinaParams.set('height', (trueDimensions.value.height * 2).toString());

    return {
        x1: `${props.imageUrl}?${params.toString()}`,
        x2: `${props.imageUrl}?${retinaParams.toString()}`
    };
});

const trueDimensions = computed(() => {
    return props.height ? { height: Math.ceil(props.height / 2), width: Math.ceil(props.width / 2) } : null;
});

const normalizedBgColor = computed(() => {
    return props.bgColor ? props.bgColor.replace('#', '') : 'fffef8';
});

</script>
