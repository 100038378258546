import { App } from 'vue';
import dictionaryService from './dictionary.service';

function translateFilter(value: string, ...args: any[]): any {
    return dictionaryService.get(value, args);
}

export function initTranslateFilter(app: App) {
    app.config.globalProperties.$translate = translateFilter;
}

export default translateFilter;