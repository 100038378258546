<script lang="ts">
import { defineComponent, computed } from 'vue';
import map from 'lodash-es/map';
import breakpointsState from '../breakpoints/breakpointsState.observable';

// Define as many slots you want with their 'name' set to breakpoints:
// <breakpoints>
//   <div v-slot:sm>
//     xx
//   </div>
//   <div v-slot:min-md,max-xl>
//     yy
//   </div>
// </breakpoints>

export default defineComponent({
    name: 'Breakpoint',
    setup(_, { slots }) {
        if (!slots) {
            throw new Error('You must provide at least 1 slot');
        }
        Object.keys(slots).forEach(slotName => {
            if ((slots[slotName]?.()?.length ?? 0) > 1) {
                throw new Error(`There must be max. 1 element for each breakpoint slot. ${slotName} has ${slots[slotName]?.length}. Consider wrapping it in a template tag.`);
            }
        });

        const visibleSlots = computed(() => {
            return map(slots, (slot, key) => {
                return {
                    visible: breakpointsState.isBreakpointActive(key),
                    slot
                };
            })
                .filter(x => x.visible)
                .map(x => x.slot);
        });


        return () => {
            const slotsToRender = visibleSlots.value;
            if (!slotsToRender.length || !slotsToRender[0]) {
                return undefined;
            }
            return slotsToRender[0]();
        };
    }
});
</script>
