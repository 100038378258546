<template>
    <VDropdown
        v-if="hasLogin && !isLoggedIn"
        trigger="manual"
        theme="custom-dropdown"
        :shown="showTooltip"
        popper-class="tooltip popover c-product-details-favorite-toggle__login-popover"
        placement="bottom-end"
        @update:open="updateTooltipState">
        <button :title="label"
                @click="showTooltip = true">
            <c-icon :name="type === 'PDP' ? 'heart-stroke-frame' : 'heart-stroke-new'"
                    :width="iconWidth"
                    :height="iconHeight"
                    :class="frameStyling"
                    class="hover:text-brown-80"/>
        </button>
        <template #popper>
            <div class="max-w-250">
                <h4 class="font-brandon text-12 font-medium uppercase">
                    {{ $translate('favorites.LoginRequired.Title') }}
                </h4>
                <p class="mt-10 leading-sm">
                    {{ $translate('favorites.LoginRequired.Body') }}
                </p>
                <div class="text-right">
                    <button
                        class="border-b relative mt-10 inline-block pb-1 font-brandon text-10 font-medium uppercase tracking-small md:text-12"
                        @click="openLogin">
                        {{ $translate('favorites.LoginRequired.LoginButton') }}
                    </button>
                </div>
            </div>
        </template>
    </VDropdown>
    <div v-else-if="hasLogin">
        <button :title="label"
                class="relative flex"
                :style="{ height: `${iconHeight}px`, width: `${iconWidth}px` }"
                @click="toggleFavorite">
            <DotLottieVue
                ref="lottieInstance"
                :data="type === 'PDP' ? heart : heartNoFrame"
                :description="label"
                :render-config="{devicePixelRatio: 2.5}" 
                :class="addingToFavorite ? 'opacity-100' : 'opacity-0'"
                class="absolute inset-0 -translate-y-[.15px] -translate-x-[.15px]"/>
            <c-icon
                v-show="!addingToFavorite && isFavorite"
                key="heart-filled"
                :name="type === 'PDP' ? 'heart-filled-frame' : 'heart-filled-new'"
                :width="iconWidth"
                :height="iconHeight"
                class="absolute inset-0 hover:text-brown-90 text-brown-80"/>
            <c-icon
                v-show="!isFavorite"
                key="heart-stroke-frame"
                :name="type === 'PDP' ? 'heart-stroke-frame' : 'heart-stroke-new'"
                :width="iconWidth"
                :height="iconHeight"
                class="absolute inset-0 hover:text-brown-80"
                :class="frameStyling"/>
        </button>
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import type { DotLottieVueInstance } from '@lottiefiles/dotlottie-vue';
import bus from '@/core/bus';
import serverContext from '@/core/serverContext.service';
import translateFilter from '@/core/translation/translate.filter';
import { ToastMessage, TOAST_BUS_KEY } from '@/project/config/constants';
import Api from '@/project/http/api';
import { newLineToBr } from '@/project/shared/string.util';
import trackingUtils from '@/project/tracking/tracking.utils';
import favoritesStore from '@/store/favorites.store';
import userStore from '@/store/user.store';
import { MediaType, v4 } from '@/types/serverContract';
import spaStore from '@/core/spa/store/spa.store';
import { heart, heartNoFrame } from '@/project/shared/lottie/lottiejson';

const props = withDefaults(
    defineProps<{
    details: v4.Products.ProductDetails | v4.Products.ProductSimple;
    selectedVariant: v4.Products.ProductDetailsVariant | v4.Products.VariantSimple;
    trackingObject?: Record<string, any>;
    type?: 'PDP' | 'PLP';
    frameStyling?: string,
    iconWidth?: string,
    iconHeight?: string
}>(),
    {
        trackingObject: undefined,
        type: 'PDP',
        frameStyling: '',
        iconWidth: '24',
        iconHeight: '24'
    }
);

const emit = defineEmits(['login-open']);

const favorites = computed(() => favoritesStore.favorites);
const trackingEventAction = computed((): string => {
    return `${props.details.name}_${props.selectedVariant.color?.name}`;
});
const myPageFavoriteProductsUrl = computed(() => {
    return serverContext.myPageFavoriteProductsUrl;
});
const hasLogin = computed(() => serverContext.hasLogin);
const isLoggedIn = computed(() => userStore.isLoggedIn);
const showTooltip = ref(false);
const updateTooltipState = (state: boolean) => {
    showTooltip.value = state;
};
const openLogin = () => {
    userStore.setLoginFormActiveState(true, false);
    updateTooltipState(false);
    emit('login-open');
};

const lottieInstance = ref<DotLottieVueInstance | null>(null);
const addingToFavorite = ref(false);
const isFavorite = computed((): boolean | null => {
    if (!isLoggedIn.value) return null;

    if (favorites.value) {
        return !!(
            favorites.value.find((favorite) => {
                return props.selectedVariant.sku === favorite.identifier;
            }) || false
        );
    }
    return false;
});

const label = computed(() => {
    return isFavorite.value ? translateFilter('favorites.RemoveFavorite') : translateFilter('favorites.SaveFavorite');
});

const toggleFavorite = () => {
    if (isFavorite.value) {
        removeFavorite();
    } else {
        addFavorite();
    }
};

const addFavorite = async() => {
    addingToFavorite.value = true;
    
    if (lottieInstance.value) {
        lottieInstance.value.getDotLottieInstance()?.play();
    }


    return Api.user.addFavorite(props.selectedVariant.sku, 'Product').then(() => {
        if (props.type === 'PDP') {
            const message: ToastMessage = {
                type: 'FAVORITE_ADD',
                message: newLineToBr(
                    translateFilter(
                        'favorites.ProductSaved',
                        `<span class="font-gibson-regular">${props.details.name}</span>`,
                        `<a href="${
                            myPageFavoriteProductsUrl.value || '#'
                        }" class="mt-12 inline-block text-current underline hover:no-underline hover:text-current">`,
                        '</a>'
                    )
                )
            };
            bus.emit(TOAST_BUS_KEY, message);

            trackAddFavoritePDP();
        } else if (props.type === 'PLP') {
            trackAddFavoritePLP();
        }
    });
};

const removeFavorite = async() => {
    addingToFavorite.value = false;

    return Api.user.removeFavorite(props.selectedVariant.sku, 'Product').then(() => {
        if (props.type === 'PDP') {
            const message: ToastMessage = {
                type: 'FAVORITE_REMOVE',
                message: newLineToBr(
                    translateFilter(
                        'favorites.ProductRemoved',
                        `<span class="font-gibson-regular">${props.details.name}</span>`
                    )
                )
            };
            bus.emit(TOAST_BUS_KEY, message);
            trackRemoveFavoritePDP();
        } else if (props.type === 'PLP') {
            trackRemoveFavoritePLP();
        }

        // TODO refactor store to handle this
        // Need to clean up the favoritesStore.favorites array when removing favorite
        if (favoritesStore.favorites !== null) {
            const index = favoritesStore.favorites?.findIndex((obj) => obj.identifier === props.selectedVariant.sku);
            if (index >= 0) {
                favoritesStore.favorites.splice(index, 1);
            }
        }
    });
};

function trackAddFavoritePDP() {
    trackingUtils.favourites.trackAddToFavoritesPdp({
        id: props.details.id,
        name: props.details.name,
        price: props.selectedVariant.pricing.unit.amount,
        inventory: props.selectedVariant.inventory?.state,
        variantSku: props.selectedVariant.sku,
        variantColorName: props.selectedVariant.color?.name,
        brandName: props.details.brand?.name,
        shortDescriptionLength: props.details.tracking?.shortDescriptionLength || 0,
        description: (props.details as v4.Products.ProductDetails).description,
        labelling: (props.details as v4.Products.ProductDetails).tracking.labelling,
        category: (props.details as v4.Products.ProductDetails).tracking.primaryCategoryId,
        numberOfImages: (props.selectedVariant as v4.Products.ProductDetailsVariant)?.sortedMedia.filter((x) => x.type === MediaType.Image).length,
        contentTitle: trackingEventAction.value
    });
}

function trackRemoveFavoritePDP() {
    trackingUtils.favourites.trackRemoveFromFavoritesPdp({
        category: props.details.tracking?.categoryIds[0] || '',
        contentTitle: trackingEventAction.value
    });
}

function trackAddFavoritePLP() {
    if (!props.trackingObject) return;

    trackingUtils.favourites.trackAddToWishlist(
        props.trackingObject.trackingEventProduct,
        props.trackingObject.trackingList,
        props.trackingObject.trackingEventVariantSku,
        props.trackingObject.trackingEventIndex,
        // the last two is for GA, btw
        props.trackingObject.trackingEventAction || spaStore.metadata.navigationTitle,
        props.trackingObject.trackingEventLabel || undefined
    );
}

function trackRemoveFavoritePLP() {
    if (!props.trackingObject) return;

    trackingUtils.favourites.trackRemoveFromWishlist(
        props.trackingObject.favoriteType,
        props.trackingObject.trackingEventProduct, // undefined for non-products
        props.trackingObject.trackingList,
        props.trackingObject.trackingEventAction || spaStore.metadata.navigationTitle,
        props.trackingObject.trackingEventLabel || undefined
    );
}

watch(lottieInstance, (lottieInstance) => {
    if (!lottieInstance) {
        return;
    }
    const dotLottie = lottieInstance.getDotLottieInstance();
    
    if(!dotLottie) return;
    
    dotLottie.addEventListener('complete', () => {
        addingToFavorite.value = false;
    });
});

onBeforeUnmount(() => {
    lottieInstance.value = null;
});
</script>

<style lang="less">
.tooltip.c-product-details-favorite-toggle__login-popover {
    .v-popper__wrapper {
        .v-popper__arrow-container {
            display: none;
        }
    }
}

@screen md {
    .tooltip.c-product-details-favorite-toggle__login-popover  {
        .v-popper__wrapper {
            .v-popper__inner {
                right: -1rem;
            }
        }
    }
}
</style>
