<template>
    <div ref="el"
         class="focus-visible:outline-none"
         :class="{'pointer-events-none': pointerEventsNone}">
        <slot/>
    </div>
</template>

<script setup lang="ts">
import Flickity from 'flickity';
import 'flickity-imagesloaded';
import { ref, onMounted, onBeforeUnmount, readonly } from 'vue';

const props = withDefaults(defineProps<{
  options?: Flickity.Options;
  handleSelect?: boolean;
  scrollWheel?: boolean;
}>(), {
    options: () => readonly({}),
    handleSelect: true,
    scrollWheel: false,
});

const emit = defineEmits(['select', 'drag-move', 'drag-end', 'init']);

const $flickity = ref<Flickity | null>(null);
const lastSelectedCellIndex = ref(-1);
const pointerEventsNone = ref(false);

const el = ref<HTMLElement | null>(null);

onMounted(() => {
    init();
});

onBeforeUnmount(() => {
    // Hackity-hack. If Flickity is part of something being animated out, 'destroyed' will be called
    // _before_ transition ends and so the flickity component falls apart.
    // https://github.com/vuejs/vue/issues/6983
    setTimeout(() => {
        $flickity.value?.destroy();
        $flickity.value = null;
    }, 5000);
});

/**
 * Initialize a new flickity and emit init event.
 */
function init() {
    $flickity.value = new Flickity(el.value!, { imagesLoaded: false, ...(props.options || {}) });

    if (props.handleSelect) {
    // Emit select event when selection changes (arrows) or when clicked.
        $flickity.value?.on('select', (cellIndex: number) => {
            // Flickity is sometimes spamming with select calls. Only send on change.
            if (lastSelectedCellIndex.value !== cellIndex) {
                lastSelectedCellIndex.value = cellIndex;
                emit('select', cellIndex);
            }

            // Flickity sets aria-hidden="true" on all slides except the current one. 
            // avoid this.
            removeAriaHiddenAttributes();
        });
    }

    if (props.scrollWheel) {
        (el.value as HTMLElement).onwheel = (e) => onScrollHandler(e);
    }

    handlePointerEventsOnDrag();

    //also on init
    removeAriaHiddenAttributes();

    emit('init', $flickity.value);
}

function removeAriaHiddenAttributes() {
    // Flickity sets aria-hidden="true" on all slides except the current one. 
    // avoid this.
    $flickity.value?.cells.forEach(cell => {
        (cell as any).element.removeAttribute('aria-hidden');
    });
}
    

function handlePointerEventsOnDrag() {
    // Prevent potential navigation on dragging elements with links,
    // handle globally instead of locally
    $flickity.value?.on('dragMove', () => {
        setPointerEventsValue(true);
        emit('drag-move');
    });

    $flickity.value?.on('dragEnd', () => {
        setPointerEventsValue(false);
        emit('drag-end');
    });
}

function setPointerEventsValue(val: boolean) {
    pointerEventsNone.value = val;
}

function onScrollHandler(e: WheelEvent) {
    e.preventDefault();
    const direction = Math.abs(e.deltaX) > Math.abs(e.deltaY) ? e.deltaX : e.deltaY;

    if (direction > 0) {
    // next slide
        next(false);
    } else {
    // prev slide
        previous(false);
    }
}

/**
 * Reinitialize (e.g. on new collection of children)
 */
function reinit() {
    destroy();
    init();
}

/**
 * Return the current flickity instance to access directly
 *
 * @return {Flickity}
 */
function flickity() {
    return $flickity.value;
}

/**
 * Select a slide
 *
 * @param {number} index
 * @param {boolean} isWrapped
 * @param {boolean} isInstant
 */
function select(index: number, isWrapped?: boolean, isInstant?: boolean) {
    $flickity.value?.select(index, isWrapped, isInstant);
}

/**
 * Change to the next slide
 *
 * @param {boolean} isWrapped
 * @param {boolean} isInstant
 */
function next(isWrapped?: boolean) {
    $flickity.value?.next(isWrapped);
}

/**
 * Change to the previous slide
 *
 * @param {boolean} isWrapped
 * @param {boolean} isInstant
 */
function previous(isWrapped?: boolean) {
    $flickity.value?.previous(isWrapped);
}

/**
 * Select a cell
 *
 * @param {number} value
 * @param {boolean} isWrapped
 * @param {boolean} isInstant
 */
function selectCell(value: number, isWrapped?: boolean, isInstant?: boolean) {
    $flickity.value?.selectCell(value, isWrapped, isInstant);
}

/**
 * Trigger a resize event
 */
function resize() {
    $flickity.value?.resize();
}

/**
 * Trigger a reposition event
 */
function reposition() {
    $flickity.value?.reposition();
}

/**
 * Prepend elements to flickity
 *
 * @param {Array<HTMLElement>|HTMLElement|NodeList} elements
 */
function prepend(elements: HTMLElement[] | HTMLElement | NodeList) {
    $flickity.value?.prepend(elements);
}

/**
 * Append elements to flickity
 *
 * @param {Array<HTMLElement>|HTMLElement|NodeList} elements
 */
function append(elements: HTMLElement[] | HTMLElement | NodeList) {
    $flickity.value?.append(elements);
}

/**
 * Insert elements at a given index
 *
 * @param {Array<HTMLElement>|HTMLElement|NodeList} elements
 * @param {number} index
 */
function insert(elements: HTMLElement[] | HTMLElement | NodeList, index: number) {
    $flickity.value?.insert(elements, index);
}

/**
 * Remove elements from flickity
 *
 * @param {Array<HTMLElement>|HTMLElement|NodeList} elements
 */
function remove(elements: HTMLElement[] | HTMLElement | NodeList) {
    $flickity.value?.remove(elements);
}

/**
 * Trigger a playPlayer event
 */
function playPlayer() {
    $flickity.value?.playPlayer();
}

/**
 * Trigger a stopPlayer event
 */
function stopPlayer() {
    $flickity.value?.stopPlayer();
}

/**
 * Trigger a pausePlayer event
 */
function pausePlayer() {
    $flickity.value?.pausePlayer();
}

/**
 * Trigger a unpausePlayer event
 */
function unpausePlayer() {
    $flickity.value?.unpausePlayer();
}

/**
 * Trigger a rerender event
 */
function rerender() {
    $flickity.value?.destroy();
    init();
}

/**
 * Destroy the flickity instance
 */
function destroy() {
    $flickity.value?.destroy();
}

/**
 * Trigger a rerender event
 */
function reloadCells() {
    $flickity.value?.reloadCells();
}

/**
 * Get the cell elements
 *
 * @return {Array}
 */
function getCellElements() {
    return $flickity.value?.getCellElements();
}

/**
 * Return flickity data
 *
 * @return {Flickity}
 */
function flickityData() {
    return Flickity.data(el.value!);
}

/**
 * Attach an event
 *
 * @param {string} eventName
 * @param {function} listener
 */
function on(eventName: keyof Flickity.EventBindings , listener: (...args: any[]) => void) {
    $flickity.value?.on(eventName, listener);
}

/**
 * Remove an event
 *
 * @param {string} eventName
 * @param {function} listener
 */
function off(eventName: keyof Flickity.EventBindings, listener: (...args: any[]) => void) {
    $flickity.value?.off(eventName, listener);
}

/**
 * Attach an event once
 *
 * @param {string} eventName
 * @param {function} listener
 */
function once(eventName: keyof Flickity.EventBindings, listener: (...args: any[]) => void) {
    $flickity.value?.once(eventName, listener);
}

/**
 * Return the selected element
 *
 * @return {HTMLElement}
 */
function selectedElement() {
    return $flickity.value?.selectedElement;
}

/**
 * Return the selected elements
 *
 * @return {Array}
 */
function selectedElements() {
    return $flickity.value?.selectedElements;
}

/**
 * Return the selected index
 *
 * @return {number}
 */
function selectedIndex() {
    return $flickity.value?.selectedIndex;
}

/**
 * Return the cells
 *
 * @return {Array}
 */
function cells() {
    return $flickity.value?.cells;
}

/**
 * Return the slides
 *
 * @return {Array}
 */
function slides() {
    return $flickity.value?.slides;
}

defineExpose({
    flickity,
    select,
    next,
    previous,
    selectCell,
    resize,
    reposition,
    prepend,
    append,
    insert,
    remove,
    playPlayer,
    stopPlayer,
    pausePlayer,
    unpausePlayer,
    rerender,
    destroy,
    reloadCells,
    getCellElements,
    flickityData,
    on,
    off,
    once,
    selectedElement,
    selectedElements,
    selectedIndex,
    cells,
    slides,
    reinit,
});

</script>