<template>
    <div
        class="c-overlay-header border-b gap-16 border-sand-30 px-24 py-20 md:py-32"
        :class="{ 'c-overlay-header--basket': isBasket }">
        <div
            class="c-overlay-header__title flex items-center text-14 font-brandon-medium uppercase leading-16"
            :class="{ 'text-center md:text-left': !isBasket }">
            <slot/>
        </div>
        <div v-if="showClose"
             class="c-overlay-header__right flex items-center justify-end">
            <button :aria-label="$translate('generic.Close')"
                    @click="onClose">
                <c-icon name="close"
                        width="16"/>
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        showClose?: boolean;
        isBasket?: boolean;
        vetoClose?:() => Promise<boolean>;
    }>(),
    {
        showClose: true,
        isBasket: false,
        vetoClose: () => Promise.resolve(false)
    }
);

const emit = defineEmits(['close']);

const onClose = () => {
    props.vetoClose().then((doVetoClose) => {
        if (!doVetoClose) {
            emit('close');
        }
    });
};
</script>

<style>
.c-overlay-header {
    @apply gap-16;

    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: '. overlay-title overlay-right';
}

.c-overlay-header--basket {
    grid-template-columns: 1fr auto;
    grid-template-areas: 'overlay-title overlay-right';
}

.c-overlay-header__title {
    grid-area: overlay-title;
}

.c-overlay-header__right {
    grid-area: overlay-right;
}

@screen md {
    .c-overlay-header {
        grid-template-columns: 1fr auto;
        grid-template-areas: 'overlay-title overlay-right';
    }
}
</style>
