import axios, { AxiosResponse } from 'axios';
import bus from '../bus';

import { ClientMessage } from '@/store/messages.store';

function validationResponseInterceptor(response: AxiosResponse) {
    if (response.data && response.data.clientMessage) {
        // Validation-messages received. If a messagesId is included (from a post) forward messages to local handler
        // If no messagesId or no one wanted to handle show globally.
        const messagesId: string | undefined = response.config ? (response.config as any).messagesId : undefined;
        let isHandled = false;
        const messages = response.data.clientMessage.messages;
        if (messagesId) {
            bus.emit(ValidationErrorEventKey, {
                messagesId,
                messages: messages,
                handled: () => (isHandled = true)
            } as ValidationErrorEvent);
        }

        if (!isHandled) {
            // addApiMessages(messages);
        }
    }
    return response;
}
axios.interceptors.response.use(validationResponseInterceptor);

export const ValidationErrorEventKey = 'ValidationError';

export interface ValidationErrorEvent {
    messagesId: string;
    messages: ClientMessage[];
    handled: () => void;
}
