import {
    disableBodyScroll as bslDisableBodyScroll,
    enableBodyScroll as bslEnableBodyScroll,
    clearAllBodyScrollLocks as bslClearAllBodyScrollLocks
} from 'body-scroll-lock';
import type { BodyScrollOptions } from 'body-scroll-lock';
import bus from '@/core/bus';

export const BODY_SCROLL_LOCKED = 'BodyScrollLocked';

class ScrollService {
    bodyLocks = 0;

    public scrollToTop() {
        window.scrollTo(0, 0);
    }

    public scrollInToView(element: HTMLElement | Element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }

    public scrollToElement(
        element: HTMLElement,
        offset: number = 0,
        duration: number = 200,
        scrollElement: HTMLElement | undefined = undefined
    ) {
        duration === 0
            ? this.simpleScroll(element.offsetTop + offset, scrollElement)
            : this.animateScroll(element.offsetTop + offset, duration, scrollElement);
    }

    public disableBodyScroll(targetElement: HTMLElement, options: BodyScrollOptions = {}): void {
        if (!targetElement) {
            throw new Error('disableBodyScroll: targetElement is null');
        }

        const defaultOptions = {
            reserveScrollBarGap: true
        };

        const finalOptions = {
            ...defaultOptions,
            ...options
        };

        const { reserveScrollBarGap } = finalOptions;

        if (this.bodyLocks === 0) {
            const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;
            setTimeout(() => bus.emit(BODY_SCROLL_LOCKED, reserveScrollBarGap ? scrollBarGap : 0));
        }

        bslDisableBodyScroll(targetElement, finalOptions);
        this.bodyLocks++;
    }

    public enableBodyScroll(targetElement: HTMLElement): void {
        if (!targetElement) {
            throw new Error('enableBodyScroll: targetElement is null');
        }

        if (this.bodyLocks === 0) {
            return;
        }

        this.bodyLocks--;

        if (this.bodyLocks === 0) {
            setTimeout(() => bus.emit(BODY_SCROLL_LOCKED, 0));
        }

        bslEnableBodyScroll(targetElement);
    }

    public clearAllBodyScrollLocks(): void {
        this.bodyLocks = 0;
        setTimeout(() => bus.emit(BODY_SCROLL_LOCKED, 0));
        bslClearAllBodyScrollLocks();
    }

    public simpleScroll(to: number, scrollElement: HTMLElement | undefined = undefined) {
        if (scrollElement) {
            scrollElement.scrollTop = to;
        } else {
            window.scrollTo(0, to);
        }
    }

    public animateScroll(to, duration, scrollElement: HTMLElement | undefined = undefined) {
        const startingY = scrollElement ? scrollElement.scrollTop : window.pageYOffset;
        const diff = to - startingY;
        let start;
        window.requestAnimationFrame(function step(timestamp) {
            if (!start) {
                start = timestamp;
            }
            const time = timestamp - start;
            const percent = Math.min(time / duration, 1);

            if (scrollElement) {
                scrollElement.scrollTop = startingY + diff * percent;
            } else {
                window.scrollTo(0, startingY + diff * percent);
            }

            if (time < duration) {
                window.requestAnimationFrame(step);
            }
        });
    }
}
// t = current time
// b = start value
// c = change in value
// d = duration
(Math as any).easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) {
        return (c / 2) * t * t + b;
    }
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
};

export default new ScrollService();
