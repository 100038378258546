import { App } from 'vue';

const detectOutsideClick = {
    beforeMount(el, binding) {
        el.clickOutsideEvent = function(event){
            if (!(el === event.target || el.contains(event.target)) && typeof binding?.value === 'function') {
                binding?.value(event);
            }
        };
        document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
    },
};

export default function vClickOutsidePlugin(app: App): void {
    app.directive('click-outside', detectOutsideClick);
}
