<template>
    <div>
        <div>
            <div class="mb-12 flex justify-between font-gibson-light leading-20"
                 :class="{ 'mb-16:': !isStorePickup }">
                <div>
                    <p class="font-gibson-regular">
                        <slot name="store-name"></slot>
                    </p>
                    <slot name="store-address"></slot>
                </div>
                <div>
                    <template v-if="showIsOpen">
                        <slot name="open-state"></slot>
                    </template>
                    <p v-if="!showIsOpen && showDistance"
                       class="text-right font-gibson-regular">
                        {{ formattedDistance }}
                    </p>
                    <div class="flex items-center justify-between pb-12 pt-8 font-gibson-light leading-20">
                        <button
                            v-if="!showIsOpen && !isSelected"
                            type="button"
                            class="c-btn c-btn--new-primary"
                            @click="select">
                            <span class="c-btn__text c-btn__text--new">{{ $translate('checkout.Droppoint.Select') }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isSelected && isDropPoint(store)"
             class="pt-8">
            <div class="font-gibson-regular leading-20">
                {{ $translate('checkout.Droppoint.OpeningHours') }}
            </div>
            <div v-if="store.simpleOpeningHours"
                 class="flex items-start">
                <SimpleOpeningHours :opening-hours="store.simpleOpeningHours"/>
            </div>
            <div v-else
                 class="flex items-start">
                <OpeningHours :opening-hours="store.openingHours"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { DropPoint, v4 } from '@/types/serverContract';
import OpeningHours from '@/project/checkout/OpeningHours.vue';
import SimpleOpeningHours from '@/project/checkout/SimpleOpeningHours.vue';

const props = defineProps<{
    store: v4.Stores.StoreWithInventory | DropPoint;
    isSelected: boolean;
    showDistance: boolean;
    isStorePickup: boolean;
    showIsOpen?: boolean;
}>();

const emit = defineEmits(['selected']);

const select = () => {
    emit('selected');
};

const isDropPoint = (store: v4.Stores.StoreWithInventory | DropPoint): store is DropPoint => {
    return 'opening-hours' in store;
};

const formattedDistance = computed(() => {
    // DropPoint has a `distanceFormatted` property, use it if available
    if ('distanceFormatted' in props.store) {
        return props.store.distanceFormatted;
    }

    // distance example: 0.754
    const { distance } = props.store;

    if (!distance) return;

    // if distance is below 1km, show distance in meters
    if (distance < 1) {
        return `${Math.round(distance * 1000)} m`;
    }

    // if distance is above 1km, show distance in km
    if (distance >= 1) {
        return `${distance.toFixed(1)} km`;
    }

    return '';
});
</script>
