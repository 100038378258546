import { App } from 'vue';
import viewportEvents, { Viewport } from '@/core/responsive/viewport/viewportEvents.service';
import { DirectiveBinding } from 'vue';

let element: HTMLElement;
let oldScrollY: number = 0;
let oldTimestamp: number;
let scrolledClass: string;
let scrollUpClass: string;
let scrollDownClass: string;

let scrolledClassSet = false;
let scrollUpClassSet = false;
let scrollDownClassSet = false;
let existingClasses = '';

function callback(viewport: Viewport, timestamp: number) {
    const maxScrollTop = element.scrollHeight - viewport.height;
    // Clamp to real scrollarea. Mobile browsers allowes 'elastic' scrolling outsite which screws up with our
    // direction logic
    const scrollY = Math.min(Math.max(viewport.scrollY, 0), maxScrollTop);
    if (scrollY === oldScrollY) return;

    handleScrollY(scrollY, timestamp);
    oldScrollY = scrollY;
    oldTimestamp = timestamp;
}

function handleScrollY(scrollY: number, timestamp: number) {
    // Sets class depending on whether we are at pos 0 (after scroll down) or greater.
    scrolledClassSet = !!scrolledClass && scrollY >= 1;

    // Sets classes depending on scroll-direction. An hysterisis applied
    if (scrollY > oldScrollY && scrollY > 20) {
        scrollDownClassSet = !!scrollDownClass;
        scrollUpClassSet = false;
    } else {
        const velocity = Math.abs((scrollY - oldScrollY) / (timestamp - oldTimestamp));
        if (scrollY < 20 || velocity > 0.8) {
            scrollDownClassSet = false;
            scrollUpClassSet = !!scrollUpClass;
        }
    }

    // Apply upon changes
    const currentClasses: string[] = [];
    if (scrolledClassSet) currentClasses.push(scrolledClass);
    if (scrollDownClassSet) currentClasses.push(scrollDownClass);
    if (scrollUpClassSet) currentClasses.push(scrollUpClass);

    const currentClassesFormatted = currentClasses.join(' ');
    if (currentClassesFormatted !== existingClasses) {
        element.classList.remove(...[scrolledClass, scrollDownClass, scrollUpClass]);
        element.classList.add(...currentClasses);
        existingClasses = currentClassesFormatted;
    }
}

const scrollTriggerDirective = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        element = el;
        scrolledClass = binding.value.scrolledClass;
        scrollUpClass = binding.value.scrollUpClass;
        scrollDownClass = binding.value.scrollDownClass;
        viewportEvents.setCallback(callback.bind(this));
    },
    unmounted() {
        viewportEvents.removeCallback(callback);
    }
};

export default function config(app: App) {
    app.directive('scrollTriggerDirective', scrollTriggerDirective);
}
