import { ref } from 'vue';
import trackingUtils from '@/project/tracking/tracking.utils';

interface TrackingContent {
    trackingName: string;
    title?: string;
    subTitle?: string;
    supplementaryTitle?: string;
    text?: string;
    position?: number;
}

export function usePromotionTracking<T extends TrackingContent>(content: T, componentName: string, _trackingListId: string | number | null = null, _trackingPositionId: number | null = null) {
    const trackingListId = ref(_trackingListId);
    const trackingPositionId = ref(_trackingPositionId);

    const trackPromotionClick = (e: Event, creativeText: string | undefined = undefined) => {
        try {
            trackingUtils.promotion.trackPromotionClick({
                componentName: componentName || '',
                trackingName: content.trackingName || '',
                trackingTitle: content.title || content.subTitle || content.supplementaryTitle || content.text,
                creativeText: creativeText || (e.target as HTMLElement)?.innerText || (e.target as HTMLElement)?.title || (e.target as HTMLElement)?.ariaLabel || (e.target as any)?.alt,
                position: content.position || trackingPositionId.value || 0
            });
        } catch (error) {
            console.log('failed', error);
        }
    };

    return {
        trackingListId,
        trackingPositionId,
        trackPromotionClick
    };
}