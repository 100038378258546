import { App, defineAsyncComponent } from 'vue';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import ResponsiveIconWrap from '@/project/shared/ResponsiveIconWrap.vue';
import BlockRender from '@/project/shared/BlockRender.vue';
import UmbracoText from '@/project/shared/UmbracoText.vue';
import '../spa/pages';
import blocksConfig from '../spa/blocks';
import productComponentRegistartion from '../product';
import HorizontalLine from '@/project/shared/horizontalLine.vue';
import TextInputNoValidation from '@/project/shared/form/TextInputNoValidation.vue';
import CheckboxCtrl from '@/project/shared/form/CheckboxCtrl.vue';
import TextInput from '@/project/shared/form/TextInput.vue';
import RadioButtonCtrl from '@/project/shared/form/RadioButtonCtrl.vue';
import PhonePrefixSelect from '@/project/shared/form/PhonePrefixSelect.vue';
import CountrySelect from '@/project/shared/form/CountrySelect.vue';
import BirthDayInput from '@/project/shared/form/BirthDayInput.vue';
import CheckboxSwitch from '@/project/shared/form/CheckboxSwitch.vue';
import Form from '@/core/form/Form.vue';
import { DotLottieVue } from '@lottiefiles/dotlottie-vue';

const EmailSuggest = defineAsyncComponent(() => import('@/project/login/EmailSuggest.vue'));
const LoginOverlay = defineAsyncComponent(() => import('@/project/login/LoginOverlay.vue'));

// Global components that we want to use on multiple pages
export default function componentRegistration(app: App): void {
    productComponentRegistartion(app);
    app.component('ResponsiveImage', ResponsiveImage);
    app.component('ResponsiveIconWrap', ResponsiveIconWrap);
    app.component('BlockRender', BlockRender);
    app.component('CheckboxCtrl', CheckboxCtrl);
    app.component('TextInput', TextInput);
    app.component('TextInputNoValidation', TextInputNoValidation);
    app.component('RadioButtonCtrl', RadioButtonCtrl);
    app.component('PhonePrefixSelect', PhonePrefixSelect);
    app.component('CountrySelect', CountrySelect);
    app.component('BirthDayInput', BirthDayInput);
    app.component('EmailSuggest', EmailSuggest);
    app.component('LoginOverlay', LoginOverlay);
    app.component('HorizontalLine', HorizontalLine);
    app.component('CheckboxSwitch', CheckboxSwitch);
    app.component('UmbracoText', UmbracoText);
    app.component('DotLottieVue', DotLottieVue);
    blocksConfig(app);
    // eslint-disable-next-line vue/no-reserved-component-names
    app.component('Form', Form);
}
