<template>
    <div>
        <transition tag="div"
                    mode="in-out"
                    enter-active-class="animated fade-cart u-anim-dur-300">
            <c-icon
                v-if="showAnimation"
                key="cart-fill"
                name="tile-cart-fill"
                width="24"
                class="absolute z-2 top-0 right-0"/>
            <c-icon
                v-else
                key="cart-empty"
                name="tile-cart-empty"
                width="24"
                class="absolute z-2 top-0 right-0"/>
        </transition>
        <transition tag="div"
                    :mode="showAnimation ? 'in-out' : 'out-in'"
                    enter-active-class="animated fade-cart u-anim-dur-300"
                    leave-active-class="animated fade-cart-icon-out">
            <c-icon
                v-if="showAnimation"
                key="cart-checkmark"
                name="tile-cart-checkmark"
                width="11"
                height="11"
                original
                class="absolute z-2 bottom-0 right-0 -mb-2"
                :class="fillColorCheckmarkTheme"/>
            <c-icon
                v-else
                key="cart-plus"
                name="tile-cart-plus"
                width="11"
                height="11"
                original
                class="absolute z-2 bottom-0 right-0 -mb-2"
                :class="fillColorTheme"/>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
        showAnimation?: boolean;
        textColor?: string;
    }>(), {
    showAnimation: false,
    textColor: undefined
});

const fillColorTheme = computed(() => {
    return props.textColor === 'theme-2' ? 'icon-plus-icon-theme icon-plus-bg-theme' : '';
});

const fillColorCheckmarkTheme = computed(() => {
    return props.textColor === 'theme-2' ? 'icon-checkmark-icon-theme icon-checkmark-bg-theme' : '';
});
</script>

<style>
@keyframes fadeCart {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeCartOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fade-cart {
    animation-name: fadeCart;
    animation-timing-function: ease-in;
}

.fade-cart-icon-out {
    animation-name: fadeCartOut;
    animation-duration: 150ms;
    animation-delay: 300ms;
    animation-timing-function: ease-out;
}

.icon-plus-icon-theme {
    path[pid="1"] {
        fill: #504139;
    }
}

.icon-plus-bg-theme {
    path[pid="0"] {
        fill: #FFFEF8;
    }
}

.icon-checkmark-icon-theme {
    path[pid="1"] {
        fill: #FFFEF8;
    }
}

.icon-checkmark-bg-theme {
    path[pid="0"] {
        fill: #504139;
    }
}
</style>
