import { App } from 'vue';
import serverContext from '@/core/serverContext.service';

function numberFilter(number: number | string, maxDigits: number = 0, minDigits: number = 0): string {
    const culture = serverContext.culture || 'da-DK';
    const theNumber = typeof number === 'string' ? parseFloat(number) : number;
    if (isNaN(theNumber)) throw new Error(number + ' is not a valid number');

    const numberFormatter = Intl.NumberFormat(culture, {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits
    });
    const fixedFormattedNumber = numberFormatter.format(theNumber);
    return fixedFormattedNumber;
}

export function initNumberFilter(app: App) {
    app.config.globalProperties.$uppercase = numberFilter;
}

export default numberFilter;
