<template>
    <div class="page"
         :class="{'is-changing-page': isChangingPage}">
        <component :is="resolvedPage"
                   v-if="resolvedPage"
                   :content="content"
                   :alias="alias"/>
    </div>
</template>

<script setup lang="ts">
/*
The page is not replaced until animation out has been active for a while -
so it won't show old page if the page is received very fast from server.
Also - no matter how fast a new page loads, we will give the animation-out and animation-in all the time they need.
 */
import { ref, computed, watch, onMounted, nextTick, provide, reactive } from 'vue';
import { pageResolver } from '@/core/spa/componentResolver.service';
import spaStore from '@/core/spa/store/spa.store';
import { JsonContent } from '@/types/serverContract';
import { SpaPageRenderedEventKey } from '@/core/spa/router';
import bus from '@/core/bus';
import { IProvideInjectTrackingData, trackingDataSymbol } from '@/types/frontend';

const pageDataState = ref<'done' | 'awaitingNewPage' | 'newPageReceived'>('done');
const timerState = ref<'done' | 'awaitingAnimationOut' | 'awaitingMinimumAnimationTime'>('done');
const localJsonContent = ref<JsonContent | null>(null);

onMounted(() => {
    renderNewPage();
});

const resolvedPage = computed(() => {
    return alias.value ? pageResolver.resolve(alias.value) : null;
});

const alias = computed(() => {
    return localJsonContent.value ? localJsonContent.value.alias : null;
});

const content = computed(() => {
    return localJsonContent.value ? localJsonContent.value.content : null;
});

const trackingData = reactive<IProvideInjectTrackingData>({
    trackingName: undefined,
    trackingTitle: undefined,
    updateTrackingListProvider: (value: string) => {
        trackingData.trackingListProvider = value;
    },
    updateTrackingSystem(value: string) {
        trackingData.trackingSystem = value;
    },
    updateTrackingTitle(value: string) {
        trackingData.trackingTitle = value;
    },
    updateBlockName: (value: string) => {
        trackingData.blockName = value;
    },
});

provide(trackingDataSymbol, trackingData);

watch(alias, (newAlias) => {
    if (newAlias) {
        trackingData.trackingName = newAlias;
        trackingData.blockName = '';
        trackingData.trackingTitle = '';
        trackingData.trackingSystem = '';
    }
});

const isFetchingSpaPage = computed(() => {
    return spaStore.isLoadingSpaPage;
});

const spaStoreJsonContent = computed(() => {
    return spaStore.jsonContent;
});

const isChangingPage = computed(() => {
    return timerState.value !== 'done' || pageDataState.value !== 'done';
});

watch(isFetchingSpaPage, (isFetching) => {
    if (isFetching) {
        pageDataState.value = 'awaitingNewPage';
        timerState.value = 'awaitingAnimationOut';

        setTimeout(() => animationOutFired(), 200); // Delay before we replace page (if new page ready).
        setTimeout(() => minimumAnimationTimeFired(), 300); // Animation out + animation in time.
    }
});

function animationOutFired() {
    timerState.value = 'awaitingMinimumAnimationTime';
    if (pageDataState.value === 'newPageReceived') {
        renderNewPage();
    }
}

watch(spaStoreJsonContent, () => {
    pageDataState.value = 'newPageReceived';
    if (timerState.value === 'awaitingMinimumAnimationTime' || timerState.value === 'done') {
        renderNewPage();
    }
});

function renderNewPage() {
    localJsonContent.value = spaStoreJsonContent.value;
    nextTick(() => {
        setPageReady();
    });
}

function setPageReady() {
    setTimeout(() => {
        // Page should be rendered.
        pageDataState.value = 'done';
    }, 0);
    // Fire event for router (scrolling).
    bus.emit(SpaPageRenderedEventKey);
}

function minimumAnimationTimeFired() {
    timerState.value = 'done';
}
</script>

<style lang="less" scoped>
    @import (less, reference) '../../../node_modules/animate.css/animate.css';

    .page {
        .animated();
        .fadeIn();
        animation-duration: 250ms;

        &.is-changing-page {
            .fadeOut();
        }
    }
</style>
