<template>
    <component :is="tag"
               v-if="text !== undefined"
               :class="[customClasses , { 'wysiwyg': wysiwyg }]"
               v-html="sanitizedText"/>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import sanitizeUmbracoText from '@/core/sanitize.service';

const props = withDefaults(defineProps<{
    text?: string;
    tag?: string;
    wysiwyg?: boolean;
    customClasses?: string;
}>(), {
    text: '',
    tag: 'span',
    wysiwyg: false,
    customClasses: ''
});

const sanitizedText = computed(() => {
    if (props.wysiwyg) {
        // TODO: sanitize HTML
        return props.text;
    } else {
        return sanitizeUmbracoText(props.text);
    }
});
</script>
