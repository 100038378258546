import axios, { AxiosResponse } from 'axios';
import basketStore from '@/store/basket.store';
import { mergeWithProductsOfNewType } from '@/project/http/api';

async function basketInterceptor(response: AxiosResponse) {
    if (response.data && Object.prototype.hasOwnProperty.call(response.data, 'lines')) {
        const doesUpdateAffectShippingPrice: boolean = (response?.request?.responseURL?.match(/\/update$/) ?? false);
        // TODO - Remove this when we have BasketLine containing v4.Products.ProductSimple's. Also make function non-async again.
        const basket = await mergeWithProductsOfNewType(response.data);
        basketStore.updateBasket(basket, doesUpdateAffectShippingPrice);
    }
    return response;
}
axios.interceptors.response.use(basketInterceptor);
