export enum CheckoutPageStepIndexes {
    Basket = 1,
    StartInvoiceAddress,
    Delivery,
    Payment
}

export interface ICheckoutStep {
    label: string;
    icon: string;
}
