import { v4 } from '@/types/serverContract';
import Api from '@/project/http/api';
import { reactive } from 'vue';
import makeStateDebugAccessible from '@/store/stateDebug';
import every from 'lodash-es/every';
import debounce from 'lodash-es/debounce';
import bus from '@/core/bus';
import { SpaPageRenderedEventKey } from '@/core/spa/router';
import { ICanAddToCart, updateCanAddToBasketState } from '@/project/product/productHelper.utils';
import serverContext from '@/core/serverContext.service';

interface IProductsState {
    products: { [productId: string]: v4.Products.ProductSimple | null };
    pageHasProducts: boolean;
}

export interface IProductAndInventory {
    inventory: v4.Products.Inventory[] | null;
    product: v4.Products.ProductSimple;
    canAddToBasket: ICanAddToCart[] | null;

}

class ProductStore {
    private debouncedProductIds: Set<string> = new Set<string>();
    private debouncedEnsureProductsAndInventoryLoaded = debounce(this.doEnsureProductsInventoryLoaded, 10);

    public state: IProductsState = reactive({
        products: {},
        pageHasProducts: false,
        inventoryState: {}
    });

    constructor() {
        makeStateDebugAccessible('productsStore', this.state);
        bus.on(SpaPageRenderedEventKey, async() => {
            this.setNewPageLoaded();
        });
    }

    public getProduct(productId: string): v4.Products.ProductSimple | null | undefined {
        return this.state.products[productId.toLowerCase()];
    }

    public getInventory(productId: string): v4.Products.Inventory[] | null | undefined {
        if (!serverContext.hasCheckout) return undefined;
        return this.getInventoryForProduct(this.getProduct(productId));
    }

    public ensureLoaded(productIds: string[]): void {
        this.ensureProductsLoaded(productIds);
    }

    private ensureProductsLoaded(productIds: string[]): void {
        if (!productIds) return;

        productIds
            .filter((productId) => !!productId)
            .forEach((productId) => {
                const isInDebouncedList = this.debouncedProductIds.has(productId.toLowerCase());
                const isInStore = this.getProduct(productId.toLowerCase()) !== undefined;
                if (!isInDebouncedList && !isInStore) {
                    this.debouncedProductIds.add(productId.toLowerCase());
                }
            });
        this.debouncedEnsureProductsAndInventoryLoaded();
    }

    public async loadProducts(productIds: string[], circumventCache = false): Promise<(v4.Products.ProductSimple | undefined | null)[]> {
        if (!circumventCache) {
            const cachedProducts = productIds.map((productId) => this.getProduct(productId.toLowerCase()));
            if (every(cachedProducts, (cachedProduct) => cachedProduct !== undefined)) {
                return cachedProducts;
            }
        }
        const products = await Api.catalog.productList(productIds);
        this.setProducts(productIds, products);
        return products;
    }

    public setProducts(askedFor: string[], products: v4.Products.ProductSimple[]) {
        if (!products) return;
        askedFor.forEach((productId) => {
            const product = products.find((p) => p.id === productId.toLowerCase());
            // Store product or null. null means we did not get a product from server for this id.
            this.state.products[productId.toLowerCase()] = product ? Object.freeze(product) : null;
        });
    }

    private async doEnsureProductsInventoryLoaded(): Promise<void> {
        if (!this.debouncedProductIds.size) return;

        if (!this.state.pageHasProducts) {
            this.state.pageHasProducts = true;
        }
        // Fetch products even though we already have them - they might be updated on server
        const productIdsAsArrays = Array.from(this.debouncedProductIds);
        this.debouncedProductIds = new Set<string>(); // reset array
        await this.loadProductAndInventory(productIdsAsArrays);
    }

    // Load all products and inventories in one go. Only return the ones that has a product
    public async loadProductAndInventory(productIds: string[]): Promise<IProductAndInventory[]> {
        // TODO: add productsIds to pending list and dont trigger load of pending items multiply times (if we trigger loadProductAndInventory from product store.
        const products = await this.loadProducts(productIds);
        return products
            .filter((product) => product !== null)
            .map((product) => {
                return {
                    product,
                    inventory: this.getInventoryForProduct(product),
                    canAddToBasket: this.convertInventoryToCartState(product!)
                } as IProductAndInventory;
            });
    }

    public convertInventoryToCartState(product: v4.Products.ProductSimple): ICanAddToCart[] {
        const states: ICanAddToCart[] = [];
        product.variants.forEach((variant) => {
            states.push(updateCanAddToBasketState(product, variant)); // Because possible null has been filtered away
        });
        return states;
    }

    private getInventoryForProduct(product: v4.Products.ProductSimple | null | undefined): v4.Products.Inventory[] | undefined {
        return product?.variants.map((variant) => (
            {
                ...variant.inventory,
                sku: variant.sku,
                productId: product.id
            })
        );
    }

    public setNewPageLoaded() {
        this.state.pageHasProducts = false;
    }

    public get anyProductsLoadedViaStore(): boolean {
        return this.state.pageHasProducts;
    }
}

export default new ProductStore();
