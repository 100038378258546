<template>
    <TeleportOverlay v-model:show="navigationActiveInternal"
                     wrapper-class="ls:max-w-360 bg-white-100"
                     :show-close-button="false"
                     :disable-body-scroll="true"
                     side="left"
                     @cancelled="navigationActiveInternal = false">
        <div class=" bg-white-100 z-mobile-menu h-full relative font-brandon">
            <transition :enter-active-class="enterAnimationClasses"
                        :leave-active-class="leaveAnimationClasses">
                <div v-if="activePage === 'Root'"
                     key="Root"
                     class="absolute inset-0 flex flex-col">
                    <section
                        class="px-20">
                        <div class="c-mobile-header__off-canvas-header border-b border-brown-50 flex items-center justify-between md:pl-10 mb-10">
                            <div class="c-mobile-header__nav-logo">
                                <cIcon name="logo-collapsed"
                                       class="text-brown-80"/>
                            </div>
                            <button @click="navigationActiveInternal = false">
                                <Breakpoints>
                                    <template #max-ls>
                                        <cIcon name="close"
                                               width="14"
                                               class=""/>
                                    </template>
                                    <template #min-md>
                                        <cIcon name="close"
                                               width="16"
                                               class=""/>
                                    </template>
                                </Breakpoints>
                            </button>
                        </div>
                    </section>
                    <ul v-if="headerData"
                        class="px-20">
                        <template v-for="(link, ix) in headerData.links">
                            <NavigationItem v-if="navigationService.isStandardLink(link.type)"
                                            :key="'standard-link-' + ix"
                                            :text="link.nameAndUrl.name"
                                            :href="link.nameAndUrl.url"/>
                            <NavigationItem v-else-if="navigationService.isProductsLink(link.type)"
                                            :key="'product-link-' + ix"
                                            :text="link.nameAndUrl.name"
                                            :action="selectProducts"
                                            :aria-label="$translate('generic.Open')"/>
                            <NavigationItem v-else-if="navigationService.isRoomsLink(link.type)"
                                            :key="'rooms-link-' + ix"
                                            :text="link.nameAndUrl.name"
                                            :action="selectRooms"
                                            :aria-label="$translate('generic.Open')"/>
                        </template>
                        <NavigationItem v-if="headerData.seasonalLink"
                                        :text="headerData.seasonalLink.name"
                                        :href="headerData.seasonalLink.url"
                                        :image="headerData.seasonalLink.icon"/>
                    </ul>
                    <div v-if="headerData"
                         class="mt-auto bg-white-310">
                        <ul class="px-20 pt-24 pb-24">
                            <NavigationItem
                                v-if="showFavoritesLink"
                                :text="$translate(isLoggedIn ? 'navigation.AfterLogin.Favorites' : 'navigation.BeforeLogin.Favorites')"
                                :href="headerData.favorites.url"
                                footer-link
                                icon-name="heart"/>
                            <NavigationItem
                                v-if="showMyPageLink"
                                :text="userNameText"
                                :href="headerData.myPage.url"
                                footer-link
                                icon-name="user"/>
                            <NavigationItem
                                v-if="showFindStoreLink"
                                :text="$translate(isLoggedIn ? 'navigation.AfterLogin.MyStore' : 'navigation.BeforeLogin.FindStore')"
                                :href="headerData.findStore.url"
                                footer-link
                                icon-name="shop"/>
                        </ul>
                    </div>
                </div>
                <div v-else
                     :key="activePage"
                     class="absolute inset-0">
                    <section
                        class="c-mobile-header__off-canvas-header bg-white-325 flex items-center mb-10">
                        <div class="mx-20 flex items-center justify-between flex-1">
                            <button @click="selectRoot()">
                                <Breakpoints>
                                    <template #max-ls>
                                        <cIcon name="chevron"
                                               width="14px"
                                               class="relative"
                                               style="top:-0.5px"/>
                                    </template>
                                    <template #min-md>
                                        <cIcon name="chevron"
                                               width="16px"
                                               class="relative"
                                               style="top:-0.5px"/>
                                    </template>
                                </Breakpoints>
                            </button>
                            <span class="text-16 leading-22 uppercase font-normal">{{ level2Title }}</span>
                            <button @click="navigationActiveInternal = false">
                                <Breakpoints>
                                    <template #max-ls>
                                        <cIcon name="close"
                                               width="14"
                                               class=""/>
                                    </template>
                                    <template #min-md>
                                        <cIcon name="close"
                                               width="16"
                                               class="relative -top-1"/>
                                    </template>
                                </Breakpoints>
                            </button>
                        </div>
                    </section>
                    <div class="mx-20 pb-20">
                        <template v-if="activePage === 'Products'">
                            <ul>
                                <NavigationItem v-for="(link, ix) in navigationService.getProductsLink()?.subNavigationLinks"
                                                :key="ix"
                                                :text="link.nameAndUrl.name"
                                                :href="link.nameAndUrl.url"
                                                :action="() => navigationActiveInternal = false"/>
                            </ul>
                            <div class="mt-32 mb-12 text-10 font-normal uppercase">
                                {{ $translate('navigation.CampaignsTitle') }}
                            </div>
                            <ul v-if="headerData?.currentTopics"
                                class="flex flex-wrap -mx-12">
                                <li v-for="(topic, ix) in headerData.currentTopics"
                                    :key="ix"
                                    class="w-1/2 px-12">
                                    <topic-navigation :content="topic"
                                                      :image-aspect-ratio="{default: 152/118}"/>
                                </li>
                            </ul>
                            <div class="mt-24 mb-12 text-10 font-normal uppercase">
                                {{ $translate('navigation.InspirationTitle') }}
                            </div>
                            <ul v-if="headerData?.inspirationalTopic">
                                <li>
                                    <div class="mb-10 relative">
                                        <responsive-image
                                            :image-url="headerData.inspirationalTopic.image?.url"
                                            :alt="headerData.inspirationalTopic.title"
                                            :focal-point="headerData.inspirationalTopic.image?.focalPoint"
                                            :width-on-screen="{xs: 100, ls:75, md:50, lg:25}"
                                            :aspect-ratio="{default: 329/144}"/>
                                        <header class="mb-20 mt-8 text-10 font-normal uppercase"
                                                :title="headerData.inspirationalTopic.title">
                                            <span>{{ headerData.inspirationalTopic.title }}</span>
                                        </header>
                                        <router-link-conditional :to="headerData.inspirationalTopic.url"
                                                                 :title="headerData.inspirationalTopic.title"
                                                                 class="absolute inset-0"/>
                                    </div>
                                </li>
                            </ul>
                        </template>
                        <template v-else>
                            <ul class="flex flex-wrap -mx-12 py-20 bg-white-100">
                                <li v-for="(category, ix) in navigationService.getRoomsLink()?.subNavigationLinks"
                                    :key="ix"
                                    class="w-1/2 px-12">
                                    <div class="mb-10 relative">
                                        <responsive-image
                                            :image-url="category.image.url"
                                            :alt="category.nameAndUrl.name"
                                            :focal-point="category.image.focalPoint"
                                            :width-on-screen="10"
                                            :aspect-ratio="{default: 152/118}"/>
                                        <router-link-conditional :to="category.nameAndUrl.url"
                                                                 :title="category.nameAndUrl.name"
                                                                 class="absolute inset-0"/>
                                    </div>
                                    <header class="mb-20 text-10 font-normal uppercase"
                                            :title="category.nameAndUrl.name">
                                        <span>{{ category.nameAndUrl.name }}</span>
                                    </header>
                                </li>
                            </ul>
                        </template>
                    </div>
                </div>
            </transition>
        </div>
    </TeleportOverlay>
</template>

<script setup lang="ts">
import { ref, computed, watch, defineAsyncComponent } from 'vue';
import { HeaderNavigationModel } from '@/types/serverContract';
import navigationService from './navigation.service';
import userStore from '@/store/user.store';
import translateFilter from '@/core/translation/translate.filter';
import useHeaderNavigation from '@/project/layout/page-header/useHeaderNavigation';

const NavigationItem = defineAsyncComponent(() => import('./NavigationItem.vue'));
const TopicNavigation = defineAsyncComponent(() => import('@/project/layout/page-header/TopicNavigation.vue'));

const props = defineProps({
    navigationActive: {
        type: Boolean,
        default: false
    },
    initialSelection: {
        type: String,
        default: 'Root',
        validator: (value: string) => ['Root', 'Products', 'Rooms'].includes(value)
    }
});

const emit = defineEmits(['update:navigation-active']);

const navigationActiveInternal = ref(false);
const activePage = ref('Root');
const level2Title = ref('');

const headerData = computed<HeaderNavigationModel | null>(() => {
    return useHeaderNavigation().headerNavigation.value;
});

const isLoggedIn = computed(() => userStore.isLoggedIn);

const showFavoritesLink = computed(() => {
    return !!(headerData.value?.favorites?.url && headerData.value.favorites?.name);
});

const showMyPageLink = computed(() => {
    return !!(headerData.value?.myPage?.url && headerData.value.myPage?.name);
});

const showFindStoreLink = computed(() => {
    return !!(headerData.value?.findStore?.url && headerData.value.findStore?.name);
});

const userNameText = computed(() => {
    if (!isLoggedIn.value) {
        return translateFilter('navigation.BeforeLogin.MyPage');
    }
    const userInformation = userStore.userInformation;
    if (!userInformation?.invoice.firstName) return undefined;
    return `${userInformation.invoice.firstName} ${userInformation.invoice.lastName}`;
});

watch(() => props.navigationActive, (value) => {
    if (value) {
        switch (props.initialSelection) {
            case 'Products':
                selectProducts();
                break;
            case 'Rooms':
                selectRooms();
                break;
            case 'Root':
                selectRoot();
                break;
        }
    }
    navigationActiveInternal.value = value;
});

watch(() => navigationActiveInternal.value, (value) => {
    emit('update:navigation-active', value);
});

const enterAnimationClasses = computed(() => {
    const animCls = activePage.value !== 'Root' ? 'slideInRight' : 'slideInLeft';
    return `animated ${animCls} u-anim-dur-300`;
});

const leaveAnimationClasses = computed(() => {
    const animCls = activePage.value !== 'Root' ? 'slideOutLeft' : 'slideOutRight';
    return `animated ${animCls} u-anim-dur-300`;
});

const selectRoot = () => {
    activePage.value = 'Root';
};

const selectProducts = () => {
    activePage.value = 'Products';
    level2Title.value = navigationService.getProductsLink()?.nameAndUrl.name ?? '';
};

const selectRooms = () => {
    activePage.value = 'Rooms';
    level2Title.value = navigationService.getRoomsLink()?.nameAndUrl.name ?? '';
};

</script>
