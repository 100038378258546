<template>
    <div class="flex items-center">
        <cIcon v-if="inStock"
               name="checkmark-circle"
               width="22"
               height="22"
               class="mr-8 text-green"/>
        <cIcon v-else-if="fewInStock"
               name="time"
               width="18"
               height="18"
               class="mr-8 text-orange"/>
        <cIcon v-else-if="notInStock"
               name="cross-circle"
               width="22"
               height="22"
               class="mr-8 text-red-600"/>
        <cIcon v-else-if="unSpecified"
               name="questionmark-circle"
               width="18"
               height="18"
               class="mr-8 text-brown-80"/>
        <span class="font-gibson-regular mr-16 text-14 leading-18 text-brown-80 mt-2">
            {{ props.inventory?.description }}
        </span>
    </div>
</template>

<script lang="ts" setup>
import cIcon from '@/project/config/c-icon.vue';
import { v4, StoreInventoryState } from '@/types/serverContract';
import { computed } from 'vue';

const props = defineProps<{
    inventory: v4.Stores.Inventory;
}>();

const inStock = computed(() => {
    return props.inventory?.state === StoreInventoryState.InStock;
});

const fewInStock = computed(() => {
    return props.inventory?.state === StoreInventoryState.FewInStock;
});

const notInStock = computed(() => {
    return props.inventory?.state === StoreInventoryState.NotInStock;
});

const unSpecified = computed(() => {
    return props.inventory?.state === StoreInventoryState.Unspecified;
});

</script>
