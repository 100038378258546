import { App } from 'vue';
import $ from 'cash-dom';
import { DirectiveBinding } from 'vue';

// This directive accepts can be used as v-in-view="{func: yourMethod, className: 'yourOwnClassName'}"
const inViewDirective = {
    beforeMount(el: HTMLElement, binding: DirectiveBinding) {
        if (binding.value && !(typeof binding.value.func === 'function')) {
            throw new Error('Func needs to be a method/function');
        }
        let isClicked: boolean = false;
        const baseClassName: string = binding.value && binding.value.className ? binding.value.className : 'in-view';

        const config = {
            threshold: [0.5]
        };
        const observer: IntersectionObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && entry.intersectionRatio > 0 && !isClicked) {
                    $(entry.target).addClass(baseClassName);
                    if (binding.value) {
                        binding.value.func();
                    }
                    observer.unobserve(entry.target);
                } else {
                    $(entry.target).removeClass(baseClassName);
                }
            });
        }, config);

        el.addEventListener('click', () => {
            if (!isClicked) {
                isClicked = true;
                $(el).removeClass(baseClassName);

                observer.unobserve(el);
            }
        });

        observer.observe(el);
    }
};

export default function config(app: App) {
    app.directive('inViewDirective', inViewDirective);
}
