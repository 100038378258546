<template>
    <div class="page-blind"
         :class="classNames"
         @click="deactivatePageBlind()"/>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
        clickHandler: () => void;
        classNames?: string;
    }>(), {
    classNames: 'absolute inset-0 z-1'
}); 

function deactivatePageBlind() {
    if (props.clickHandler) {
        props.clickHandler();
    }
}
</script>

<style lang="less" scoped>
.page-blind {
    background-color: color-mod(theme('colors.black') a(0.5));
}
</style>
