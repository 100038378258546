import { Address, DayOfWeek, DropPointOpeningHoursInterval } from '@/types/serverContract';

const GoogleApiKey = 'AIzaSyC94I-saeQoi7HAHYr4uU1zJhjTdrmRKX8';
export interface WarehouseOrderAddress extends Omit<Address, 'company' | 'firstName' | 'lastName' | 'title'> {
    // This interface is just for reusability of Address format function
    title?: string;
    company?: string;
    firstName?: string;
    lastName?: string;
}
export default {
    GoogleApiKey: GoogleApiKey,
    NoImageImgSrcBase64: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    warehouses: [
        {
            market: 'DK',
            address: {
                firstName: 'Søstrene Grene Webshop',
                street: 'Gunnar Clausens Vej 20-22',
                postalCode: '8260',
                city: 'Viby J',
                region: 'Østjylland',
                location: { lat: 56.11004, lng: 10.13501 },
                countryCode: 'DK'
            } as WarehouseOrderAddress,
            openingHours: [
                {
                    start: {
                        day: DayOfWeek.Monday,
                        formatted: 'mandag'
                    },
                    end: {
                        day: DayOfWeek.Friday,
                        formatted: 'fredag'
                    },
                    opens: {
                        formatted: '8.00'
                    },
                    closes: {
                        formatted: '16.00'
                    },
                    formatted: {
                        label: 'Mandag - Fredag',
                        hours: '8.00 - 16.00'
                    }
                },
                {
                    start: {
                        day: DayOfWeek.Saturday,
                        formatted: 'lørdag'
                    },
                    end: {
                        day: DayOfWeek.Saturday,
                        formatted: 'lørdag'
                    },
                    formatted: {
                        label: 'Lørdag',
                        hours: 'Lukket'
                    }
                },
                {
                    start: {
                        day: DayOfWeek.Sunday,
                        formatted: 'søndag'
                    },
                    end: {
                        day: DayOfWeek.Sunday,
                        formatted: 'søndag'
                    },
                    formatted: {
                        label: 'Søndag',
                        hours: 'Lukket'
                    }
                }
            ] as Partial<DropPointOpeningHoursInterval>[]
        }
    ]
};

export const OpenEcoChargeKey = 'OpenEcoChargeKey';
export const OpenReceiptPasswordResetKey = 'OpenReceiptPasswordReset';
export const OpenShippingInformationKey = 'OpenShippingInformationKey';
export const OpenVariantPickerKey = 'OpenVariantPickerKey';
export const CloseVariantPickerKey = 'CloseVariantPickerKey';
export const OpenProductSpecificationsKey = 'OpenProductSpecificationsKey';
export const CloseProductSpecificationsKey = 'CloseProductSpecificationsKey';
export const OpenEnergyLabelKey = 'OpenEnergyLabelKey';
export const UserLoadedEventKey = 'UserLoadedEventKey';
export const CookieConsentChangeEventKey = 'CookieConsentChangeEventKey';
export const SlideChangeEventKey = 'SlideChange';
export const NextSlideCommandKey = 'ChangeSlide';
export const PageHeaderResetScrollBehaviour = 'PageHeaderResetScrollBehaviour';
export const PageHeaderStopScrollBehaviour = 'PageHeaderStopScrollBehaviour';
export const PageHeaderToggleHidden = 'PageHeaderToggleHidden';
export const PageHeaderToggleShowAtNextCategory = 'PageHeaderToggleShowAtNextCategory';
export const OpenVariantRemindMeKey = 'OpenVariantRemindMeKey';

export const SCROLL_TO_TOP = 'SCROLL_TO_TOP';
export const FOOTER_IN_VIEW = 'FOOTER_IN_VIEW';
export const TOAST_BUS_KEY = 'TOAST_BUS_KEY';
export const TOAST_MESSAGE_TYPES = ['FAVORITE_ADD', 'FAVORITE_REMOVE', 'ADD_TO_CART', 'REMOVE_FROM_CART'] as const;
export interface ToastMessage {
    type: (typeof TOAST_MESSAGE_TYPES)[number];
    message: string;
}
export class NoLoginAtSite extends Error {}
