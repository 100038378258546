<template>
    <div v-if="show && (publishDate || unPublishDate)"
         class="c-umbraco-publish-state relative text-10">
        <div class="absolute left-0 bottom-0">
            <div v-if="publishDate"
                 class="p-5"
                 style="background-color: #9cff9c"
                 :title="publishDate">
                Publish: {{ publishDateFormated }}
            </div>
            <div v-if="unPublishDate"
                 class="p-5"
                 style="background-color: #ff9c9c"
                 :title="unPublishDate">
                Unublish:  {{ unPublishDateFormated }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
    block: any;
}>();

const isUmbracoAvailabilityDateSet = (date): boolean => {
    return date && (date.indexOf('9999') === -1 && date.indexOf('0001') === -1);
};

const show = computed(() => {
    return props.block && props.block.content && props.block.content.availability;
});

const publishDate = computed(() => {
    return show.value && isUmbracoAvailabilityDateSet(props.block.content.availability.publishAt) ? new Date(props.block.content.availability.publishAt).toString() : null;
});

const publishDateFormated = computed(() => {
    return publishDate.value && publishDate.value.toLocaleString();
});

const unPublishDate = computed(() => {
    return show.value && isUmbracoAvailabilityDateSet(props.block.content.availability.unpublishAt) ? new Date(props.block.content.availability.unpublishAt).toString() : null;
});

const unPublishDateFormated = computed(() => {
    return unPublishDate.value && unPublishDate.value.toLocaleString();
});
</script>

<style lang="less">
    .c-umbraco-publish-state {
        @apply opacity-50;
        cursor: help;
        transition: opacity ease-in-out .2s;
        &:hover {
            @apply opacity-100;
        }
    }
</style>
