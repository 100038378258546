import {
    BreadcrumbItem,
    LanguageAndUrl,
    Media,
    MediaType,
    Metadata,
    EntityData,
    JsonContent,
    File,
    Diy,
    ImageType,
    v4
} from '@/types/serverContract';
import $ from 'cash-dom';
import spaStore from './spa/store/spa.store';
import serverContext from '@/core/serverContext.service';
import urlHelperService from '@/project/facets/urlHelper.service';

class DomService {
    private static VIEWPORT = 'viewport';
    private static TITLE = 'title';
    private static KEYWORDS = 'keywords';
    private static DESCRIPTION = 'description';
    private static ROBOTS = 'robots';
    private static OPENGRAPHTITLE = 'og:title';
    private static OPENGRAPHURL = 'og:url';
    private static OPENGRAPHDESCRIPTION = 'og:description';
    private static OPENGRAPHIMAGE = 'og:image';
    private static OPENGRAPHIMAGEALT = 'og:image:alt';
    private static OPENGRAPHIMAGEWIDTH = 'og:image:width';
    private static OPENGRAPHIMAGEHEIGHT = 'og:image:height';
    private static OPENGRAPHTYPE = 'og:type';
    private static OPENGRAPHPRODUCTPRICE = 'product:price:amount';
    private static OPENGRAPHPRODUCTCURRENCY = 'product:price:currency';

    public updateMetaData(metadata: Metadata, entity: EntityData, jsonContent: JsonContent) {
        this.setCanonicalUrl(metadata.url);
        this.setOpenGraphUrl(metadata.url);
        this.setMetaDescription(metadata.seoDescription);
        this.setOpenGraphDescription(metadata.seoDescription);
        this.setMetaRobots(metadata.index);
        this.setPageTitle(metadata.seoTitle);
        this.setOpenGraphTitle(metadata.seoTitle);
        this.setHreflang(metadata.languages);
        this.setOpenGraphImage(metadata.seoImage);
        this.setOpenGraphForStoreDetails(jsonContent.alias);
        this.setJsonlsForBreadcrumb();
        this.setSiteimproveData(entity);
    }

    public setPageTitle(title: string): void {
        document.title = title;
    }

    public setOpenGraphTitle(title: string) {
        return this.setContent(null, DomService.OPENGRAPHTITLE, title);
    }

    public setMetaDescription(description: string) {
        return this.setContent(DomService.DESCRIPTION, null, description);
    }

    public setOpenGraphDescription(description: string) {
        return this.setContent(null, DomService.OPENGRAPHDESCRIPTION, description);
    }

    public setMetaRobots(index: boolean) {
        const content = index ? '' : 'noindex, follow';
        return this.setContent(DomService.ROBOTS, null, content);
    }

    public setMetaKeywords(keywords: string) {
        return this.setContent(DomService.KEYWORDS, null, keywords);
    }

    public getMetaViewport() {
        return this.getMetaElement(DomService.VIEWPORT).content;
    }

    public updateCheckoutView(isCheckoutView: boolean): void {
        $('html').toggleClass('checkout-view', !!isCheckoutView);
    }

    public setMetaViewport(content: string) {
        return this.setContent(DomService.VIEWPORT, null, content);
    }

    public setCanonicalUrl(canonicalUrl: string): void {
        if (urlHelperService.getPage() > 1 && canonicalUrl.indexOf('?') === -1) {
            // Add page=N params to canonical urls
            const rawLocation = urlHelperService.getLocationForPage(urlHelperService.getPage()) as any;
            const query = rawLocation && rawLocation.query;
            canonicalUrl =
                rawLocation && rawLocation.query
                    ? canonicalUrl + '?' + new URLSearchParams(query).toString()
                    : canonicalUrl;
        }
        const canonicalUrlElement = this.getCanonicalUrlElement();
        canonicalUrlElement.href = this.sanitizeInput(canonicalUrl);
    }

    public setOpenGraphUrl(url: string): void {
        this.setContent(null, DomService.OPENGRAPHURL, this.sanitizeInput(url));
    }

    public setOpenGraphImage(image: Media | null): void {
        if (image) {
            const width = 1200;
            const height = 627;
            const focal = image.focalPoint ? `&rxy=${image.focalPoint.left},${image.focalPoint.top}` : '';
            const imageUrl = `${image.url}?width=${width}&height=${height}${focal}&format=jpg&mode=min&quality=80`;
            this.setContent(null, DomService.OPENGRAPHIMAGE, imageUrl);
            this.setContent(null, DomService.OPENGRAPHIMAGEWIDTH, width.toString());
            this.setContent(null, DomService.OPENGRAPHIMAGEHEIGHT, height.toString());
            this.setContent(null, DomService.OPENGRAPHIMAGEALT, image.name);
        } else {
            const metaTags = $(`meta[property^="${DomService.OPENGRAPHIMAGE}"]`);
            metaTags.each((i, e) => $(e).remove());
        }
    }

    public setOpenGraphForStoreDetails(alias: string): void {
        this.clearUnusedOpenGraphTags(null);
        if (alias === 'storeDetails') {
            this.setContent(null, DomService.OPENGRAPHTYPE, 'business.business');
        }
    }

    public setOpenGraphProduct(product: v4.Products.ProductDetails, variant: v4.Products.ProductDetailsVariant) {
        this.clearUnusedOpenGraphTags('product');
        const numberFormatter = Intl.NumberFormat('en-gb', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
        const fixedFormattedNumber = numberFormatter.format(variant?.pricing?.unit?.amount);
        this.setContent(null, DomService.OPENGRAPHPRODUCTPRICE, fixedFormattedNumber);
        this.setContent(null, DomService.OPENGRAPHPRODUCTCURRENCY, serverContext.currencyCode);
        this.setContent(null, DomService.OPENGRAPHTYPE, 'product.item');
        const images = variant?.sortedMedia;
        if (images.length) {
            this.setOpenGraphImage({
                url: images[0].url,
                name: product.name,
                extension: '',
                focalPoint: { left: 0.5, top: 0.5 },
                height: 0,
                width: 0,
                config: {},
                type: MediaType.Image,
                imageType: ImageType.Unspecified
            });
        }
    }

    public setOpenGraphDIY(diy: Diy, images: File[]) {
        if (images.length) {
            this.setOpenGraphImage({
                url: images[0].url,
                name: diy.title,
                extension: '',
                focalPoint: { left: 0.5, top: 0.5 },
                height: 0,
                width: 0,
                config: {},
                type: MediaType.Image,
                imageType: ImageType.Unspecified
            });
        }
    }

    private clearUnusedOpenGraphTags(typeOfPage: 'product' | 'diy' | null) {
        const productSelector = 'meta[property^="product"]';
        const diySelector = `meta[property="${DomService.OPENGRAPHTYPE}"][content="business.business"]`;
        const searchMetaPropSelector: string[] = [];
        if (typeOfPage !== 'diy') {
            searchMetaPropSelector.push(diySelector);
        }
        if (typeOfPage !== 'product') {
            searchMetaPropSelector.push(productSelector);
        }
        if (searchMetaPropSelector.length) {
            const metaTags = $(searchMetaPropSelector.join(','));
            metaTags.each((i, e) => $(e).remove());
        }
    }

    public setHreflang(languages: LanguageAndUrl[]) {
        // First delete all existing hreflang elements, then there will be left no one behind.
        const existingLinkElements = $('link[rel=alternate][hreflang]');
        if (existingLinkElements !== null) {
            existingLinkElements.each((i, e) => {
                const tmpE = e as HTMLElement;
                if (tmpE && tmpE.parentElement) {
                    tmpE.parentElement.removeChild(e);
                }
            });
        }

        // Now for all languages, make a hreflang link element.
        languages &&
            languages.map((language) => {
                const element = document.createElement('link');
                element.rel = 'alternate';
                element.hreflang = language.language;
                element.href = language.url;
                const headElement = this.getHeadElement();
                if (headElement) {
                    headElement.appendChild(element);
                }
            });
    }

    private setContent(
        nameAttribute: string | null = null,
        propertyAttribute: string | null = null,
        content: string
    ): HTMLMetaElement {
        const tag = this.getMetaElement(nameAttribute, propertyAttribute);
        tag.content = this.sanitizeInput(content);
        return tag;
    }

    private sanitizeInput(input: string): string {
        return input || '';
    }

    private getCanonicalUrlElement(): HTMLLinkElement {
        return this.getOrCreate<HTMLLinkElement>('link[rel=canonical]', () => {
            const element = document.createElement('link');
            element.rel = 'canonical';
            const headElement = this.getHeadElement();
            if (headElement) {
                headElement.appendChild(element);
            }
            return element;
        });
    }

    private getHeadElement(): HTMLHeadElement | null {
        return document.head;
    }

    private getMetaElement(
        nameAttribute: string | null = null,
        propertyAttribute: string | null = null
    ): HTMLMetaElement {
        return this.getOrCreate<HTMLMetaElement>(
            'meta[' +
                (nameAttribute ? 'name="' + nameAttribute + '"' : '') +
                (propertyAttribute ? 'property="' + propertyAttribute + '"' : '') +
                ']',
            () => {
                const element = document.createElement('meta');
                if (nameAttribute) {
                    element.name = nameAttribute;
                }
                if (propertyAttribute) {
                    element.setAttribute('property', propertyAttribute);
                }
                const headElement = this.getHeadElement();
                if (headElement) {
                    headElement.appendChild(element);
                }
                return element;
            }
        );
    }

    private getOrCreate<TElement extends HTMLElement>(selector: string, create?: () => TElement): TElement {
        const element = document.querySelector(selector) as TElement;
        if (element) {
            return element;
        }
        if (create) {
            return create();
        }
        throw new Error(`Provide create param when element ${selector} cannot be found.`);
    }

    private setJsonlsForBreadcrumb() {
        const elementId = 'jsonld-breadcrumb';
        const element = this.getOrCreate('#' + elementId, () => {
            const element = document.createElement('script');
            element.setAttribute('id', elementId);
            element.setAttribute('type', 'application/ld+json');

            const headElement = this.getHeadElement();
            if (headElement) {
                headElement.appendChild(element);
            }
            return element;
        });
        element.innerHTML = this.getJsonldForBreadcrumb();
    }

    private getJsonldForBreadcrumb(): string {
        if (!(spaStore.navigation && spaStore.navigation.breadcrumb && spaStore.navigation.breadcrumb.length)) {
            return '';
        }
        const jsonldobj: any = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: []
        };
        // Parent pages
        spaStore.navigation.breadcrumb.map((item: BreadcrumbItem, index) => {
            jsonldobj.itemListElement.push({
                '@type': 'ListItem',
                position: index + 1,
                name: item.name,
                item: serverContext.baseUrl + item.url
            } as any);
        });
        // Current page
        jsonldobj.itemListElement.push({
            '@type': 'ListItem',
            position: jsonldobj.itemListElement.length + 1,
            name: spaStore.metadata.navigationTitle,
            item: spaStore.metadata.url
        } as any);
        return JSON.stringify(jsonldobj);
    }

    private setSiteimproveData(entity: EntityData): void {
        const element = this.setContent('pageID', null, entity.id.toString());
        element.setAttribute('provider', entity.provider);
        if (entity.provider === 'cms' && serverContext.culture) {
            element.setAttribute('culture', serverContext.culture);
        } else {
            element.removeAttribute('culture');
        }
    }
}

export default new DomService();
