<template>
    <span v-if="getBlockAnchorLinkComputed"
          :id="getBlockAnchorLinkComputed"/>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { JsonContent } from '@/types/serverContract';
import { getBlockAnchorLink } from '@/project/shared/string.util';

const props = defineProps<{
    block: JsonContent;
}>();

const getBlockAnchorLinkComputed = computed((): string | null => {
    return getBlockAnchorLink(props.block);
});
</script>