import Cookies from 'js-cookie';
import bus from '@/core/bus';
import loggingService from '@/core/logging.service';
import serverContext from '@/core/serverContext.service';
import { SpaPageRenderedEventKey, router } from '@/core/spa/router';
import spaStore, { CookieInformationDotComConsent } from '@/core/spa/store/spa.store';
import { CookieConsentChangeEventKey, UserLoadedEventKey } from '@/project/config/constants';
import { UrlFacets } from '@/project/facets/urlHelper.service';
import { jsDateToServerStringDate } from '@/project/shared/string.util';
import PageViewController from '@/project/tracking/mParticle/pageViewController';
import basketStore from '@/store/basket.store';

import {
    FormAction,
    NewsletterFormAction,
    SocialId,
    formatStoreInventoryStateToTrackingState,
    formatProductBrandNameToTracking,
    formatProductCategoryToTracking,
    formatProductLabelingToTracking,
    formatProductLongDescriptionToTracking,
    formatProductShortDescriptionToTracking,
    formatProductVariantImageCountToTracking,
    formatProductVariantSalesColorToTracking,
    formatProductVariantStockToTracking,
    getProductCountInBasketOrOrderReceipt,
    iTrackPromotionClickArgs
} from '@/project/tracking/tracking.utils';
import userStore from '@/store/user.store';
import {
    AccountInfo,
    Address,
    Basket,
    Phone,
    ShippingMethod,
    StoreInventoryState,
    v4
} from '@/types/serverContract';
import { Experiment, Result } from '@growthbook/growthbook';
import {
    EventType,
    IdentityResult,
    ProductActionType,
    TransactionAttributes,
    User,
    UserIdentities,
    Product as mParticleProduct
} from '@mparticle/web-sdk';
import { CheckoutPageStepIndexes } from '@/project/checkout/checkoutPageStepIndexes';
import { checkoutStore } from '@/store/checkout.store';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import { BasketLineWithNewProduct } from '../http/api';

interface PartialAccountInfo extends Partial<AccountInfo> {
    address: Partial<Address>;
    dateOfBirthAsDate: Date;
}
interface ProductAttributes {
    id: string; // ID of product. E.g. 5cb45e0b. Pattern: ^[a-zA-Z0-9_]*$
    name: string; // Name of product
    quantity?: number; // Quantity of products for the action
    price: number; // Price of product.
    variant: string; // Variant name of product.
    category: string; // Category of product. TODO: Kaspers documentation says number, this is probably wrong, tell Kasper!
    brand: string; // Brand name of product
    position?: number; // Position in a list
    coupon?: string;
    // eslint-disable-next-line camelcase
    currency_code?: string; // Currency of ecommerce event products. TODO: It is in Kaspers documentation, but mParticle does not support currency code in product, it seems... Ask!
}

export interface ProductCustomAttributes {
    Marking: string; // Stickers on product. E.g. paperRecycled
    Stockstatus?: string; // Normal | Sold out | Very Low | Low | Stock not present | Last product
    ProductPictures: number; // Number of pictures on the product
    ShortDescription: number; // Number of characters for short description
    LongDescription: number; // Number of characters for long description
    RealColourName?: string | null; // The colour of the product in words. E.g. "White"
    ItemsInBasket: number;
    LabelPrioritized?: string; // Label of product. E.g. "Trending" or "Kommer snart"
    // eslint-disable-next-line camelcase
    item_list_name?: string; // List name for the list of products (ignore if not in a list)
}

export interface CommonCustomAttributes {
    'Google.Page': string;
    LoggedInState: 'LoggedIn' | 'LoggedOut';
    pageType: string | undefined;
    CountryCode: string;
    CategoryId?: string;
    screentitle?: string;
    screentype?: string;
    contentGroupID?: string,
    contentGroupName?: string,
    contentID?: number | null,
    contentTypeID?: number | null,
}

interface CheckoutCustomAttributes extends CommonCustomAttributes {
    // eslint-disable-next-line camelcase
    c_checkout_step?: string;
    // eslint-disable-next-line camelcase
    c_checkout_state?: string;
}

interface trackCheckoutOptionInvoiceAddress {
    step: CheckoutPageStepIndexes.StartInvoiceAddress;
}

interface trackCheckoutOptionDelivery {
    step: CheckoutPageStepIndexes.Delivery;
    data?: ShippingMethod;
}

interface trackCheckoutOptionPayment {
    step: CheckoutPageStepIndexes.Payment;
    data?: string;
}

type trackCheckoutOption = trackCheckoutOptionInvoiceAddress | trackCheckoutOptionDelivery | trackCheckoutOptionPayment;

// TODO:
// This file is gigantic. Refactor the classes into individual files, like done with the PageViewController

const SFID_COOKIE_NAME = 'vertica_sfid';

class SharedController {
    private pretendCookiesAccepted: boolean = location.search.includes('pretendCookiesAccepted=true'); // QA enviroment does not have cookieinformation.com script setup, this can fake consent

    private trackingQueue: Promise<void | any>;
    private trackingQueueNoConsentNeeded: Promise<void | any>;

    constructor() {
        this.trackingQueueNoConsentNeeded = SharedController.waitForMParticleReadyAndInitialized();

        this.trackingQueue = Promise.resolve()
            .then(SharedController.waitForMParticleReadyAndInitialized)
            .then(SharedController.grabSalesforceIdFromUrlAndStoreIt)
            .then(SharedController.waitForCookieDecision)
            .catch((msg?: string) => {
                loggingService.error(msg || 'tracking queue could not initialize');
                // return a promise that never settles in order to avoid the chain to continue when "then" calls gets chained in queueUpForTracking()
                return new Promise(() => {});
            });
    }

    private static grabSalesforceIdFromUrlAndStoreIt() {
        return new Promise<void>((resolve) => {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('sfid')) {
                const sfid = urlParams.get('sfid');
                if (sfid) {
                    Cookies.set(SFID_COOKIE_NAME, sfid);
                    mParticleUtils.user.login(mParticleUtils.user.createUserIdentitiesObject({ other: sfid }));
                }
            }
            resolve();
        });
    }

    private static createPollingPromise(
        conditionCheck: () => boolean,
        timeoutInMs: number = 200,
        maxWaitInMs: number = 6000,
        rejectMsg: string = 'Polling cancelled, maximum wait time exceeded'
    ): Promise<void> {
        let retryCount: number = 0;
        return new Promise<void>((resolve, reject) => {
            const poll = () => {
                if (conditionCheck()) {
                    resolve();
                } else {
                    if (retryCount * timeoutInMs > maxWaitInMs) {
                        reject(rejectMsg);
                    } else {
                        retryCount++;
                        setTimeout(poll, timeoutInMs);
                    }
                }
            };
            poll();
        });
    }

    private static waitForMParticleInitialized(): Promise<void> {
        return SharedController.createPollingPromise(
            () => {
                return Boolean(window?.mParticle?.isInitialized?.());
            },
            200,
            6000,
            'Gave up waiting for mParticle to become initialized'
        );
    }

    private static waitForMParticleReady(): Promise<void> {
        return new Promise<void>((resolve) => {
            window.mParticle.ready(() => {
                resolve();
            });
        });
    }

    private static waitForMParticleReadyAndInitialized(): Promise<void> {
        return SharedController.waitForMParticleInitialized().then(() => {
            return SharedController.waitForMParticleReady();
        });
    }

    private static waitForCookieScript(): Promise<void> {
        return SharedController.createPollingPromise(
            () => {
                return 'CookieInformation' in window;
            },
            200,
            6000,
            'Gave up waiting for cookie script (timeout)'
        );
    }

    /**
     * Wait for user to make a decision regarding cookies (that is: either accept or reject)
     */

    private static waitForCookieDecision(): Promise<void> {
        return SharedController.waitForCookieScript().then(() => {
            return new Promise<void>((resolve) => {
                if (
                    spaStore?.cookieConsentStatus().cookieCatStatistic === null ||
                    spaStore?.cookieConsentStatus().cookieCatStatistic === undefined
                ) {
                    bus.once(CookieConsentChangeEventKey, () => {
                        // Use setTimeout to delay the event and allow handleCookieConsentChanges to settle in mParticle backend
                        setTimeout(() => {
                            resolve();
                        }, 100);
                    });
                } else {
                    resolve();
                }
            });
        });
    }

    /**
     * Push a tracking request to tracking queue.
     *
     * The queue is a promise-based queue and handles waiting for promises being settled.
     * If the callback returns a promise, the queue will wait for it to be settled.
     * The functionality was added because we want to wait for login() tracking to complete
     * before moving on.
     *
     * Usage:
     * For calls that doesn't involve asyncronous functions, simply call like this:
     * queueUpForTracking(() => {
     *   // do some tracking
     * });
     *
     * Here is how to make the queue wait for asyncronous code:
     * queueUpForTracking(() => {
     *     return new Promise<void>((resolve, reject) => {
     *         window.setTimeout(() => {
     *             resolve();
     *             // rejecting is also ok - it will not halt the queue.
     *         }, 500);
     *     });
     * });
     *
     * Rejecting a promise is ok, it does not halt the queue.
     * Check out how we achieve that in login() and calls to login()
     *
     * @param callback
     */
    public queueUpForTracking(
        callback: (value: void) => PromiseLike<void | any> | void,
    ): Promise<void | any> {
        const pretendCookiesAccepted = this.pretendCookiesAccepted;
        function validatePermission() {
            if (spaStore?.cookieConsentStatus().cookieCatStatistic === true || pretendCookiesAccepted === true) {
                return callback();
            }
        }
        return new Promise((resolve, reject) => {
            // Set trackingQueue to be the new promise generated by "then" in order to chain the subsequent "then" call
            // to this "then" call. - We are effectively getting the queue functionality by chaining them with "then".
            // see: https://dev.to/doctolib/using-promises-as-a-queue-co5
            this.trackingQueue = this.trackingQueue.then(validatePermission).then(resolve).catch(reject);
        });
    }

    /**
     * Queue up for tracking WITHOUT REQUIRING COOKIE CONSENT
     *
     * Warning: Think twice before calling this method!
     * Currently it is only legally allowed after the user performed a buy
     */
    public queueUpForTrackingBypassConsentRequirement(
        callback: (value: void) => PromiseLike<void | any> | void
    ): Promise<void | any> {
        // If there already is consent, we can use the consent queue, so things get done in correct order
        if (spaStore?.cookieConsentStatus().cookieCatStatistic === true) {
            return this.queueUpForTracking(callback);
        } else {
            return new Promise((resolve, reject) => {
                this.trackingQueueNoConsentNeeded = this.trackingQueueNoConsentNeeded
                    .then(callback)
                    .then(resolve)
                    .catch(reject);
            });
        }
    }

    public logEvent(eventName: string, eventType: EventType, customAttributes: object) {
        mParticleUtils.shared.queueUpForTracking(() => {
            window.mParticle.logEvent(eventName, eventType, customAttributes, mParticleUtils.shared.getCustomFlags());
        });
    }

    // TODO: The following method isn't used. Either remove or finish the refactor that was started

    /**
     *
     * @param productActionType  ie window.mParticle.ProductActionType.AddToCart. - see https://docs.mparticle.com/developers/sdk/web/commerce-tracking/#product-events
     * @param products
     * @param customAttributes
     */

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public logProductAction(productActionType: ProductActionType, products: v4.Products.ProductSimple[], customAttributes: object) {
        // Currency Code must be set before any mparticle.ecommerce.logProductAction() calls
        window.mParticle.eCommerce.setCurrencyCode(serverContext.currencyCode);

        mParticleUtils.shared.queueUpForTracking(() => {
            // const mParticleProducts = [];
            /*
            products.forEach((product: v4.Products.ProductSimple, index:number) => {
                mParticleProducts.push(
                    this.convertProductToTracking(
                        product
                    )
                );
            });

            window.mParticle.eCommerce.logProductAction(
                productActionType,
                customAttributes,
                mParticleUtils.shared.getCustomFlags()
            );

            mParticle.eCommerce.logProductAction(
                productActionType

            ); */
        });
    }

    public getCustomAttributes(): CommonCustomAttributes {
        const trackingData = spaStore.pageData?.tracking;
        const attrs: CommonCustomAttributes = {
            // if not passing any custom attributes, pass null
            'Google.Page': window.location.pathname,
            LoggedInState: userStore?.isLoggedIn ? 'LoggedIn' : 'LoggedOut',
            pageType: spaStore?.jsonContent?.alias,
            CountryCode: serverContext?.market, // or .language or .culture or the country code in .culture?
            screentitle: spaStore?.metadata.navigationTitle,
            screentype: spaStore?.jsonContent?.alias,
            contentGroupID: trackingData.contentGroupId,
            contentGroupName: trackingData.contentGroupName,
            contentID: trackingData.contentId,
            contentTypeID: trackingData.contentTypeId
        };
        // CategoryId must be set on product navigation pages (meaning: category pages and product detail pages),
        // as they are clearly defined by only one category.
        if (spaStore?.jsonContent?.content) {
            if (spaStore.jsonContent.content.categoryId) {
                // We are on a category page
                attrs.CategoryId = spaStore.jsonContent.content.categoryId;
            } else if (spaStore.jsonContent.content.product?.categoryId) {
                // We are on product details page:
                attrs.CategoryId = spaStore.jsonContent.content.product.categoryId;
            }
        }
        return attrs;
    }

    public getCustomAttributesForProduct(product: v4.Products.ProductSimple | undefined): CommonCustomAttributes {
        const attrs: CommonCustomAttributes = this.getCustomAttributes();
        // We intensionally do not override CategoryId as category id from a category page is more relevant than the
        // main category of the clicked product
        if (!attrs.CategoryId) {
            if (product && product?.tracking?.primaryCategoryId) {
                attrs.CategoryId = product.tracking.primaryCategoryId;
            }
        }
        return attrs;
    }

    public getCustomFlags() {
        const flags = {
            // if not passing any custom attributes, pass null
            'Facebook.EventSourceUrl': `${window.location.origin}${window.location.pathname}`,
            'Facebook.ClientUserAgent': navigator?.userAgent,
            ...this.getTikTokAttributes()
        };

        const fbp = Cookies.get('_fbp');
        if (fbp) {
            flags['Facebook.BrowserId'] = fbp;
        }

        const user = mParticleUtils.user?.getCurrentUserIdentities();

        // Defaults: read `email` and `phone` from mParticle
        let email: string | undefined = user.email;
        let phone: string | undefined = (user as any).phone_number || user.mobile_number;
        phone = phone ? mParticleUtils.shared.convertPhoneTrackingStringToGoogleAdsString(phone) : undefined;

        // Prioritize AccountInfo over mParticle if user is logged in
        // Note: First load will always use mParticle data because of the 5 sec delay in user.store constructor before calling initUser
        if (userStore.isLoggedIn && userStore.hasLoadedUser && userStore.userInformation) {
            if (userStore.userInformation.email) {
                email = userStore.userInformation.email;
            }

            if (userStore.userInformation.phone.phonePrefix && userStore.userInformation.phone.number) {
                phone = mParticleUtils.shared.convertPhoneToGoogleAdsString(userStore.userInformation.phone);
            }
        }

        if (email || phone) {
            flags['GoogleAds.ECData'] = {
                email,
                phone_number: phone
            };
        }

        return flags;
    }

    public getTikTokAttributes() {
        // Look for TokTikClickId
        const ttclid = router.currentRoute.value.query.ttclid;

        const attrs = {
            'TikTok.URL': window.location.href,
            'TikTok.Referrer': document.referrer
        };

        if (ttclid) {
            return {
                'TikTok.Callback': ttclid?.toString() || '',
                ...attrs
            };
        }
        return attrs;
    }

    /**
     * Create standard product custom attributes used for all product actions
     *
     * Note: Only standard attributes are returned. Some product action calls requires more attributes than these
     *
     */
    private createStandardProductCustomAttributes(
        product: v4.Products.ProductSimple,
        variant?: v4.Products.VariantSimple,
        useStock: boolean = true
    ): ProductCustomAttributes {
        const productCustomAttributes: ProductCustomAttributes = {
            Marking: formatProductLabelingToTracking(product),
            Stockstatus: formatProductVariantStockToTracking(product, variant, useStock),
            ProductPictures: formatProductVariantImageCountToTracking(product, variant),
            ShortDescription: formatProductShortDescriptionToTracking(product),
            LongDescription: formatProductLongDescriptionToTracking(product),
            RealColourName: formatProductVariantSalesColorToTracking(variant) || null,
            ItemsInBasket: variant?.sku ? getProductCountInBasketOrOrderReceipt(variant.sku) : -1000,
            LabelPrioritized: variant?.senseOfUrgencyLabel?.label ?? variant?.availabilityLabel?.label ?? '',
        };
        return productCustomAttributes;
    }

    private createStandardProductAttributes(product: v4.Products.ProductSimple, variant: v4.Products.VariantSimple): ProductAttributes {
        const productAttributes: ProductAttributes = {
            id: product.id,
            name: product.name,
            price: variant.pricing.unit?.amount,
            variant: variant.sku,
            category: formatProductCategoryToTracking(product),
            brand: formatProductBrandNameToTracking(product),
        };
        return productAttributes;
    }

    public createProduct(productAttributes: ProductAttributes, productCustomAttributes: ProductCustomAttributes) {
        const product: mParticleProduct = window.mParticle.eCommerce.createProduct(
            productAttributes.name,
            productAttributes.id,
            productAttributes.price,
            productAttributes.quantity,
            productAttributes.variant,
            productAttributes.category,
            productAttributes.brand,
            productAttributes.position,
            productAttributes.coupon,
            productCustomAttributes
        );
        return product;
    }

    public createImpression(name: string, product: mParticleProduct) {
        const impression = window.mParticle.eCommerce.createImpression(name, product);
        return impression;
    }

    public convertProductToTracking(
        product: v4.Products.ProductSimple,
        trackingListName?: string,
        position: number | undefined = undefined,
        variant: v4.Products.VariantSimple | undefined = undefined,
        quantity?: number,
        useStock: boolean = true
    ): mParticleProduct | undefined {
        const computedVariant = variant || (product.variants.length ? product.variants[0] : undefined);
        if (!computedVariant) return;

        const productAttributes = this.createStandardProductAttributes(product, computedVariant);
        if (quantity !== undefined) {
            productAttributes.quantity = quantity;
        }
        if (position !== undefined) {
            productAttributes.position = position + 1;
        }

        const productCustomAttributes = this.createStandardProductCustomAttributes(product, variant, useStock);
        if (trackingListName) {
            productCustomAttributes.item_list_name = trackingListName;
        }

        return this.createProduct(productAttributes, productCustomAttributes);
    }

    public digitsOnly(input: string) {
        return input.replace(/[^\d]/g, '');
    }

    // For mParticle phone should be formated as digits only
    // +45 10 10 10 10 -> 4510101010
    public convertPhoneToTrackingString(phone: Partial<Phone>) {
        return this.digitsOnly(`${phone.phonePrefix || ''}${phone.number || ''}`);
    }

    // For Google Ads phone should be formated as E.164
    // +45 10 10 10 10 -> +4510101010
    public convertPhoneToGoogleAdsString(phone: Partial<Phone>) {
        return `${phone.phonePrefix}${this.digitsOnly(phone.number || '')}`;
    }

    // Keep only digits and add + sign
    // Expecting input to be mParticle -> UserIdentities object -> mobile_number
    public convertPhoneTrackingStringToGoogleAdsString(phone: string) {
        return `+${this.digitsOnly(phone)}`;
    }
}

class UserController {
    constructor() {
        bus.on(UserLoadedEventKey, (userInformation) => this.trackUserLoaded(userInformation));
        bus.on(CookieConsentChangeEventKey, (status) => this.handleCookieConsentChanges(status));
        bus.once(SpaPageRenderedEventKey, () =>
            this.handleCookieConsentChanges(spaStore.cookieConsentStatus(), true)
        ); // Initial send users cookie consent to mParticle but keep consent date and document name
    }

    /**
     * Conveniently create a UserIdentities object
     *
     * The "convenience" part is that mobile_number can be a Phone object as well
     *
     * Notes:
     * - customerid corresponds to accountId
     * - other is used for Salesforce id (which is passed in querystring from ie newsletters, as "sfid=xxx")
     */
    public createUserIdentitiesObject(identities: {
        customerid?: string;
        email?: string;
        // eslint-disable-next-line camelcase
        mobile_number?: Phone | string;
        other?: string;
    }): UserIdentities {
        const ids = { ...identities };
        if (typeof ids.mobile_number == 'object') {
            ids.mobile_number = mParticleUtils.shared.convertPhoneToTrackingString(ids.mobile_number);
        } else if (typeof ids.mobile_number == 'string' && ids.mobile_number.indexOf('+') > -1) {
            ids.mobile_number = mParticleUtils.shared.digitsOnly(ids.mobile_number);
        }
        return ids as UserIdentities;
    }

    private convertAccountToUserIdentitiesObject(user: AccountInfo): UserIdentities {
        return this.createUserIdentitiesObject({
            customerid: user.accountId,
            email: user.email,
            mobile_number: user.phone
        });
    }

    private userIdentitiesObjectHasAtLeastOneIdentity(ui: UserIdentities): boolean {
        return Object.keys(ui).length > 0;
    }

    /**
     * Get the user identities of the current user.
     *
     * @return The user identies. An empty object will be returned in case user isn't logged in
     **/
    public getCurrentUserIdentities(): UserIdentities {
        if (!window.mParticle || !window.mParticle.Identity || typeof window.mParticle.Identity.getCurrentUser !== 'function') {
            return {} as UserIdentities;
        }

        const currentUser = window.mParticle.Identity.getCurrentUser();
        if (currentUser) {
            const userIdentities = currentUser.getUserIdentities();
            if (userIdentities) {
                return userIdentities.userIdentities;
            }
        }

        return {} as UserIdentities;
    }

    /**
     * Remove empty properties from an object (null, undefined, empty string)
     */
    private removeEmptyObjectProperties(obj: Record<string, unknown>): Record<string, unknown> {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return Object.fromEntries(Object.entries(obj).filter(([_key, value]) => !!value));
    }

    /**
     * Login directly (no queue)
     *
     * Note that the userIdentities will be enriched with current user identities in case some are
     * missing. This is done because it is vital that login() calls are done with as many identities as possible.
     * Logging in with only email could for example result in an account switch if the email differs.
     *
     * In addition to this, if salesforce id was provided in a param (?sfid=xxx), it will be used for login() as well
     * The way to tie salesforce id with customerid is to provide both in a login().
     *
     * @param ids:UserIdentities  User identities to be used for login. If some known are missing, they will be added
     * @returns Promise<IdentityResult>
     */
    private loginDirectly(ids: UserIdentities): Promise<IdentityResult> {
        return new Promise((resolve, reject) => {
            const userIdentities = { ...ids };

            // If we know some identities that is not in the request, copy them!
            const currentUserIDs: UserIdentities = this.getCurrentUserIdentities();
            Object.keys(currentUserIDs).forEach((key) => {
                if (!(key in userIdentities)) {
                    userIdentities[key] = currentUserIDs[key];
                }
            });

            // Pick identities still not found, if available in accountinfo
            if (userStore.isLoggedIn && userStore.userInformation) {
                if ('accountId' in userStore.userInformation && !('customerid' in userIdentities)) {
                    userIdentities.customerid = userStore.userInformation.accountId;
                }
                if ('email' in userStore.userInformation && !('email' in userIdentities)) {
                    userIdentities.email = userStore.userInformation.email;
                }
                if ('externalId' in userStore.userInformation && !('facebook' in userIdentities)) {
                    userIdentities.facebook = userStore.userInformation.externalId;
                }
            }

            const sfid = Cookies.get(SFID_COOKIE_NAME);
            if (sfid) {
                userIdentities.other = sfid;
            }

            const cleanedIds: UserIdentities = this.removeEmptyObjectProperties(userIdentities) as UserIdentities;
            window.mParticle.Identity.login({ userIdentities: cleanedIds }, (result: IdentityResult) => {
                // result is guarenteed (according to the mparticle types).
                // if there is an error, it is part of the result.
                // httpCode is for example -4 if a wrong type was sent, ie 'customerid' => 1
                // in case the user is not found, a new user is created (this is not an error). mpid will be negative
                if (result.httpCode === 200) {
                    resolve(result);
                } else {
                    loggingService.error({ error: 'mparticle login failed', result });
                    reject();
                }
            });
        });
    }

    /**
     * Perform a deferred mParticle login.
     *
     * "deferred" login here means that the login will not be performed until the user accepted statistic cookies.
     * Note that this is an mparticle login, as understood in the mParticle terminology. It does not log user into the website
     *
     * @return A Promise. If it succeeds, the login result is returned.
     *
     * @see https://docs.mparticle.com/developers/sdk/web/idsync/
     */
    public login(userIdentities: UserIdentities): Promise<IdentityResult> {
        return mParticleUtils.shared.queueUpForTracking(() => {
            return this.loginDirectly(userIdentities);
        });
    }

    public loginBypassConsentRequirement(userIdentities: UserIdentities): Promise<IdentityResult> {
        return mParticleUtils.shared.queueUpForTrackingBypassConsentRequirement(() => {
            return this.loginDirectly(userIdentities);
        });
    }

    public loginGuestUserAfterProvidingContactInfoInCheckout(email: string, phone: Phone) {
        const userObject = {
            email: email,
            mobile_number: phone
        };

        this.login(this.createUserIdentitiesObject(userObject));
    }

    /**
     * Modify
     *
     * A modify call should only hold changed identities. This method makes sure to only include
     * the identitiees that have changed (compared to the identities of the current mparticle user)
     *
     * Beware that email should only be modified when profile email changes. It should NOT be modififed
     * when the user uses another email than profile email in checkout.
     *
     * The method only takes email and phone because in this project, that is all that may be modified
     * We must DEFINITELY NOT create a modify request with customerid - if customerid should change, that would mean that the
     * identity changes and the proper way to handle that would be a login().
     * We do not modify "other" either, as that identity type has been set to immutable.
     *
     * @see https://docs.mparticle.com/developers/sdk/web/idsync/#modify
     *
     * @param email?:string         BEWARE: Only change this when profile email changes
     * @param phone?:string|Phone
     *
     * @returns A promise
     */
    public modify(propsToModify: { email?: string; phone?: string | Phone }): Promise<void> {
        return mParticleUtils.shared.queueUpForTracking(() => {
            const newUserIdentities: UserIdentities = this.createUserIdentitiesObject(propsToModify);
            const currentUserIdentities = window.mParticle.Identity.getCurrentUser().getUserIdentities().userIdentities;

            // We should only call modified for values that actually changed.
            // - find out which values that changed
            let changedUserIdentities = {};
            Object.keys(newUserIdentities).forEach((key) => {
                if (!currentUserIdentities[key] || newUserIdentities[key] !== currentUserIdentities[key]) {
                    changedUserIdentities[key] = newUserIdentities[key];
                }
            });

            changedUserIdentities = this.removeEmptyObjectProperties(changedUserIdentities);

            if (this.userIdentitiesObjectHasAtLeastOneIdentity(changedUserIdentities)) {
                return new Promise((resolve) => {
                    window.mParticle.Identity.modify({ userIdentities: changedUserIdentities }, (result) => {
                        resolve(result);
                    });
                });
            } else {
                return Promise.resolve();
            }
        });
    }

    public trackUserLoaded(userInformation: AccountInfo | null = userStore.userInformation) {
        if (!userInformation) {
            return;
        }

        const userIdentities: UserIdentities = this.convertAccountToUserIdentitiesObject(userInformation);

        this.login(userIdentities).then(() => {
            const user = window.mParticle.Identity.getCurrentUser();
            const ui = userInformation as PartialAccountInfo;
            ui.dateOfBirthAsDate = new Date(userInformation.dateOfBirth);
            ui.address = userInformation.invoice;
            this.setUserAttributes(user, ui);
        });
    }

    public trackFormActionUserLogin(eventAction: 'submit' | 'success' | 'error', loginMethod: string = 'manual') {
        const customAttributes = mParticleUtils.shared.getCustomAttributes();
        customAttributes['Google.Action'] = eventAction; // "Submit/Success/Error" always first action and then another with success or errror
        // @ts-ignore
        customAttributes.method = loginMethod; // manual if typed. Otherwise Facebook etc for the method name
        customAttributes['Google.Category'] = 'login';

        mParticleUtils.shared.queueUpForTracking(() => {
            window.mParticle.logEvent(
                'login',
                window.mParticle.EventType.Other,
                customAttributes,
                mParticleUtils.shared.getCustomFlags()
            );
        });
    }

    public trackUserLogout() {
        mParticleUtils.shared.queueUpForTracking(() => {
            window.mParticle.Identity.logout({ userIdentities: {} }, () => {});
        });
    }

    public trackUserInformationCreated(user: PartialAccountInfo) {
        mParticleUtils.shared.queueUpForTracking(() => {
            const currentUser = window.mParticle.Identity.getCurrentUser();
            this.setUserAttributes(currentUser, user);
        });
    }

    public trackUserInformationModified(accountInfo: AccountInfo) {
        this.modify({ email: accountInfo.email, phone: accountInfo.phone })
            .catch(() => {})
            .finally(() => {
                const currentUser = window.mParticle.Identity.getCurrentUser();
                this.setUserAttributes(currentUser, accountInfo as PartialAccountInfo);
            })
            .catch(() => {});
    }

    public trackUserInformationModifiedInCheckout(phone: Phone) {
        this.modify({ phone: phone })
            .catch(() => {})
            .finally(() => {
                const currentUser = window.mParticle.Identity.getCurrentUser();
                this.setUserAttributes(currentUser, { phone: phone } as PartialAccountInfo);
            });
    }

    public trackFormActionUserCreate(
        eventAction: 'submit' | 'success' | 'error',
        formMethod: string = 'manual',
        formName: string
    ) {
        mParticleUtils.shared.queueUpForTracking(() => {
            const customAttributes = mParticleUtils.shared.getCustomAttributes();
            customAttributes['Google.Action'] = eventAction; // "Submit/Success/Error" always first action and then another with success or error
            // @ts-ignore
            customAttributes.method = formMethod; // manual if typed. Otherwise Facebook etc for the method name
            // @ts-ignore
            customAttributes.formname = formName; // could also be 'market' or 'birthday' for unknown users and the extra steps to create a user
            customAttributes['Google.Category'] = 'sign up';

            window.mParticle.logEvent(
                'sign_up',
                window.mParticle.EventType.Other,
                customAttributes,
                mParticleUtils.shared.getCustomFlags()
            );
        });
    }

    /**
     * Copy user information from (partial) account to mParticle user attributes.
     * This operation will not remove attributes and only try to modify those supplied in the partial account
     *
     * @param user  mParticle user
     * @param account  Søstrene Grene user account (only partial) used to copy values from
     */
    private setUserAttributes(user: User, account: PartialAccountInfo) {
        if (account.dateOfBirthAsDate || account.dateOfBirth) {
            const date = account.dateOfBirth
                ? account.dateOfBirth
                : jsDateToServerStringDate(account.dateOfBirthAsDate);
            if (date && (typeof date !== 'string' || (typeof date === 'string' && date !== 'NaN-NaN-NaN'))) {
                user.setUserAttribute('birthday', date);
            }
        }
        if (account.phone?.number?.length) {
            user.setUserAttribute('$Mobile', mParticleUtils.shared.convertPhoneToTrackingString(account.phone));
        }
        if (account.address?.firstName) {
            user.setUserAttribute('$FirstName', account.address.firstName);
        }
        if (account.address?.lastName) {
            user.setUserAttribute('$LastName', account.address.lastName);
        }
        if (account.address?.city) {
            user.setUserAttribute('$City', account.address.city);
        }
        if (account.address?.postalCode) {
            user.setUserAttribute('$Zip', account.address.postalCode);
        }
        if (account.address?.street) {
            user.setUserAttribute('$Address', account.address.street);
        }
        if (account.address?.countryCode) {
            user.setUserAttribute('$Country', account.address.countryCode);
        }
    }

    /**
     * Called when the Cookie Consent is changed (cookieinformation.com popup) (with reuse set to false)
     * ALSO called when spa loads (with reuse set to true). So a page reload for example also triggers this.
     */
    private handleCookieConsentChanges(
        cookieConsentStatus: CookieInformationDotComConsent,
        reuseConsentDateAndDocuments: boolean = false
    ) {
        try {
            const currentUser = window.mParticle.Identity.getCurrentUser();
            const location = 'web';
            const hardwareId = this.getDeviceId();
            let statisticDate = Date.now();
            let marketingDate = Date.now();
            let statisticsDocument: string = '';
            let marketingDocument: string = '';
            if (reuseConsentDateAndDocuments) {
                const currentUserConsentState = currentUser.getConsentState().getGDPRConsentState();
                if (
                    currentUserConsentState?.statistic &&
                    currentUserConsentState.statistic.Consented === cookieConsentStatus.cookieCatStatistic
                ) {
                    statisticDate = currentUserConsentState.statistic.Timestamp;
                    statisticsDocument = currentUserConsentState.statistic.ConsentDocument;
                }
                if (
                    currentUserConsentState?.marketing &&
                    currentUserConsentState.marketing.Consented === cookieConsentStatus.cookieCatMarketing
                ) {
                    marketingDate = currentUserConsentState.marketing.Timestamp;
                    marketingDocument = currentUserConsentState.marketing.ConsentDocument;
                }
            }

            // We reuse the current state if it exists.
            // Not related to reusing date/documents, but because the state might have other consents
            // We would loose the "email" which we set when subscribing to newsletter, if we did not reuse the state
            let consentState = currentUser.getConsentState();
            if (consentState) {
                // We want to replace the "statistic" and "marketing" consents.
                // To make sure the old ones aren't kept, lets remove them
                // (actually not currently necessary, as addGDPRConsentState does throw out the old one if it exists,
                //  but as it isn't documented, we do not rely on this, but remove explicitly)
                if (consentState?.statistic) {
                    consentState.removeGDPRConsentState('statistic');
                }
                if (consentState?.marketing) {
                    consentState.removeGDPRConsentState('marketing');
                }
            } else {
                consentState = window.mParticle.Consent.createConsentState();
            }

            const statisticConsent = window.mParticle.Consent.createGDPRConsent(
                !!cookieConsentStatus.cookieCatStatistic,
                statisticDate,
                statisticsDocument,
                location,
                hardwareId
            );
            consentState.addGDPRConsentState('statistic', statisticConsent);
            const marketingConsent = window.mParticle.Consent.createGDPRConsent(
                !!cookieConsentStatus.cookieCatMarketing,
                marketingDate,
                marketingDocument,
                location,
                hardwareId
            );
            consentState.addGDPRConsentState('marketing', marketingConsent);
            currentUser.setConsentState(consentState);
        } catch (e) {

        }
    }

    private getDeviceId() {
        return window.mParticle.getDeviceId();
    }
}
class ProductImpressionController {
    public trackProductClick(
        product: v4.Products.ProductSimple,
        trackingListName: string,
        position: number | undefined = undefined,
        variant: v4.Products.VariantSimple | undefined = undefined
    ) {
        mParticleUtils.shared.queueUpForTracking(() => {
            const productToTracking = mParticleUtils.shared.convertProductToTracking(
                product,
                trackingListName,
                position,
                variant
            );
            const transactionAttributes = undefined;
            if (!productToTracking) return;
            window.mParticle.eCommerce.setCurrencyCode(serverContext.currencyCode);
            window.mParticle.eCommerce.logProductAction(
                window.mParticle.ProductActionType.Click,
                [productToTracking],
                mParticleUtils.shared.getCustomAttributesForProduct(product),
                mParticleUtils.shared.getCustomFlags(),
                transactionAttributes
            );
        });
    }

    public trackProductImpression(
        product: v4.Products.ProductSimple,
        trackingListName: string,
        position: number | undefined = undefined,
        variant: v4.Products.VariantSimple | undefined = undefined
    ) {
        mParticleUtils.shared.queueUpForTracking(() => {
            const productToTracking = mParticleUtils.shared.convertProductToTracking(
                product,
                trackingListName,
                position,
                variant
            );
            const transactionAttributes = undefined;
            if (!productToTracking) return;

            const impression = mParticleUtils.shared.createImpression('variant_overlay_impression', productToTracking);

            window.mParticle.eCommerce.setCurrencyCode(serverContext.currencyCode);

            window.mParticle.eCommerce.logImpression(
                impression,
                mParticleUtils.shared.getCustomAttributesForProduct(product),
                mParticleUtils.shared.getCustomFlags(),
                transactionAttributes
            );
        });
    }

    public trackProductDetailsView(product: v4.Products.ProductSimple, variant: v4.Products.VariantSimple | undefined = undefined) {
        mParticleUtils.shared.queueUpForTracking(() => {
            const productToTracking = mParticleUtils.shared.convertProductToTracking(
                product,
                undefined,
                undefined,
                variant
            );
            const transactionAttributes = undefined;
            if (!productToTracking) return;
            window.mParticle.eCommerce.setCurrencyCode(serverContext.currencyCode);
            window.mParticle.eCommerce.logProductAction(
                window.mParticle.ProductActionType.ViewDetail,
                [productToTracking],
                mParticleUtils.shared.getCustomAttributesForProduct(product),
                mParticleUtils.shared.getCustomFlags(),
                transactionAttributes
            );
        });
    }

    public trackModifyCart(
        product: v4.Products.ProductSimple,
        quantity: number,
        isAddToCart: boolean = true,
        price: undefined | number = undefined,
        list: undefined | string = undefined,
        variantSku: string,
        position?: number
    ) {
        return this.trackModifyCartMany(
            [{ product: product, quantity: quantity, quantityAcum: quantity, price: price, variantSku: variantSku }],
            list,
            isAddToCart,
            position
        );
    }

    public trackModifyCartMany(
        items: {
            product: v4.Products.ProductSimple;
            quantity: number;
            quantityAcum: number;
            price?: undefined | number;
            variantSku: string;
        }[],
        list: undefined | string = undefined,
        isAddToCart: boolean = true,
        position: number | undefined
    ) {
        mParticleUtils.shared.queueUpForTracking(() => {
            type mParticleProductOrUndefined = mParticleProduct | undefined;

            let firstProduct: v4.Products.ProductSimple | undefined;
            const productsToTracking: mParticleProductOrUndefined[] = items
                .map((item, index) => {
                    const variant = item.product.variants.find((x) => x.sku === item.variantSku);
                    const p: mParticleProductOrUndefined = mParticleUtils.shared.convertProductToTracking(
                        item.product,
                        list,
                        position || index,
                        variant,
                        item.quantity
                    );
                    if (!firstProduct && p) {
                        firstProduct = item.product;
                    }
                    return p;
                })
                .filter((x) => !!x); // the filter removes undefined

            if (productsToTracking.length === 0) return;

            const transactionAttributes = undefined;
            window.mParticle.eCommerce.setCurrencyCode(serverContext.currencyCode);
            window.mParticle.eCommerce.logProductAction(
                isAddToCart
                    ? window.mParticle.ProductActionType.AddToCart
                    : window.mParticle.ProductActionType.RemoveFromCart,
                productsToTracking,
                productsToTracking.length === 1
                    ? mParticleUtils.shared.getCustomAttributesForProduct(firstProduct)
                    : mParticleUtils.shared.getCustomAttributes(),
                mParticleUtils.shared.getCustomFlags(),
                transactionAttributes
            );
        });
    }

    public trackCheckoutStep(step: CheckoutPageStepIndexes) {
        mParticleUtils.shared.queueUpForTracking(() => {
            const { basket, clientSelectedShippingMethod, estimatedShippingPrice } = basketStore;

            if (!basket) return;

            const productsToTrack = this.convertBasketLinesToTracking(basket.lines);
            if (!productsToTrack.length) return;

            const shippingPrice = clientSelectedShippingMethod
                ? clientSelectedShippingMethod.price
                : estimatedShippingPrice ?? null;

            const totalPriceInclShipping = shippingPrice !== null ? basket.totalInclVat + shippingPrice : undefined;

            const transactionAttributes = this.createTransactionAttributes(
                step,
                0,
                undefined,
                basket,
                totalPriceInclShipping
            );

            const { infoState, deliveryState, paymentState } = checkoutStore;

            const customAttributes: CheckoutCustomAttributes = {
                ...mParticleUtils.shared.getCustomAttributes(),
                c_checkout_step: step.toString()
            };

            if (step === CheckoutPageStepIndexes.StartInvoiceAddress) {
                customAttributes.c_checkout_state = infoState;
            } else if (step === CheckoutPageStepIndexes.Delivery) {
                customAttributes.c_checkout_state = deliveryState;
            } else if (step === CheckoutPageStepIndexes.Payment) {
                customAttributes.c_checkout_state = paymentState;
            }

            window.mParticle.eCommerce.setCurrencyCode(serverContext.currencyCode);
            window.mParticle.eCommerce.logProductAction(
                window.mParticle.ProductActionType.Checkout,
                productsToTrack,
                customAttributes,
                mParticleUtils.shared.getCustomFlags(),
                transactionAttributes
            );
        });
    }

    /**
     * Track checkout options.
     *
     * This tracks extra info belonging to checkout step just completed (previous)
     * Notice that you must pass different things in the options.data depending on which step you are on.
     * Ie on one step, you must supply shipping method, on another payment method
     */
    public trackCheckoutOption(options: trackCheckoutOption) {
        mParticleUtils.shared.queueUpForTracking(() => {
            const { basket, clientSelectedShippingMethod, estimatedShippingPrice } = basketStore;
            if (!basket) return;

            const productsToTrack = this.convertBasketLinesToTracking(basket.lines);
            if (!productsToTrack.length) return;

            const shippingPrice = clientSelectedShippingMethod
                ? clientSelectedShippingMethod.price
                : estimatedShippingPrice ?? null;

            const totalPriceInclShipping = shippingPrice !== null ? basket.totalInclVat + shippingPrice : undefined;

            const customAttributes: CheckoutCustomAttributes = {
                ...mParticleUtils.shared.getCustomAttributes(),
                c_checkout_step: options.step.toString()
            };
            let shippingFlags = {};
            let paymentFlags = {};
            let transactionAttributes = {};

            const { infoOptionState, deliveryOptionState, paymentOptionState } = checkoutStore;
            if (options.step === CheckoutPageStepIndexes.StartInvoiceAddress) {
                customAttributes.c_checkout_state = infoOptionState;
            } else if (options.step === CheckoutPageStepIndexes.Delivery) {
                customAttributes.c_checkout_state = deliveryOptionState;
            } else if (options.step === CheckoutPageStepIndexes.Payment) {
                customAttributes.c_checkout_state = paymentOptionState;
            }

            if (options.step === CheckoutPageStepIndexes.Delivery) {
                const shippingMethod = options.data as ShippingMethod | undefined;
                const shippingMethodName = shippingMethod?.name || undefined;
                const shippingMethodPrice = shippingMethod?.price || undefined;
                transactionAttributes = this.createTransactionAttributes(
                    options.step,
                    0,
                    shippingMethodName,
                    basket,
                    totalPriceInclShipping
                );

                if (shippingMethodName && shippingMethodPrice) {
                    shippingFlags = {
                        'GA4.CommerceEventType': 'add_shipping_info',
                        'GA4.ShippingTier': [shippingMethodName, shippingMethodPrice, serverContext.currencyCode].join(
                            '|'
                        )
                    };
                    // @ts-ignore
                    transactionAttributes.Option = shippingFlags['GA4.ShippingTier'];
                }
            }

            if (options.step === CheckoutPageStepIndexes.Payment) {
                const selectedPaymentMethodName = options.data;
                transactionAttributes = this.createTransactionAttributes(
                    options.step,
                    0,
                    selectedPaymentMethodName,
                    basket,
                    totalPriceInclShipping
                );

                if (selectedPaymentMethodName) {
                    paymentFlags = {
                        'GA4.CommerceEventType': 'add_payment_info',
                        'GA4.PaymentType': selectedPaymentMethodName
                    };
                }
            }

            window.mParticle.eCommerce.setCurrencyCode(serverContext.currencyCode);
            window.mParticle.eCommerce.logProductAction(
                window.mParticle.ProductActionType.CheckoutOption,
                productsToTrack,
                customAttributes,
                { ...shippingFlags, ...paymentFlags, ...mParticleUtils.shared.getCustomFlags() },
                transactionAttributes
            );
        });
    }

    /**
     * Create transaction attributes
     *
     * Note that "option" has different meaning depending on which step you are on
     * On the shipping step, it for example contains shipping method and on the payment step, it contains payment method.
     */
    private createTransactionAttributes(step, id = 0, option, basket?: Basket, totalPriceInclShipping?: number) {
        const transactionAttributes: TransactionAttributes = {
            // Yes, it is called Step
            // @ts-ignore
            Step: step,
            Id: id,
            Option: option
        };
        if (totalPriceInclShipping !== undefined) {
            // @ts-ignore
            transactionAttributes.Revenue = totalPriceInclShipping.toString();
        }
        if (basket && basket.shipping?.shippingMethod?.price) {
            // @ts-ignore
            transactionAttributes.Shipping = basket.shipping.shippingMethod.price.toString();
        }
        if (basket && basket.totalVat) {
            // @ts-ignore
            transactionAttributes.Tax = basket.totalVat.toString();
        }
        return transactionAttributes;
    }

    private convertBasketLinesToTracking(lines): mParticleProduct[] {
        return (
            lines
                ?.map((line, lineIndex) => {
                    const variant = line.product.variants.find((x) => x.sku === line.sku);
                    return mParticleUtils.shared.convertProductToTracking(
                        line.product,
                        undefined,
                        lineIndex,
                        variant,
                        line.quantity
                    );
                })
                .filter(<T>(n?: T): n is T => Boolean(n)) || []
        );
    }

    public trackTransactionComplete(
        id: string | number,
        email: string,
        convertedBasket: Basket,
        marketingPermission?: boolean | null
    ) {
        function trackPurchaseAction() {
            const customFlags = mParticleUtils.shared.getCustomFlags();
            // @ts-ignore
            customFlags.sale = true;

            const customAttributes: CommonCustomAttributes & {
                // eslint-disable-next-line camelcase
                MFL_10_permission?: 'True' | 'False';
            } = mParticleUtils.shared.getCustomAttributes();
            if (marketingPermission !== undefined && marketingPermission !== null) {
                customAttributes.MFL_10_permission = marketingPermission === true ? 'True' : 'False';
            }

            const transactionAttributes: TransactionAttributes = {
                Id: id.toString(),
                Revenue: convertedBasket.totalInclVat + convertedBasket.shipping.shippingMethod.price,
                Shipping: convertedBasket.shipping.shippingMethod.price.toString(),
                Tax: convertedBasket.totalVat,
                // @ts-ignore
                Step: 5
            };

            // Always send tracking on receipt page as user has agreed on terms.
            window.mParticle.eCommerce.setCurrencyCode(serverContext.currencyCode);
            window.mParticle.eCommerce.logProductAction(
                window.mParticle.ProductActionType.Purchase,
                (convertedBasket.lines as any as BasketLineWithNewProduct[])
                    .map((line) => {
                        return mParticleUtils.shared.convertProductToTracking(
                            (line as any as BasketLineWithNewProduct).product,
                            '',
                            -1,
                            line.product.variants.find((v) => v.sku === line.sku),
                            line.quantity,
                            // ignore stock as they are not loaded on receipt page that are redirected from payment
                            false
                        );
                    })
                    .filter(<T>(n?: T): n is T => Boolean(n)),
                customAttributes,
                customFlags,
                transactionAttributes
            );

            // User attributes
            // When the user have placed an order we send the billing address info to mParticle as user attributes
            const currentUser: User = window.mParticle.Identity.getCurrentUser();
            currentUser.setUserAttribute('email', email);
            currentUser.setUserAttribute(
                '$Mobile',
                mParticleUtils.shared.convertPhoneToTrackingString(convertedBasket.phone)
            );
            currentUser.setUserAttribute('$FirstName', convertedBasket.invoiceAddress.firstName);
            currentUser.setUserAttribute('$LastName', convertedBasket.invoiceAddress.lastName);
            currentUser.setUserAttribute('$City', convertedBasket.invoiceAddress.city);
            currentUser.setUserAttribute('$Zip', convertedBasket.invoiceAddress.postalCode);
            currentUser.setUserAttribute('$Address', convertedBasket.invoiceAddress.street);
            currentUser.setUserAttribute('$Country', convertedBasket.invoiceAddress.countryCode);
        }

        if (!mParticleUtils.user.getCurrentUserIdentities()?.email) {
            const userIdentities: UserIdentities = mParticleUtils.user.createUserIdentitiesObject({
                email: email,
                mobile_number: convertedBasket.phone
            });
            mParticleUtils.user.loginBypassConsentRequirement(userIdentities).then(() => {
                trackPurchaseAction();
            });
        } else {
            trackPurchaseAction();
        }
    }

    public trackRegretMoveFromBasketToFavorite(product: v4.Products.ProductSimple) {
        const customAttributes = {
            ...mParticleUtils.shared.getCustomAttributesForProduct(product),
            'Google.Category': 'Regret',
            'Google.Action': 'FavoriteMoveRegret'
        };

        mParticleUtils.shared.logEvent('Regret', window.mParticle.EventType.Other, customAttributes);
    }
}

class FavouritesController {
    public trackAddToWishlist(
        product: v4.Products.ProductSimple | undefined, // undefined for DIY items
        trackingListName: string | undefined,
        variant: v4.Products.VariantSimple | undefined, // undefined for DIY items
        trackingEventIndex: number | undefined,
        contentTitle: string | undefined
    ) {
        /*
        Add to wishlist
        Send when a product is added to a wishlist. If not wishlisting a product then leave items array
        blank and send info in action line.
        */
        const productsToTracking: Array<mParticleProduct> = [];

        let commonCustomAttributes: CommonCustomAttributes;
        window.mParticle.eCommerce.setCurrencyCode(serverContext.currencyCode);
        if (product && variant) {
            mParticleUtils.shared.queueUpForTracking(() => {
                commonCustomAttributes = mParticleUtils.shared.getCustomAttributesForProduct(product);
                const productToTracking = mParticleUtils.shared.convertProductToTracking(
                    product,
                    trackingListName,
                    trackingEventIndex,
                    variant
                );
                if (!productToTracking) return;

                productsToTracking.push(productToTracking);
                const transactionAttributes = undefined;
                window.mParticle.eCommerce.logProductAction(
                    window.mParticle.ProductActionType.AddToWishlist,
                    productsToTracking,
                    { action: 'add_to_wishlist', ...commonCustomAttributes },
                    mParticleUtils.shared.getCustomFlags(),
                    transactionAttributes
                );
            });
        } else {
            const customAttributes = {
                ...mParticleUtils.shared.getCustomAttributes(),
                action: contentTitle
            };

            mParticleUtils.shared.logEvent('add_to_wishlist', window.mParticle.EventType.Other, customAttributes);
        }
    }

    public trackRemoveFromWishlist(
        product?: v4.Products.ProductSimple, // undefined for non-products
        contentTitle?: string, // Content title for non-products, productname(_variant) for products
        listName?: string
    ) {
        const customAttributes = {
            ...mParticleUtils.shared.getCustomAttributesForProduct(product),
            action: 'remove_from_wishlist',
            Wishlist_listname: listName,
            Wishlist_product: contentTitle // "[productname]_[dimension7(color)]"
        };

        mParticleUtils.shared.logEvent('remove_from_wishlist', window.mParticle.EventType.Other, customAttributes);

        /*
        TODO: logProductAction type shall have this type:
        window.mParticle.ProductActionType.RemoveFromWishlist,
        */
    }
}

class NavigationController {
    private logNavigationEvent(eventName: string, customAttributes: object) {
        mParticleUtils.shared.logEvent(eventName, window.mParticle.EventType.Navigation, {
            ...customAttributes,
            ...mParticleUtils.shared.getCustomAttributes()
        });
    }

    public trackOverlayOrMinipage(action: string) {
        const eventName: string = 'Overlay/minipage';
        this.logNavigationEvent(eventName, {
            action: action,
            'Google.Action': action, // [minipage- overlay- or tabname], eg.: accordionItemMeasurement.
            'Google.Category': eventName
            // TODO: It seems from Kaspers Excel that a "Google.Label" is also needed ("Product id of clicked item")
        });
    }

    public trackCheckoutFlowNavigation(
        action: 'basket' | 'login' | 'customerinfo' | 'delivery' | 'paymentchoice' | 'topaymentprovider'
    ) {
        const eventName: string = 'Checkoutflow';

        this.logNavigationEvent(eventName, {
            action: action,
            'Google.Category': eventName
        });
    }

    public trackGrowthBookExposure(experiment: Experiment<any>, result: Result<any>) {
        mParticleUtils.shared.logEvent('experimentViewed', window.mParticle.EventType.Other, {
            experimentId: experiment.key,
            variationId: result.key,
            ...mParticleUtils.shared.getCustomAttributes()
        });
    }
}

class SearchController {
    public trackQuickLinkClick(popularTerm: string, label: 'Suggest' | 'NoResults' | 'quicklink' | 'latest' | 'term_suggestion' | 'category_suggestion' | 'category_link' | 'continuation' | 'did_you_mean') {
        mParticleUtils.shared.logEvent(
            'Search quick link', // seachQuickLinksClick
            window.mParticle.EventType.Search,
            {
                label,
                Action: popularTerm,
                'search_term': popularTerm,
                'Google.Category': 'seachQuickLinksClick',
                ...mParticleUtils.shared.getCustomAttributes()
            }
        );
    }

    public search(term: string, numberOfProductHits, numberOfInspirationHits) {
        mParticleUtils.shared.logEvent(
            'Search', // searching
            window.mParticle.EventType.Search,
            {
                Action: term,
                'search_term': term,
                'search_results_products': numberOfProductHits,
                'search_results_inspiration': numberOfInspirationHits,
                'Google.Category': 'search',
                'Google.Action': term,
                ...mParticleUtils.shared.getCustomAttributes()
            }
        );
    }

    public trackSearchPreview(searchTerm: string) {
        mParticleUtils.shared.logEvent(
            'Search type ahead', // searchOverlay
            window.mParticle.EventType.Search,
            {
                'Google.Action': searchTerm,
                'search_term': searchTerm,
                'Google.Category': 'searchOverlay',
                ...mParticleUtils.shared.getCustomAttributes()
            }
        );
    }

    public trackProductTileClick(searchTerm: string, numberOfProductHits: number, numberOfInspirationHits: number, name: string) {
        mParticleUtils.shared.logEvent(
            'search_products_click',
            window.mParticle.EventType.Search,
            {
                'Google.Category': 'search_results_products',
                'Google.Action': name,
                'action': name,
                'search_term': searchTerm,
                'search_results_products': numberOfProductHits,
                'search_results_inspiration': numberOfInspirationHits,
                ...mParticleUtils.shared.getCustomAttributes()
            }
        );
    }

    public trackInspirationTileClick(searchTerm: string, numberOfProductHits: number, numberOfInspirationHits: number, name: string) {
        mParticleUtils.shared.logEvent(
            'search_inspiration_click',
            window.mParticle.EventType.Search,
            {
                'Google.Category': 'search_results_inspiration',
                'Google.Action': name,
                'action': name,
                'search_term': searchTerm,
                'search_results_products': numberOfProductHits,
                'search_results_inspiration': numberOfInspirationHits,
                ...mParticleUtils.shared.getCustomAttributes()
            }
        );
    }
}

class ErrorController {
    public track404() {
        mParticleUtils.shared.logEvent('Error Page', window.mParticle.EventType.Other, {
            'Google.Action': '404',
            'Google.Category': 'Error Page',
            ...mParticleUtils.shared.getCustomAttributes()
        });
    }
}

class StoreController {
    public trackSearch(addressOrGeolocation: string, isMyStore: boolean) {
        mParticleUtils.shared.logEvent(
            'store search', // Store
            window.mParticle.EventType.Location, // "Social" in docs
            {
                'Google.Category': isMyStore ? 'MyStore' : 'Store',
                'Google.Action': addressOrGeolocation,
                ...mParticleUtils.shared.getCustomAttributes()
            }
        );
    }

    public trackFoldOut(storeName: string, addressOrGeolocation: string, storeInventoryState?: StoreInventoryState) {
        const stockStatusAttr = {
            storeFoldoutStockstatus: storeInventoryState
                ? formatStoreInventoryStateToTrackingState(storeInventoryState)
                : null
        };
        const attr = {
            'Google.Category': 'Store',
            'Google.Action': addressOrGeolocation,
            'Google.Label': 'Foldout:' + storeName,
            ...mParticleUtils.shared.getCustomAttributes()
        };
        const trackingObject = storeInventoryState ? { ...attr, ...stockStatusAttr } : { ...attr };

        mParticleUtils.shared.logEvent(
            'store fold out', // "Store" in docs
            window.mParticle.EventType.Location, // "Social" in docs
            {
                ...trackingObject
            }
        );
    }

    public trackOpenClick(addressOrGeolocation: string, storeName: string) {
        mParticleUtils.shared.logEvent(
            'store specific page', // "Store" in docs
            window.mParticle.EventType.Location, // "Social" in docs
            {
                'Google.Category': 'Store',
                'Google.Action': addressOrGeolocation,
                'Google.Label': 'Open:' + storeName,
                ...mParticleUtils.shared.getCustomAttributes()
            }
        );
    }

    public trackMyStoreUnknownStockStatus() {
        mParticleUtils.shared.logEvent('unknown_store_stock_on_my_store', window.mParticle.EventType.Other, {
            ...mParticleUtils.shared.getCustomAttributes()
        });
    }

    // Can throw if window.mParticle.Store is not ready
    private growthbookAttributes() {
        // In some browsers, when loading the page from a fresh browser session,
        // mParticle will report ready === true, but mpid will be zero. 
        // Therefore, we check for this value before continuing setting attributes,
        // as a wrong mpid will result in a unexpected default variation from Growthbook.
        if (!window.mParticle.Store.mpid) throw 'No mParticle id';
        return {
            id: window.mParticle.Store.mpid,
            country: window.vertica.culture,
            url: window.location.href,
            platform: window.navigator.userAgent,
            mobileWeb: !breakpointsState.isBreakpointActive('min-ls'),
            isLoggedIn: userStore.isLoggedIn
        };
    }

    public async getMParticleGrowthBookAttributes(): Promise<Record<string, any>> {
        // In edgecases mParticle is not ready immediately, so we retry for a short while
        const retryInterval = 10; // milliseconds
        const maxTries = 3 * 1000 / retryInterval; // 3 seconds
        let tries = 0;
        do {
            try {
                const attribs = mParticleUtils.store.growthbookAttributes();
                loggingService.info(`mParticle ready after ${tries * retryInterval} ms.`);
                return attribs;
            } catch(e) {
                await sleep(retryInterval);
            }
        } while (++tries < maxTries);
        loggingService.error('mParticle not ready after 3 seconds!');
        return {};

        async function sleep(ms: number) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    }        

    public getSessionId() {
        return window.mParticle.Store?.sessionId ?? undefined;
    }
}

class PromotionController {
    public trackPromotionClick(args: iTrackPromotionClickArgs) {
        mParticleUtils.shared.queueUpForTracking(() => {
            // https://docs.mparticle.com/developers/sdk/web/core-apidocs/classes/mParticle.eCommerce.html#method_createPromotion
            const promotion = window.mParticle.eCommerce.createPromotion(
                args.componentName + ':' + (args?.trackingName ?? args?.trackingTitle ?? '') + '_' + (args?.position ?? ''), // ie "BlockHeroBanner:Til sommerens udflugter_homePage_0"
                args.creativeText ?? '', // // Promotion Creative
                args.componentName + ':' + (args?.trackingTitle ?? ''), // "BlockHeroBanner:Til sommerens udflugter", // Promotion Name
                args?.position // "1" // Promotion Position - the position in the list in the module
            );
            // https://docs.mparticle.com/developers/sdk/web/core-apidocs/classes/mParticle.eCommerce.html#method_logPromotion
            window.mParticle.eCommerce.logPromotion(
                window.mParticle.PromotionType.PromotionClick,
                promotion,
                mParticleUtils.shared.getCustomAttributes(),
                mParticleUtils.shared.getCustomFlags() // Docs doesn't specify this, however its on the other logEvents and not in their docs either
            );
        });
    }
}

class LinksController {
    public trackSocialLinkClick(id: SocialId) {
        const socialId: string = SocialId[id];
        mParticleUtils.shared.logEvent('Social links', window.mParticle.EventType.Social, {
            'Google.Action': socialId,
            'Google.Category': 'Social link',
            ...mParticleUtils.shared.getCustomAttributes()
        });
    }
}

class FacetFilterController {
    private facetsToString(existingFacets: UrlFacets) {
        const eventLabel: string[] = [];
        Object.keys(existingFacets).forEach((key) => {
            const labels = existingFacets[key].map((label) => {
                return key + ':' + label;
            });
            eventLabel.push(labels.join(', '));
        });
        return eventLabel.join();
    }

    public trackClearFilter(existingFacets: UrlFacets) {
        mParticleUtils.shared.logEvent('sort_facet', window.mParticle.EventType.Navigation, {
            'Google.Action': 'Remove=' + this.facetsToString(existingFacets),
            'Google.Category': 'sort_facet',
            ...mParticleUtils.shared.getCustomAttributes()
        });
    }

    public trackSortingChange(newSorting: string, existingSorting: string) {
        mParticleUtils.shared.logEvent('sort_facet', window.mParticle.EventType.Navigation, {
            'Google.Action': 'Sortering: ' + newSorting + '_Sortering: ' + existingSorting,
            'Google.Category': 'sort_facet',
            ...mParticleUtils.shared.getCustomAttributes()
        });
    }

    private trackFacetUse(
        actionPrefix: 'Add=' | 'Remove=' | '',
        facetGroup: string,
        facet: string | undefined,
        existingFacets: UrlFacets
    ) {
        mParticleUtils.shared.logEvent('sort_facet', window.mParticle.EventType.Navigation, {
            'Google.Action':
                actionPrefix + facetGroup + ':' + (facet || '') + '_' + this.facetsToString(existingFacets),
            'Google.Category': 'sort_facet',
            ...mParticleUtils.shared.getCustomAttributes()
        });
    }

    public trackFacetUpdate(facetGroup: string, facet: string | undefined, existingFacets: UrlFacets) {
        this.trackFacetUse('', facetGroup, facet, existingFacets);
    }

    public trackFacetAdd(facetGroup: string, facet: string | undefined, existingFacets: UrlFacets) {
        this.trackFacetUse('Add=', facetGroup, facet, existingFacets);
    }

    public trackFacetRemove(facetGroup: string, facet: string | undefined, existingFacets: UrlFacets) {
        this.trackFacetUse('Remove=', facetGroup, facet, existingFacets);
    }
}

class DownloadsController {
    public trackDownloadClick(name: string) {
        mParticleUtils.shared.logEvent('file_download', window.mParticle.EventType.Other, {
            Action: name,
            'Google.Category': 'file_download',
            ...mParticleUtils.shared.getCustomAttributes()
        });
    }
}

class NewsletterController {
    public trackNewsletterFormAction(submitAction: NewsletterFormAction) {
        mParticleUtils.shared.logEvent('newsletter_signup', window.mParticle.EventType.Other, {
            Action: submitAction, // "Submit/Success/Error/checkbox",
            // always first action and then another with
            // success or error unless it’s a checkbox where we only send checkbox and only that one
            // event when there is only a checkbox and no fields that can be filled out incorrect for
            // newsletter signup
            'Google.Category': 'newsletter_signup',
            ...mParticleUtils.shared.getCustomAttributes()
        });
    }
}

class FormsController {
    public trackFormAction(action: FormAction, formName: string) {
        mParticleUtils.shared.logEvent('send form', window.mParticle.EventType.Other, {
            Action: action,
            'Google.Category': 'form',
            'Google.Label': formName,
            ...mParticleUtils.shared.getCustomAttributes()
        });
    }
}

export default class mParticleUtils {
    public static shared = new SharedController();
    public static catalog = new ProductImpressionController();
    public static user = new UserController();
    public static favourites = new FavouritesController();
    public static navigation = new NavigationController();
    public static search = new SearchController();
    public static error = new ErrorController();
    public static store = new StoreController();
    public static promotion = new PromotionController();
    public static links = new LinksController();
    public static facetFilter = new FacetFilterController();
    public static downloads = new DownloadsController();
    public static newsletter = new NewsletterController();
    public static forms = new FormsController();
    public static pageView = new PageViewController();
}
