import trackingUtils, { getTrackingListNameForBlock } from '@/project/tracking/tracking.utils';
import { selectedVariant } from '@/project/product/productHelper.utils';
import { IProvideInjectTrackingData } from '@/types/frontend';

export default function useProductTracking(trackingData?: IProvideInjectTrackingData | undefined) {
    function trackProductClick(event, product, variant?) {
        const variantToTrack = variant ?? selectedVariant(product.variants, product.mostRelevantVariant);
        trackingUtils.catalog.trackProductClick(
            product,
            getTrackingListName(event),
            trackingData?.trackingIndex,
            variantToTrack
        );
    }

    function trackProductImpression(event, product, variant) {
        const variantToTrack = variant ?? selectedVariant(product.variants, product.mostRelevantVariant);

        trackingUtils.catalog.trackProductImpression(
            product,
            getTrackingListName(event),
            trackingData?.trackingIndex,
            variantToTrack
        );
    }

    function getTrackingListName(event = null) {
        return getTrackingListNameForBlock(trackingData, event);
    }

    return {
        trackProductClick,
        trackProductImpression,
        getTrackingListName,
    };
}
