import { router } from '@/core/spa/router';
import { reactive } from 'vue';
import { RouteLocationRaw } from 'vue-router';

const termKey = 'term';
const pageKey = 'page';
const categoryIdKey = 'categoryId';
const sortByKey = 'sortBy';
const nonFacetKeys = [termKey, categoryIdKey, sortByKey, pageKey];

const observableFacets = reactive({});

export type UrlFacets = { [key: string]: string[] };

export default {
    updateFacetValue,
    setFacetValues,
    clearFacets,
    getFacets,
    getPage,
    setPage,
    getTerm,
    setTerm,
    getCategoryId,
    setCategoryId,
    getSortBy,
    setSortBy,
    getLocationForPage,
    getQueryParam
};

// For some reason, router is not always available right away
if (router) {
    setupAfterEach(router);
} else {
    const intervalHandle = setInterval(() => {
        if (router) {
            setupAfterEach(router);
            clearInterval(intervalHandle);
        }
    }, 50);
}

// Resets observable facets for every route-change
function setupAfterEach(router) {
    router.afterEach(updateObservableFacets);
}

function updateFacetValue(facetGroupKey: string, value: string, selected: boolean) {
    let facetValues = router.currentRoute.value.query[facetGroupKey];

    // Convert to array for all cases
    if (!facetValues) {
        facetValues = [];
    } else if (!(facetValues instanceof Array)) {
        facetValues = [facetValues];
    } else {
        // Create new array or changes wont be detected
        facetValues = [...facetValues];
    }

    // Add or remove
    if (selected) {
        facetValues.push(value);
    } else {
        facetValues = facetValues.filter(v => v !== value);
    }

    setFacetValues(facetGroupKey, facetValues as string[]);
}

function setFacetValues(facetGroupKey: string, facetValues: string[]) {
    // Cleans all facets for this group and set the new ones
    const query = { ...router.currentRoute.value.query };
    delete query[facetGroupKey];

    if (facetValues.length > 0) {
        query[facetGroupKey] = facetValues;
    }

    replaceQuery(query, true);
}

function clearFacets() {
    // If query is empty, dont do anything
    if (Object.keys(router.currentRoute.value.query).length === 0) {
        return;
    }

    // Remove all keys related to facets
    const query = { ...router.currentRoute.value.query };
    Object
        .keys(query)
        .filter(key => !nonFacetKeys.includes(key))
        .forEach(key => {
            delete query[key];
        });

    replaceQuery(query, true);
}

function toUrlFacets(query: Record<string, string | string[]>): UrlFacets {
    // Ensure all values are arrays
    const result: UrlFacets = {};
    Object
        .keys(query)
        .forEach(key => {
            result[key] = (query[key] instanceof Array ? query[key] : [query[key]]) as string[];
        });
    return result;
}

function getFacets(): UrlFacets {
    if (router) {
        const queryLength = Object.keys(router.currentRoute.value.query).length;
        const observableFacetsLength = Object.keys(observableFacets).length;
        if (observableFacetsLength !== queryLength) {
            updateObservableFacets();
        }
    }
    return observableFacets;
}

function updateObservableFacets() {
    // Clean
    Object
        .keys(observableFacets)
        .forEach(facetGroup => delete observableFacets[facetGroup]);

    // Refill
    const query = toUrlFacets({ ...router.currentRoute.value.query } as Record<string, string | string[]>);
    Object
        .keys(query)
        .filter(key => !nonFacetKeys.includes(key))
        .forEach(facetGroup => {
            observableFacets[facetGroup] = query[facetGroup];
        });
}

function getTerm(): string | undefined {
    return getQueryParam(termKey);
}

function getPage(): number {
    const value = parseInt(getQueryParam(pageKey) || '', 10);
    return !value || Number.isNaN(value) ? 1 : value;
}

function getLocationForPage(page: number): RouteLocationRaw {
    const query = { ...router.currentRoute.value.query };
    query[pageKey] = page + '';

    return ({ query });
}

function getCategoryId(): string | undefined {
    return getQueryParam(categoryIdKey);
}

function getSortBy(): string {
    return getQueryParam(sortByKey) as string || '';
}

function setPage(page: number) {
    setQueryParam(pageKey, page + '');
}

function setTerm(term: string): void {
    setQueryParam(termKey, term, true);
}

function setCategoryId(categoryId: string): void {
    setQueryParam(categoryIdKey, categoryId);
}

function setSortBy(sortBy: string): void {
    setQueryParam(sortByKey, sortBy, true);
}

function getQueryParam(param) : string | undefined {
    return router.currentRoute.value.query[param] as string;
}

function setQueryParam(param: string, value: string, resetPage: boolean = false) {
    const query = { ...router.currentRoute.value.query };
    if (!value && !query[param]) return;
    if (value === query[param]) return;
    query[param] = value;
    replaceQuery(query, resetPage);
}

function replaceQuery(query: Record<string, string | (string | null)[] | null | undefined>, resetPage: boolean = false) {
    if (resetPage) {
        delete query[pageKey];
    }

    router.replace({ query });
}
