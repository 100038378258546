import { File, Media, InventoryState, v4, Color } from '@/types/serverContract';
import translateFilter from '@/core/translation/translate.filter';
import currencyFilter from '@/project/shared/currency/currency.filter';

export enum FewInStockMessage {
    'Few' = 1,
    'VeryFew' = 2,
    'Not' = 3,
    'Expired'
}

export const PDP_IMAGE_FULLSCREEN = 'PDP_IMAGE_FULLSCREEN';
export interface pricesInterFace {
    hasFixedUnitPrice: boolean;
    hasFixedComparisonPrice: boolean;
    lowestUnitPrice: number;
    lowestComparisonPrice: number | undefined;
}

export function getFirstVariantSalesColor(variant: v4.Products.VariantSimple): Color | null {
    return variant.color?.codes?.length ? { code: variant.color?.codes[0], name: variant.color?.name } : null;
}
export function getSecondVariantSalesColor(variant: v4.Products.VariantSimple): Color | null {
    return variant.color?.codes?.length && variant.color.codes.length >= 1 ? { code: variant.color.codes[1], name: variant.color.name } : null;
}
export function productHasVariantWithMultiColor(product: v4.Products.ProductSimple): boolean {
    if (product.variants.find((v) => variantWithMultiColor(v))) {
        return true;
    }
    return false;
}
export function variantWithMultiColor(variant: v4.Products.VariantSimple): boolean {
    return variant.color?.type === v4.Products.ColorType.Multi;
}
export function variantWithTwoColor(variant: v4.Products.VariantSimple): boolean {
    return variant.color?.type === v4.Products.ColorType.Dual;
}
export function titleForVariant(product: v4.Products.ProductSimple, variant: v4.Products.VariantSimple): string {
    return variant.variantName || variant.sku;
}
export function productVariesOnColor(product: v4.Products.ProductSimple): boolean {
    if (!product) return false;
    return product.variantInfo?.type === v4.Products.GenericVariantType.Color;
}
export function productHasVariants(product: v4.Products.ProductSimple): boolean {
    if (!product) return false;
    return product.variants.length > 1;
}

export function getSelectedVariantName(variant: v4.Products.VariantSimple | null): string {
    return variant?.variantName || '';
}

export function productImageOverride(
    product: v4.Products.ProductSimple,
    overrideImage: Media | undefined,
    variantSku: undefined | string = undefined
): Media 
| File 
| {
    url: ''
    focalPoint: undefined
} {
    if (overrideImage) return overrideImage;
    else if (product.variants?.[0].media?.length) {
        if (variantSku) {
            const variant = getVariantBySku(product.variants, variantSku);
            if (variant) {
                return variant.media[0];
            }
        }
        return selectedVariant(product.variants, product.mostRelevantVariant)?.media[0];
    } else {
        return {
            url: '',
            focalPoint: undefined
        }; // will trigger 404 on image but still allow to bind for getImage.url and getImage.focalPoint
    }
}
export function getVariantBySku(variants: v4.Products.VariantSimple[], variantSku: string): v4.Products.VariantSimple | null {
    if (!variants || !variantSku) return null;
    const variant = variants.find((v) => v.sku === variantSku);
    if (variant) {
        return variant;
    }
    return null;
}
export function selectedVariant(variants: v4.Products.VariantSimple[], mostRelevantVariant: string): v4.Products.VariantSimple {
    return variants?.find(v => v.sku === mostRelevantVariant) ?? variants?.[0];
}
export function productPrices(product: v4.Products.ProductSimple, variantSku: string | undefined = undefined): pricesInterFace {
    let hasFixedUnitPrice: boolean = true;
    let hasFixedComparisonPrice: boolean = true;
    let lowestUnitPrice: number = selectedVariant(product.variants, product.mostRelevantVariant).pricing.unit.amount;
    let lowestComparisonPrice: number | undefined = selectedVariant(product.variants, product.mostRelevantVariant).pricing.comparison?.amount;

    product.variants.map((variant: v4.Products.VariantSimple) => {
        if (variantSku) {
            if (variant.sku === variantSku) {
                lowestUnitPrice = variant.pricing.unit.amount;
                lowestComparisonPrice = variant.pricing.comparison?.amount;
            }
        } else {
            if (variant.pricing.unit.amount !== lowestUnitPrice) {
                hasFixedUnitPrice = false;
            }

            if (variant.pricing.comparison?.amount !== lowestComparisonPrice) {
                hasFixedComparisonPrice = false;
            }

            if (variant.pricing.unit.amount < lowestUnitPrice) {
                lowestUnitPrice = variant.pricing.unit.amount;
            }

            if (
                variant.pricing.comparison?.amount &&
                (!lowestComparisonPrice || variant.pricing.comparison?.amount < lowestComparisonPrice)
            ) {
                lowestComparisonPrice = variant.pricing.comparison?.amount;
            }
        }
    });

    return {
        hasFixedUnitPrice,
        hasFixedComparisonPrice,
        lowestUnitPrice,
        lowestComparisonPrice
    };
}
export function productUnitPriceLabel(prices: pricesInterFace): string {
    return prices.hasFixedUnitPrice === false
        ? translateFilter('product.Price.FromPrice', currencyFilter(prices.lowestUnitPrice, true))
        : currencyFilter(prices.lowestUnitPrice, true);
}
export function productComparisonPriceLabel(product: v4.Products.ProductSimple): string {
    const variant = selectedVariant(product.variants, product.mostRelevantVariant);
    return variant.pricing.comparison?.formatted || '';
}
export function productComparisonUnitLabel(variant: v4.Products.VariantSimple): string | null {
    return variant.pricing.comparison?.formatted || '';
}
export enum ProductAddToCartAvailability {
    Available,
    NotInStock,
    NotForSale,
    SoldFromDate
}
export interface ICanAddToCart {
    sku: string;
    addToCartAvailability: ProductAddToCartAvailability | null;
    inventoryMaxQuantity: number | null;
    showAddToCart?: boolean;
    fewInStockMessage?: null | FewInStockMessage;
    fewInStockMessageLabel?: string | null;
    variantExpired: boolean;
    inventory?: v4.Products.Inventory;
}

function getFewInStockState(variant: v4.Products.VariantSimple | undefined): null | FewInStockMessage {
    let fewInStockMessage: null | FewInStockMessage = null;

    if (variant?.availability.status !== v4.Products.AvailabilityStatus.Available &&
        variant?.availability.status !== v4.Products.AvailabilityStatus.AvailableOnlySoldOnline) {
        return FewInStockMessage.Expired;
    }

    if (!variantIsForSale(variant)) {
        return null;
    }

    if (
        variant?.inventory.state === InventoryState.Normal ||
        variant?.inventory.state === InventoryState.Low ||
        variant?.inventory.state === InventoryState.VeryLow
    ) {
        // In stock
        fewInStockMessage = null;
    } else {
        // Not in stock
        fewInStockMessage = FewInStockMessage.Not;
    }

    return fewInStockMessage;
}

function variantIsForSale(variant: v4.Products.VariantSimple | undefined): boolean {
    if (!variant) {
        return false;
    }

    return variant?.availability.status === v4.Products.AvailabilityStatus.Available ||
           variant?.availability.status === v4.Products.AvailabilityStatus.AvailableOnlySoldOnline;
}

function labelForFewInStock(message: FewInStockMessage | null): null | string {
    if (message === FewInStockMessage.Not) {
        return translateFilter('product.FewInStock.Not');
    }
    return null;
}

export function updateCanAddToBasketState(
    product: v4.Products.ProductSimple | null,
    variant: v4.Products.VariantSimple | undefined
): ICanAddToCart {
    const fewInStockMessage: null | FewInStockMessage = getFewInStockState(variant);
    const onlineOnly = product?.availability?.status === v4.Products.AvailabilityStatus.AvailableOnlySoldOnline;
    const canAddToBasketState: ICanAddToCart = {
        sku: variant?.sku || '',
        addToCartAvailability: null,
        inventoryMaxQuantity: null,
        showAddToCart: true,
        variantExpired: variant?.availability.status !== v4.Products.AvailabilityStatus.Available &&
                        variant?.availability.status !== v4.Products.AvailabilityStatus.AvailableOnlySoldOnline,
        fewInStockMessage: fewInStockMessage,
        fewInStockMessageLabel: labelForFewInStock(fewInStockMessage)
    };
    if (variant?.inventory) {
        if (variant.availability.status === v4.Products.AvailabilityStatus.NeverAvailableOnline) {
            if (!onlineOnly) {
                canAddToBasketState.addToCartAvailability = ProductAddToCartAvailability.NotForSale;
            } else {
                canAddToBasketState.addToCartAvailability = ProductAddToCartAvailability.NotInStock;
            }
        } else if (variant.availability.status === v4.Products.AvailabilityStatus.AvailableAtLaterDate) {
            canAddToBasketState.addToCartAvailability = ProductAddToCartAvailability.SoldFromDate;
        } else {
            if (variant.inventory.inStock > 0) {
                const addToCartAvailability =
                variant.inventory.inStock > 0
                    ? ProductAddToCartAvailability.Available
                    : ProductAddToCartAvailability.NotInStock;
                canAddToBasketState.inventoryMaxQuantity = addToCartAvailability;
                canAddToBasketState.inventoryMaxQuantity = variant.inventory.inStock;
                canAddToBasketState.inventory = variant.inventory;
            } else {
                canAddToBasketState.addToCartAvailability = ProductAddToCartAvailability.NotInStock;
            }
        }
    }
    if (
        canAddToBasketState.variantExpired ||
        canAddToBasketState.inventoryMaxQuantity === 0 ||
        canAddToBasketState.addToCartAvailability === ProductAddToCartAvailability.NotInStock ||
        canAddToBasketState.addToCartAvailability === ProductAddToCartAvailability.NotForSale ||
        canAddToBasketState.addToCartAvailability === ProductAddToCartAvailability.SoldFromDate
    ) {
        canAddToBasketState.showAddToCart = false;
    }
    return canAddToBasketState;
}
export function getFirstVariantInStock(product: v4.Products.ProductSimple, inventory: v4.Products.Inventory[] | null | undefined): v4.Products.VariantSimple | null {
    if (!inventory) return null;
    // Find variant with "normal" stock
    const variantWithNormalStock = product.variants.find((v) => {
        return (
            inventory &&
            inventory.find((inventoryState: v4.Products.Inventory) => {
                return inventoryState && inventoryState.sku === v.sku && inventoryState.state === InventoryState.Normal;
            })
        );
    });
    // Alternative, find variant with stock > 0
    const variantInStock =
        variantWithNormalStock ||
        product.variants.find((v) => {
            return (
                inventory &&
                inventory.find((inventoryState: v4.Products.Inventory) => {
                    return inventoryState && inventoryState.sku === v.sku && inventoryState.inStock > 0;
                })
            );
        });
    return variantInStock || null;
}
export function variantHasEcoCharge(variant: v4.Products.VariantSimple): boolean {
    return !!variant.ecoCharge?.totalPrice.amount;
}
export function variantEcoCharge(variant: v4.Products.VariantSimple | undefined): number {
    return (variant && variant.ecoCharge?.ecoChargeSharePrice.amount) || 0;
}
