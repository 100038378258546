/* eslint-disable */
    export interface LinkGroupModel {
        links: Link[];
        noFollow: boolean;
        title: string;
        trackingName: string;
    }
    export interface PaymentProviderSettingsModel {
        clientKey: string;
        environment: string;
    }
    export interface SearchContextModel {
        batchSearchResultUrl: string;
    }
    export interface ServerCultureModel {
        isoCode: string;
        language: string;
        name: string;
        url: string;
    }
    export interface SitePageData extends PageData {
        entity: EntityData;
        messages: MessageData;
    }
    export interface TopicNavigationModel {
        image: Media;
        text: string;
        title: string;
        url: string;
    }
    export enum ImageType {
        Unspecified = 0,
        PackShot = 1,
        Environmental = 2
    }
    export enum MediaType {
        Image = 0,
        Pack = 0,
        Video = 1,
        Environment = 2,
        Unknown = 3
    }
    export interface NameAndUrl {
        name: string;
        url: string;
    }
    export interface BreadcrumbItem {
        name: string;
        url: string;
    }
    export interface ImageFocalPoint {
        left: number | null;
        top: number | null;
    }
    export interface ImageMap {
        areas: ImageMapArea[];
        id: number | null;
    }
    export interface ImageMapArea {
        coords: string;
        index: number | null;
        type: string;
    }
    export interface JsonContent {
        alias: string;
        content: any;
    }
    export interface LanguageAndUrl {
        language: string;
        url: string;
    }
    export interface Link {
        name: string;
        target: string;
        url: string;
    }
    export interface LinkWithIcon extends NameAndUrl {
        icon: Media;
    }
    export interface Media extends NameAndUrl {
        aspectRatio?: number | null;
        config: any;
        extension: string;
        focalPoint?: ImageFocalPoint;
        height: number;
        imageType: ImageType | null;
        map?: ImageMap;
        mediaUrlConfig?: MediaUrlConfig;
        type: MediaType;
        videoId?: string;
        width: number;
    }
    export interface MediaUrlConfig {
        cdnBaseUrl: string;
        sourceUrl: string;
    }
    export interface Metadata {
        id: string;
        index: boolean;
        languages: LanguageAndUrl[];
        navigationTitle: string;
        seoDescription: string;
        seoImage: Media;
        seoTitle: string;
        url: string;
    }
    export interface Navigation {
        breadcrumb: BreadcrumbItem[];
    }
    export interface PageData {
        jsonContent: JsonContent;
        metadata: Metadata;
        navigation: Navigation;
        showChatbot: boolean;
        showWatermelonChatbot: boolean;
        tracking: TrackingData;
    }
    export interface TrackingData {
        contentGroupId: string;
        contentGroupName: string;
        contentId: number | null;
        contentTypeId: number | null;
    }
    export interface EntityData {
        id: number;
        provider: string;
    }
    export interface MessageData {
        productIds: string[];
    }
    export enum DayOfWeek {
        Sunday = 0,
        Monday = 1,
        Tuesday = 2,
        Wednesday = 3,
        Thursday = 4,
        Friday = 5,
        Saturday = 6
    }
    export enum UriHostNameType {
        Unknown = 0,
        Basic = 1,
        Dns = 2,
        IPv4 = 3,
        IPv6 = 4
    }
    export enum ReferenceType {
        Product = 0,
        DiyProject = 1,
        FeatureStory = 2,
        GalleryStory = 3,
        Experience = 4,
        Recipe = 5,
        HowTo = 6,
        Theme = 7,
        Category = 8,
        Room = 9
    }
    export interface Reference {
        id: string;
        title: string;
        type: ReferenceType;
    }
    export enum DiyType {
        HowTo = 0,
        Recipe = 1
    }
    export enum EffectType {
        Explosion = 0,
        MildExplosion = 1
    }
    export enum FacetType {
        Term = 0,
        Range = 1,
        Bool = 2
    }
    export enum InventoryState {
        Normal = 0,
        Low = 10,
        VeryLow = 20
    }
    export enum ProductDisplayIcon {
        EnergyClass = 0,
        Certifications = 1,
        FSC = 2,
        None = 3
    }
    export enum ShippingLabel {
        Normal = 0,
        Special = 1,
        None = 2
    }
    export interface CategoryPath {
        hrefLangDictionary: KeyValuePair<string, string>[];
        name: string;
        parentCategoryId: string;
        pimId: string;
        url: string;
        urlIdentifier: string;
    }
    export interface Color {
        code: string;
        name: string;
    }
    export interface Diy {
        categoryName: string;
        diyItemGroups: DiyItemGroup[];
        diyType: DiyType;
        downloads: File[];
        id: string;
        imageLandscape: File;
        imagePortrait: File;
        itemsHeadline: string;
        marketTitles: KeyValuePair<string, string>[];
        noShoppingList: boolean;
        productLinks: DiyProductLink[];
        publishDate: Date;
        seoDescription: string;
        seoTitle: string;
        steps: DiyStep[];
        teaser: string;
        teaserHeadline: string;
        title: string;
        tracking: TrackingData;
        url: string;
        video: Video;
    }
    export interface DiyItem {
        measurement: string;
        name: string;
        quantity: number | null;
    }
    export interface DiyItemGroup {
        groupName: string;
        items: DiyItem[];
    }
    export interface DiyProductLink {
        quantity: number;
        sku: string;
    }
    export interface DiySearchRequest {
        category: number[];
        page: number;
        pageSize: number;
        sortBy: string | null;
        term: string | null;
    }
    export interface DiySearchResult {
        diys: DiySnapshot[];
        facets: FacetGroup[];
        sortBy: string;
        total: number;
    }
    export interface DiySnapshot {
        contentId: number;
        id: string;
        image: File;
        lastModified: Date | null;
        text: string;
        title: string;
        type: string;
        typeLabel: string;
        url: string;
    }
    export interface DiyStep {
        description: string;
        headline: string;
        images: Media[];
        number: number | null;
        sortOrder: number;
    }
    export interface Effect {
        colors: string[];
        type: EffectType;
    }
    export interface FacetGroup {
        ceil: number | null;
        floor: number | null;
        key: string;
        max: number | null;
        min: number | null;
        path: string;
        type: FacetType;
        values: FacetValue[];
    }
    export interface FacetSelection {
        facetId: string;
        values: string[];
    }
    export interface File {
        description: string;
        name: string;
        url: string;
    }
    export interface Inventory {
        inStock: number;
        productId: string;
        sku: string;
        state: InventoryState;
    }
    export interface Labeling {
        commercial: CommercialLabeling;
        legal: LegalLabeling;
        safety: SafetyLabeling;
    }
    export interface Nutrition {
        carbohydrate: number | null;
        carbohydrateSugars: number | null;
        energyKcal: number | null;
        energyKj: number | null;
        fat: number | null;
        fatSaturatedAcids: number | null;
        protein: number | null;
        salt: number | null;
        unitOfMeasure: string;
    }
    export interface Product {
        brandId: string;
        brandName: string;
        careInstructions: string[];
        categoryIds: string[];
        cleaning: string[];
        comparisonUnit: string;
        defaultVariantEnergyClass: string;
        description: string;
        directUrl: string;
        displayIcon: ProductDisplayIcon;
        downloads: File[];
        environmentalImage: File;
        foodHandling: string[];
        fullCategoryList: KeyValuePair<string, CategoryPath>[][];
        hrefLangUrls: KeyValuePair<string, string>[];
        id: string;
        images: File[];
        labeling: Labeling;
        marketNames: KeyValuePair<string, string>[];
        mostRelevantVariant: string;
        name: string;
        news: boolean;
        onlineOnly: boolean;
        pimId: number;
        pimLastModified: Date;
        productType: string[];
        secondaryImage: File;
        seoDescription: string;
        seoTitle: string;
        shippingLabel: ShippingLabel;
        shippingRules: ShippingRules;
        shortDescription: string;
        specifications: Specifications;
        stockSortKey: number;
        subTitle: string;
        url: string;
        variantInfo?: v4.Products.ProductVariantInfo;
        variants: Variant[];
        variantType: string;
        videoLink: string;
        vimeoId: string;
        vimeoLink: string;
        warnings: string[];
        youTubeId: string;
        youtubeLink: string;
    }
    export interface ProductSearchRequest {
        brandId: string;
        categoryId: string;
        crossCategory: string;
        facetSelections: FacetSelection[];
        page: number;
        pageSize: number;
        roomId: string;
        sortBy: string;
        term: string;
    }
    export interface ProductSearchResult {
        effect?: Effect;
        facets: FacetGroup[];
        products: Product[];
        sortBy: string;
        total: number;
    }
    export interface ProductSearchResultNew {
        effect?: Effect;
        facets: FacetGroup[];
        isEmpty: boolean;
        searchResultItems: v4.Products.ProductSimple[];
        sortBy: string;
        total: number;
    }
    export interface RelewiseUserContextRequest {
        authenticatedId: string;
        email: string;
        temporaryId: string;
        userCulture: string;
    }
    export interface ShippingRules {
        priceClass: number;
    }
    export interface Specifications {
        gots: boolean;
        oekoTex: boolean;
    }
    export interface Variant {
        allergens: string;
        assemblyGuide: boolean | null;
        barcode: string;
        batteries: string;
        batteryNumber: number | null;
        batteryType: string;
        bookCoverType: string;
        bookPages: number | null;
        brushSize: string[];
        burnTime: string;
        cableLength: number | null;
        carryingCapacity: number | null;
        clothesSize: string;
        colorGroups: Color[];
        colorTemperature: number | null;
        comparisonPriceInclVat: number | null;
        contentDescription: string;
        countryOfOrigin: string;
        crochetingNeedleSize: string;
        crochetingNeedleSizeRecommended: string;
        diameter: number | null;
        dimmable: boolean | null;
        drainedWeight: number | null;
        ecoChargeInclVat: number | null;
        ecomLimited: boolean | null;
        energyClass: string;
        energyClassLabel: string;
        energyConsumptionKWH: number | null;
        energyLabelId: string;
        eprelLink: string;
        expired: boolean;
        glassesStrength: string;
        gramMeter: number | null;
        handmade: boolean | null;
        height: number | null;
        images: File[];
        indoorOutdoor: string;
        ingredients: string;
        knittingGauge: string;
        knittingNeedleSize: string;
        knittingNeedleSizeRecommended: string;
        layer: number | null;
        length: number | null;
        lightbulbSocket: string;
        lumen: number | null;
        material: string[];
        materialDetail: string[];
        maxWatt: string;
        netWeight: number | null;
        notForSale: boolean;
        nutrition: Nutrition;
        otherInformation: string;
        output: string;
        ownDesign: boolean | null;
        packageQuantity: number | null;
        pantoneColor: string[];
        paperPieces: number | null;
        pencilSize: string;
        quantity: number | null;
        ratedLampLife: string;
        realLeather: boolean | null;
        recommendedAge: string;
        salesColor: Color[];
        salesHeight: number | null;
        salesLength: number | null;
        salesWidth: number | null;
        seatHeight: number | null;
        shippingWeight: number;
        shoeSize: string;
        showAsTwoColors: boolean;
        sku: string;
        sockSize: string;
        soldFrom: Date | null;
        sortedMedia: Media[];
        stockSortKey: number;
        unitPriceInclVat: number;
        variantName: string;
        variationData: string[];
        volume: number | null;
        watt: number | null;
        width: number | null;
    }
    export interface Video {
        videoImage: File;
        youtubeIdLandscape: string;
        youtubeIdPortrait: string;
    }
    export enum CalendarAlgorithmType {
        Unknown = 0,
        SolarCalendar = 1,
        LunarCalendar = 2,
        LunisolarCalendar = 3
    }
    export enum CalendarWeekRule {
        FirstDay = 0,
        FirstFullWeek = 1,
        FirstFourDayWeek = 2
    }
    export enum CultureTypes {
        NeutralCultures = 1,
        SpecificCultures = 2,
        InstalledWin32Cultures = 4,
        AllCultures = 7,
        UserCustomCulture = 8,
        ReplacementCultures = 16,
        WindowsOnlyCultures = 32,
        FrameworkCultures = 64
    }
    export enum DigitShapes {
        Context = 0,
        None = 1,
        NativeNational = 2
    }
    export interface Calendar {
        algorithmType: CalendarAlgorithmType;
        eras: number[];
        isReadOnly: boolean;
        maxSupportedDateTime: Date;
        minSupportedDateTime: Date;
        twoDigitYearMax: number;
    }
    export interface CompareInfo {
        lCID: number;
        name: string;
        version: SortVersion;
    }
    export interface CultureInfo {
        calendar: Calendar;
        compareInfo: CompareInfo;
        cultureTypes: CultureTypes;
        currentCulture: CultureInfo;
        currentUICulture: CultureInfo;
        dateTimeFormat: DateTimeFormatInfo;
        defaultThreadCurrentCulture: CultureInfo;
        defaultThreadCurrentUICulture: CultureInfo;
        displayName: string;
        englishName: string;
        ietfLanguageTag: string;
        installedUICulture: CultureInfo;
        invariantCulture: CultureInfo;
        isNeutralCulture: boolean;
        isReadOnly: boolean;
        keyboardLayoutId: number;
        lCID: number;
        name: string;
        nativeName: string;
        numberFormat: NumberFormatInfo;
        optionalCalendars: Calendar[];
        parent: CultureInfo;
        textInfo: TextInfo;
        threeLetterISOLanguageName: string;
        threeLetterWindowsLanguageName: string;
        twoLetterISOLanguageName: string;
        useUserOverride: boolean;
    }
    export interface DateTimeFormatInfo {
        abbreviatedDayNames: string[];
        abbreviatedMonthGenitiveNames: string[];
        abbreviatedMonthNames: string[];
        aMDesignator: string;
        calendar: Calendar;
        calendarWeekRule: CalendarWeekRule;
        currentInfo: DateTimeFormatInfo;
        dateSeparator: string;
        dayNames: string[];
        firstDayOfWeek: DayOfWeek;
        fullDateTimePattern: string;
        invariantInfo: DateTimeFormatInfo;
        isReadOnly: boolean;
        longDatePattern: string;
        longTimePattern: string;
        monthDayPattern: string;
        monthGenitiveNames: string[];
        monthNames: string[];
        nativeCalendarName: string;
        pMDesignator: string;
        rFC1123Pattern: string;
        shortDatePattern: string;
        shortestDayNames: string[];
        shortTimePattern: string;
        sortableDateTimePattern: string;
        timeSeparator: string;
        universalSortableDateTimePattern: string;
        yearMonthPattern: string;
    }
    export interface NumberFormatInfo {
        currencyDecimalDigits: number;
        currencyDecimalSeparator: string;
        currencyGroupSeparator: string;
        currencyGroupSizes: number[];
        currencyNegativePattern: number;
        currencyPositivePattern: number;
        currencySymbol: string;
        currentInfo: NumberFormatInfo;
        digitSubstitution: DigitShapes;
        invariantInfo: NumberFormatInfo;
        isReadOnly: boolean;
        naNSymbol: string;
        nativeDigits: string[];
        negativeInfinitySymbol: string;
        negativeSign: string;
        numberDecimalDigits: number;
        numberDecimalSeparator: string;
        numberGroupSeparator: string;
        numberGroupSizes: number[];
        numberNegativePattern: number;
        percentDecimalDigits: number;
        percentDecimalSeparator: string;
        percentGroupSeparator: string;
        percentGroupSizes: number[];
        percentNegativePattern: number;
        percentPositivePattern: number;
        percentSymbol: string;
        perMilleSymbol: string;
        positiveInfinitySymbol: string;
        positiveSign: string;
    }
    export interface SortVersion {
        fullVersion: number;
        sortId: string;
    }
    export interface TextInfo {
        aNSICodePage: number;
        cultureName: string;
        eBCDICCodePage: number;
        isReadOnly: boolean;
        isRightToLeft: boolean;
        lCID: number;
        listSeparator: string;
        macCodePage: number;
        oEMCodePage: number;
    }
    export interface KeyValuePair<TKey, TValue> {
        key: TKey;
        value: TValue;
    }
    export interface SpacingModel {
        bottom: string;
        top: string;
    }
    export interface ProductImpressionRequest {
        productId: string;
        sku: string;
    }
    export interface Accessibility {
        wheelchairElevator: boolean | null;
        wheelchairEntrance: boolean | null;
        wheelchairParking: boolean | null;
    }
    export interface Location {
        latitude: number;
        longitude: number;
    }
    export interface PaymentOptions {
        americanExpress: boolean | null;
        cashOnly: boolean | null;
        checks: boolean | null;
        chinaUnionPay: boolean | null;
        dankort: boolean | null;
        debitCards: boolean | null;
        dinersClub: boolean | null;
        discover: boolean | null;
        jbc: boolean | null;
        mastercard: boolean | null;
        mobilePayments: boolean | null;
        visa: boolean | null;
    }
    export interface SmileyReport {
        description: string;
        url: string;
    }
    export interface Store {
        accessibility: Accessibility;
        address: string;
        country: string;
        description: string;
        googleMapsUrl: string;
        id: string;
        location: Location;
        name: string;
        openingHours: StoreHours[];
        paymentOptions: PaymentOptions;
        phone: string;
        smileyReports: SmileyReport[];
        url: string;
    }
    export interface StoreHours {
        closes: string | null;
        closingHour: number | null;
        closingMinut: number | null;
        date: Date;
        dayOfWeek: number;
        openingHour: number | null;
        openingMinute: number | null;
        opens: string | null;
        remarks: string;
    }
    export interface StoreSearchRequest {
        latitude: number;
        longitude: number;
    }
    export interface StoreSearchResult {
        stores: StoreSearchResultHit[];
    }
    export interface StoreSearchResultHit {
        distance: number | null;
        store: Store;
    }
    export interface InstagramImage {
        croppedImageUrlDesktop1x: string;
        croppedImageUrlDesktop2x: string;
        croppedImageUrlMobile1x: string;
        croppedImageUrlMobile2x: string;
        imageUrl: string;
        link: string;
        timestamp: string;
    }
    export interface NotificationBarViewModel {
        backgroundColor: string;
        expandedByDefault: boolean;
        icon: Media;
        link: Link;
        text: string;
        textColor: string;
    }
    export interface BasketLineBasics {
        quantity: number;
        sku: string;
    }
    export interface AdyenHandlePaymentRequest {
        checkedoutBasketId: string;
        paymentType: string;
        resultCode: string;
        sessionData: string;
        sessionResult: string;
    }
    export interface AdyenSession {
        amount: Amount;
        cardsLabel: string;
        checkedoutBasketId: string;
        countryCode: string;
        expiresAt: Date;
        id: string;
        merchantAccount: string;
        reference: string;
        returnUrl: string;
        sessionData: string;
    }
    export interface Amount {
        currency: string;
        value: number;
    }
    export interface Basket {
        changedSkus: string[];
        deliveryAddress: Address;
        email: string;
        encryptedEmail: string;
        encryptedPhone: string;
        id: string;
        invoiceAddress: Address;
        lastModified: Date;
        lines: BasketLine[];
        marketingPermission: boolean | null;
        modifiedByServer: boolean;
        mParticleSessionId: string;
        orderNumber: number;
        paymentMethod: string;
        phone: Phone;
        removedSkus: string[];
        shipping: Shipping;
        totalInclVat: number;
        totalVat: number;
        totalWeight: number;
        trackingInformation: string;
    }
    export interface BasketLine extends BasketLineBasics {
        product: Product;
        totalInclVat: number;
        unitPrice: number;
        unitPriceInclVat: number;
    }
    export interface BasketUpdateRequest {
        lines: BasketLineBasics[];
    }
    export interface BasketUpsertRequest {
        deliveryAddress: Address | null;
        dhlPostNumber: string | null;
        dropPoint: DropPoint | null;
        email: string;
        invoiceAddress: Address;
        marketingPermission?: boolean | null;
        newsletterSignup: boolean;
        phone: Phone;
        shippingMethodId: string;
    }
    export interface DropPoint {
        address: Address;
        depot: string;
        distance: number | null;
        distanceFormatted: string;
        id: string;
        latitude: number;
        longitude: number;
        openingHours: DropPointOpeningHoursInterval[];
        routingCode: string;
        simpleOpeningHours: SimpleDropPointOpeningHours[];
    }
    export interface DropPointOpeningHoursDate {
        date: Date | null;
        day: DayOfWeek;
        formatted: string;
    }
    export interface DropPointOpeningHoursInterval {
        closes: DropPointOpeningHoursTime;
        end: DropPointOpeningHoursDate;
        formatted: DropPointOpeningHoursIntervalFormatted;
        opens: DropPointOpeningHoursTime;
        remarks: string[];
        start: DropPointOpeningHoursDate;
    }
    export interface DropPointOpeningHoursIntervalFormatted {
        hours: string;
        label: string;
    }
    export interface DropPointOpeningHoursTime {
        formatted: string;
        minutes: number;
        time: string;
    }
    export interface DropPointsRequest {
        city: string;
        postalCode: string;
        shippingMethodId: string;
        street: string;
    }
    export interface PaymentMethodLogo {
        label: string;
        url: string;
    }
    export interface PaymentSessionRequest {
        adtractionGd: string;
        basket: Basket;
        culture: CultureInfo;
        historianConversionId: string;
        historianPageId: string;
        historianSessionId: string;
        historianTimeOffset: number | null;
        privacyPolicyVersion: string;
        returnUrl: string;
        termsOfTradeVersion: string;
        userAgent: string;
    }
    export interface Phone {
        number: string;
        phonePrefix: string;
    }
    export interface SessionRequest {
        historianConversionId: string;
        historianPageId: string;
        historianSessionId: string;
        historianTimeOffset: number | null;
        marketingPermission: boolean | null;
        mParticleSessionId: string;
    }
    export interface Shipping {
        dhlPostNumber: string | null;
        dropPoint: DropPoint;
        shippingMethod: ShippingMethod;
    }
    export interface ShippingMethod {
        businessDelivery: boolean;
        deliveryTimeDescription: string;
        dropPoint: boolean;
        freeLimit: number | null;
        friendlyName: string;
        fullName: string;
        id: string;
        logoUrl: string;
        name: string;
        price: number;
        requiresPostNumber: boolean;
        storePickup: boolean;
        warehousePickup: boolean;
    }
    export interface SimpleDropPointOpeningHours {
        hours: string;
        label: string;
    }
    export interface UpsertBasketWithRecentShippingRequest {
        basketId: string;
        culture: CultureInfo;
    }
    export interface CommercialLabeling {
        asthmaAndAllergy: boolean;
        ecocert: boolean;
        ecologicalDenmark: boolean;
        ecologicalEurope: boolean;
        fairTrade: boolean;
        fsc: string[];
        gots: string;
        grs: boolean;
        oekotex: string;
        oekotexGreen: string;
        swan: boolean;
        veganCosmetics: boolean;
    }
    export interface LegalLabeling {
        ce: boolean;
        childrenUnapproved: boolean;
        electronicsDumping: boolean;
    }
    export interface SafetyLabeling {
        ce: boolean;
        euhPhrases: string[];
        ghsSymbols: string[];
        hPhrases: string[];
        signalWords: string[];
    }
    export interface Address {
        city: string;
        company: string;
        countryCode: string;
        firstName: string;
        lastName: string;
        postalCode: string;
        shorthand?: string;
        street: string;
        title: string;
    }
    export interface Country {
        countryCode: string;
        isMarket: boolean;
        phoneMaxLength: number;
        phoneMinLength: number;
        phoneNumberPattern: string;
        phonePrefix: string;
        postalCodePattern: string;
        removeLeadingZero: boolean;
    }
    export interface DiysViewedWithDiyRequest {
        diyId: string;
        numberOfResults: number | null;
    }
    export interface ProductsFromUmbracoBlockRequest {
        allowLowStock: boolean;
        cartOpener: boolean;
        categoryId: string;
        contentId: string;
        disableReplacingRecommendations: boolean;
        dropCodeId: string;
        globalColor: string;
        globalMaxPrice: number | null;
        globalMinPrice: number | null;
        globalProductType: string;
        limitToLowStockProducts: boolean;
        news: boolean;
        newToCustomer: boolean;
        numberOfRecommendations: string;
        popularProductsTimespan: number | null;
        priceFilter: boolean;
        recommendationType: RequestTypes;
        roomName: string;
        useEnvironmentalImages: boolean;
    }
    export interface ProductsPurchasedAndViewedWithProductRequest {
        numberOfResults: number | null;
        productId: string;
    }
    export interface ProductsPurchasedAndViewedWithProductResponse {
        productsPurchasedWithProduct: v4.Products.ProductSimple[];
        productsViewedWithProduct: v4.Products.ProductSimple[];
    }
    export interface ProductsPurchasedWithBasketRequest {
        numberOfResults: number | null;
    }
    export interface ProductsPurchasedWithDiyRequest {
        diyId: string;
        numberOfResults: number | null;
    }
    export interface PurgeUserDataRequest {
        email: string;
    }
    export interface IsSignedUpForNewsletterRequest {
        email: string;
    }
    export interface NewsletterSubscribeRequest {
        email: string;
        firstName: string | null;
        lastName: string | null;
    }
    export enum NavigationLinkType {
        Products = 0,
        Rooms = 1,
        Link = 2
    }
    export interface CatalogNavigationModel {
        categories: NameAndUrl[];
        categoryOverview: NameAndUrl;
        inspiration: TopicNavigationModel;
        topics: TopicNavigationModel[];
    }
    export interface FooterNavigationModel {
        annaAndClaraBlockLink: Link;
        annaAndClaraBlockText: string;
        annaAndClaraBlockTitle: string;
        appInfoBlockImage: Media;
        appInfoBlockText: string;
        graphicsDesktop: Media;
        graphicsMobile: Media;
        linkGroupAboveGraphics: LinkGroupModel[];
        linkGroupBelowGraphics: LinkGroupModel[];
    }
    export interface HeaderNavigationModel {
        basket: NameAndUrl;
        currentTopics: TopicNavigationModel[];
        favorites: NameAndUrl;
        findStore: NameAndUrl;
        frontPage: NameAndUrl;
        inspirationalTopic: TopicNavigationModel;
        links: NavigationLinkItem[];
        myPage: NameAndUrl;
        searchSuggestions: NameAndUrl[];
        seasonalLink: LinkWithIcon;
    }
    export interface NavigationLinkItem {
        image: Media;
        nameAndUrl: NameAndUrl;
        navigationHide: boolean;
        subNavigationLinks: NavigationLinkItem[];
        type: NavigationLinkType;
    }
    export interface LegalContentModel {
        deliveryPolicySupplementaryTitle: string;
        deliveryPolicyText: string;
        deliveryPolicyTitle: string;
        privacyPolicyText: string;
        privacyPolicyTitle: string;
        privacyPolicyVersionNumber: string;
        specialDeliveryPolicyText: string;
        specialDeliveryPolicyTitle: string;
        termsOfTradeText: string;
        termsOfTradeTitle: string;
        termsOfTradeVersionNumber: string;
    }
    export interface BaseContentModel {
        blocks: JsonContent[];
    }
    export interface ArticleContentModel {
        blocks: JsonContent[];
    }
    export interface BrandPageContentModel extends BaseContentModel {
        blocksBelow: JsonContent[];
        brandId: string;
        categoryId: string;
        contentId: number;
        crossCategory: string;
        enrichments: JsonContent[];
        hideBreadcrumbPath: boolean;
        image: Media;
        isTopCategory: boolean;
        nextCategoryId: string | null;
        nextCategoryNavigationTitle: string | null;
        nextCategoryUrl: string | null;
        siblingsAndSelfCategories: NavigationLinkItem[];
        subCategories: NavigationLinkItem[];
        title: string;
    }
    export interface CampaignContentModel extends BaseContentModel {
        blocksBelow: JsonContent[];
        categoryId: string;
        enrichments: JsonContent[];
        hideBreadcrumbPath: boolean;
    }
    export interface CategoryContentModel extends BaseContentModel {
        blocksBelow: JsonContent[];
        brandId: string;
        breadCrumbPath: BreadcrumbItem[];
        categoryId: string;
        contentId: number;
        crossCategory: string;
        enrichments: JsonContent[];
        image: Media;
        isTopCategory: boolean;
        nextCategoryId: string | null;
        nextCategoryNavigationTitle: string | null;
        nextCategoryUrl: string | null;
        seoDescription: string;
        seoImage: Media;
        seoTitle: string;
        siblingsAndSelfCategories: NavigationLinkItem[];
        subCategories: NavigationLinkItem[];
        title: string;
        tracking: TrackingData;
    }
    export interface CategoryDetailsContentModel extends BaseContentModel {
        blocksBelow: JsonContent[];
        brandId: string;
        categoryId: string;
        contentId: number;
        crossCategory: string;
        enrichments: JsonContent[];
        hideBreadcrumbPath: boolean;
        image: Media;
        isTopCategory: boolean;
        nextCategoryId: string;
        nextCategoryNavigationTitle: string;
        nextCategoryUrl: string;
        siblingsAndSelfCategories: NavigationLinkItem[];
        subCategories: NavigationLinkItem[];
        title: string;
    }
    export interface CategoryListContentModel extends BaseContentModel {
        categories: JsonContent[];
    }
    export interface ChangePasswordContentModel extends BaseContentModel {
        token: string;
    }
    export interface CheckoutContentModel {
        footerInformation: JsonContent[];
    }
    export interface ContentHubBrand {
        brandId: string;
        contentId: number;
        name: string;
        url: string;
    }
    export interface ContentHubCategory {
        categoryId: string;
        contentId: number;
        icon: Media;
        name: string;
        subCategories: ContentHubCategory[];
        url: string;
    }
    export interface CookiePolicyContentModel extends BaseContentModel {
    }
    export interface CountryPickerContentModel {
    }
    export interface CustomerServiceContentModel extends BaseContentModel {
    }
    export interface DeliveryPolicyContentHubContentModel extends BaseContentModel {
        legalData: LegalDataModel;
    }
    export interface DiyDetailsContentModel {
        diy: Diy;
    }
    export interface DiyListContentModel extends BaseContentModel {
        blocksBelow: JsonContent[];
    }
    export interface ExternalAccountContentModel extends BaseContentModel {
        externalAccountData: ExternalAccountData;
    }
    export interface FaqContentModel extends BaseContentModel {
        groups: JsonContent[];
    }
    export interface HomeContentModel extends BaseContentModel {
    }
    export interface HowToListContentModel {
        blocksBelow: JsonContent[];
        categoryId: number;
        image: Media;
        landscapeImage?: Media;
        seoTextTop: string;
        textColor: string;
        title: string;
        typeLabel: string;
    }
    export interface InspirationListContentModel extends BaseContentModel {
        blocksBelow: JsonContent[];
    }
    export interface LegalDataModel {
        supplementaryTitle: string;
        text: string;
        title: string;
        versionNumber: string;
    }
    export interface MomentDetailsContentModel {
        moment: Moment;
    }
    export interface MomentFrontpageContentModel {
        backgroundColor: string;
        blocksBelow: JsonContent[] | null;
        callToAction: Link | null;
        carrouselMoments: MomentSnapshot[];
        exploreCarrousels: MomentExplorePreviewList[];
        text: string | null;
        textColor: string;
        timeInterval: number | null;
    }
    export interface MomentListContentModel {
        blocksBelowContent: JsonContent[];
        contentHubMomentAlias: string;
        introText: string;
        momentType: string;
        title: string;
    }
    export interface MomentSnapshot {
        contentId: number;
        image: Media;
        lastModified: Date | null;
        title: string;
        type: string;
        typeLabel: string;
        url: string;
    }
    export interface MyFavoriteContentContentModel extends BaseContentModel {
    }
    export interface MyFavoriteProductsContentModel extends BaseContentModel {
    }
    export interface MyOrderDetailsContentModel {
        legalData: LegalDataModel;
    }
    export interface MyOrdersContentModel extends BaseContentModel {
        orderDetails: NameAndUrl;
    }
    export interface MyPageContentModel extends BaseContentModel {
        favoriteContent: NameAndUrl;
        favoriteProducts: NameAndUrl;
        orders: NameAndUrl;
        personalInformation: NameAndUrl;
    }
    export interface MyPersonalInformationContentModel extends BaseContentModel {
    }
    export interface PageNotFoundContentModel extends BaseContentModel {
        blocksBelow: JsonContent[];
        diyRecommendations: DiySnapshot[];
        productRecommendations?: v4.Products.ProductSimple[];
    }
    export interface PrivacyPolicyContentHubContentModel extends BaseContentModel {
        legalData: LegalDataModel;
    }
    export interface PrivacyPolicyContentModel extends BaseContentModel {
    }
    export interface ProductDetailsContentModel {
        details: v4.Products.ProductDetails;
        supplementaryTitle: string;
    }
    export interface ProductNewsContentModel extends BaseContentModel {
        blocksBelow: JsonContent[];
        enrichments: JsonContent[];
    }
    export interface ReceiptContentModel extends BaseContentModel {
        accountState: AccountEmailState | null;
        convertedBasket: Basket;
        convertedBasketId: string | null;
        diyRecommendations: DiySnapshot[];
        email: string;
        errorMessage: string;
        marketingPermission: boolean | null;
        orderNumber: string;
        trackConvertedBasket: boolean;
    }
    export interface ReturnPolicyContentHubContentModel extends BaseContentModel {
        legalData: LegalDataModel;
    }
    export interface RoomContentModel extends BaseContentModel {
        categoryImage: Media;
        contentId: number;
        enrichments: JsonContent[];
        roomId: string;
        title: string;
    }
    export interface RoomDetailsContentModel extends BaseContentModel {
        blocksBelow: JsonContent[];
        categoryImage: Media;
        contentId: number;
        enrichments: JsonContent[];
        roomId: string;
        seoDescription: string;
        seoTitle: string;
        title: string;
        tracking: TrackingData;
    }
    export interface RoomListContentModel extends BaseContentModel {
        rooms: JsonContent[];
    }
    export interface SearchResultBatchedSearchContentModel extends BaseContentModel {
        blocks: JsonContent[];
    }
    export interface SharedFavoritesContentModel extends BaseContentModel {
        favoriteProductIds: string[];
    }
    export interface SpecialDeliveryPolicyContentHubContentModel extends BaseContentModel {
        legalData: LegalDataModel;
    }
    export interface StoreDetailsContentModel {
        store: Store;
    }
    export interface StoreListContentModel extends BaseContentModel {
        preRenderStores: Store[];
    }
    export interface TermsOfTradeContentHubContentModel extends BaseContentModel {
        legalData: LegalDataModel;
    }
    export interface TermsOfTradeContentModel extends BaseContentModel {
    }
    export interface ThemeDetailsContentModel {
        theme: Theme;
    }
    export interface TopicContentModel extends BaseContentModel {
    }
    export enum OrderState {
        Received = 0,
        InProgress = 1,
        Completed = 2,
        Delivered = 3,
        Unexported = 4
    }
    export interface AccountCreateRequestBase {
        dateOfBirth: string;
        email: string;
        firstName: string;
        lastName: string;
        newsletterSignup: boolean;
        phone: Phone;
        title: string;
    }
    export interface AccountCreateForExternalSourceRequest extends AccountCreateRequestBase {
        externalToken: string;
        source: string;
    }
    export interface AccountCreateFromBasketRequest {
        basketId: string;
        dateOfBirth: string;
        password: string;
    }
    export interface AccountCreateRequest extends AccountCreateRequestBase {
        password: string;
    }
    export interface AccountExistsRequest {
        email: string;
    }
    export interface AccountExistsResult {
        culture: string;
        exists: boolean;
    }
    export interface AccountGetTokenWithAnonymousBasketRequest {
        basketId: string;
        email: string;
        password: string;
    }
    export interface AccountInfo {
        accountId: string;
        dateOfBirth: string;
        email: string;
        encryptedEmail: string;
        encryptedPhone: string;
        externalId: string;
        favoriteStoreId: string;
        invoice: Address;
        isExternal: boolean;
        newsletterSignup: boolean;
        phone: Phone;
    }
    export interface AccountInfoAndToken {
        accountId: string;
        accountInfo: AccountInfo;
        redirectUrl: string;
        token: string;
    }
    export interface AccountUpdateRequest {
        city: string | null;
        dateOfBirth: string | null;
        email: string | null;
        favoriteStoreId: string | null;
        firstName: string | null;
        lastName: string | null;
        phone: Phone | null;
        postalCode: string | null;
        street: string | null;
        title: string | null;
    }
    export interface ChangePasswordRequest {
        newPassword: string;
        oldPassword: string;
    }
    export interface CreateRemindMeRequest {
        email: string;
        productId: string;
        sku: string;
    }
    export interface ExternalAccountData {
        email: string;
        externalToken: string;
        firstName: string;
        lastName: string;
        source: string;
    }
    export interface FavoriteContentModel {
        id: string;
        image: Media;
        title: string;
        url: string;
    }
    export interface FavoritesModel {
        content: FavoriteContentModel[];
        inspiration: string[];
        products: string[];
        shareUrl: string;
    }
    export interface OrderDetails {
        complaintLink: string;
        created: Date;
        delivery: Address;
        displayFiscalNumber: boolean;
        fiscalNumber: string;
        goodsInclVat: number;
        invoice: Address;
        orderLines: OrderLine[];
        orderNumber: string;
        paymentMethod: string;
        shippingInclVat: number;
        state: OrderState;
        totalEcoChargeInclVat: number;
        totalInclVat: number;
        totalVat: number;
        trackingUrls: string[];
        vatNumber: string;
        warehousePickup: boolean;
    }
    export interface OrderDetailsRequest {
        orderNumber: string;
        state: OrderState;
        unexported: boolean;
    }
    export interface OrderLine {
        description: string;
        ecoChargeInclVat: number;
        ecoChargeTotalInclVat: number;
        name: string;
        quantity: number;
        sku: string;
        totalInclVat: number;
        unitPriceInclVat: number;
        vat: number;
    }
    export interface OrderSnapshot {
        created: Date;
        orderNumber: string;
        paymentMethod: string;
        state: OrderState;
        storeName: string;
        totalInclVat: number;
        trackingUrls: string[];
        unexported: boolean;
        warehousePickup: boolean;
    }
    export interface RequestResetPasswordRequest {
        email: string;
    }
    export interface ResetPasswordRequest {
        email: string;
        password: string;
        token: string;
    }
    export interface ContentHubBlockWithAlias {
        alias: string;
    }
    export interface ContentHubEnrichmentBlock {
        position: number;
    }
    export interface MomentExplorePreviewListBase {
        alias: string;
        previewItems: MomentSnapshot[];
    }
    export interface ContentHubAnnaAndClaraDialogueBlock {
        annaSays: string;
        claraSays: string;
        url: string;
    }
    export interface ContentHubCaption {
        bodyText: string;
        introText: string;
    }
    export interface ContentHubCollectionBlock {
        backgroundColor: string;
        heading: string;
        previewItems: ContentHubPreviewListItem[];
        spacing: SpacingModel;
        text: string;
        textColor: string;
        url: string;
        view: string;
    }
    export interface ContentHubContentHubLink {
        id: string;
        label: string;
        type: string;
        url: string;
    }
    export interface ContentHubContentTeaserBlock {
        image: Media;
        link: ContentHubContentHubLink;
        spacing: SpacingModel;
        text: string;
        title: string;
        url: string;
    }
    export interface ContentHubContentTeaserNoImageBlock {
        backgroundColor: string;
        link: ContentHubContentHubLink;
        spacing: SpacingModel;
        supplementaryTitle: string;
        text: string;
        textColor: string;
        title: string;
        url: string;
    }
    export interface ContentHubDateBlock {
        date: string;
        url: string;
    }
    export interface ContentHubEnrichment extends ContentHubBlockWithAlias {
        content: any;
    }
    export interface ContentHubEnrichmentSize {
        cols: number;
        rows: number;
    }
    export interface ContentHubExperienceBlock {
        backgroundColor: string;
        contentId: number;
        image: Media;
        spacing: SpacingModel;
        textColor: string;
        title: string;
        type: string;
        typeLabel: string;
        url: string;
    }
    export interface ContentHubExperienceDescriptionBlock {
        backgroundColor: string;
        text: string;
        textColor: string;
        title: string;
        url: string;
    }
    export interface ContentHubExperienceExitBlock {
        backgroundColor: string;
        cta: string;
        icon: Media;
        text: string;
        textColor: string;
        title: string;
        url: string;
    }
    export interface ContentHubExperienceHint {
        text: string;
        title: string;
    }
    export interface ContentHubExperienceHintsBlock {
        backgroundColor: string;
        hints: ContentHubExperienceHint[];
        icon: Media;
        textColor: string;
        title: string;
        url: string;
    }
    export interface ContentHubExperienceIntroBlock {
        backgroundImage: Media;
        title: string;
        url: string;
        vimeoVideoId: string;
        vimeoVideoUrl: string;
    }
    export interface ContentHubFeaturedProductEnrichmentBlock extends ContentHubEnrichmentBlock {
        image: Media;
        productId: string;
        size: ContentHubEnrichmentSize;
        sku: string;
        textColor: string;
        title: string;
        useEnvironmentalImage: boolean;
    }
    export interface ContentHubFeatureStoryBlock {
        backgroundColor: string;
        contentId: number;
        image: Media;
        spacing: SpacingModel;
        textColor: string;
        title: string;
        type: string;
        typeLabel: string;
        url: string;
    }
    export interface ContentHubFeatureStoryCombinedHeaderBlock {
        alias: string;
        content: ContentHubFeatureStoryCombinedHeaderBlockContent;
    }
    export interface ContentHubFeatureStoryCombinedHeaderBlockContent {
        backgroundColor: string;
        date: string;
        image: Media;
        signature: string;
        text: string;
        textColor: string;
        title: string;
    }
    export interface ContentHubFeatureStoryFooterBlock {
        backgroundColor: string;
        content: ContentHubFeatureStoryFooterBlockContent[];
        image: Media;
        textColor: string;
        url: string;
    }
    export interface ContentHubFeatureStoryFooterBlockContent {
        text: string;
        title: string;
    }
    export interface ContentHubFeatureStoryHeaderBlock {
        backgroundColor: string;
        image: Media;
        signature: string;
        textColor: string;
        title: string;
        url: string;
    }
    export interface ContentHubFeatureStoryIntroTextBlock {
        text: string;
        url: string;
    }
    export interface ContentHubFrontPageHeaderBlock {
        heading: string;
        headingImage: Media;
        introText: string;
        url: string;
    }
    export interface ContentHubGalleryStoryBlock {
        contentId: number;
        primaryImage: Media;
        secondaryImage: Media;
        spacing: SpacingModel;
        title: string;
        type: string;
        typeLabel: string;
        url: string;
    }
    export interface ContentHubGalleryStoryHeaderBlock {
        backgroundColor: string;
        image: Media;
        introText: string;
        previewImage: Media;
        textColor: string;
        title: string;
        url: string;
    }
    export interface ContentHubHeaderBlock {
        backgroundColor: string;
        maxTextWidth: number;
        spacing: SpacingModel;
        supplementaryTitle: string;
        textColor: string;
        title: string;
        url: string;
    }
    export interface ContentHubHowToBlock {
        backgroundColor: string;
        contentId: number;
        imageLandscape: Media;
        imagePortrait: Media;
        spacing: SpacingModel;
        textColor: string;
        title: string;
        type: string;
        typeLabel: string;
        url: string;
    }
    export interface ContentHubImageBlock {
        backgroundColor: string;
        caption: ContentHubCaption;
        image: Media;
        spacing: SpacingModel;
        textColor: string;
        url: string;
    }
    export interface ContentHubImageWithTextBoxBlock {
        backgroundColor: string;
        caption: ContentHubCaption;
        image: Media;
        spacing: SpacingModel;
        textBoxAlignment: string;
        textBoxColor: string;
        textColor: string;
        url: string;
    }
    export interface ContentHubMomentPreviewWrapperBlock {
        alias: string;
        content: JsonContent[];
    }
    export interface ContentHubPreviewListBlock {
        modifiedHeading: boolean;
        previewItems: ContentHubPreviewListItem[];
        previewItemsType: string;
        spacing: SpacingModel;
        url: string;
    }
    export interface ContentHubPreviewListItem {
        contentId: string;
        image: Media;
        text: string;
        title: string;
        type: string;
        typeLabel: string;
        url: string;
    }
    export interface ContentHubProductAssortmentBlock {
        backgroundColor: string;
        displayStyle: string;
        enrichments: ContentHubEnrichment[];
        products: ContentHubProductAssortmentProduct[];
        spacing: SpacingModel;
        textColor: string;
        url: string;
        useEnvironmentalImages: boolean;
    }
    export interface ContentHubProductAssortmentProduct {
        image: Media;
        productId: string;
        sku: string;
    }
    export interface ContentHubProductDisplayBlock {
        backgroundColor: string;
        displaySecondImage: boolean;
        image: Media;
        products: ContentHubProductDisplayProduct[];
        secondImage: Media;
        secondProducts: ContentHubProductDisplayProduct[];
        spacing: SpacingModel;
        supplementaryTitle: string;
        text: string;
        textColor: string;
        title: string;
        url: string;
    }
    export interface ContentHubProductDisplayProduct {
        defaultActive: boolean;
        left: number;
        productId: string;
        text: string;
        top: number;
        variantSku: string;
    }
    export interface ContentHubProductRecommendationsBlock {
        backgroundColor: string;
        blockKey: string;
        contentId: string;
        spacing: SpacingModel;
        textColor: string;
        title: string;
        url: string;
        useEnvironmentalImages: boolean;
        viewType: string;
    }
    export interface ContentHubQuoteBlock {
        backgroundColor: string;
        spacing: SpacingModel;
        supplementaryText: string;
        text: string;
        textColor: string;
        url: string;
    }
    export interface ContentHubQuoteBoxBlock {
        backgroundColor: string;
        boxBackgroundColor: string;
        spacing: SpacingModel;
        supplementaryText: string;
        text: string;
        textColor: string;
        url: string;
    }
    export interface ContentHubRecipeBlock {
        contentId: number;
        imageLandscape: Media;
        imagePortrait: Media;
        spacing: SpacingModel;
        title: string;
        type: string;
        typeLabel: string;
        url: string;
    }
    export interface ContentHubRecommendedProductEnrichmentBlock extends ContentHubEnrichmentBlock {
        recommendationsModel: RecommendationsModel;
        size: ContentHubEnrichmentSize;
        useEnvironmentalImage: boolean;
    }
    export interface ContentHubScalableImageBlock {
        backgroundColor: string;
        image: Media;
        spacing: SpacingModel;
        textColor: string;
    }
    export interface ContentHubSimpleHeaderBlock {
        spacing: SpacingModel;
        title: string;
        url: string;
    }
    export interface ContentHubTeaserTextBlock {
        backgroundColor: string;
        largeTitle: boolean;
        link: ContentHubContentHubLink;
        spacing: SpacingModel;
        text: string;
        textColor: string;
        title: string;
        url: string;
    }
    export interface ContentHubTextBlock {
        alignment: string;
        heading: string;
        spacing: SpacingModel;
        subHeading: string;
        text: string;
        url: string;
    }
    export interface ContentHubThemeBlock {
        contentId: number;
        linkLabel: string;
        previewItems: ContentHubPreviewListItem[];
        spacing: SpacingModel;
        title: string;
        type: string;
        typeLabel: string;
        url: string;
    }
    export interface ContentHubThemeHeaderBlock {
        image: Media;
        introText: string;
        landscapeImage: Media;
        title: string;
        url: string;
    }
    export interface ContentHubTracking {
        contentGroupId: string;
        contentGroupName: string;
        contentId: number;
        contentTypeId: number;
        screenClass: string;
        screenName: string;
    }
    export interface ContentHubTwoImagesBlock {
        backgroundColor: string;
        caption1: ContentHubCaption;
        caption2: ContentHubCaption;
        image1: Media;
        image2: Media;
        spacing: SpacingModel;
        textColor: string;
        url: string;
    }
    export interface ContentHubUspEnrichmentBlock extends ContentHubEnrichmentBlock {
        icon: Media;
        text: string;
        textColor: string;
        title: string;
    }
    export interface Moment {
        alias: string;
        blocks: any[];
        contentId: number;
        contentName: string;
        seo: MomentSeo;
        tracking: ContentHubTracking;
    }
    export interface MomentExploreMomentTypePreviewList extends MomentExplorePreviewListBase {
        previewItemsType: string;
    }
    export interface MomentExplorePreviewList extends MomentExplorePreviewListBase {
        heading: string;
        isThemePreviewList: boolean;
        itemHeading: string;
        url: string;
    }
    export interface MomentExploreResult {
        creativeCategories: MomentExploreMomentTypePreviewList;
        experiences: MomentExploreMomentTypePreviewList;
        featureStories: MomentExploreMomentTypePreviewList;
        galleryStories: MomentExploreMomentTypePreviewList;
        recipes: MomentExploreMomentTypePreviewList;
        themes: MomentExploreThemePreviewList[];
    }
    export interface MomentExploreThemePreviewList extends MomentExplorePreviewListBase {
        categoryName: string;
    }
    export interface MomentSearchRequest {
        category?: number[];
        contentType: string[];
        includeDiys?: boolean;
        page: number;
        pageSize: number;
        sortBy: string | null;
        term: string | null;
    }
    export interface MomentSearchResult {
        facets: FacetGroup[];
        moments: MomentSnapshot[];
        sortBy: string;
        total: number;
    }
    export interface MomentSeo {
        pathByCulture: KeyValuePair<string, NameAndId[]>[];
    }
    export interface MomentWithSeoContentModel {
        alias: string;
        blocks: any[];
        blocksBelow: JsonContent[];
        contentId: number;
        contentName: string;
        seo: SeoContent;
        tracking: ContentHubTracking;
    }
    export interface Theme {
        alias: string;
        blocksBelow: JsonContent[];
        id: number;
        image: Media;
        introText: string;
        landscapeImage: Media;
        moments: JsonContent[];
        seoDescription: string;
        seoImage: Media;
        seoTitle: string;
        title: string;
        typeLabel: string;
    }
    export interface NameAndId {
        id: number;
        name: string;
    }
    export interface SeoContent {
        description: string;
        image: Media;
        pathByCulture: KeyValuePair<string, NameAndId[]>[];
        seoBlocks: any[];
        title: string;
    }
    export interface AccordionBlockModel {
        availability: PublishUnpublishModel | null;
        backgroundColor: string;
        column1Items: JsonContent[];
        column2Items: JsonContent[];
        spacing: SpacingModel;
        textColor: string;
    }
    export interface AccordionItemBlockModel {
        anchorLink: string;
        text: string;
        title: string;
    }
    export interface ArticleAsymmetricalImagesBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string;
        leftPart: JsonContent;
        rightPart: JsonContent;
        spacing: SpacingModel;
        textColor: string;
    }
    export interface ArticleImageBlockModel {
        image: Media;
        text: string;
    }
    export interface ArticleTextBlockModel {
        anchorLink: string;
        image: Media;
        imageLink: Link;
        imageText: string;
        leadParagraph: string;
        text: string;
        title: string;
        trackingName: string;
    }
    export interface CampaignProductFocusBlockModel {
        availability: PublishUnpublishModel | null;
        backgroundColor: string;
        leftPart: JsonContent;
        rightPart: JsonContent;
        spacing: SpacingModel | null;
        textColor: string;
        trackingName: string;
    }
    export interface CategoryListCategoryBlockModel {
        categoryLink: Link;
        image: Media;
        trackingName: string;
    }
    export interface CategoryListContentTeaserBlockModel {
        image: Media;
        link: Link;
        size: GridSizeFourthsModel;
        supplementaryTitle: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface CategoryListTopicTeaserBlockModel {
        image: Media;
        link: Link;
        size: GridSizeFourthsModel;
        subTitle: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface ContentFocusBlockModel {
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        leftPart: JsonContent;
        rightPart: JsonContent;
        spacing: SpacingModel | null;
        textColor: string;
    }
    export interface ContentSpotBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        headerType: string;
        icon: Media | null;
        link: Link | null;
        mainImage: Media | null;
        productId: string;
        secondaryImage: Media | null;
        sku: string;
        spacing: SpacingModel | null;
        supplementaryTitle: string;
        text: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface ContentSpotTallBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        image: Media;
        link: Link;
        spacing: SpacingModel | null;
        supplementaryTitle: string;
        text: string;
        textColor: string;
        textOnLeft: boolean;
        title: string;
        trackingName: string;
    }
    export interface ContentTeasersBlockModel {
        availability: PublishUnpublishModel | null;
        blocks: JsonContent[];
    }
    export interface FaqGroupBlockModel {
        anchorLink: string;
        items: JsonContent[];
        title: string;
    }
    export interface FaqGroupItemBlockModel {
        text: string;
        title: string;
    }
    export interface FeaturedProductBlockModel {
        image: Media;
        link: Link;
        position: number;
        productId: string;
        size: GridSizeFourthsModel;
        sku: string;
        textColor: string;
        title: string;
        trackingName: string;
        useEnvironmentalImage: boolean;
    }
    export interface FindStoreBlockModel {
        anchorLink: string;
        backgroundColor: string;
        backgroundPattern: Media;
        buttonColor: string;
        contentBackgroundColor: string;
        icon: Media;
        spacing: SpacingModel;
        textColor: string;
        title: string;
    }
    export interface FridayStarBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        icon: Media;
        image: Media;
        imageTextColor: string;
        productId: string;
        subTitle: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface FullWidthPatternBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundPattern: Media | null;
        headerType: string;
        link: Link;
        linkColor: string;
        maximumTitleWidth: number;
        spacing: SpacingModel | null;
        text: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface HeaderBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        headerType: string;
        image: Media;
        imageAlignment: string;
        maxTextWidth: number;
        maxTitleWidth: number;
        spacing: SpacingModel | null;
        supplementaryTitle: string;
        text: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface HeroBlockModel {
        altText: string;
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        headerType: string;
        image: Media;
        imageSquareFormat: Media;
        imageTallFormat: Media;
        link: Link;
        maxTitleWidth: number;
        parallaxEffect: ParallaxScrolling;
        supplementaryTitle: string;
        textColor: string;
        textColorMobile: string;
        textOffset: number;
        textOffsetMobile: number;
        title: string;
        trackingName: string;
        videoUrlDefault: string;
        videoUrlPortrait: string;
        vimeoIdDefault: string;
        vimeoIdPortrait: string;
    }
    export interface HeroScrollBlockModel {
        altText: string;
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        fadeOutContentOfLastScreen: boolean;
        headerType: string;
        imageLandscape: Media;
        imageLandscapeEndZoom: number;
        imagePortrait: Media;
        imagePortraitEndZoom: number;
        overlayImageLandscape: Media;
        overlayImageLandscapeEndZoom: number;
        overlayImagePortrait: Media;
        overlayImagePortraitEndZoom: number;
        screens: JsonContent[];
        screenSizeMultiplier: number;
        showScrollArrowDown: boolean;
        textColor: string;
    }
    export interface HorizontalLineBlockModel {
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        spacing: SpacingModel | null;
        textColor: string;
    }
    export interface IconListBlockModel {
        backgroundColor: string;
        icons: JsonContent[];
        spacing: SpacingModel;
        textColor: string;
    }
    export interface IconTitleTextBlockModel {
        icon: Media | null;
        text: string;
        title: string;
    }
    export interface ImageBlockModel {
        altText: string;
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        image: Media;
        spacing: SpacingModel | null;
        textColor: string;
        title: string;
    }
    export interface InspirationalSpotBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        image: Media;
        imageLeadParagraph: string;
        imageText: string;
        link: Link;
        mobileVideoImage: Media;
        mobileVideoUrl: string;
        mobileYouTubeId: string;
        spacing: SpacingModel | null;
        supplementaryTitle: string;
        text: string;
        textColor: string;
        title: string;
        trackingName: string;
        videoImage: Media;
        videoOnTop: boolean;
        videoUrl: string;
        youTubeId: string;
    }
    export interface InstagramBlockModel {
        availability: PublishUnpublishModel;
        backgroundColor: string;
        headerType: string;
        instagramLinkText: string;
        instagramLinkUrl: string;
        numberOfImages: number;
        showInstagramLink: boolean;
        spacing: SpacingModel;
        supplementaryTitle: string;
        text: string;
        textColor: string;
        title: string;
    }
    export interface LargeProductTeaserBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel;
        backgroundColor: string;
        headerType: string;
        largeProducts: JsonContent[];
        link: Link;
        smallProducts: ProductListItemModel[];
        supplementaryTitle: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface LinkBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        behavior: string;
        headerType: string;
        links: JsonContent[];
        spacing: SpacingModel | null;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface NewsletterSignupBlockModel {
        anchorLink: string;
        backgroundColor: string;
        backgroundPattern: Media;
        buttonColor: string;
        contentBackgroundColor: string;
        icon: Media;
        spacing: SpacingModel;
        textColor: string;
        title: string;
    }
    export interface ProductAssortmentBlockModel {
        altText: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        blocks: JsonContent[];
        displayStyle: string;
        link: Link;
        products: ProductListItemModel[];
        spacing: SpacingModel | null;
        textColor: string;
        trackingName: string;
        useEnvironmentalImages: boolean;
    }
    export interface ProductCollectionBlockModel {
        altText: string;
        anchorLink: string;
        backgroundColor: string;
        headerType: string;
        products: ProductListItemModel[];
        spacing: SpacingModel;
        supplementaryTitle: string;
        textColor: string;
        title: string;
        trackingName: string;
        useEnvironmentalImages: boolean;
    }
    export interface ProductDisplayBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        description: string;
        displaySecondImageOnMobile: boolean;
        headerType: string;
        imageText: string;
        link: Link;
        products: ProductDisplayModel;
        products_new: JsonContent;
        products2: ProductDisplayModel;
        products2_new: JsonContent;
        products3: ProductDisplayModel;
        products3_new: JsonContent;
        productTextColor: string;
        spacing: SpacingModel | null;
        supplementaryTitle: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface ProductDisplaySplitViewBlockModel {
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        hotspotTextColor: string;
        productBackgroundColor: string;
        products: ProductDisplayModel;
        spacing: SpacingModel | null;
        textColor: string;
        trackingName: string;
    }
    export interface ProductGalleryBlockModel {
        altText: string;
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        headerType: string;
        link: Link;
        products: ProductListItemModel[];
        spacing: SpacingModel | null;
        supplementaryTitle: string;
        text: string;
        textColor: string;
        title: string;
        trackingName: string;
        useEnvironmentalImages: boolean;
    }
    export interface ProductListBlockModel {
        altText: string;
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        blocks: JsonContent[];
        products: ProductListItemModel[];
        spacing: SpacingModel | null;
        textColor: string;
        title: string;
        trackingName: string;
        useEnvironmentalImages: boolean;
    }
    export interface QuoteBlockModel {
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        maxTextWidth: number;
        spacing: SpacingModel | null;
        supplementaryText: string;
        text: string;
        textColor: string;
    }
    export interface RecommendationsBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        spacing: SpacingModel | null;
        textColor: string;
        title: string;
        trackingName: string;
        useEnvironmentalImages: boolean;
        value: RecommendationsModel;
    }
    export interface RoomListRoomBlockModel {
        image: Media;
        roomLink: Link;
        trackingName: string;
    }
    export interface ShoppingListBlockModel {
        anchorLink: string;
        backgroundColor: string;
        products: ProductListItemModel[];
        supplementaryTitle: string;
        textColor: string;
        title: string;
        trackingName: string;
        useEnvironmentalImages: boolean;
    }
    export interface SplitTestBlockModel {
        featureKey: string;
        variations: JsonContent[];
    }
    export interface TeaserTextBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        headerType: string;
        largeTitle: boolean;
        link: Link;
        maxTextWidth: number;
        spacing: SpacingModel | null;
        supplementaryTitle: string;
        text: string;
        textColor: string;
        trackingName: string;
    }
    export interface TextBlockModel {
        alignment: string;
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        headerType: string;
        spacing: SpacingModel | null;
        text: string;
        textColor: string;
        title: string;
    }
    export interface TextBoxesBlockModel {
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        blocksPerLine: number;
        boxes: JsonContent[];
        spacing: SpacingModel | null;
        textColor: string;
    }
    export interface TextSectionBlockModel {
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        bodyText: string;
        headerType: string;
        heading: string;
        image: Media;
        spacing: SpacingModel | null;
        teaserText: string;
        textColor: string;
    }
    export interface TextSectionHeaderBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        headerType: string;
        maximumWidth: number;
        spacing: SpacingModel | null;
        supplementaryTitle: string;
        textColor: string;
        title: string;
    }
    export interface TitleAndTextBlockModel {
        text: string;
        title: string;
    }
    export interface TopicListContentTeaserBlockModel {
        image: Media;
        link: Link;
        position: number;
        size: string;
        subTitle: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface TwoIconsBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        iconOne: Media;
        iconTwo: Media;
        linkOne: Link;
        linkTwo: Link;
        showOnDesktop: boolean;
        showOnMobile: boolean;
        spacing: SpacingModel | null;
        spacingBetweenIcons: HorizontalSpacingModel;
        trackingName: string;
    }
    export interface UniqueSellingPointsBlockModel {
        anchorLink: string;
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        items: JsonContent[];
        spacing: SpacingModel | null;
        textColor: string;
    }
    export interface UniqueSellingPointsItemBlockModel {
        description: string;
        icon: Media;
        title: string;
    }
    export interface UspBlockModel {
        backgroundColor: string;
        icon: Media;
        link: Link;
        position: number;
        text: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface VariationBlockModel {
        blocks: JsonContent[];
        variationKey: string;
    }
    export interface VideoBlockModel {
        availability: PublishUnpublishModel | null;
        backgroundColor: string | null;
        image: Media;
        introText: string;
        mobileImage: Media;
        mobileVideoUrl: string;
        mobileYouTubeId: string;
        spacing: SpacingModel | null;
        text: string;
        textColor: string;
        videoUrl: string;
        youTubeId: string;
    }
    export interface PublishUnpublishModel {
        publishAt: Date;
        unpublishAt: Date;
    }
    export interface GridSizeFourthsModel {
        cols: number;
        rows: number;
    }
    export interface ProductDisplayModel {
        image: Media;
        markers: ProductDisplayMarkerModel[];
        useOnDesktop: boolean;
        useOnMobile: boolean;
    }
    export interface ProductListItemModel {
        image: Media | null;
        productId: string;
        sku: string;
    }
    export interface ProductDisplayMarkerModel {
        isDefault: boolean;
        left: number;
        productId: string;
        text: string;
        top: number;
        variantSku: string;
    }
    export interface RecommendationsModel {
        allowLowStock: boolean;
        cartOpener: boolean;
        categoryId: number | null;
        disableReplacingRecommendations: boolean;
        dropCodeId: string;
        globalColor: string;
        globalMaxPrice: number | null;
        globalMinPrice: number | null;
        globalProductType: string;
        limitToLowStockProducts: boolean;
        news: boolean;
        newToCustomer: boolean;
        numberOfRecommendations: string;
        popularProductsTimespan: number | null;
        priceFilter: boolean;
        recommendationType: string;
        roomName: string;
        useEnvironmentalImages: boolean;
        viewType: string;
    }
    export interface HorizontalSpacingModel {
        horizontal: string;
    }
    export interface ContentFocusBlockPartModel {
        image: Media;
        link: Link;
        supplementaryTitle: string;
        text: string;
        title: string;
        trackingName: string;
    }
    export interface ContentHubBrandPickerLinkBlockModel {
        image: Media;
        target: ContentHubBrand;
        targetText: string;
        text: string;
        title: string;
        trackingName: string;
    }
    export interface ContentHubCategoryPickerLinkBlockModel {
        image: Media;
        target: ContentHubCategory;
        targetText: string;
        text: string;
        title: string;
        trackingName: string;
    }
    export interface ContentTeasersBlockImagePartModel {
        image: Media;
        link: Link;
        subTitle: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface ContentTeasersBlockTextPartModel {
        backgroundColor: string;
        backgroundPattern: Media;
        headerType: string;
        icon: Media;
        link: Link;
        text: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface HeroScrollBlockPartModel {
        backgroundColor: string;
        bodyText: string;
        effectEnd: number;
        effectStart: number;
        effectType: HeroBannerScreenEffect;
        maxBodyTextWidth: number;
        maxTitleWidth: number;
        supplementaryTitle: string;
        textColor: string;
        title: string;
    }
    export interface IconListBlockPartModel {
        heading: string;
        icon: Media;
        text: string;
    }
    export interface LinkBlockPartModel {
        image: NameAndUrl;
        target: Link;
        text: string;
        title: string;
        trackingName: string;
    }
    export interface ProductDisplayImageBlockModel {
        image: ProductDisplayModel;
    }
    export interface ProductFocusBlockPartModel {
        image: Media;
        productId: string;
        sku: string;
        text: string;
        useEnvironmentalImage: boolean;
    }
    export interface ProductListBlockContentTeaserPartModel {
        image: Media;
        link: Link;
        position: number;
        size: GridSizeFourthsModel;
        subTitle: string;
        textColor: string;
        title: string;
        trackingName: string;
    }
    export interface TextBoxesBlockTextPartModel {
        text: string;
        title: string;
    }
    export interface VimeoVideoBlockModel {
        videoUrl: string;
    }
    export interface TopicSearchRequest {
        page: number;
        pageSize: number;
        term: string;
    }
    export interface TopicSearchResult {
        topics: TopicNavigationModel[];
        total: number;
    }
    export interface FacetValue {
        color: string;
        count: number;
        key: string;
        label: string;
        selected: boolean;
    }
    export interface JsonNodeOptions {
        propertyNameCaseInsensitive: boolean;
    }
    export interface FeaturedProductEnrichmentBlockModel {
        contentAlias: string;
        image: Media;
        position: number;
        productId: string;
        size: GridSizeFourthsModel;
        sku: string;
        textColor: string;
        title: string;
        useEnvironmentalImage: boolean;
    }
    export interface UspEnrichmentBlockModel {
        contentAlias: string;
        icon: Media;
        position: number;
        text: string;
        title: string;
    }
    export interface LoginRequest {
        email: string;
        password: string;
        persistent: boolean;
    }
    export interface TokenResult {
        redirectUrl: string;
        token: string;
    }
    export interface SimpleNestedContentModel {
        key: string;
        ncContentTypeAlias: string;
    }
    export enum StoreInventoryState {
        Unspecified = 0,
        NotInStock = 1,
        FewInStock = 2,
        InStock = 3
    }
    export enum RequestTypes {
        PurchasedWithBasket = 0,
        Personal = 1,
        Popular = 2,
        BasedOnContent = 3,
        PopularViewed = 4
    }
    export enum AccountEmailState {
        LoggedIn = 0,
        AccountWithEmailExists = 1,
        AccountWithEmailDoesNotExist = 2
    }
    export enum ParallaxScrolling {
        None = 0,
        Slow = 1,
        Medium = 2,
        Fast = 3
    }
    export enum HeroBannerScreenEffect {
        FadeInWholeScreen = 0,
        FadeOutWholeScreen = 1,
        FadeInOutWholeScreen = 2,
        FadeBackgroundColor = 3
    }
export namespace v4 {
    export enum LinkType {
        Url = 0,
        Reference = 1
    }
    export interface Link {
        anchorOrQuerystring?: string;
        target?: string;
        text: string;
        type: v4.LinkType;
        url: string;
    }
    export interface Formattable<T> {
        formatted: string;
        value?: T;
    }
    export interface OpeningHoursInterval {
        hours: string;
        label: string;
    }
    export interface Percentage {
        amount: number;
        formatted: string;
    }
    export interface Price {
        amount: number;
        formatted: string;
    }
    export interface ReferenceLink extends v4.Link {
        reference: Reference;
    }
    export interface UrlLink extends v4.Link {
        url: string;
    }
}
export namespace v4.Products {
    export enum AvailabilityStatus {
        Available = 0,
        AvailableOnlySoldOnline = 1,
        AvailableAtLaterDate = 2,
        NeverAvailableOnline = 3,
        NotAvailableOnline = 4,
        NotAvailableOnlySoldOnline = 5
    }
    export enum AvailabilityType {
        Green = 0,
        Yellow = 1,
        Red = 2
    }
    export enum ColorType {
        Single = 0,
        Dual = 1,
        Multi = 2
    }
    export enum DetailsType {
        KeyValues = 0,
        TextList = 1,
        Text = 2,
        Html = 3,
        Labeling = 4
    }
    export enum GenericVariantType {
        Color = 0,
        Text = 1
    }
    export enum LabelCategory {
        Commercial = 0,
        Legal = 1,
        Safety = 2
    }
    export enum LabelType {
        Default = 0,
        EnergyClass = 1
    }
    export enum Luminance {
        Light = 0,
        Dark = 1
    }
    export enum ProductRecommendationType {
        Similar = 0,
        PurchasedWith = 1,
        SameSeries = 2,
        SameBrand = 3,
        RecentlyViewed = 4,
        Personal = 5,
        PurchasedWithMultiple = 6,
        ProductsViewedAfterViewingProduct = 7,
        ProductsViewedAfterViewingContent = 8,
        PopularPurchasedProducts = 9,
        PopularViewedProducts = 10
    }
    export enum ValueType {
        String = 0,
        Strings = 1,
        Integer = 2,
        Double = 3,
        Boolean = 4,
        Download = 5,
        Measurement = 6,
        Energy = 7
    }
    export enum VariationType {
        Color = 0,
        Volume = 1,
        GlassesStrength = 2,
        ShoeSize = 3,
        SockSize = 4,
        ClothingSize = 5
    }
    export interface AdditionalInfo {
        label?: string;
        supplementaryTitle?: string;
        text?: string;
        title?: string;
    }
    export interface Details {
        header: string;
        type: v4.Products.DetailsType;
    }
    export interface KeyValue {
        key: string;
        type: v4.Products.ValueType;
    }
    export interface Label {
        caption?: string;
        customLabelText?: string;
        icon?: Media;
        id?: string;
        name: string;
        subtitle?: string;
        type: v4.Products.LabelType;
    }
    export interface AnnaAndClaraText {
        description: string;
        title: string;
    }
    export interface Availability {
        availableAt?: Date | null;
        status: v4.Products.AvailabilityStatus;
    }
    export interface AvailabilityLabel {
        availabilityType: v4.Products.AvailabilityType;
        label: string;
    }
    export interface BooleanKeyValue extends v4.Products.KeyValue {
        value: v4.Formattable<boolean>;
    }
    export interface Brand {
        iconDisplayHeight: number;
        imageUrl?: string;
        link?: v4.Link;
        media?: Media;
        name?: string;
        note?: string;
        title?: string;
        url?: string;
    }
    export interface Color {
        codes?: string[];
        luminance?: v4.Products.Luminance | null;
        name: string;
        type: v4.Products.ColorType;
    }
    export interface DefaultLabel extends v4.Products.Label {
        description: string;
        link: v4.Link;
    }
    export interface DiyProjectTeaser {
        image: Media;
        link: v4.Link;
        text: string;
        title: string;
    }
    export interface DoubleKeyValue extends v4.Products.KeyValue {
        value: number;
    }
    export interface Download {
        description: string;
        url: string;
    }
    export interface DownloadKeyValue extends v4.Products.KeyValue {
        value: v4.Products.Download;
    }
    export interface EcoChargeInfo extends v4.Products.AdditionalInfo {
        ecoChargeShareLabel?: string;
        ecoChargeSharePrice: v4.Price;
        totalPrice: v4.Price;
        totalPriceLabel?: string;
    }
    export interface Energy {
        formatted: string;
        kiloCalories: v4.Products.Measurement;
        kiloJoule: v4.Products.Measurement;
    }
    export interface EnergyClassLabel extends v4.Products.Label {
        energyClass?: v4.Products.StringKeyValue;
        energyConsumption?: v4.Products.DoubleKeyValue;
        energyLabel: Media;
        url: string;
    }
    export interface EnergyKeyValue extends v4.Products.KeyValue {
        value: v4.Products.Energy;
    }
    export interface EprelLink {
        link: string;
        title: string;
    }
    export interface Identifiers {
        brand?: string;
        categories?: string[];
        pim: number;
        series?: string;
    }
    export interface Information {
        name: string;
        sections: v4.Products.Details[];
        title: string;
        trackingId: string;
    }
    export interface IntegerKeyValue extends v4.Products.KeyValue {
        value: number;
    }
    export interface Inventory {
        description?: string;
        inStock: number;
        inStores?: v4.Products.Inventory.StoreInventory;
        productId: string;
        sku: string;
        state: InventoryState;
    }
    export interface KeyValueDetails extends v4.Products.Details {
        value: v4.Products.KeyValue[];
    }
    export interface LabelGrouping {
        category: v4.Products.LabelCategory;
        labels: v4.Products.Label[];
        name: string;
    }
    export interface LabelingDetails extends v4.Products.Details {
        value: v4.Products.DefaultLabel[];
    }
    export interface ListDetails extends v4.Products.Details {
        value: string[];
    }
    export interface Measurement {
        amount: number;
        formatted: string;
        unit: string;
    }
    export interface MeasurementKeyValue extends v4.Products.KeyValue {
        value: v4.Products.Measurement;
    }
    export interface Pricing {
        comparison?: v4.Price;
        ecoCharge?: v4.Price;
        package?: v4.Price;
        packageComparison?: v4.Price;
        packageDescriptionLabel?: string;
        unit: v4.Price;
        vat: v4.Percentage;
    }
    export interface ProductAndVariantId {
        id: string;
        variantId: string;
    }
    export interface ProductDetails {
        additionalShippingInfo: v4.Products.AdditionalInfo;
        annaAndClaraText?: v4.Products.AnnaAndClaraText;
        availability?: v4.Products.Availability;
        brand?: v4.Products.Brand;
        categoryIds: string[];
        description: string;
        directUrl: string;
        favoritedBy?: number | null;
        fullCategoryList: KeyValuePair<string, CategoryPath>[][];
        hrefLangUrls: KeyValuePair<string, string>[];
        id: string;
        identifiers: v4.Products.Identifiers;
        information?: v4.Products.Information[];
        marketNames: KeyValuePair<string, string>[];
        name: string;
        projects?: v4.Products.DiyProjectTeaser[];
        seriesId?: string;
        subtitle: string;
        tracking: v4.Products.Tracking;
        uniqueSellingPoints?: v4.Products.UniqueSellingPoint[];
        variantInfo: v4.Products.ProductVariantInfo;
        variants: v4.Products.ProductDetailsVariant[];
    }
    export interface ProductDetailsVariant {
        allowRemindMe: boolean;
        availability: v4.Products.Availability;
        color?: v4.Products.Color;
        ecoCharge: v4.Products.EcoChargeInfo;
        fetchRecommendationsOnVariantChange: boolean;
        information: v4.Products.Information[];
        inventory?: v4.Products.Inventory;
        isFavorite?: boolean | null;
        labelling?: v4.Products.LabelGrouping[];
        media: Media[];
        notForSale: boolean;
        packageQuantity?: number | null;
        pricing: v4.Products.Pricing;
        productModules: v4.Products.ProductModules.ProductModule[];
        seoTitle?: string;
        sku: string;
        sortedMedia: Media[];
        variantName?: string;
    }
    export interface ProductInventory {
        availability: v4.Products.Availability;
        id: string;
        variants: v4.Products.ProductInventoryVariant[];
    }
    export interface ProductInventoryVariant {
        availability: v4.Products.Availability;
        inventory: v4.Products.Inventory;
        sku: string;
    }
    export interface ProductRecommendation {
        brand?: v4.Products.Brand;
        id: string;
        name: string;
        subtitle?: string;
        url?: string;
        variants?: v4.Products.ProductRecommendationVariant[];
        variantType?: v4.Products.VariationType | null;
    }
    export interface ProductRecommendations {
        name: string;
        products: v4.Products.ProductRecommendation[];
        type: v4.Products.ProductRecommendationType;
    }
    export interface ProductRecommendationsIdOnly {
        name: string;
        products: v4.Products.ProductAndVariantId[];
        trackingName: string;
        type: v4.Products.ProductRecommendationType;
    }
    export interface ProductRecommendationVariant {
        availability?: v4.Products.Availability;
        color?: v4.Products.Color;
        isFavorite?: boolean | null;
        media?: Media;
        pricing?: v4.Products.Pricing;
        sku: string;
    }
    export interface ProductSimple extends v4.Search.SearchBase {
        additionalShippingInfo?: v4.Products.AdditionalInfo;
        availability: v4.Products.Availability;
        brand?: v4.Products.Brand;
        categoryIds: string[];
        directUrl: string;
        hrefLangUrls: KeyValuePair<string, string>[];
        id: string;
        mostRelevantVariant: string;
        name: string;
        subtitle?: string;
        tracking?: v4.Products.Tracking;
        variantInfo?: v4.Products.ProductVariantInfo;
        variants: v4.Products.VariantSimple[];
    }
    export interface ProductVariantInfo {
        label?: string;
        type: v4.Products.GenericVariantType;
    }
    export interface StringKeyValue extends v4.Products.KeyValue {
        value: string;
    }
    export interface StringsKeyValue extends v4.Products.KeyValue {
        value: string[];
    }
    export interface TextDetails extends v4.Products.Details {
        icons?: Media[];
        links?: v4.Link[];
        value: string;
    }
    export interface Tracking {
        brand?: string;
        categoryIds: string[];
        descriptionLength: number;
        labelling?: string;
        primaryCategoryId: string;
        shortDescription?: string;
        shortDescriptionLength: number;
    }
    export interface UniqueSellingPoint {
        link?: v4.Link;
        logo?: v4.Products.UniqueSellingPointLogo;
        text: string;
        trackingId: string;
        type: string;
    }
    export interface UniqueSellingPointLogo {
        png?: Media;
        svg?: Media;
    }
    export interface VariantSimple {
        allowRemindMe: boolean;
        availability: v4.Products.Availability;
        availabilityLabel?: v4.Products.AvailabilityLabel;
        color?: v4.Products.Color;
        directUrl: string;
        ecoCharge?: v4.Products.EcoChargeInfo;
        environmentalImage?: Media;
        eprelLink?: v4.Products.EprelLink;
        inventory: v4.Products.Inventory;
        labellingsFlattened?: v4.Products.Label[];
        media: Media[];
        packageQuantity?: number | null;
        packShotImage?: Media;
        pricing: v4.Products.Pricing;
        senseOfUrgencyLabel?: v4.Products.AvailabilityLabel;
        sku: string;
        variantName?: string;
    }
}
export namespace v4.Products.Inventory {
    export interface StoreInventory {
        description: string;
        status: v4.Products.Inventory.StoreInventory.AvailabilityStatus;
    }
}
export namespace v4.Products.Inventory.StoreInventory {
    export enum AvailabilityStatus {
        Available = 0,
        NotAvailable = 1
    }
}
export namespace v4.Products.ProductModules {
    export interface ProductModule {
        modelType: string;
    }
    export interface ProductModuleMedia {
    }
    export interface DisplayModule extends v4.Products.ProductModules.ProductModule {
        anchorLink?: string;
        backgroundColor?: string;
        description?: string;
        headerType?: string;
        imageText?: string;
        link?: v4.Link;
        media?: v4.Products.ProductModules.ProductModuleMedia[];
        modelType: string;
        productTextColor?: string;
        spacing?: SpacingModel;
        supplementaryTitle?: string;
        textColor?: string;
        title?: string;
        trackingName?: string;
    }
    export interface ImageMarker {
        image: Media;
        markers: v4.Products.ProductModules.Marker[];
    }
    export interface Marker {
        defaultActive: boolean;
        left: number;
        productId: string;
        text: string;
        top: number;
        variantSku: string;
    }
    export interface ProductModuleMediaImage extends v4.Products.ProductModules.ProductModuleMedia {
        image: Media;
        mediaType: string;
    }
    export interface ProductModuleMediaImageMarker extends v4.Products.ProductModules.ProductModuleMedia {
        imageMarker: v4.Products.ProductModules.ImageMarker;
        mediaType: string;
    }
    export interface ProductModuleMediaVimeo extends v4.Products.ProductModules.ProductModuleMedia {
        mediaType: string;
        videoUrl?: string;
        vimeoId?: string;
    }
    export interface ProductModuleMediaYouTube extends v4.Products.ProductModules.ProductModuleMedia {
        mediaType: string;
        videoUrl?: string;
        youTubeId?: string;
    }
}
export namespace v4.Search {
    export enum BatchedSearchType {
        ProductAndContent = 0,
        ProductOnly = 1,
        ContentOnly = 2
    }
    export enum ResultItemType {
        ContentResult = 0,
        ProductSimple = 1
    }
    export enum SuggestionType {
        WordCorrection = 0,
        Continuation = 1
    }
    export interface SearchBase {
        type: v4.Search.ResultItemType;
        url?: string;
    }
    export interface BatchSearchQuery {
        culture: CultureInfo;
        includeDiys: boolean;
        pageSize: number;
        relewiseUserContextRequest?: RelewiseUserContextRequest;
        searchType: v4.Search.BatchedSearchType;
        skipContents: number;
        skipProducts: number;
        sortScenario?: string;
        term?: string;
    }
    export interface BatchSearchRequest {
        includeDiys?: boolean | null;
        pageSize?: number | null;
        searchType?: number | null;
        skipContents?: number | null;
        skipProducts?: number | null;
        sortScenario?: string;
        term?: string;
    }
    export interface BatchSearchRequestModelBinder {
    }
    export interface BatchSearchResult {
        area?: string;
        effect?: Effect;
        hasMorePages: boolean;
        hitsContent: number;
        hitsProduct: number;
        hitsProductCategories: number;
        isEmpty: boolean;
        nextSkipContents: number;
        nextSkipProducts: number;
        productCategoriesResultItems?: v4.Search.ProductCategoryHit[];
        searchResultItems: v4.Search.SearchBase[];
        searchSuggestion?: v4.Search.SearchResponseSuggestion;
        sortBy: string;
        total: number;
    }
    export interface ContentResult extends v4.Search.SearchBase {
        categoryIds: string[];
        contentType?: string;
        id: string;
        media?: Media;
        name: string;
        subtitle?: string;
        teaserText?: string;
        typeLabel?: string;
    }
    export interface ProductCategoryHit {
        categoryId: string;
        displayName: string;
        hits: number;
        url: string;
    }
    export interface SearchResponseSuggestion {
        predictedHits: number;
        suggestionType?: v4.Search.SuggestionType | null;
        term: string;
    }
}
export namespace v4.Search.Prediction {
    export interface PredictionProductCategoryHit {
        categoryId: string;
        displayName: string;
        term: string;
        url: string;
    }
    export interface PredictionResponse {
        categoryHits: v4.Search.Prediction.PredictionProductCategoryHit[];
        predictions: v4.Search.Prediction.SearchTermPredictionResult[];
    }
    export interface SearchTermPredictionResult {
        correctedWordsMask: boolean[];
        expectedResultTypes: v4.Search.Prediction.SearchTermPredictionResult.ExpectedSearchTermResult[];
        rank: number;
        term: string;
        type: v4.Search.Prediction.SearchTermPredictionResult.PredictionType;
        url: string;
    }
}
export namespace v4.Search.Prediction.SearchTermPredictionResult {
    export enum EntityType {
        Product = 1,
        Variant = 2,
        ProductCategory = 3,
        Brand = 4,
        Content = 5,
        ContentCategory = 6
    }
    export enum PredictionType {
        Match = 1,
        WordContinuation = 2,
        Word = 3,
        WordSequence = 4
    }
    export interface ExpectedSearchTermResult {
        estimatedHits: number;
        type: v4.Search.Prediction.SearchTermPredictionResult.EntityType;
    }
}
export namespace v4.Stores {
    export enum OpeningHoursState {
        Open = 0,
        Closed = 1
    }
    export enum StoreSearchStatus {
        Success = 0,
        NoAddressGiven = 1,
        NoGeocodingMatch = 2
    }
    export interface Store {
        city: string;
        distance?: number | null;
        id: string;
        name: string;
        openingHours?: v4.Stores.OpeningHours;
        postalCode: string;
        street: string;
    }
    export interface StoreWithInventory extends v4.Stores.Store {
        inventory?: v4.Stores.Inventory[];
    }
    export interface CurrentState {
        description?: string;
        state?: v4.Stores.OpeningHoursState | null;
        title: string;
    }
    export interface FavoriteStore extends v4.Stores.StoreWithInventory {
        labels: v4.Stores.FavoriteStoreLabels;
    }
    export interface FavoriteStoreLabels {
        change: string;
        description: string;
    }
    export interface FavoriteStoreSearch extends v4.Stores.StoreSearch<v4.Stores.Store> {
        labels: v4.Stores.FavoriteStoreSearch.FavoriteStoreSearchLabels;
    }
    export interface Inventory {
        description?: string;
        sku: string;
        state: StoreInventoryState;
    }
    export interface OpeningHours {
        current: v4.Stores.CurrentState;
        description: string;
        weekly: v4.OpeningHoursInterval[];
    }
    export interface StoreSearch<TStore> {
        status: v4.Stores.StoreSearchStatus;
        stores: TStore[];
    }
    export interface StoresWithInventorySearch extends v4.Stores.StoreSearch<v4.Stores.StoreWithInventory> {
        labels: v4.Stores.StoresWithInventorySearch.StoreInventoryFinderLabels;
    }
}
export namespace v4.Stores.FavoriteStoreSearch {
    export interface FavoriteStoreSearchLabels {
        choose: string;
        chosen: string;
        description: string;
        searchByPostalCodeOrCity: string;
        searchYieldedNoResults: string;
    }
}
export namespace v4.Stores.Requests {
    export interface GetFavoriteStore {
        sku?: string;
    }
    export interface SearchForStores {
        address?: string;
        useGeoCodingFeatureToggle: boolean;
    }
    export interface SearchForStoresWithInventory {
        address?: string;
        sku: string;
        useGeoCodingFeatureToggle: boolean;
    }
    export interface SelectFavoriteStore {
        storeId: string;
    }
}
export namespace v4.Stores.StoresWithInventorySearch {
    export interface StoreInventoryFinderLabels {
        description: string;
        searchByPostalCodeOrCity: string;
        searchYieldedNoResults: string;
    }
}
export namespace v4.Vimeo {
    export enum VimeoResponseStatus {
        Ok = 0,
        NotFound = 1,
        InternalError = 2
    }
    export interface Size {
        height: number;
        link: string;
        linkWithPlayButton: string;
        width: number;
    }
    export interface VideoInfoResponse {
        createdTime: Date;
        description: string;
        duration: number;
        height: number;
        link: string;
        modifiedTime: Date;
        name: string;
        player_Embed_Url: string;
        responseStatus: v4.Vimeo.VimeoResponseStatus;
        uri: string;
        vimeoPictures: v4.Vimeo.VimeoPictures;
        width: number;
    }
    export interface VimeoPictures {
        active: boolean;
        link: string;
        resourceKey: string;
        sizes: v4.Vimeo.Size[];
        type: string;
        uri: string;
    }
}

