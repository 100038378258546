import { reactive } from 'vue';
import get from 'lodash-es/get';
import { styleFunctionsForThemedContent } from '@/project/shared/styleFunctionsForThemedContent';
import spaStore from '../core/spa/store/spa.store';
import makeStateDebugAccessible from '@/store/stateDebug';

interface INavState {
    pageHeaderStickyState: boolean;
    navigationActive: boolean;
}

class NavStore {
    private state: INavState = reactive({
        pageHeaderStickyState: false,
        navigationActive: false
    });

    constructor() {
        makeStateDebugAccessible('navState', this.state);

        // Will disappear when Vue supports watch outside component
        spaStore.subscribeIsLoadingSpaPage(this.onIsLoadingSpaPageChange.bind(this));
    }

    public get pageHeaderStickyState(): boolean {
        return this.state.pageHeaderStickyState;
    }

    public get hasHeroBlockAsFirstNavigation(): boolean {
        const aliasOfFirstBlock = get(this.pageJsonContent, 'content.blocks[0].alias');
        return aliasOfFirstBlock === 'heroBlock' || aliasOfFirstBlock === 'heroScrollBlock';
    }

    public get themeForNavigation(): string {
        const firstBlock = get(this.pageJsonContent, 'content.blocks[0]');
        const textColor = get(firstBlock, 'content.textColor');
        if (firstBlock && (firstBlock.alias === 'heroBlock' || firstBlock.alias === 'heroScrollBlock') && textColor) {
            return styleFunctionsForThemedContent(textColor);
        }
        return '';
    }

    public get navigationActive(): boolean {
        return this.state.navigationActive;
    }

    public setPageHeaderStickyState(toggled: boolean) {
        this.toggleNavigation(toggled);
    }

    public setNavigationStateActive(active: boolean): void {
        this.state.navigationActive = active;
    }

    public toggleNavigation(toggleNavigation: boolean) {
        this.state.pageHeaderStickyState = toggleNavigation;
    }

    private get pageJsonContent() {
        return spaStore.jsonContent;
    }

    private onIsLoadingSpaPageChange(value: boolean) {
        if (value) {
            this.setNavigationStateActive(false);
        } else {
            const bodySelector = document.querySelector('body');
            if (bodySelector) {
                bodySelector.classList.remove('prevent-scroll');
            }
        }
    }
}

export default new NavStore();
