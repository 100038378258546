<template>
    <OverlayWrapper :open="localOpen"
                    portal="overlay2"
                    @cancelled="close(false)">
        <template #header>
            <OverlayHeader 
                @close="close(false)">
                {{ $translate('store.FavoriteShopNudgeTitle') }}
            </OverlayHeader>
        </template>
        <div class="flex flex-col items-center p-24">
            <DotLottieVue
                :data="store"
                :render-config="{devicePixelRatio: 2.5}"
                autoplay
                loop
                class="h-96 w-96 md:h-120 md:w-120"/>
            <p class="mt-16 px-24 text-center font-gibson-light">
                {{ $translate('store.FavoriteShopNudgeDescription') }}
            </p>
            <button class="c-btn c-btn--new-primary c-btn--primary w-full mt-32 justify-center"
                    @click="close(true)">
                {{ $translate('store.FavoriteShopNudgeStorePick') }}
            </button>
            <button class="font-brandon-regular text-12 text-brown-80 underline hover:no-underline uppercase justify-center mt-24"
                    @click="close(false)">
                {{ $translate('store.FavoriteShopNudgeDeclineButton') }}
            </button>
        </div>
    </OverlayWrapper>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import OverlayWrapper from '@/project/shared/overlay/OverlayWrapper.vue';
import OverlayHeader from '@/project/shared/overlay/OverlayHeader.vue';
import { store } from '@/project/shared/lottie/lottiejson';

const emit = defineEmits(['close']);

const props = defineProps<{
    open: boolean;
}>();

const localOpen = ref(props.open);

watch(() => props.open, (newVal) => {
    localOpen.value = newVal;
});

const close = (accepted: boolean) => {
    emit('close', accepted);
    localOpen.value = false;
};

</script>