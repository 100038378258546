import { App } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// GSAP (https://greensock.com/) with ScrollTrigger plugin

gsap.registerPlugin(ScrollTrigger);

export function initGsap(app: App) {
    app.config.globalProperties.$gsap = gsap;
}

export default gsap;
