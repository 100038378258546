<template>
    <div class="leading-none"
         :class="themeTextColor('text-brown-60 decoration-color-brown-40')">
        <button type="button"
                class="font-light text-12"
                @click.prevent="showShippingTextOverlay()">
            <span>+</span>
            <span class="underline hover:no-underline">
                {{ props.shippingInfo.label }}
            </span>
        </button>
    </div>
</template>

<script setup lang="ts">

import { v4 } from '@/types/serverContract';
import bus from '@/core/bus';
import { OpenShippingInformationKey } from '@/project/config/constants';

const props = defineProps<{
    shippingInfo: v4.Products.AdditionalInfo
    textColor?: string
}>();

const themeTextColor = (defaultColor:string = ''): string => {
    return props.textColor === 'theme-2' ? 'text-theme-2 decoration-color-white-100' : defaultColor;
};

const showShippingTextOverlay = () => {
    bus.emit(OpenShippingInformationKey, props.shippingInfo);
};
</script>
