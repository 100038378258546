import { v4 } from '@/types/serverContract';
import { StoreOverlayMode } from '@/types/frontend';
import httpService from '@/core/http/http.service';
import serverContext from '@/core/serverContext.service';

export default async function useStoreWithInventory(mode, searchValue, selectedVariantSku?) {
    const geoCodingEnabled = serverContext.enableGeoCoding ?? false;
    const searchParam = searchValue ? `address=${searchValue}&usegeocodingfeaturetoggle=${geoCodingEnabled}` : '';
    const storeSearchPath = mode === StoreOverlayMode.StockStatus && selectedVariantSku ? `inventorysearch?sku=${selectedVariantSku}&` : 'favoritesearch?';
    const result = await httpService.get<v4.Stores.StoresWithInventorySearch>(`/store/${storeSearchPath}${searchParam}`);

    return {
        result
    };
}
