<template>
    <div class="flex flex-col py-16"
         :class="{'border-b border-sand-30' : !isSelected}">
        <StoreDetailsListItem :store="store"
                              :is-selected="isSelected"
                              :show-distance="shouldShowDistance"
                              :is-store-pickup="false"
                              :show-is-open="isSelected || props.mode === StoreOverlayMode.StockStatus"
                              @selected="selectStore">
            <template #store-name>
                <span>
                    {{ store.name }}
                </span>
            </template>
            <template #store-address>
                <p>
                    {{ props.store.street }}<br>
                    {{ props.store.postalCode }} {{ props.store.city }}
                </p>
            </template>
            <template #open-state>
                <div>
                    <div class="flex flex-col justify-end">
                        <div class="flex items-center justify-end">
                            <div class="rounded-full w-14 h-14 mr-8"
                                 :class="openStateColor"></div>
                            <p v-if="props.store.openingHours?.current.title"
                               class="leading-18 pt-2 font-gibson-regular text-12">
                                {{ props.store.openingHours?.current.title }}<br>
                            </p>
                        </div>
                        <span v-if="props.store.openingHours?.current.title"
                              class="leading-20 font-gibson-light text-14">
                            {{ props.store.openingHours?.current.description }}
                        </span>
                    </div>
                </div>
            </template>
        </StoreDetailsListItem>
        <button v-if="shouldShowOpeningHours"
                class="flex items-center"
                aria-label="Show opening hours"
                @click="setShowOpeningHours">
            <div class="text-12 text-brown-80 leading-14 mt-1 mr-8"
                 :class="{'text-14 mb-2': openingHoursExpanded}">
                {{ props.store.openingHours?.description }}
            </div>
            <cIcon v-show="!openingHoursExpanded"
                   name="chevron"
                   width="17"
                   height="17"
                   class="transform transition-all ease-in-out duration-300 mr-auto"
                   :class="[{'rotate-90': showOpeningHours}, {'-rotate-90': !showOpeningHours}]"/>
        </button>

        <Transition mode="out-in"
                    leave-active-class="animated fadeOut u-anim-dur-200"
                    enter-active-class="animated fadeIn u-anim-dur-200">
            <div v-if="openingHoursExpanded || showOpeningHours"
                 class="flex items-start">
                <StoreOpeningHours :opening-hours="props.store.openingHours"
                                   class="mb-16"/>
            </div>
        </Transition>
        <StockStatusIndicator v-if="props.store.inventory"
                              :inventory="props.store.inventory[0]"
                              class="mt-16"/>
    </div>
</template>

<script lang="ts" setup>
import { v4 } from '@/types/serverContract';
import { ref, computed } from 'vue';
import StoreDetailsListItem from '@/project/shared/StoreDetailsListItem.vue';
import StoreOpeningHours from '@/project/product/StoreOpeningHours.vue';
import StockStatusIndicator from '@/project/product/StockStatusIndicator.vue';
import { StoreOverlayMode, PendingState } from '@/types/frontend';
import httpService from '@/core/http/http.service';
import userStore from '@/store/user.store';
import bus from '@/core/bus';
import { SCROLL_TO_TOP } from '../config/constants';
import trackingUtils from '../tracking/tracking.utils';

const props = defineProps<{
    store: v4.Stores.StoreWithInventory,
    mode: StoreOverlayMode,
    openingHoursExpanded?: boolean,
    status?: v4.Stores.StoreSearchStatus
}>();

const showOpeningHours = ref(false);
const state = ref(PendingState.Resolved);

const selectStore = async() => {
    state.value = PendingState.Pending;
    const payLoad = { storeId: props.store.id };
    await httpService.post('/store/favorite', payLoad);
    userStore.updateFavoriteStore(props.store.id);
    state.value = PendingState.Resolved;
    bus.emit(SCROLL_TO_TOP);
};

const selectedStore = computed(() => {
    return userStore.userFavoriteStore;
});

const isSelected = computed(() => {
    return props.store.id === selectedStore.value?.id;
});

const shouldShowOpeningHours = computed(() => {
    return props.mode === StoreOverlayMode.StockStatus || !!props.store.openingHours;
});

const shouldShowDistance = computed(():boolean => {
    return !!(props.mode === StoreOverlayMode.StorePicker && props.store.distance);
});

const openStateColor = computed(() => {
    return props.store.openingHours?.current.state === v4.Stores.OpeningHoursState.Open ? 'bg-green-10' : 'bg-red-600 opacity-25';
});

const setShowOpeningHours = () => {
    showOpeningHours.value = !showOpeningHours.value;

    const storeInventoryState = props.store?.inventory?.length ? props.store.inventory[0].state : undefined;
    trackingUtils.store.trackFoldOut(props.store.name, `Address:${props.store.street},${props.store.postalCode}`, storeInventoryState);
};

</script>
