import { createRouter, createWebHistory } from 'vue-router';
import { RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router';
import bus from '@/core/bus';
import { ensure as pageReadyForScroll, cancel as cancelPendingWaiting } from './ensure';

declare global {
    interface Window {
        spaInitialLoad: boolean;
    }
  }

preventAutoScroll();

export const SpaPageRenderedEventKey = 'SpaPageRendered';
export const RouterAfterEachEventKey = 'RouterAfterEach';

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:pathMatch(.*)*', // Catch-all route
            name: 'NotFound',
            component: () => import('@/project/spa/pages/PageNotFound404Page.vue')
        }
    ],
    scrollBehavior(to: RouteLocationNormalizedLoaded, from: RouteLocationNormalized, savedPosition) {
        // Make sure an unfinished waiting to scroll is canceled first.
        cancelPendingWaiting();

        if (to.path === from.path) {
            if (to.hash) {
                if (isAnchorPresent(to.hash)) {
                    // Use timeout to allow scroll to work.
                    setTimeout(() => scrollToHash(to.hash, true), 50);
                }
            }
            // Only query or params changed - don't scroll
            return;
        }

        return new Promise((resolve) => {
            bus.once(SpaPageRenderedEventKey, async() => {
                if (to.hash) {
                    if (isAnchorPresent(to.hash)) {
                        const offsetY = window.innerWidth > 1000 ? 300 : 150;
                        resolve({ top: offsetY, left: 0 });
                        return;
                    }
                } else if (!savedPosition) {
                    // New page with no hash - scroll to top
                    resolve({ left: 0, top: 0 });
                    return;
                }

                // We have a saved position. New SPA page is now loaded but async elements may not be there yet, so page could be too short
                try {
                    const position = await pageReadyForScroll(
                        () => document.body.clientHeight >= (savedPosition?.top || 0) + window.innerHeight,
                        savedPosition
                    );
                    resolve({left: position?.left, top: position?.top});
                } catch (e) {
                    // Timeout or cancelled - dont scroll
                    resolve();
                }
            });
        });
    },

});

// For tracking to GTM, we need to send click etc events before route changes. So a setTimeout delay at 1 will do.
window.spaInitialLoad = true;

export let prevRoute: null | RouteLocationNormalized;

router.beforeEach((to, from, next) => {
    prevRoute = from;
    // if next is delayed at initial page load, it triggers spa server to fetch the page via XHR too.
    if (window.spaInitialLoad) {
        window.spaInitialLoad = false;
        next();
    } else {
        setTimeout(next, 1);
    }
});
router.afterEach((to, from) => {
    bus.emit(RouterAfterEachEventKey, {
        to,
        from
    });
});

function preventAutoScroll() {
    if ('scrollRestoration' in history) {
        try {
            history.scrollRestoration = 'manual';
        } catch (e) {
            // Ignore
        }
    }
}

// Potential initial scrolling to hash
bus.once(SpaPageRenderedEventKey, async() => {
    window.spaInitialLoad = false;
    setTimeout(() => {
        scrollToHash(router.currentRoute.value.hash);
    }, 1000);
});

let timesTried = 0;
const maxTimesToTry = 20;

function isAnchorPresent(hash) {
    const hashElement = hash && (document.querySelector(hash) as HTMLElement);

    if (hashElement) {
        return true;
    }
    else if (timesTried < maxTimesToTry) {
        timesTried++;
        setTimeout(()=> isAnchorPresent(hash), 200);
    }
    else {
        return false;
    }
}

function scrollToHash(hash, smooth: boolean = false) {
    try {
        const hashElement = hash && (document.querySelector(hash) as HTMLElement);
        if (hashElement) {
            hashElement.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto', block: 'center' });
        }
    } catch (e) {}
}


export default router;