<template>
    <article>
        <div v-if="facetGroup">
            <header :class="classHeader">
                <span>
                    {{ title }}
                </span>
            </header>
            <ul class="flex flex-wrap items-center -ml-10">
                <li v-for="facet in facetGroup.values"
                    :key="facet.key"
                    :class="classListItems">
                    <button type="button"
                            class="w-full pt-10 pb-6 relative font-light text-12 leading-sm uppercase"
                            :aria-label="facet.key"
                            :title="facet.label"
                            :class="facetButtonClass(facet)"
                            @click="handleClickFacet(facetGroup, facet)">
                        <span>
                            {{ facet.label }}
                        </span>
                        <c-icon v-if="facet.selected"
                                width="10"
                                name="close"
                                class="absolute right-10 top-1/2 -mt-5"/>
                    </button>
                </li>
            </ul>
        </div>
    </article>
</template>
<script setup lang="ts">
import urlHelper from '@/project/facets/urlHelper.service';
import { FacetGroup, FacetValue } from '@/types/serverContract';
import trackingUtils from '@/project/tracking/tracking.utils';
import translateFilter from '@/core/translation/translate.filter';

const props = defineProps({
    title: {
        type: String,
        default: translateFilter('search.Filters.Material')
    },
    facetGroup: {
        type: Object as () => FacetGroup | undefined,
        default: undefined
    },
    classHeader: {
        type: String,
        required: true
    },
    classListItems: {
        type: String,
        required: true
    },
    classListButton: {
        type: String,
        required: true
    },
    buttonBackground: {
        type: String,
        required: true
    },
    buttonSelectedBackground: {
        type: String,
        required: true
    }
});

const handleClickFacet = (facetGroup: FacetGroup, facet: FacetValue) => {
    facet.selected = !facet.selected;
    facetChange(facetGroup, facet);
};

const facetChange = (facetGroup: FacetGroup, facet: FacetValue) => {
    trackingUtils.facetFilter.trackFacetAddOrRemove(
        facet.selected,
        facetGroup.key,
        facet?.key,
        urlHelper.getFacets()
    );
    urlHelper.updateFacetValue(facetGroup.key, facet.key, facet.selected);
};

const facetButtonClass = (facet: FacetValue) => {
    return [
        props.classListButton,
        facet.selected ? props.buttonSelectedBackground : '',
        !facet.selected ? props.buttonBackground : ''
    ];
};

</script>
