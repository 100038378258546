<template>
    <button @click="showEnergyLabelOverlay">
        <ResponsiveIcon
            :image-url="icon?.url"
            :width="getIconDimension(icon?.width)"
            :height="getIconDimension(icon?.height)"
            :media-url-config="icon?.mediaUrlConfig"
            :alt="label"
            :quality="80"
            class="w-auto h-16 lg:h-24"
            format="webp"
            bg-color="transparent"/>
    </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import bus from '@/core/bus';
import { v4 } from '@/types/serverContract';
import { OpenEnergyLabelKey } from '@/project/config/constants';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';

const props = defineProps<{
    selectedVariant: v4.Products.VariantSimple
}>();

const icon = computed(() => {
    return props.selectedVariant.labellingsFlattened?.[0].icon;
});

const label = computed(() => {
    // @ts-ignore
    return `${props.selectedVariant?.labellingsFlattened?.[0].energyClass?.key} ${props.selectedVariant?.labellingsFlattened?.[0].energyClass?.value}`;
});

const getIconDimension = (dimension) => {
    return dimension ? dimension * 2 : 30;
};

function showEnergyLabelOverlay(): void {
    bus.emit(OpenEnergyLabelKey, props.selectedVariant);
}
</script>
