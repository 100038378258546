<template>
    <ul class="flex">
        <li v-for="(label, i) in labelsToShow"
            :key="i"
            class="ml-8">
            <ResponsiveIcon
                :image-url="label.icon?.url"
                :width="getIconDimension(label.icon?.width)"
                :height="getIconDimension(label.icon?.height)"
                :media-url-config="label?.icon?.mediaUrlConfig"
                :alt="label.name"
                :quality="80"
                :class="overrideStyling || 'w-auto h-16 md:h-24'"
                format="webp"
                bg-color="transparent"/>
        </li>
    </ul>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { v4 } from '@/types/serverContract';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';

const props = defineProps<{
    selectedVariant: v4.Products.VariantSimple | null;
    overrideStyling?: string
}>();

const labelsToShow = computed(() => {
    const numberOfIconsToShow = isMobile.value ? 2 : 3;
    return props.selectedVariant?.labellingsFlattened?.slice(0, numberOfIconsToShow) || [];
});

const getIconDimension = (dimension) => {
    return dimension ? dimension * 2 : 30;
};

const isMobile = computed(() => {
    return ['xs', 'sm', 'ls'].includes(breakpointsState.activeBreakpoint);
});
</script>
