<template>
    <TeleportOverlay
        v-model:show="open"
        :side="side"
        :veto-close="props.vetoClose"
        wrapper-class="c-overlay-wrapper w-full md:max-w-480 ml-auto"
        :portal="portal"
        :show-close-button="false"
        :content-auto-scroll="false"
        :disable-body-scroll="disableBodyScroll"
        @cancelled="cancelled">
        <template #default="{ scrollPosition }">
            <div ref="wrapper"
                 class="min-h-full"
                 :class="bodyBgClass">
                <div class="sticky inset-x-0 top-0 z-mobile-menu w-full bg-sand-10">
                    <slot name="header"></slot>
                </div>
                <slot></slot>
                <div class="relative px-24"
                     :class="[styling, bodyBgClass]">
                    <slot name="body"></slot>
                </div>
                <transition
                    mode="out-in"
                    enter-active-class="animated fadeIn u-anim-dur-200"
                    leave-active-class="animated fadeOut u-anim-dur-200">
                    <div
                        v-if="!scrollPosition && props.showScrollIndicator"
                        class="c-overlay-wrapper__scroll-indicator pointer-events-none fixed bottom-0 z-1 h-100 w-full text-sand-10 md:max-w-480"></div>
                </transition>
            </div>
        </template>
    </TeleportOverlay>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import BreakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';

const props = withDefaults(
    defineProps<{
        open: boolean;
        bodyBgClass?: string;
        styling?: string;
        portal?: string;
        vetoClose?:() => Promise<boolean>;
        showScrollIndicator?: boolean;
        disableBodyScroll?: boolean;
    }>(),
    {
        bodyBgClass: 'bg-sand-10',
        styling: 'py-32',
        portal: 'overlay',
        vetoClose: () => Promise.resolve(false),
        showScrollIndicator: true,
        disableBodyScroll: true
    }
);

const emit = defineEmits(['cancelled']);

const open = ref(props.open);
const wrapper = ref<HTMLElement | null>(null);
const side = ref<'bottom' | 'right'>('right');

const activeBreakpoint = computed(() => BreakpointsState.activeBreakpoint);

const cancelled = () => {
    emit('cancelled');
};

watch(
    () => props.open,
    (newValue) => {
        open.value = newValue;
    },
    { immediate: true }
);

watch(
    open,
    (newValue) => {
        const main = document.querySelector('main');
        if (!main) return;
        if (newValue) {
            main.classList.add('overflow-hidden');
        } else {
            main.classList.remove('overflow-hidden');
        }
    },
    { immediate: true }
);

watch(
    activeBreakpoint,
    () => {
        side.value = BreakpointsState.isBreakpointActive('min-md') ? 'right' : 'bottom';
    },
    { immediate: true }
);
</script>

<style>
.c-overlay-wrapper.c-overlay-wrapper {
    top: 5.6rem;
    height: calc(100vh - 5.6rem);
    height: calc(100dvh - 5.6rem);
}

.c-overlay-wrapper__scroll-indicator {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, currentColor 98%);
}

@screen md {
    .c-overlay-wrapper.c-overlay-wrapper {
        top: 0;
        height: 100vh;
        height: 100dvh;
    }
}
</style>
