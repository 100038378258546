<template>
    <div :id="id+'accordion'"
         class="accordion border-color-theme"
         :class="{ 'accordion--active': show, eightpxgrid: eightpxgrid }">
        <button type="button"
                class="header"
                :aria-label="buttonLabel"
                @click="toggleShow">
            <slot name="header">
                [Header missing, use slot="header"]
            </slot>
            <span class="header-icon">
                <c-icon
                    :width="iconWidth"
                    :height="iconHeight"
                    class="header-icon__icon"
                    :name="iconName"
                    :class="{ rotate: show, 'short-rotation': headerIconRotateShort }"/>
            </span>
        </button>
        <transition
            name="accordion"
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"
            @before-leave="beforeLeave"
            @leave="leave">
            <div v-show="show"
                 ref="accordion"
                 class="body">
                <div class="body-inner">
                    <slot/>
                </div>
                <button v-if="hasFooterSlot"
                        type="button"
                        class="footer"
                        @click="toggleShow">
                    <slot name="footer"/>
                </button>
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, useSlots } from 'vue';
import { useRoute } from 'vue-router';
import translateFilter from '@/core/translation/translate.filter';

const props = withDefaults(defineProps<{
    headerIconRotateShort?: boolean;
    iconName?: string;
    iconWidth?: string;
    iconHeight?: string;
    anchorId?: string;
    ariaLabelItemDescription?: string;
    eightpxgrid?: boolean;
}>(), {
    headerIconRotateShort: false,
    iconName: 'chevron',
    iconWidth: '25',
    iconHeight: '22',
    anchorId: undefined,
    ariaLabelItemDescription: undefined,
    eightpxgrid: false
});

const emit = defineEmits(['open', 'close', 'open-state-change']);

const show = ref(false);
const accordion = ref<HTMLElement | null>(null);

const route = useRoute();
watch(() => route.hash, (newHash) => {
    if (newHash === `#${props.anchorId}`) {
        show.value = true;
    }
}, { immediate: true });


const id = computed(() => props.anchorId ? props.anchorId : null);

const toggleShow = () => {
    if (show.value) {
        // afterEnter sets height to auto, now set it to some specific size before transition to 0px
        accordion.value!.style.height = `${accordion.value!.scrollHeight}px`;
    }
    show.value = !show.value;
    emit(show.value ? 'open' : 'close');
    emit('open-state-change');
};

const beforeEnter = (el: Element) => {
    (el as HTMLElement).style.height = '0';
};

const enter = (el: Element) => {
    (el as HTMLElement).style.height = `${(el as HTMLElement).scrollHeight}px`;
};

const afterEnter = (el: Element) => {
    // allow responsive height of element
    (el as HTMLElement).style.height = 'auto';
};

const beforeLeave = (el: Element) => {
    (el as HTMLElement).style.height = `${(el as HTMLElement).scrollHeight}px`;
};

const leave = (el: Element) => {
    (el as HTMLElement).style.height = '0';
};

const buttonLabel = computed(() => {
    if (props.ariaLabelItemDescription) {
        return translateFilter(
            show.value ? 'generic.Accordion.Close' : 'generic.Accordion.Open',
            props.ariaLabelItemDescription
        );
    }
    return translateFilter(show.value ? 'generic.Close' : 'generic.Open');
});

const slots = useSlots();
const hasFooterSlot = computed(() => 'footer' in slots);

</script>
<style lang="less">
.accordion {
    transition: border-color ease-in-out 0.2s;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    &.accordion--no-border {
        border: none 0px;
    }

    .header {
        @apply flex w-full select-none items-center text-left font-brandon text-12 font-medium uppercase;
        height: 6rem;
        padding: 0 4rem 0 1rem;
        position: relative;
        cursor: pointer;
    }

    .header-icon {
        @apply y-center;
        right: 1rem;
        transform: translateY(-50%);
    }

    .header-icon__icon {
        transform: rotate(-90deg);
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &.short-rotation {
            transform: rotate(-45deg);
        }

        &.rotate {
            transform: rotate(90deg);
            transition-duration: 0.2s;
        }
    }

    .body {
        @apply overflow-hidden;
        border-top: 0;
        transition: 0.3s ease-out;

        .prerender-is-active & {
            display: block !important;
        }
    }

    .body-inner {
        @apply select-text break-words p-10 pb-20;
    }

    .footer {
        @apply flex w-full items-center justify-center;
    }
}

.accordion.eightpxgrid {
    .header {
        padding-left: 0.8rem;
    }

    .body-inner {
        @apply p-8;
    }
}
</style>
