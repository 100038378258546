import { reactive } from 'vue';

import Api, { AsyncReturnType } from '@/project/http/api';
import serverContext from '@/core/serverContext.service';
import { isUserAddressFilledForUse } from '@/store/user.store';
import { AccountInfo, Address, Basket, BasketUpsertRequest, DropPoint, Phone } from '@/types/serverContract';
import { CheckoutPageStepIndexes } from '@/project/checkout/checkoutPageStepIndexes';
import mParticleUtils from '@/project/tracking/mParticle.utils';
import googleTagManagerUtils from '@/project/tracking/googleTagManager.utils';
import trackingUtils from '@/project/tracking/tracking.utils';
import formatPhone, { EnhancedPhone } from '@/project/shared/form/formatPhoneHelper';

type CheckoutState = 'USER' | 'NEW_USER' | 'GUEST';
type trackingState = 'new' | 'lastused' | 'edit';
type trackingOptionState = 'new' | 'lastused' | 'changed';

type DropPointsResult = AsyncReturnType<typeof Api.basket.droppoints>;

interface CheckoutStoreState {
    checkoutState: CheckoutState;
    formPending: boolean;
    stepHistory: CheckoutPageStepIndexes[];
    invoiceAddressEditMode: 'open' | 'partly-summarized' | 'summarized';
    infoState: trackingState;
    infoOptionState: trackingOptionState;
    deliveryEditMode: 'open' | 'summarized';
    deliveryState: trackingState;
    deliveryOptionState: trackingOptionState;
    blockPayment: boolean;
    paymentState: trackingState;
    paymentOptionState: trackingOptionState;
    email: string;
    phone: EnhancedPhone;
    dhlPostNumber: string | null;
    prefillStatus: 'UNKNOWN' | 'FAILED' | 'SUCCESSFUL';
    prefillAccountId: string;
    invoiceAddress: Address;
    useDeliveryAddress: boolean;
    deliveryAddress: Address | null;
    shippingMethodDeliveryAddressMap: Map<string, Address> | null;
    selectedShippingMethod: Basket['shipping']['shippingMethod'] | null;
    selectedDropPoint: DropPoint | null;
    dropPointMap: Map<string, DropPoint | null> | null;
    selectedPaymentMethodName: string;
    newsletterSignup: boolean;
}

const defaultCheckoutState: CheckoutStoreState = {
    checkoutState: 'GUEST',
    formPending: false,
    stepHistory: [],
    invoiceAddressEditMode: 'open',
    infoState: 'new',
    infoOptionState: 'new',
    deliveryEditMode: 'open',
    deliveryState: 'new',
    deliveryOptionState: 'new',
    blockPayment: false,
    paymentState: 'new',
    paymentOptionState: 'new',
    email: '',
    phone: { phonePrefix: '', number: '', formatted: '' },
    dhlPostNumber: '',
    prefillStatus: 'UNKNOWN',
    prefillAccountId: '',
    invoiceAddress: {
        city: '',
        company: '',
        countryCode: '',
        firstName: '',
        lastName: '',
        postalCode: '',
        street: '',
        title: ''
    },
    useDeliveryAddress: false,
    deliveryAddress: null,
    shippingMethodDeliveryAddressMap: null,
    selectedShippingMethod: null,
    selectedDropPoint: null,
    dropPointMap: null,
    selectedPaymentMethodName: '',
    newsletterSignup: false
};

export const checkoutStore = reactive({
    ...defaultCheckoutState,
    reset() {
        this.checkoutState = defaultCheckoutState.checkoutState;
        this.formPending = defaultCheckoutState.formPending;
        this.stepHistory = [];
        this.invoiceAddressEditMode = defaultCheckoutState.invoiceAddressEditMode;
        this.infoState = defaultCheckoutState.infoState;
        this.infoOptionState = defaultCheckoutState.infoOptionState;
        this.deliveryEditMode = defaultCheckoutState.deliveryEditMode;
        this.deliveryState = defaultCheckoutState.deliveryState;
        this.deliveryOptionState = defaultCheckoutState.deliveryOptionState;
        this.blockPayment = defaultCheckoutState.blockPayment;
        this.paymentState = defaultCheckoutState.paymentState;
        this.paymentOptionState = defaultCheckoutState.paymentOptionState;
        this.email = defaultCheckoutState.email;
        this.phone = { ...defaultCheckoutState.phone };
        this.dhlPostNumber = defaultCheckoutState.dhlPostNumber;
        this.prefillAccountId = defaultCheckoutState.prefillAccountId;
        this.invoiceAddress = { ...defaultCheckoutState.invoiceAddress };
        this.useDeliveryAddress = defaultCheckoutState.useDeliveryAddress;
        this.deliveryAddress = defaultCheckoutState.deliveryAddress;
        this.shippingMethodDeliveryAddressMap = null;
        this.selectedShippingMethod = defaultCheckoutState.selectedShippingMethod;
        this.selectedDropPoint = defaultCheckoutState.selectedDropPoint;
        this.selectedPaymentMethodName = defaultCheckoutState.selectedPaymentMethodName;
        this.newsletterSignup = defaultCheckoutState.newsletterSignup;
    },
    setCheckoutState(state: CheckoutStoreState['checkoutState']) {
        this.checkoutState = state;
    },
    activeStep() {
        return this.stepHistory[this.stepHistory.length - 1];
    },
    trackStep(step: CheckoutPageStepIndexes) {
        const selectedShippingMethodName = this.selectedShippingMethod?.name || undefined;
        const selectedPaymentMethodName = this.selectedPaymentMethodName || undefined;

        mParticleUtils.catalog.trackCheckoutStep(step);

        if (step === CheckoutPageStepIndexes.StartInvoiceAddress) {
            trackingUtils.navigation.trackCheckoutFlowNavigation('customerinfo');
            googleTagManagerUtils.basket.trackCheckoutStep(step);
        } else if (step === CheckoutPageStepIndexes.Delivery) {
            trackingUtils.navigation.trackCheckoutFlowNavigation('delivery');
            googleTagManagerUtils.basket.trackCheckoutStep(step, undefined, selectedShippingMethodName);
        } else if (step === CheckoutPageStepIndexes.Payment) {
            trackingUtils.navigation.trackCheckoutFlowNavigation('paymentchoice');
            googleTagManagerUtils.basket.trackCheckoutStep(step, undefined, selectedPaymentMethodName);
        }
    },
    addStep(newStep: CheckoutPageStepIndexes, trackStep = true) {
        if (trackStep) {
            this.trackStep(newStep);
        }

        return this.stepHistory.push(newStep);
    },
    setFormPending(pending: boolean) {
        this.formPending = pending;
    },
    setInvoiceAddressEditMode(state: CheckoutStoreState['invoiceAddressEditMode']) {
        this.invoiceAddressEditMode = state;
    },
    setInfoState(state: CheckoutStoreState['infoState']) {
        this.infoState = state;
    },
    setInfoOptionState(state: CheckoutStoreState['infoOptionState']) {
        this.infoOptionState = state;
    },
    setDeliveryEditMode(state: CheckoutStoreState['deliveryEditMode']) {
        this.deliveryEditMode = state;
    },
    setDeliveryState(state: CheckoutStoreState['deliveryState']) {
        this.deliveryState = state;
    },
    setDeliveryOptionState(state: CheckoutStoreState['deliveryOptionState']) {
        this.deliveryOptionState = state;
    },
    setBlockPayment(state: CheckoutStoreState['blockPayment']) {
        this.blockPayment = state;
    },
    setPaymentState(state: CheckoutStoreState['paymentState']) {
        this.paymentState = state;
    },
    setPaymentOptionState(state: CheckoutStoreState['paymentOptionState']) {
        this.paymentOptionState = state;
    },
    setEmail(newEmail: string | null) {
        this.email = newEmail || '';
    },
    setPhone(input: Phone) {
        this.phone = formatPhone(input, serverContext.getSelectedCountryPhone(input.phonePrefix).removeLeadingZero);
    },
    isInvoiceAddressValid() {
        return isUserAddressFilledForUse({
            email: this.email,
            phone: this.phone,
            invoice: this.invoiceAddress
        });
    },
    setInvoiceAddress(input: Partial<Address>, fallback = serverContext.market) {
        const newInvoiceAddress = {
            title: input?.title || '',
            firstName: input?.firstName || '',
            lastName: input?.lastName || '',
            company: input?.company || '',
            street: input?.street || '',
            postalCode: input?.postalCode || '',
            city: input?.city || '',
            countryCode: input?.countryCode || fallback
        };
        this.invoiceAddress = newInvoiceAddress;
    },
    setDhlPostNumber(input: string) {
        this.dhlPostNumber = input;
    },
    prefillInvoiceAddress(userInfo: AccountInfo) {
        if (this.prefillAccountId === userInfo.accountId) {
            return;
        }

        Api.basket
            .upsertBasket({
                email: userInfo.email,
                phone: userInfo.phone,
                invoiceAddress: userInfo.invoice,
                newsletterSignup: false,
                shippingMethodId: '',
                dropPoint: null
            })
            .then(() => {
                const isInvoiceAddressValid = isUserAddressFilledForUse({
                    email: userInfo.email,
                    phone: userInfo.phone,
                    invoice: userInfo.invoice
                });

                if (isInvoiceAddressValid) {
                    this.infoState = 'lastused';
                    this.infoOptionState = 'lastused';
                }

                return Api.basket.upsertBasketWithRecentShipping();
            })
            .then((basket) => {
                // try to update basket with recent shipping method
                if (basket.shipping.shippingMethod) {
                    this.deliveryState = 'lastused';
                    this.deliveryOptionState = 'lastused';
                }

                return this.updateFromBasketRequest(basket);
            })
            .then(() => {
                this.prefillStatus = 'SUCCESSFUL';
                this.prefillAccountId = userInfo.accountId;
            })
            .catch(() => (this.prefillStatus = 'FAILED'));
    },
    updateFromBasketRequest(basket: Basket) {
        this.setEmail(basket.email);
        this.setPhone(basket.phone);
        this.setInvoiceAddress(basket.invoiceAddress);
        this.setDeliveryAddress(basket.deliveryAddress);
        this.setSelectedShippingMethod(basket.shipping?.shippingMethod || null);
        this.setDhlPostNumber(basket.shipping?.dhlPostNumber || '');
        this.setSelectedDropPoint(basket.shipping?.dropPoint || null);
    },
    setUseDeliveryAddress(newValue: boolean) {
        this.useDeliveryAddress = newValue;
    },
    isValidForPrivateDelivery() {
        const addressToUse = this.useDeliveryAddress ? this.getDeliveryAddress() : this.invoiceAddress;
        if (!addressToUse) return false;

        if (addressToUse.countryCode !== serverContext.market) {
            if (!(addressToUse.countryCode === 'LI' && serverContext.market === 'CH')) {
                return false;
            }
        }

        return isUserAddressFilledForUse({
            email: this.email,
            phone: this.phone,
            invoice: addressToUse
        });
    },
    isValidForBusinessDelivery() {
        if (this.useDeliveryAddress) {
            const deliveryAddress = this.getDeliveryAddress();

            if (!deliveryAddress || !deliveryAddress.company) {
                return false;
            }

            if (deliveryAddress.countryCode !== serverContext.market) {
                return false;
            }

            return isUserAddressFilledForUse({
                email: this.email,
                phone: this.phone,
                invoice: deliveryAddress
            });
        }

        if (!this.invoiceAddress.company) {
            return false;
        }

        if (this.invoiceAddress.countryCode !== serverContext.market) {
            return false;
        }

        return this.isInvoiceAddressValid();
    },
    setDeliveryAddress(newDeliveryAddress: Address) {
        if (this.selectedShippingMethod) {
            if (!this.shippingMethodDeliveryAddressMap) {
                this.shippingMethodDeliveryAddressMap = new Map<string, Address>();
            }

            if (this.shippingMethodDeliveryAddressMap.has(this.selectedShippingMethod.id)) {
                this.shippingMethodDeliveryAddressMap.delete(this.selectedShippingMethod.id);
            }

            this.shippingMethodDeliveryAddressMap.set(this.selectedShippingMethod.id, newDeliveryAddress);
        } else {
            // fallback: set main delivery address
            this.deliveryAddress = newDeliveryAddress;
        }
    },
    getDeliveryAddress() {
        if (this.selectedShippingMethod && this.shippingMethodDeliveryAddressMap) {
            const address = this.shippingMethodDeliveryAddressMap.get(this.selectedShippingMethod.id);
            return address || null;
        }

        // fallback: read main delivery address
        return this.deliveryAddress;
    },
    setSelectedShippingMethod(selectedShippingMethod: CheckoutStoreState['selectedShippingMethod']) {
        this.selectedShippingMethod = selectedShippingMethod;
    },
    getDropPoints({ postalCode, city }: { postalCode: string; city?: string }) {
        const selectedShippingMethodId = this.selectedShippingMethod?.id;
        if (!selectedShippingMethodId) return;

        // Only use street if postalCode equals invoiceAddress.postalCode
        const street = postalCode === this.invoiceAddress.postalCode ? this.invoiceAddress.street : undefined;

        return new Promise<{
            postalCode: string;
            city?: string;
            street?: string;
            dropPoints: DropPointsResult;
        }>((resolve) => {
            Api.basket
                .droppoints(selectedShippingMethodId, postalCode, street, city)
                .then((dropPoints) =>
                    resolve({
                        postalCode,
                        city,
                        street,
                        dropPoints: dropPoints
                    })
                )
                .catch(() => {
                    resolve({
                        postalCode,
                        city,
                        street,
                        dropPoints: []
                    });
                });
        });
    },
    setSelectedDropPoint(selectedDropPoint: DropPoint | null) {
        // cache selected drop point for shipping method
        if (this.selectedShippingMethod) {
            if (!this.dropPointMap) {
                this.dropPointMap = new Map<string, DropPoint | null>();
            }
            if (this.dropPointMap.has(this.selectedShippingMethod.id)) {
                this.dropPointMap.delete(this.selectedShippingMethod.id);
            }
            this.dropPointMap.set(this.selectedShippingMethod.id, selectedDropPoint);
        }

        // set selected drop point
        this.selectedDropPoint = selectedDropPoint;
    },
    getSelectedDropPoint() {
        if (this.selectedShippingMethod && this.dropPointMap) {
            const dropPoint = this.dropPointMap.get(this.selectedShippingMethod.id);
            return dropPoint || null;
        }
        return null;
    },
    setSelectedPaymentMethodName(selectedPaymentMethodName: CheckoutStoreState['selectedPaymentMethodName']) {
        this.selectedPaymentMethodName = selectedPaymentMethodName;
    },
    setNewsletterSignup(newValue: CheckoutStoreState['newsletterSignup']) {
        this.newsletterSignup = newValue;
    },
    generatePayload(updatePayload: Partial<BasketUpsertRequest> = {}): Partial<BasketUpsertRequest> {
        const phone = formatPhone(
            this.phone,
            serverContext.getSelectedCountryPhone(this.phone.phonePrefix).removeLeadingZero
        );
        const deliveryAddress = this.getDeliveryAddress();
        const dropPoint = this.getSelectedDropPoint();

        const defaultPayload = {
            email: this.email,
            phone,
            invoiceAddress: this.invoiceAddress,
            deliveryAddress: deliveryAddress,
            shippingMethodId: this.selectedShippingMethod?.id || '',
            dhlPostNumber:
                this.selectedShippingMethod?.requiresPostNumber && this.dhlPostNumber ? this.dhlPostNumber : null,
            dropPoint: this.selectedShippingMethod?.dropPoint ? dropPoint : null,
            newsletterSignup: false
        };

        const finalPayload = Object.assign(defaultPayload, updatePayload);
        return finalPayload;
    }
});
