<template>
    <div>
        <label v-if="label"
               class="c-select__label c-select--has-selection">
            {{ label }}
        </label>
        <button type="button"
                class="c-select__select text-left border border-brown-80"
                @click="handleClickSelect">
            {{ labelForCountry(modelValue) }}
        </button>
        <TeleportOverlay v-model:show="countryOverlayActive"
                         :disable-body-scroll="true"
                         side="right"
                         wrapper-class="w-full md:max-w-480 ml-auto"
                         :show-sticky-header="true"
                         :show-blind="true"
                         @cancelled="countryOverlayActive = false">
            <div class="bg-white-100 relative z-3 min-h-screen pt-30 md:pt-40">
                <div class="md:max-w-480 mx-auto my-auto px-20 w-full">
                    <div ref="overlayContent"
                         class="pt-20 pb-40">
                        <div v-for="(countryGroup, pix) in getAllCountries"
                             :key="pix"
                             :class="{'border-t': pix !== 0}">
                            <button v-for="(cfix, ix) in countryGroup.options"
                                    :key="cfix.countryCode"
                                    class="flex w-full text-left py-10 relative items-center"
                                    :class="{'border-t border-white-500': ix !== 0, 'font-light': countryValue !== cfix.countryCode}"
                                    type="button"
                                    @click="handleChangeCountry(cfix)">
                                <div>{{ labelForCountry(cfix.countryCode) }}</div>
                                <div class="ml-auto w-20 h-20">
                                    <transition name="fadeover">
                                        <c-icon v-if="countryValue === cfix.countryCode"
                                                :key="'checked' + ix"
                                                name="radiobutton"
                                                color="currentColor"
                                                width="20"
                                                class="c-radiobutton__icon h-20"/>
                                        <c-icon v-else
                                                :key="'unchecked' + ix"
                                                name="radiobutton-unchecked"
                                                color="currentColor"
                                                width="20"
                                                class="c-radiobutton__icon h-20"/>
                                    </transition>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </TeleportOverlay>
    </div>
</template>




<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import serverContext from '@/core/serverContext.service';
import translateFilter from '@/core/translation/translate.filter';
import { Country } from '@/types/serverContract';

const props = defineProps({
    label: {
        type: String,
        required: false,
        default: ''
    },
    name: {
        type: String,
        required: true
    },
    modelValue: {
        type: String,
        required: true
    }
});

const emit = defineEmits(['change']);

const overlayContent = ref<HTMLDivElement | null>(null);
const countryOverlayActive = ref(false);
const countryValue = ref('');

watch(() => props.modelValue, (newCountry) => {
    countryValue.value = newCountry;
}, { immediate: true });

const availableCountries = computed(() => serverContext.availableCountries);

const getAllCountries = computed(() => {
    const allCountries = availableCountries.value;
    const marketCountries = allCountries.filter((c) => c.isMarket);
    const nonMarketsCountries = allCountries.filter((c) => !c.isMarket);
    return [{ label: '', options: marketCountries }, { label: '', options: nonMarketsCountries }];
});

const handleClickSelect = () => {
    countryOverlayActive.value = !countryOverlayActive.value;
    if (countryOverlayActive.value) {
        try {
            setTimeout(() => overlayContent.value && overlayContent.value.focus(), 400);
        } catch (e) {
            // Safari sometimes
        }
    }
};

const handleChangeCountry = (prefix: Country) => {
    countryValue.value = prefix.countryCode;
    emit('change', prefix.countryCode);
    setTimeout(() => {
        // Let user see changes
        countryOverlayActive.value = false;
    }, 300);
};

const labelForCountry = (countryCode: string) => {
    return `${translateFilter(`country.${countryCode}`)}`;
};
</script>
